import { useQuery } from 'react-query';
import { ReactQueryOptionsType, TaskTemplate, TaskTemplateQueryOptions } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export const fetchWorkflowsTasks = async ({ queryKey }) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}/${_params.task_template_id}`);
  return data;
};

export const useWorkflowsTasksQuery = (
  params?: TaskTemplateQueryOptions,
  reactQueryOptions?: ReactQueryOptionsType
) => {
  return useQuery<TaskTemplate, Error>(
    [API_ENDPOINTS.WORKFLOWS_TASKS, params],
    fetchWorkflowsTasks,
    reactQueryOptions
  );
};
