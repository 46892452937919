import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const updateTaskStatusMutation = () => {
  return useMutation(({ id, token, data }: { id: string; token?: string; data: any }) =>
    http.put(API_ENDPOINTS.UPDATE_TASK_STATUS(id, token), data)
  );
};

export default updateTaskStatusMutation;
