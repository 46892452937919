import { StripePricingTable } from '@components/payment/StripePricingTable';

export default function FreeTrialExpired() {
  return (
    <div className="bg-white">
      <StripePricingTable
        pricingTableId={process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID}
        publishableKey={process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}
      />
    </div>
  );
}
