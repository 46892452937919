import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { TaskResponseType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function updateTask(inputs: TaskResponseType) {
  return http.put(`${API_ENDPOINTS.TASKS}/${inputs.id}`, { milestone: inputs });
}

export const useUpdateTaskMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((inputs: TaskResponseType) => updateTask(inputs), {
    onSuccess: () => {
      queryClient.refetchQueries([API_ENDPOINTS.TASKS]);
      toastNotification({
        type: 'success',
        description: 'Task was successfully updated.'
      });
    }
  });
};
