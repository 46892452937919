import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkAddTrackingToShipmentMutation = () => {
  return useMutation((inputs: any) =>
    http.post(`${API_ENDPOINTS.BULK_ADD_SHIPMENT_TRACKING}`, inputs)
  );
};

export default bulkAddTrackingToShipmentMutation;
