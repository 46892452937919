// TODO: remove useManageXXXXX hooks because they are not breaking the react/hooks rule and creating boilerplate code
import { useTasksQuery } from '@services/api/tasks/get-all-tasks';
import { useAddTaskMutation } from '@services/api/tasks/use-add-task';
import { useBulkDeleteTasksMutation } from '@services/api/tasks/use-bulk-delete-tasks';
import { useDeleteTaskMutation } from '@services/api/tasks/use-delete-task';
import { useUpdateTaskMutation } from '@services/api/tasks/use-update-task';
import useManageParams from './use-manage-params';

const useManageTasks = () => {
  // hooks
  const { params } = useManageParams();

  // const
  const tasksQueryParams = {
    page: params?.page,
    add_task: params?.add_task,
    direction: params?.direction,
    sort: params?.sort,
    task_status: params?.task_status,
    task_assignee: params?.task_assignee,
    task_reviewer: params?.task_reviewer,
    shipment_id: params?.id
  };

  const getTasks = (removeSortParams: boolean = false) => {
    if (removeSortParams) {
      delete tasksQueryParams.direction;
      delete tasksQueryParams.sort;
      delete tasksQueryParams.task_status;
      delete tasksQueryParams.task_assignee;
      delete tasksQueryParams.task_reviewer;
    }
    const { data, isLoading, refetch } = useTasksQuery(tasksQueryParams, {
      keepPreviousData: true
    });

    const shipmentPlanId = !isLoading && data ? data.shipmentPlanId : '';
    const pagination = !isLoading && data && data.pagination;
    const dataTable = !isLoading && data ? data.data : [];
    const assignee_emails = !isLoading && data ? data.assignee_emails : [];

    return {
      data: dataTable,
      isLoading,
      refetch,
      shipmentPlanId,
      assignee_emails,
      pagination
    };
  };

  const bulkDeleteTasksMutation = () => {
    const mutation = useBulkDeleteTasksMutation();
    const { mutateAsync: bulkDeleteMutation } = mutation;

    const bulkDeleteTasks = async (inputs) => {
      try {
        await bulkDeleteMutation(inputs);
      } catch (err) {
        console.error(err);
      }
    };

    return { mutation, bulkDeleteTasks };
  };

  const deleteTaskMutation = () => {
    const mutation = useDeleteTaskMutation();
    const { mutateAsync: deleteTaskMutation } = mutation;

    const deleteTask = async (id) => {
      try {
        await deleteTaskMutation(id);
      } catch (err) {
        console.error(err);
      }
    };

    return { mutation, deleteTask };
  };

  const addOrUpdateTaskMutation = () => {
    const addTaskMutation = useAddTaskMutation();
    const updateTaskMutation = useUpdateTaskMutation();
    const { mutateAsync: addTask } = addTaskMutation;
    const { mutateAsync: updateShipmentTask } = updateTaskMutation;

    const addOrUpdateTask = async ({ inputs, isNewTask = true }) => {
      if (isNewTask) {
        await addTask(inputs);
      } else {
        await updateShipmentTask(inputs);
      }
    };

    return { addTaskMutation, updateTaskMutation, addOrUpdateTask };
  };

  return {
    tasksQueryParams,
    getTasks,
    bulkDeleteTasksMutation,
    deleteTaskMutation,
    addOrUpdateTaskMutation
  };
};

export default useManageTasks;
