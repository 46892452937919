const TooltipIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="11"
      height="11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.5 8.344v-.5a.5.5 0 0 0-.5.5h.5Zm.004 0h.5a.5.5 0 0 0-.5-.5v.5Zm0 .004v.5a.5.5 0 0 0 .5-.5h-.5Zm-.004 0H5a.5.5 0 0 0 .5.5v-.5ZM4.022 2.696a.5.5 0 1 0 .658.753l-.658-.753Zm2.627.377-.33.376.33-.376Zm0 2.01.33.377-.33-.376Zm-.363.24.218.45-.218-.45ZM5 6.719a.5.5 0 0 0 1 0H5ZM9.875 5.5A4.375 4.375 0 0 1 5.5 9.875v1A5.375 5.375 0 0 0 10.875 5.5h-1ZM5.5 9.875A4.375 4.375 0 0 1 1.125 5.5h-1A5.375 5.375 0 0 0 5.5 10.875v-1ZM1.125 5.5A4.375 4.375 0 0 1 5.5 1.125v-1A5.375 5.375 0 0 0 .125 5.5h1ZM5.5 1.125A4.375 4.375 0 0 1 9.875 5.5h1A5.375 5.375 0 0 0 5.5.125v1Zm0 7.719h.004v-1H5.5v1Zm-.496-.5v.004h1v-.004h-1Zm.5-.496H5.5v1h.004v-1Zm.496.5v-.004H5v.004h1ZM4.68 3.449c.446-.39 1.194-.39 1.64 0l.658-.753c-.823-.72-2.133-.72-2.956 0l.658.753Zm1.64 0c.407.356.407.902 0 1.258l.658.753c.863-.755.863-2.01 0-2.764l-.658.753Zm0 1.258c-.075.066-.16.122-.252.166l.436.9c.169-.082.329-.186.474-.313l-.658-.753ZM5 6.313v.406h1v-.407H5Zm1.068-1.44C5.618 5.091 5 5.563 5 6.313h1c0-.149.146-.367.504-.54l-.436-.9Z"
        fill="#99ABBA"
      />
    </svg>
  );
};

export default TooltipIcon;
