import RenderIf from '@components/common/render-if';
import Button from '@components/general/button';
import { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';

interface ImageEditorProps {
  url?: string;
  imagePreviewSizeSet?: { width: number; height: number };
  borderRadius?: number;
  isInlineEdit?: boolean;
  handleSubmit?: (file: any) => void;
}
const ImageEditor = ({
  url,
  imagePreviewSizeSet,
  borderRadius,
  isInlineEdit = false,
  handleSubmit
}: ImageEditorProps) => {
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);

  const handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const setEditorRef = (editorRef) => {
    setEditor(editorRef);
  };

  const onCropSubmit = () => {
    if (editor !== null) {
      handleSubmit?.(editor.getImageScaledToCanvas().toDataURL());
    }
  };

  const handleInlineEdit = () => {
    if (editor !== null && isInlineEdit) {
      handleSubmit?.(editor.getImageScaledToCanvas().toDataURL());
    }
    return;
  };

  return (
    <div className="flex gap-3">
      <AvatarEditor
        ref={setEditorRef}
        image={url}
        border={40}
        borderRadius={borderRadius}
        width={imagePreviewSizeSet?.width}
        height={imagePreviewSizeSet?.height}
        color={[0, 0, 0, 0.5]} // RGBA
        scale={scale}
        rotate={0}
        onImageChange={handleInlineEdit}
      />
      <div className="flex flex-col justify-between">
        <div className="flex items-center mx-2">
          <span className="mr-2">Zoom:</span>
          <input
            name="scale"
            type="range"
            onChange={handleScale}
            min={'0.1'}
            max="2"
            step="0.01"
            defaultValue="1"
          />
        </div>
        <RenderIf isTrue={!isInlineEdit}>
          <Button variant="secondary" onClick={onCropSubmit}>
            Save
          </Button>
        </RenderIf>
      </div>
    </div>
  );
};

export default ImageEditor;
