import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createUserMutation = () => {
  return useMutation((inputs: any) => http.post(API_ENDPOINTS.CREATE_USER, { user: inputs }));
};

export default createUserMutation;
