import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getProduct = ({ id }, options?: ReactQueryOptionsType): any => {
  return useQuery([API_ENDPOINTS.GET_PRODUCT(id)], queryFetch, options);
};

export default getProduct;
