import PdfIcon from '@assets/svg/PdfIcon';
import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import FileUpload from '@components/dataEntry/file-upload';

const RequestedAttachment = (props) => {
  return (
    <div>
      <div>
        <p className="text-primary-grey-500 text-sm mb-3">Required attachments</p>
        <RenderIf isTrue={!props.data?.filename_url}>
          <div className="flex gap-2 mb-2">
            <Typography className="text-gray-500">Requested filename:</Typography>
            <Typography>{props.data.document?.filename}</Typography>
          </div>
        </RenderIf>
        <RenderIf isTrue={props.data?.filename_url}>
          <div className="flex justify-between items-center">
            <a href={props.data?.filename_url} download>
              <div className="flex items-center gap-2 mb-5">
                <div className="w-8 h-8 bg-neutral-white-500 stroke flex items-center justify-center">
                  <PdfIcon />
                </div>
                <p className="text-primary-navy-400 text-sm link">{props.data?.filename}</p>
              </div>
            </a>
            {/* <div className="">
              <Button
                onClick={props.removeFile}
                className="h-4 w-4 ml-1 border-transparent rounded-full focus:outline-none"
                icon={<TrashIcon className="h-3 w-3 cursor-pointer" />}
              />
            </div> */}
          </div>
        </RenderIf>
        <FileUpload maxFiles={1} onChangeFile={props.onChangeFile} alignment="vertical" />
      </div>
    </div>
  );
};

export default RequestedAttachment;
