import { toastNotification } from '@hooks/toast-notification';
import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function deleteShipmentProduct(id: string) {
  return http.delete(`${API_ENDPOINTS.SHIPMENT_PRODUCTS}/${id}`);
}

export const useDeleteShipmentProductMutation = () => {
  const queryClient = useQueryClient();
  const {
    query: { id, shipmentId }
  } = useRouter();

  return useMutation((id: string) => deleteShipmentProduct(id), {
    onSuccess: () => {
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PRODUCTS, {
        queryKey: id
      });
      queryClient.refetchQueries(`${API_ENDPOINTS.SHIPMENT_PACKAGES}?shipment_id=${shipmentId}`);
      toastNotification({
        type: 'success',
        description: 'Product was successfully removed from shipment.'
      });
    }
  });
};
