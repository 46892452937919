import { useMutation } from 'react-query';

import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createGeneratedDocumentMutation = () => {
  return useMutation((data: any) => http.post(API_ENDPOINTS.GENERATED_DOCUMENTS, data), {
    onSuccess: (data) => {
      appEvent(EventsNameEnum.Document_CreateDocument, {
        document_id: data.data.document_id
      });
    }
  });
};

export default createGeneratedDocumentMutation;
