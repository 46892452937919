import CargoflipLogoIcon from '@assets/svg/CargoflipLogoIcon';
import CargoflipLogoText from '@assets/svg/CargoflipLogoText';

const CompanyLogo = ({ open, userData }) => {
  return (
    <div className={`flex gap-x-4 items-center h-10 ${!open ? 'justify-center' : 'justify-start'}`}>
      <CargoflipLogoIcon
        className={`font-bold fill-current text-primary-navy-500 ${
          userData?.cargoflip_admin ? 'text-emerald-500' : ''
        }`}
      />
      <CargoflipLogoText
        className={`h-6 font-bold fill-current text-primary-navy-500 ${
          userData?.cargoflip_admin ? 'text-emerald-500' : ''
        } ${!open ? 'hidden' : ''}`}
      />
    </div>
  );
};

export default CompanyLogo;
