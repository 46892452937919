import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import { toastNotification } from '@hooks/toast-notification';
import useManageWorkflows from '@hooks/use-manage-workflows';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const WorkflowForm = () => {
  // Set states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});

  // Set data stores
  const modalState = useStore((state) => state.modalState);
  const { addWorkflows } = useManageWorkflows();

  // State variables
  const modalMode = modalState?.[0]?.mode;
  const workflowId = modalState?.[0]?.entityId;
  const isNewWorkflow = modalMode === 'add';
  const headerLabel = modalMode === 'edit' ? 'Edit Workflow' : 'Add Workflow';

  // hooks
  const router = useRouter();
  const { closeModalByPosition } = useModal();
  const { mutateAsync: updateWorkflowMutation, isLoading: updateWorkflowLoading } =
    useRequest.workflows.update(workflowId);

  // Queries
  const {
    data: { data: workflowDetails = {} } = {},
    isLoading: workflowDetailsLoading,
    isSuccess
  } = useRequest.workflows.getById(
    { id: workflowId },
    { options: { keepPreviousData: true, enabled: !!workflowId } }
  );

  const defaultValues = {
    id: workflowDetails?.data?.id,
    name: modalMode === 'edit' ? workflowDetails?.data?.name : '',
    description: modalMode === 'edit' ? workflowDetails?.data?.description : ''
  };

  // form validation
  const validationSchema = yup.object({
    name: yup.string().required('Please enter a name for this workflow.')
  });

  const fullLoading = workflowDetailsLoading;

  // actions
  const queryClient = useQueryClient();

  const onSubmit = async (inputs: any) => {
    try {
      if (isNewWorkflow) {
        const { data } = await addWorkflows({ inputs });
        closeModalByPosition();
        router.push(`workflows/${data.workflow_id}?add_task=true`);
      } else {
        await updateWorkflowMutation(
          { plan_template: inputs },
          {
            onSuccess: () => {
              queryClient.refetchQueries([API_ENDPOINTS.WORKFLOWS]);
              queryClient.refetchQueries([`${API_ENDPOINTS.WORKFLOWS}/${inputs.id}`]);
              toastNotification({
                type: 'success',
                description: 'Workflow was successfully updated'
              });
            }
          }
        );
        closeModalByPosition();
      }
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        onEnterSubmit
        onSubmit={onSubmit}
        updateValuesAfterSuccess={isSuccess}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="grid space-y-2">
                <FormInput label="Name" containerClass="col-span-3" name="name" type="text" />
                <FormInput
                  type="textarea"
                  label="Description"
                  rows={3}
                  name="description"
                  placeholder="Add a description"
                  containerClass="col-span-3"
                  inputClassName="w-full"
                />
              </div>
              <ModalFooter
                disabled={!isDirty || updateWorkflowLoading}
                onClick={onSubmitForm(onSubmit)}
                modalId={ModalIds.WORKFLOW_FORM}
                mainButtonText={isNewWorkflow ? 'Add' : 'Update'}
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default WorkflowForm;
