function Drawer(props) {
  return (
    <div
      className={`top-0 right-0 w-[35vw] bg-white  fixed h-full z-100  ease-in-out duration-300 ${
        props.open ? 'translate-x-0 ' : 'translate-x-full'
      }`}
    >
      <div className="flex h-full shadow-xl">
        <div className="flex-1 overflow-y-scroll">{props.children}</div>
      </div>
    </div>
  );
}

export default Drawer;
