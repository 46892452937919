import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function updateShipmentPackage(inputs: any) {
  const id = inputs.package.id;
  delete inputs.package.id;
  return http.put(`${API_ENDPOINTS.SHIPMENT_PACKAGES}/${id}`, inputs);
}

export const useUpdateShipmentPackageMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((inputs: any) => updateShipmentPackage(inputs), {
    onSuccess: () => {
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PACKAGES);
      toastNotification({
        type: 'success',
        description: 'Package updated for shipment.'
      });
    }
  });
};
