import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

const uploadTaskDocumentMutation = () => {
  return useMutation(({ id, token, data }: { id: string; token: string; data: any }) =>
    http.post(
      API_ENDPOINTS.UPLOAD_TASK_DOCUMENT(id, token),
      jsonToFormData({ schema: 'document', inputs: data })
    )
  );
};

export default uploadTaskDocumentMutation;
