import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface UpdateGeneratedDocumentType {
  id: string;
  fields: any;
  document_template_id?: string;
}

const updateGeneratedDocumentMutation = (props?: any) => {
  return useMutation((data: any) =>
    http.put(API_ENDPOINTS.UPDATE_GENERATED_DOCUMENT(data.id || props?.id), {
      generated_document: data
    })
  );
};

export default updateGeneratedDocumentMutation;
