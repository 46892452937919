export enum PDF_ACTION_TYPE {
  UPDATE = 'UPDATE',
  DOWNLOAD = 'DOWNLOAD',
  CREATE = 'CREATE'
}

const pdfStateInitialValues = {
  loading: false,
  id: '',
  action: PDF_ACTION_TYPE.UPDATE,
  status: '',
  templateId: null,
  showNotification: false,
  redirectTo: null,
  error: false,
  documentUrl: null,
  documentData: null
};

export interface PdfState {
  loading: boolean;
  id: string;
  action: PDF_ACTION_TYPE;
  status: string;
  templateId: string | null;
  showNotification: boolean;
  redirectTo: string | null;
  error: boolean;
  documentUrl: string | null;
}

const initialGeneratedDocumentState = {
  selectedGeneratedDocumentIDs: [],
  productToShipmentModalId: '',
  shipmentIdOnGeneratedDocument: '',
  isAddStamp: false,
  TemplateIdForTemplatePage: null,
  pdfState: {
    loading: false,
    id: '',
    action: PDF_ACTION_TYPE.UPDATE,
    status: '',
    templateId: null,
    showNotification: false,
    redirectTo: null,
    error: false,
    documentUrl: null
  }
};

export const createGeneratedDocumentSlice = (set) => ({
  ...initialGeneratedDocumentState,
  setSelectedGeneratedDocumentIDs: (ids) =>
    set(() => ({ selectedGeneratedDocumentIDs: ids }), false, 'setSelectedGeneratedDocumentIDs'),
  setAddProductToShipmentModalId: (id) =>
    set(() => ({ productToShipmentModalId: id }), false, 'setAddProductToShipmentModalId'),
  setShipmentIdOnGeneratedDocument: (id) =>
    set(() => ({ shipmentIdOnGeneratedDocument: id }), false, 'setShipmentOnGeneratedDocument'),
  setTemplateIdForTemplatePage: (id) =>
    set(() => ({ TemplateIdForTemplatePage: id }), false, 'setShipmentOnGeneratedDocument'),
  setIsAddStamp: (value) => set(() => ({ isAddStamp: value }), false, 'setIsAddStamp'),
  setPdfState: (update) =>
    set(
      () => ({ pdfState: { ...initialGeneratedDocumentState.pdfState, ...update } }),
      false,
      'setPdfState'
    ),
  setPdfStateInitialValues: () =>
    set(() => ({ pdfState: pdfStateInitialValues }), false, 'setPdfStateInitialValues')
});
