import { DocumentTemplate } from '../base';
import { FeatureLabelOptions } from '../types';

const TemplateBaseOptions: FeatureLabelOptions = {
  customLabel: null,
  hidden: false,
  disabledOptions: false,
  required: false
};

export type BuildTemplateSchemaType = { data; schema; tableOptions?: any };

const keysThatNotRequireBeCompared = ['TableProps'];

export const normalizeName = (name: string) => name.toLocaleLowerCase().split(' ').join('_');
export const removeNoSynced = (name) => name?.replace('no_synced', '');

function objectsAreSame(x, y) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (keysThatNotRequireBeCompared.includes(propertyName)) return true;
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export const templateOptionsHaveChanged = ({ schema, baseSchema }) => {
  const hasChange = schema?.reduce((acc, x) => {
    const obj = baseSchema?.find((i) => i.name === x.name);

    acc.push(objectsAreSame(obj?.options, x?.options));
    return acc;
  }, []);
  return hasChange?.some((x) => x === false);
};

/*eslint no-unused-vars: ["error", {"args": "none"}]*/
export const buildTemplateSchema = ({ data, schema, tableOptions }: BuildTemplateSchemaType) => {
  if (schema) {
    return schema;
  } else {
    // TODO: remove after testing. No longer use base template data at all, since full schema is copied each time
    const buildSchema = DocumentTemplate[data?.template_name]?.reduce((acc, x, i) => {
      if (tableOptions) {
        if (x.name === 'product_table') {
          acc.push({
            ...x,
            options: {
              ...x.options,
              ...tableOptions
            }
          });
          return acc;
        }
      }

      acc.push({
        ...x,
        // allow name to be updated to allow synchronize fields changes done by the user
        ...schema?.[i],
        options: {
          ...TemplateBaseOptions,
          ...x.options,
          ...(schema && schema?.find((item) => item.name === x.name)?.options)
        }
      });
      return acc;
    }, []);

    return buildSchema;
  }
};

export const getInputObject = (props) => props?.schema?.find((item) => item.name === props?.name);

export const isSynchronizedField = ({ name, arrayOfStrings }) => {
  // all those workaround are not a good practice, but it's a quick solution to allow the user to synchronize fields.
  // TODO: update DB and backend to standardize the fields name
  return arrayOfStrings?.find(
    (item) =>
      item?.toLocaleLowerCase() === name?.toLocaleLowerCase() ||
      // workaround to allow, eg: shipper and shipper_id comparison
      item?.toLocaleLowerCase() === name?.concat('_id')?.toLocaleLowerCase() ||
      // workaround to allow, eg: Method of payment and method_of_payment comparison
      item?.toLocaleLowerCase() === name?.split('_').join(' ')?.toLocaleLowerCase()
  );
};

export const onHideInput = ({ name, schema }) =>
  schema?.reduce((acc, x) => {
    if (x.name === name) {
      acc.push({
        ...x,
        options: {
          ...x?.options,
          hidden: !x?.options?.hidden
        }
      });
    } else {
      acc.push(x);
    }
    return acc;
  }, []);

export const onRenameLabel = ({ name, value, schema }) => {
  if (value === '') return schema;
  const updatedSchema = schema?.reduce((acc, x) => {
    const avoidRenameLabel = !(typeof x.label === 'boolean' && x.label === false);
    if (x.name === name && avoidRenameLabel) {
      acc.push({
        ...x,
        options: {
          ...x.options,
          customLabel: value
        }
      });
    } else {
      acc.push(x);
    }
    return acc;
  }, []);
  return updatedSchema;
};

export const onUpdateInputOptionsByName = ({ inputName, newName, options, schema }) => {
  if (newName) {
    return onSwitchInputsName({
      schema,
      newIndex: schema.findIndex((x) => x.name === inputName),
      oldIndex: schema.findIndex((x) => x.name === newName),
      newName,
      options
    });
  }

  const updatedSchema = schema?.reduce((acc, x) => {
    if (x.name === inputName) {
      acc.push({
        ...x,
        options: {
          ...x.options,
          ...options
        }
      });
    } else {
      acc.push(x);
    }
    return acc;
  }, []);
  return updatedSchema;
};

export const onGetTemplateOptions = ({ schema }) =>
  schema?.reduce((acc, x) => {
    acc.push({
      name: x.name,
      ...x,
      options: {
        ...getInputObject({ name: x.name, schema })?.options
      }
    });
    return acc;
  }, []);

export const onSwitchInputsName = ({ schema, newIndex, oldIndex, newName, options }) => {
  const updatedSchema = schema?.reduce((acc, x, i) => {
    if (i === newIndex) {
      acc.push({
        ...x,
        name: schema?.[oldIndex]?.name || newName,
        label: options.label || schema?.[oldIndex]?.label || schema?.[oldIndex]?.name
      });
    } else if (i === oldIndex) {
      acc.push({
        ...x,
        name: schema?.[newIndex]?.name,
        label: schema?.[newIndex]?.label || schema?.[newIndex]?.name
      });
    } else {
      acc.push(x);
    }
    return acc;
  }, []);

  return updatedSchema;
};
