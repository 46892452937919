import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const shareDocumentMutation = () => {
  return useMutation((data: any) =>
    http.post(API_ENDPOINTS.DOCUMENT_SHARES, { document_share: data })
  );
};

export default shareDocumentMutation;
