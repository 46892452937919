import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkUploadProductsMutation = () => {
  return useMutation((data: any) =>
    http.post(API_ENDPOINTS.BULK_UPLOAD_PRODUCTS, { import_data: JSON.stringify(data) })
  );
};

export default bulkUploadProductsMutation;
