import TooltipIcon from '@assets/svg/TooltipIcon';
import RenderIf from '@components/common/render-if';
import { Dialog, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import useOnClickOutside from '@hooks/use-outside-click';
import cn from 'classnames';
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import Tooltip from '../../../../general/tooltip';

export type DotsActionButtonListType = {
  label: string;
  icon?: any;
  defaultChecked?: any;
  show: boolean;
  isCheckboxField?: boolean;
  checkboxClick?: (value) => void;
  onClick?: (data: any) => void;
  id?: string | number;
  tooltipText?: string;
  tooltipIcon?: JSX.Element;
};

interface DotsActionButtonType {
  data?: any;
  onClick?: (data: any) => void;
  footerElement?: JSX.Element;
  optionList: DotsActionButtonListType[] | ReactNode;
  actionWidth?: string;
}

const DotsActionButton: FC<DotsActionButtonType> = ({
  data,
  optionList,
  onClick,
  footerElement,
  actionWidth
}) => {
  const ref = useRef(null);
  const actionRef = useRef(null);
  const [open, setOpen] = useState(false);
  const timeRef = useRef<NodeJS.Timeout>();
  const [top, setTop] = useState('');
  const [left, setLeft] = useState('');

  const onOpenHandle = () => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
      setOpen(true);
    }
    timeRef.current = setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  useOnClickOutside(ref, () => {
    onOpenHandle();
  });

  const onClickActionMenuButton = () => {
    setOpen(!open);
    setLeft('');
    setTop('');
  };

  const onHandleClick = (item) => {
    onClickActionMenuButton();
    if (onClick && data) return onClick({ itemSelected: item, data: data });
    if (onClick) onClick(item);
  };

  const IconElement = ({ item }) => (
    <span className="inline-flex justify-center items-center">
      <item.icon className="text-gray-500 h-3.5 w-3.5 -mr-1 ml-1" strokeWidth={1} />
    </span>
  );

  const CheckboxElement = ({ item }) => (
    <>
      <input
        type="checkbox"
        defaultChecked={item.defaultChecked}
        className="form-checkbox transition duration-150 ease-in-out select-item w-4"
        onClick={() => {
          item.checkboxClick?.({ value: !item.defaultChecked, id: item.id });
          onClickActionMenuButton();
        }}
      />
      <label className="pl-2 py-2">{item.label}</label>
    </>
  );

  const TooltipElement = ({ item }) => (
    <Tooltip className="pl-1" description={item.tooltipText} align="right">
      {item.tooltipIcon ? item.tooltipIcon : <TooltipIcon />}
    </Tooltip>
  );

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const coordinates = actionRef?.current?.getBoundingClientRect();
        const containerWidth = ref?.current?.clientWidth;
        const containerHeight = ref?.current?.clientHeight;
        let left = coordinates?.x - containerWidth - 10;
        let top = 0;
        if (
          coordinates?.bottom + containerHeight <=
          (window.innerHeight || document.documentElement.clientHeight)
        ) {
          top = coordinates?.y;
        } else {
          top = coordinates?.y - containerHeight;
        }
        setTop(`${top}px`);
        setLeft(`${left}px`);
      }, 100);
    }
  }, [open, ref?.current]);

  return (
    <div
      className="self-center relative"
      key={Math.random()}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <button
        ref={actionRef}
        type="button"
        className="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none w-full justify-center"
        onClick={onClickActionMenuButton}
      >
        <span className="sr-only">Open User Options</span>
        <DotsVerticalIcon className="fill-current h-4 w-4" strokeWidth={1} />
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-20" onClose={onClickActionMenuButton}>
          <div className="flex justify-center items-center fixed left-0 top-0 w-full h-full z-50 transition-opacity duration-300 opacity-100">
            <>
              {Array.isArray(optionList) && (
                <div
                  ref={ref}
                  className={cn(
                    `absolute mt-1 z-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-sm text-primary-navy-400`,
                    {
                      invisible: !left || !top,
                      [actionWidth]: !!actionWidth,
                      'w-40': !actionWidth
                    }
                  )}
                  style={{
                    left,
                    top
                  }}
                >
                  {optionList.map((item, i) => (
                    <RenderIf key={i} isTrue={item.show}>
                      <div
                        onClick={() => !item.isCheckboxField && onHandleClick(item)}
                        className="flex items-center bg-white leading-none p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <RenderIf isTrue={item.icon}>
                          <IconElement item={item} />
                        </RenderIf>
                        <RenderIf isTrue={item.isCheckboxField}>
                          <CheckboxElement item={item} />
                          <span className="inline-flex px-4 py-1 link">{item.label}</span>
                        </RenderIf>
                        <RenderIf isTrue={item.tooltipText}>
                          <TooltipElement item={item} />
                        </RenderIf>
                      </div>
                    </RenderIf>
                  ))}
                  <RenderIf isTrue={footerElement}>
                    <div className="border-t border-primary-grey-100 px-4 py-2 flex justify-end">
                      {footerElement}
                    </div>
                  </RenderIf>
                </div>
              )}
              {!Array.isArray(optionList) && optionList}
            </>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default DotsActionButton;
