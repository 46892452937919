import RenderIf from '@components/common/render-if';
import Tooltip from '@components/general/tooltip';
import AddShipmentForm from '@components/sharing/forms/add-shipment-form';
import ContactForm from '@components/sharing/forms/contact-form';
import GeneratedDocumentForm from '@components/sharing/forms/generate-document-form';
import ProductForm from '@components/sharing/forms/product-form';
import Edit from '@components/sharing/portals/portalsBody/Task/Edit';
import {
  ArchiveIcon,
  CheckCircleIcon,
  CogIcon,
  CreditCardIcon,
  DocumentTextIcon,
  HomeIcon,
  PlusCircleIcon,
  TruckIcon,
  UsersIcon
} from '@heroicons/react/outline';
import useModal from '@hooks/use-modal';
import useOnClickOutside from '@hooks/use-outside-click';
import { ModalIds } from 'constants/modal-ids';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { useContextUI } from 'states/ui';

const navbarItemTopMap = [
  { label: 'Home', url: '/dashboard', match: 'dashboard', icon: HomeIcon },
  {
    label: 'Shipments',
    url: '/shipments',
    match: 'shipments',
    icon: TruckIcon,
    create: true,
    params: { mode: 'add', redirect: true },
    Component: <AddShipmentForm />
  },
  {
    label: 'Documents',
    url: '/documents',
    match: 'documents',
    params: { mode: 'add' },
    icon: DocumentTextIcon,
    Component: <GeneratedDocumentForm />,
    create: true
  },
  {
    label: 'Contacts',
    url: '/contacts',
    match: 'contacts',
    params: { mode: 'add' },
    icon: UsersIcon,
    create: true,
    Component: <ContactForm />
  },
  {
    label: 'Products',
    url: '/products',
    match: 'products',
    icon: ArchiveIcon,
    create: true,
    params: { mode: 'add' },
    Component: <ProductForm />
  },
  { label: 'Payments', url: '/payments', match: 'payments', icon: CreditCardIcon },
  {
    label: 'Tasks',
    url: '/tasks',
    match: 'tasks',
    params: { mode: 'add', redirect: true },
    icon: CheckCircleIcon,
    create: true,
    Component: <Edit />
  }
];

const CreateOptions = ({ open }) => {
  const [_, { openPortal }] = useContextUI();
  const { openModal } = useModal();

  const onCreateAction = (item) => {
    if (item.label === 'Shipments') {
      return openModal({ id: ModalIds.ADD_SHIPMENT_FORM, isOpen: true });
    }

    openPortal({
      portal: 'modal',
      params: item.params,
      Component: item.Component
    });
  };

  return (
    <div
      className="origin-top-right absolute mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
      style={!open ? { left: '2.75rem', bottom: 0 } : { left: '11rem', bottom: '1rem' }}
    >
      <div className="py-1" role="none">
        {navbarItemTopMap
          .filter((x) => x.create)
          .map((item, index) => (
            <div
              key={index}
              className="flex justify-start px-2 hover:bg-primary-grey-50 cursor-pointer"
              onClick={() => onCreateAction(item)}
            >
              <div className="py-2 pl-1.5">
                <item.icon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </div>
              <div>
                <span
                  className="text-gray-700 block px-3 py-2 text-sm"
                  onClick={() => onCreateAction(item)}
                >
                  {item.label}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

const NavigationItem = ({ item, open, setShowCreateOptions }) => {
  const { pathname } = useRouter();
  const ref = useRef();
  const [_, { openPortal }] = useContextUI();

  useOnClickOutside(ref, () => setShowCreateOptions(false));
  const { openModal } = useModal();

  const onCreateAction = () => {
    if (item.label === 'Shipments') {
      return openModal({ id: ModalIds.ADD_SHIPMENT_FORM, isOpen: true });
    }
    openPortal({
      portal: 'modal',
      params: item.params,
      Component: item.Component
    });
  };

  return (
    <div className="relative">
      <RenderIf isTrue={item.url}>
        <Tooltip
          description={item.label}
          className="mb-1 py-1 text-archived"
          disabled={open}
          style={{ bottom: '-0.5rem', left: '2.75rem' }}
        >
          <a className="flex has-tooltip items-center mt-2">
            <Link href={item.url || ''} passHref>
              <div
                className={`flex rounded-md py-2 cursor-pointer hover:bg-white text-gray-300 text-sm items-center gap-x-4    ${
                  pathname.includes(item.url) ? 'bg-white' : ''
                } ${!open ? 'justify-center' : 'px-2'}`}
                style={{ height: '2.25rem', width: open ? '100%' : '2.25rem' }}
              >
                <>
                  <item.icon
                    className={`w-4 h-4 ${
                      pathname.includes(item.url)
                        ? 'text-semantic-success-500'
                        : 'text-primary-navy-400'
                    }`}
                  />
                  <span
                    className={`${!open && 'hidden'} origin-left duration-200 ${
                      pathname.includes(item.url)
                        ? 'text-semantic-success-500'
                        : 'text-primary-navy-400'
                    }`}
                  >
                    {item.label}
                  </span>
                </>
              </div>
            </Link>
            <div className=" absolute tooltip right-0 pr-1 cursor-pointer" onClick={onCreateAction}>
              <RenderIf isTrue={item.create && open}>
                <PlusCircleIcon className="h-5 w-5" />
              </RenderIf>
            </div>
          </a>
        </Tooltip>
      </RenderIf>
      <RenderIf isTrue={item.click}>
        <Tooltip
          description={item.label}
          className="mb-1 py-1 text-archived"
          disabled={open || item.show}
          style={{ bottom: '-0.5rem', left: '2.75rem' }}
        >
          <li
            ref={ref}
            className={`flex rounded-md py-2 cursor-pointer hover:bg-white text-gray-300 text-sm items-center gap-x-4 mt-2 relative px-2 ${
              item.show ? 'bg-white' : ''
            } ${!open ? 'justify-center' : ''}`}
            onClick={() => item.click(!item.show)}
            style={{ height: '2.25rem', width: open ? '100%' : '2.25rem' }}
          >
            <item.icon
              className={`w-4 h-4 ${
                pathname.includes(item.url) ? 'text-semantic-success-500' : 'text-primary-navy-400'
              }`}
            />
            <span
              className={`${!open && 'hidden'} origin-left duration-200 ${
                pathname.includes(item.url) ? 'text-semantic-success-500' : 'text-primary-navy-400'
              }`}
            >
              {item.label}
            </span>
            <RenderIf isTrue={item.show}>
              <CreateOptions open={open} />
            </RenderIf>
          </li>
        </Tooltip>
      </RenderIf>
    </div>
  );
};

const Navigation = ({ open }) => {
  const [showCreateOptions, setShowCreateOptions] = useState(false);

  const navbarItemBottomMap = [
    { label: 'Create', click: setShowCreateOptions, show: showCreateOptions, icon: PlusCircleIcon },
    { label: 'Settings', url: '/settings/profile', match: '/settings', icon: CogIcon }
  ];

  return (
    <div className="grid" style={{ height: '97%' }}>
      <div className="py-4">
        {navbarItemTopMap.map((item, index) => (
          <NavigationItem
            key={index}
            item={item}
            open={open}
            setShowCreateOptions={setShowCreateOptions}
          />
        ))}
      </div>
      <div className="self-end">
        {navbarItemBottomMap.map((item, index) => (
          <NavigationItem
            key={index}
            item={item}
            open={open}
            setShowCreateOptions={setShowCreateOptions}
          />
        ))}
      </div>
    </div>
  );
};

export default Navigation;
