// TODO: remove useManageXXXXX hooks because they are not breaking the react/hooks rule and creating boilerplate code
import { usePaymentsQuery } from '@services/api/payments/get-all-payments';
import { useAddPaymentToShipmentMutation } from '@services/api/payments/use-add-payment-to-shipment';
import { useBulkDeletePaymentDetailsMutation } from '@services/api/payments/use-bulk-delete-payment-details';
import { useDeletePaymentDetailMutation } from '@services/api/payments/use-delete-payment-detail';
import { useRecordPaymentMutation } from '@services/api/payments/use-record-payment';
import { useUpdatePaymentDetailsMutation } from '@services/api/payments/use-update-payment-details';
import { useUpdatePaymentsDueDateMutation } from '@services/api/payments/use-update-payments-due-date';
import { useUpdateTotalPaymentsMutation } from '@services/api/payments/use-update-total-payments';
import { useStore } from '@store/useStore';
import useManageParams from './use-manage-params';

const useManagePayments = () => {
  // states
  const selectedShipmentPaymentIDs = useStore((state) => state.selectedShipmentPaymentIDs);

  // hooks
  const { params } = useManageParams();

  // const
  const queryParams = {
    page: params?.page,
    payment_search_query: params?.payment_search_query,
    shipment_type: params?.shipment_type,
    payment_status: params?.payment_status,
    direction: params?.direction,
    sort: params?.sort
  };

  const getAllPayments = () => {
    const { data, isLoading, refetch } = usePaymentsQuery(queryParams, {
      keepPreviousData: true
    });

    const shipmentData =
      !isLoading && data ? data.shipmentData : { importPayable: '', exportReceivable: '' };
    const pagination = !isLoading && data && data.pagination;
    const dataTable = !isLoading && data ? data.data : [];

    return {
      data: dataTable,
      isLoading,
      refetch,
      pagination,
      shipmentData
    };
  };

  const updateTotalPaymentsMutation = () => {
    const mutation = useUpdateTotalPaymentsMutation();
    const { mutateAsync: updateTotalPaymentsMutation } = mutation;

    const updateTotalPayments = async (inputs) => {
      await updateTotalPaymentsMutation(inputs);
    };

    return { mutation, updateTotalPayments };
  };

  const updatePaymentsDueDateMutation = () => {
    const mutation = useUpdatePaymentsDueDateMutation();
    const { mutateAsync: updatePaymentsDueDateMutation } = mutation;

    const updatePaymentsDueDate = async (inputs) => {
      await updatePaymentsDueDateMutation(inputs);
    };

    return { mutation, updatePaymentsDueDate };
  };

  const recordPaymentMutation = () => {
    const mutation = useRecordPaymentMutation();
    const { mutateAsync: recordPaymentMutation } = mutation;

    const recordPayment = async (inputs) => {
      await recordPaymentMutation(inputs);
    };

    return { mutation, recordPayment };
  };

  const bulkDeletePaymentDetailsMutation = () => {
    const mutation = useBulkDeletePaymentDetailsMutation();
    const { mutateAsync: bulkDeletePaymentDetailsMutation } = mutation;

    const bulkDeletePaymentDetails = async () => {
      const inputs = {
        delete_data: selectedShipmentPaymentIDs
      };

      await bulkDeletePaymentDetailsMutation(inputs);
    };

    return { mutation, bulkDeletePaymentDetails };
  };

  const deletePaymentDetailMutation = () => {
    const mutation = useDeletePaymentDetailMutation();
    const { mutateAsync: deletePaymentDetailMutation } = mutation;

    const deletePaymentDetail = async (id) => {
      await deletePaymentDetailMutation(id);
    };

    return { mutation, deletePaymentDetail };
  };

  const updatePaymentDetailsMutation = () => {
    const mutation = useUpdatePaymentDetailsMutation();
    const { mutateAsync: updatePaymentDetailsMutation } = mutation;

    const updatePaymentDetails = async (inputs) => {
      await updatePaymentDetailsMutation(inputs);
    };

    return { mutation, updatePaymentDetails };
  };

  const addPaymentToShipmentMutation = () => {
    const mutation = useAddPaymentToShipmentMutation();
    const { mutateAsync: addPaymentToShipmentMutation } = mutation;

    const addPaymentToShipment = async (inputs) => {
      await addPaymentToShipmentMutation(inputs);
    };

    return { mutation, addPaymentToShipment };
  };

  return {
    queryParams,
    getAllPayments,
    updateTotalPaymentsMutation,
    recordPaymentMutation,
    updatePaymentsDueDateMutation,
    deletePaymentDetailMutation,
    bulkDeletePaymentDetailsMutation,
    updatePaymentDetailsMutation,
    addPaymentToShipmentMutation
  };
};

export default useManagePayments;
