import { useMutation } from 'react-query';
import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

interface documentUploadParams {
  documentData: any;
  source?: string;
  token?: string;
  linkedId?: string;
}

async function uploadDocument({ documentData, source, token, linkedId }: documentUploadParams) {
  const files = documentData.file;
  delete documentData.file;

  const doc_type = documentData?.doc_type;
  delete documentData.doc_type;

  let formData = jsonToFormData({ schema: 'document', inputs: documentData });
  if (Array.isArray(files)) {
    files.map((file) => {
      formData.append('document[file][]', file);
    });
  } else {
    formData.append('document[file]', files);
  }

  if (Array.isArray(doc_type)) {
    doc_type.map((type) => {
      formData.append(`document[doc_type][]`, type);
    });
  } else {
    formData.append('document[doc_type]', doc_type);
  }

  formData.append('source', source);
  formData.append('token', token);
  formData.append('id', linkedId);

  const { data } = await http.post(`${API_ENDPOINTS.DOCUMENTS}`, formData);

  return data;
}

const uploadDocumentMutation = () => {
  return useMutation((inputs: documentUploadParams) => uploadDocument(inputs), {
    onSuccess: () => {
      appEvent(EventsNameEnum.Document_UploadDocument);
    }
  });
};

export default uploadDocumentMutation;
