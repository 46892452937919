import View from '@components/sharing/portals/portalsBody/Task/View';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { queryFetch } from '@services/api/utils/query-fetch';
import { useQueryClient } from 'react-query';
import { useContextUI } from 'states/ui';
import { toastNotification } from './toast-notification';

function replaceSpacesWithUnderscoreAndLowercase(inputString: string): string {
  return inputString.split(' ').join('_').toLowerCase();
}

const useTasksValidation = () => {
  const queryClient = useQueryClient();
  const [_, { openPortal }] = useContextUI();

  const onHandleTask = ({ taskData }) => {
    toastNotification({
      type: 'warning',
      description: 'Please complete all required fields.'
    });
    setTimeout(() => {
      openPortal({
        portal: 'drawer',
        params: { taskId: taskData.id },
        Component: <View />
      });
    }, 500);
  };

  const validateDataRequested = ({ inputs, taskData, fieldData }) => {
    const shipmentData = inputs;
    const fieldDataInputs = fieldData?.filter((x) =>
      taskData.requested_data?.find((y) => y.value === x.id)
    );

    const requiredInputs = fieldDataInputs
      .map(
        (x) =>
          shipmentData?.[replaceSpacesWithUnderscoreAndLowercase(x.name)] || shipmentData?.[x.name]
      )
      .filter(Boolean || '' || null || undefined); // remove empty values;

    return requiredInputs.length === taskData.requested_data?.length;
  };

  const validateDataFieldsRequested = async ({
    taskData,
    inputs
  }: {
    taskData: any;
    inputs?: object;
  }) => {
    const shipment = await queryClient.fetchQuery(
      [API_ENDPOINTS.GET_SHIPMENT(taskData.shipment_id)],
      queryFetch
    );

    const fieldData = await queryClient.fetchQuery(
      [API_ENDPOINTS.FIELD_DATA, { entity_type: 'Shipment' }],
      queryFetch
    );

    return validateDataRequested({
      inputs: inputs || shipment,
      taskData,
      fieldData
    });
  };

  const isTaskComplete = async ({ taskData }) => {
    const areAllShipmentFieldsFilled = await validateDataFieldsRequested({
      taskData
    });

    if (!taskData.is_file_upload && !taskData.is_data_request) {
      return true;
    }

    if (!areAllShipmentFieldsFilled && taskData.is_data_request) {
      onHandleTask({
        taskData
      });

      return false;
    }
    if (taskData?.is_file_upload && !taskData.filename_url) {
      onHandleTask({
        taskData
      });

      return false;
    }

    return true;
  };

  return {
    validateDataFieldsRequested,
    isTaskComplete
  };
};

export default useTasksValidation;
