import {
  MasterCustomFieldMappingOptionsType,
  MasterCustomFieldsResponseType,
  ReactQueryOptionsType
} from '../../types';

import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export const fetchMasterCustomFields = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}`, {
    params: _params
  });
  return data;
};

export const useMasterCustomFieldsQuery = (
  options?: MasterCustomFieldMappingOptionsType,
  reactQueryOptions?: ReactQueryOptionsType
) => {
  return useQuery<MasterCustomFieldsResponseType, Error>(
    [API_ENDPOINTS.MASTER_CUSTOM_FIELDS(options.id), options],
    fetchMasterCustomFields,
    reactQueryOptions
  );
};
