import { toastNotification } from '@hooks/toast-notification';
import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function deleteShipmentPackage(id: string) {
  return http.delete(`${API_ENDPOINTS.SHIPMENT_PACKAGES}/${id}`);
}

export const useDeleteShipmentPackageMutation = () => {
  const queryClient = useQueryClient();
  const { query } = useRouter();

  return useMutation((id: string) => deleteShipmentPackage(id), {
    onSuccess: () => {
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PACKAGES, {
        queryKey: query.id
      });
      toastNotification({
        type: 'success',
        description: 'Package was successfully removed from shipment.'
      });
    }
  });
};
