import { YEAR_MONTH_DAY_FORMAT } from '@services/constants';
import { CustomFieldMappingType } from '@services/types';
import dayjs from 'dayjs';

export const formatCustomFieldData = ({
  data,
  details,
  onlyRequired
}: {
  data: CustomFieldMappingType[];
  details: any[];
  onlyRequired?: boolean;
}) => {
  const customFields = data.reduce((acc, field) => {
    // TODO: Remove image check when custom field image upload is fixed
    if ((onlyRequired && !field.required) || field.fieldType === 'image') return acc;
    let value: string | boolean | number = '';

    switch (field.fieldType) {
      case 'boolean':
        value = details?.[field.name] || false;
        break;
      case 'contact':
      case 'shipment':
      case 'product':
      case 'image':
        value = details?.[field.name]?.id || details?.[field.name];
        break;
      case 'date':
        value = details?.[field.name]
          ? dayjs(details?.[field.name]).format(YEAR_MONTH_DAY_FORMAT)
          : '';
        break;
      default:
        value = details?.[field.name];
    }

    return {
      ...acc,
      ...{
        [field.name]: value
      }
    };
  }, {});

  return customFields;
};
