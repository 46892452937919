import * as Sentry from '@sentry/react';
import { ReactQueryOptions } from '@utils/react-query-config';
import Head from 'next/head';
import { useState } from 'react';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import UIProvider from 'states/ui';
import useSegment from 'tools/hooks/use-segment';
import { useThirdPartyApps } from 'tools/hooks/use-third-party-apps';

const isDev = process.env.NODE_ENV === 'development';

const ErrorPageRedirect = () => {
  // running only in production to allow local debuggin
  if (!isDev) window.location.href = '/error';
  return <></>;
};

function AppProviders({ children, ...props }) {
  useThirdPartyApps();
  useSegment();
  const [queryClient] = useState(() => ReactQueryOptions);
  return (
    <>
      <Head>
        <title>Cargoflip</title>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link rel="shortcut" href="/favicon.ico" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={props.pageProps.dehydratedState}>
          <Sentry.ErrorBoundary fallback={<ErrorPageRedirect />}>
            <UIProvider>{children}</UIProvider>
          </Sentry.ErrorBoundary>
        </Hydrate>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </>
  );
}

export default AppProviders;
