import Card from '@components/dataDisplay/card';
import DotsActionButton from '@components/dataDisplay/Table-v2/components/@common/DotsActionButton';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import { XIcon } from '@heroicons/react/outline';
import useManageShipments from '@hooks/use-manage-shipments';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const ShipmentDetailShareForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [defaultValues, setDefaultValues] = useState({
    share_by_user_id: '',
    reference_id: '',
    share_type: 'Shipment',
    guest_email: '',
    comment: ''
  });

  // hooks
  const { closeModalByPosition } = useModal();
  const {
    query: { id: shipmentId = '' }
  } = useRouter();
  const { shareShipmentMutation, deleteShipmentShareMutation } = useManageShipments();

  // queries
  const { data: { existing_shares = [] } = {}, refetch } = useRequest.shipments.getById(
    { id: shipmentId },
    { options: { keepPreviousData: true } }
  );
  const {
    data: { user }
  } = useRequest.user.get();

  // const
  const {
    mutation: { isLoading: shareLoading },
    shareShipment
  } = shareShipmentMutation();
  const {
    mutation: { isLoading: deleteShareLoading },
    deleteShipmentShare
  } = deleteShipmentShareMutation();
  const header = (
    <div className="flex space-x-4">
      <div className="w-3/5">Share with a guest</div>
      <div className="w-2/5">Shared With</div>
    </div>
  );
  const threeDotsOption = [{ label: 'Remove', icon: XIcon, show: true }];

  const validationSchema = yup.object({
    guest_email: yup.string().required('Guest email is required').email('Invalid email format')
  });

  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveShare = async (inputs: any) => {
    try {
      const {
        data: { id }
      } = inputs;
      window.confirm('Are you sure?') ? await deleteShipmentShare(id) : null;
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      await shareShipment(inputs);
      closeModalByPosition();
      refetch();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  useEffect(() => {
    if (user && shipmentId) {
      setDefaultValues({
        ...defaultValues,
        share_by_user_id: user.id,
        reference_id: shipmentId.toString()
      });
    }
  }, [user, shipmentId]);

  return (
    <ModalView header={header}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="flex space-x-4">
                <div className="w-3/5">
                  <div className="flex flex-wrap">
                    <div className="w-full mb-3">
                      <FormInput
                        label="Guest Email"
                        name="guest_email"
                        containerClass="col-span-3"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-full mb-3">
                      <FormInput
                        type="textarea"
                        label="Message"
                        name="comment"
                        rows={3}
                        containerClass="col-span-3"
                        placeholder={'Message line 1\nMessage line 2\nMessage line 3'}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-2/5">
                  {existing_shares.length > 0 ? (
                    <Card header="Name">
                      {existing_shares.map((share, index) => (
                        <div key={index} role="list" className="divide-y divide-gray-200">
                          <div className="p-3 bg-white text-xs text-gray-500 font-medium">
                            <div className="flex items-center justify-between">
                              <div className="truncate">{share.guest_email}</div>
                              <div className="ml-2 flex-shrink-0 flex">
                                <DotsActionButton
                                  onClick={handleRemoveShare}
                                  data={share}
                                  optionList={threeDotsOption}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Card>
                  ) : (
                    <div className="bg-white sm:rounded-lg sm:shadow">
                      <div className="p-3 bg-white text-xs text-gray-500 font-medium">
                        This shipment isn't shared yet.
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <ModalFooter
                disabled={shareLoading || deleteShareLoading || !isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.SHIPMENT_DETAIL_SHARE_FORM}
                mainButtonText="Share"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default ShipmentDetailShareForm;
