import RenderIf from '@components/common/render-if';
import Accordion from '@components/dataDisplay/accordion';
import UserDropdown from '@components/dataEntry/UserDropdown';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const GeneratedDocumentShareForm = () => {
  // states
  const modalState = useStore((state) => state.modalState);
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [addedEmails, setAddedEmails] = useState([]);
  const [shareIds, setShareIds] = useState([]);
  const selectedGeneratedDocumentIDs = useStore((state) => state.selectedGeneratedDocumentIDs);
  const setSelectedGeneratedDocumentIDs = useStore(
    (state) => state.setSelectedGeneratedDocumentIDs
  );
  const { closeModalByPosition } = useModal();
  const generatedDocumentsPaths = {
    '/documents/[id]': true,
    '/documents/[id]/edit': true,
    '/documents/[id]/pdf': true
  };

  // hooks
  const { pathname, query } = useRouter();
  const queryClient = useQueryClient();
  const { mutateAsync: shareDocument, isLoading } = useRequest.generated_documents.share();
  const { data: { data = [] } = {}, isSuccess } = useRequest.generated_documents.get({
    params: {
      pagination: false,
      ...(!generatedDocumentsPaths[pathname] && {
        shipment_id: query.id
      })
    },
    options: { keepPreviousData: false }
  });
  const { data: { data: userData = [] } = {} } = useRequest.user.getManageUsers({
    params: { pagination: false },
    options: { keepPreviousData: true }
  });

  const { data: contacts } = useRequest.contacts.get({
    params: { pagination: false },
    options: { keepPreviousData: true }
  });

  // const
  const disabledFooter = isLoading || addedEmails.length === 0;
  const generatedDocumentIds =
    selectedGeneratedDocumentIDs.length > 0
      ? selectedGeneratedDocumentIDs
      : modalState?.[0]?.entityId || [];
  const defaultValues = useMemo(
    () => ({
      documents: data?.reduce((acc, cur) => {
        if (generatedDocumentIds.includes(cur.id)) {
          acc = [...acc, { type: cur.doc_class_name, id: cur.id }];
        }
        return acc;
      }, []),
      emails: '',
      comment: ''
    }),
    [data, generatedDocumentIds]
  );
  const validationSchema = yup.object({
    emails: yup.string()
  });

  // actions
  const handleAdd = (item) => {
    if (item.email || item.value) {
      setAddedEmails([...addedEmails, item.email || item.value]);
    }
  };

  const handleRemoveSharingWith = (email) => {
    if (email) {
      setAddedEmails(addedEmails.filter((addedEmail) => addedEmail !== email));
    }
  };

  const handleRemoveSharedDocuments = (id) => {
    if (id && shareIds.length > 0) {
      setShareIds(shareIds.filter((docId) => docId !== id));
    }
  };

  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs: any) => {
      const modifiedInputs = {
        ...inputs,
        emails: [...addedEmails]
      };
      await shareDocument(modifiedInputs, {
        onSuccess: () => {
          setSelectedGeneratedDocumentIDs([]);
          inputs.documents.map((doc) => {
            queryClient.invalidateQueries([API_ENDPOINTS.GENERATED_DOCUMENTS_ACTIVITY(doc.id)]);
          });
          queryClient.invalidateQueries([API_ENDPOINTS.GENERATED_DOCUMENTS]);
          toastNotification({
            type: 'success',
            description: `${
              inputs.documents.length > 1 ? 'Documents were' : 'Document was'
            } successfully shared`
          });
          closeModalByPosition();
        }
      });
    }
  });

  const SharedDocumentsComponent = ({ id }) => {
    const doc = data?.find((data) => data.id === id) || {};
    const name = doc.document_name;
    const type = doc?.doc_type
      ?.replace(/_/g, ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    return (
      <div className="flex justify-between text-xs text-gray-500 py-2">
        <div className={`${shareIds.length > 1 ? 'w-5/6' : 'w-full'} self-center`}>
          <div className="text-sm text-primary-navy-500">{name}</div>
          <div className="flex flex-row items-center gap-x-2 text-primary-grey-500 mt-0.5">
            <span className="text-xs">{type}</span>
          </div>
        </div>
        <RenderIf isTrue={shareIds.length > 1}>
          <div className="w-1/6 flex justify-end self-center cursor-pointer">
            <span
              onClick={() => handleRemoveSharedDocuments(id)}
              className="text-semantic-danger-400"
            >
              Remove
            </span>
          </div>
        </RenderIf>
      </div>
    );
  };

  const SharingWithComponent = ({ email }) => {
    const user = userData?.find((data) => data.email === email);
    const userOrg = user?.organization || '';
    return (
      <div className="flex justify-between text-xs text-gray-500 py-0.5">
        <div className="flex py-1 w-5/6 break-all">
          <span className="inline-flex self-center justify-center h-9 w-9 px-3 rounded-full bg-primary-green-500 hover:bg-primary-green-400">
            <span className="text-2xl font-medium text-white capitalize self-center">
              {email.charAt(0)}
            </span>
          </span>
          <div className="pl-3 self-center">
            <div className="text-sm text-primary-navy-500">{email}</div>
            <RenderIf isTrue={!!userOrg}>
              <div className="flex flex-row items-center gap-x-2 text-primary-grey-500 mt-0.5">
                <span className="text-xs">{userOrg}</span>
              </div>
            </RenderIf>
          </div>
        </div>
        <div className="w-1/6 flex justify-end self-center cursor-pointer">
          <span onClick={() => handleRemoveSharingWith(email)} className="text-semantic-danger-400">
            Remove
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (generatedDocumentIds?.length > 0) {
      setShareIds(generatedDocumentIds);
    }
  }, [generatedDocumentIds]);

  const concatEmailsLists = () => {
    const userWithEmails = contacts?.data?.filter((x) => x.email) || [];
    return [...userData, ...userWithEmails];
  };

  return (
    <ModalView header="Share Document">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        errorsServerValidation={errorsServerValidation}
        onSubmit={handleSubmit}
        formId={formId}
        updateValuesAfterSuccess={isSuccess}
      >
        <div className="flex flex-col w-full gap-y-3">
          <div className="w-full">
            <UserDropdown onChange={handleAdd} data={concatEmailsLists()} addedList={addedEmails} />
          </div>
          <div className="w-full">
            <Accordion
              title="Shared documents"
              borderLess={true}
              defaultOpen={true}
              showHelperText={true}
            >
              <RenderIf isTrue={shareIds?.length > 0}>
                <div className="border-b border-common-stroke">
                  {shareIds?.map((id, index) => (
                    <SharedDocumentsComponent key={index} id={id} />
                  ))}
                </div>
                <div className="bg-white text-xs text-primary-grey-500 font-base py-2">
                  No document selected for sharing yet.
                </div>
              </RenderIf>
            </Accordion>
          </div>
          <div className="w-full">
            <FormInput
              type="textarea"
              label="Message"
              name="comment"
              rows={3}
              containerClass="col-span-3"
              placeholder={'Message line 1\nMessage line 2\nMessage line 3'}
            />
          </div>
          <div className="w-full">
            <Accordion
              title="Sharing with"
              borderLess={true}
              defaultOpen={true}
              showHelperText={true}
            >
              <RenderIf isTrue={addedEmails.length > 0}>
                <div>
                  {addedEmails.map((email, index) => (
                    <SharingWithComponent key={index} email={email} />
                  ))}
                </div>
                <div className="bg-white text-xs text-primary-grey-500 font-base py-2">
                  No email selected for sharing yet.
                </div>
              </RenderIf>
            </Accordion>
          </div>
        </div>
        <ModalFooter
          disabled={disabledFooter}
          modalId={ModalIds.GENERATE_DOCUMENT_SHARE_FORM}
          mainButtonText="Share"
          mainButtonType="submit"
          loading={isLoading}
        />
      </Form>
    </ModalView>
  );
};

export default GeneratedDocumentShareForm;
