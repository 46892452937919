import { DocumentTypesProps } from '@components/sharing/forms/upload-document-form';
import { FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import FileUpload, { FileUploadProps } from './file-upload';
import FormInputContainer from './form-input-container';
import { FormLabelType } from './form-label';

export interface FormFileUploadProps extends FileUploadProps {
  name?: string;
  label?: string;
  containerClass?: string;
  labelSize?: FormLabelType['labelSize'];
  labelClass?: string;
  isRequired?: boolean;
  DocumentTypesItem?: (props: DocumentTypesProps) => JSX.Element;
}

const FormFileUpload: FC<FormFileUploadProps> = ({
  name,
  label,
  containerClass,
  labelSize,
  accept = ['*'],
  multiple = false,
  minSizeBytes,
  maxSizeBytes,
  maxFiles,
  disabled = false,
  message,
  alignment = 'horizontal',
  DocumentTypesItem,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const containerRef = useRef();

  const onChangeFile = (files) => {
    setValue(name, files, { shouldValidate: true });
  };

  return (
    <FormInputContainer
      name={name}
      label={label}
      labelSize={labelSize}
      className={containerClass}
      containerRef={containerRef}
      {...rest}
    >
      <span ref={containerRef}>
        <FileUpload
          accept={accept}
          multiple={multiple}
          minSizeBytes={minSizeBytes}
          maxSizeBytes={maxSizeBytes}
          maxFiles={maxFiles}
          disabled={disabled}
          message={message}
          alignment={alignment}
          onChangeFile={onChangeFile}
          DocumentTypesItem={DocumentTypesItem}
        />
      </span>
    </FormInputContainer>
  );
};

export default FormFileUpload;
