import { withComponent } from '@components/@utils/withComponent';
import RenderIf from '@components/common/render-if';
import { Variant } from '@components/dataDisplay/typography';
import Button from '@components/general/button';
import { PencilIcon } from '@heroicons/react/outline';
import useOnClickOutside from '@hooks/use-outside-click';
import { useStore } from '@store/useStore';
import cn from 'classnames';
import { FC, ReactChild } from 'react';
import { useFormContext } from 'react-hook-form';
import FormError from './form-error';
import FormLabel, { FormLabelType } from './form-label';
import FormReadOnly, { FormReadOnlyProps } from './form-read-only';

export interface FormInputContainerProps {
  label?: string | boolean;
  justifyLabel?: 'start' | 'end';
  containerClass?: string;
  align?: 'block' | 'inline';
  inputClassName?: string;
  labelSize?: FormLabelType['labelSize'];
  name?: string;
  readOnly?: boolean;
  readOnlyChildren?: ReactChild;
  className?: string;
  value?: string;
  readOnlyClassName?: string;
  showError?: boolean;
  tooltipIcon?: JSX.Element;
  tooltipText?: string;
  link?: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  LabelItemAction?: any;
  capitalize?: boolean;
  typographyVariant?: Variant;
  showBlank?: boolean;
  tooltip?: ReactChild;
  errorMessageIndex?: number;
  FormReadOnlyProps?: FormReadOnlyProps;
  inlineEditable?: boolean;
  containerRef?: any;
  isRequired?: boolean;
  onInlineSubmit?: ({ name }: { name?: string }) => void;
  setInputName?: (empty: string) => void;
}

const FormInputContainer: FC<FormInputContainerProps> = ({
  name,
  containerClass,
  justifyLabel = 'start',
  label,
  labelSize,
  children,
  readOnly,
  className,
  readOnlyChildren,
  value,
  showError = true,
  tooltipIcon,
  tooltipText,
  link = '',
  LabelItemAction = false,
  typographyVariant,
  tooltip,
  errorMessageIndex = 0,
  FormReadOnlyProps,
  inlineEditable = false,
  ...rest
}) => {
  const {
    getValues,
    formState: { errors },
    readOnly: readOnlyForm
  } = useFormContext() as any;

  const fullReadOnly = readOnly || readOnlyForm;
  const formValue = getValues(name);
  const isError = showError && errors && errors[name];
  const isLabelBoolean = typeof label === 'boolean' && label === false;

  const classNameLabel = cn(className, {
    'text-left': justifyLabel === 'start',
    'text-right': justifyLabel === 'end',
    'text-semantic-success-500': inlineEditable && !readOnly
  });

  const containerClassName = cn(containerClass, {
    'flex flex-row hover:bg-primary-grey-50 group w-full cursor-pointer':
      inlineEditable && fullReadOnly
  });

  const LabelItem = withComponent(
    LabelItemAction,
    <FormLabel
      className={classNameLabel}
      label={label}
      name={name}
      labelSize={labelSize}
      readOnly={fullReadOnly}
      tooltipIcon={tooltipIcon}
      tooltipText={tooltipText}
      isRequired={rest.isRequired}
    />
  );

  const ReadOnlyProps = {
    link: link,
    className: rest.readOnlyClassName,
    value: value || formValue,
    type: rest?.type,
    capitalize: rest?.capitalize,
    showBlank: rest?.showBlank,
    typographyVariant: typographyVariant,
    ...FormReadOnlyProps
  };

  const modal = useStore((state) => state.modalState[0]);

  const onClickOutside = () => {
    if (!modal?.isOpen && inlineEditable && rest.onInlineSubmit && !fullReadOnly) {
      rest?.onInlineSubmit?.({ name });
      rest?.setInputName('');
    }
  };

  useOnClickOutside(rest.containerRef, onClickOutside);

  return (
    <RenderIf isTrue={inlineEditable}>
      <div
        className={`${containerClassName}`}
        onClick={(event) => {
          event.stopPropagation();
          rest.setInputName(name);
        }}
      >
        <div className="flex justify-between w-full">
          <div className={readOnly ? 'w-[90%]' : 'w-full'}>
            <RenderIf isTrue={!isLabelBoolean}>{LabelItem}</RenderIf>
            <RenderIf isTrue={readOnly}>
              <FormReadOnly {...ReadOnlyProps}>{readOnlyChildren}</FormReadOnly>
            </RenderIf>
            <RenderIf isTrue={readOnly && tooltip}>{tooltip}</RenderIf>
            <RenderIf isTrue={!readOnly}>
              <>{children}</>
            </RenderIf>
            <FormError
              showError={isError}
              errors={errors}
              id={name}
              errorMessageIndex={errorMessageIndex}
            />
          </div>
          <RenderIf isTrue={inlineEditable && fullReadOnly}>
            <Button
              type="button"
              className="w-6 h-6 hidden group-hover:block flex justify-center self-center bg-white rounded-md focus:outline-none focus:ring-offset-gray-100 focus:opacity-30 ml-2"
              icon={<PencilIcon className="text-primary-grey-500 h-3 w-3" />}
              defaultClass={false}
              iconWrapperClass="inline-block justify-center self-center"
            />
          </RenderIf>
        </div>
      </div>

      <div className={`${containerClass} group`}>
        <RenderIf isTrue={!isLabelBoolean}>{LabelItem}</RenderIf>

        <RenderIf isTrue={readOnly}>
          <FormReadOnly {...ReadOnlyProps}>{readOnlyChildren}</FormReadOnly>
        </RenderIf>
        <RenderIf isTrue={readOnly && tooltip}>{tooltip}</RenderIf>
        <RenderIf isTrue={!readOnly}>
          <>{children}</>
        </RenderIf>
        <FormError
          showError={isError}
          errors={errors}
          id={name}
          errorMessageIndex={errorMessageIndex}
        />
      </div>
    </RenderIf>
  );
};

export default FormInputContainer;
