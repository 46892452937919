import Typography from '@components/dataDisplay/typography';
import { ReactNode } from 'react';

interface Props {
  name: string;
  RowItem?: ReactNode;
}

const DrawerRow = ({ name, RowItem }: Props) => {
  return (
    <div className="grid grid-cols-3 justify-items-start">
      <Typography className="text-primary-grey-500 col-span-1">{name}</Typography>
      <div className="col-span-2">{RowItem}</div>
    </div>
  );
};

export default DrawerRow;
