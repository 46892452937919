import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { setCookie } from '@services/api/utils/cookies';
import { useStore } from '@store/useStore';
import { CookieList } from 'constants/cookies';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ModalFooter from '../modal-footer';

const GeneratedDocumentForm = () => {
  // hooks
  const { query, pathname, push } = useRouter();
  const pdfState = useStore((state) => state.pdfState);
  const { closeAllModals } = useModal();
  const isDocumentPage = pathname === '/documents/[id]';
  const { mutateAsync: generateNewDocument, isLoading } = useRequest.generated_documents.create();
  const { data: documentData } = useRequest.generated_documents.getById(
    { id: isDocumentPage ? query?.id : '' },
    { options: { keepPreviousData: true, enabled: !!(isDocumentPage && query?.id) } }
  );
  const shipmentId = isDocumentPage
    ? Number(documentData?.document?.shipment_id)
    : query.id
    ? Number(query.id)
    : '';

  // states
  const generatedDocumentsPaths = {
    '/documents/[id]': true,
    '/documents/[id]/edit': true
  };
  const [errorsServerValidation, setErrorsServerValidation] = useState({});

  // queries
  const { data: typeMappings } = useRequest.generated_documents.getDocTypes({
    params: {
      shipment_id: documentData?.document?.shipment_id
    }
  });

  const defaultValues = {
    shipment_id: shipmentId,
    source_document_id: generatedDocumentsPaths[pathname] ? query.id : '',
    is_html_document: true,
    document_template_id: typeMappings?.[0]?.id,
    name: typeMappings?.[0]?.name,
    document_name: ''
  };

  const handleSubmit = async (inputs: any) => {
    try {
      const selectedTemplate = typeMappings.find((item) => item.name === inputs.name);
      // eslint-disable-next-line no-unused-vars
      const { document_template_id, ...props } = inputs;
      const mergedInputs = {
        document_template_id: selectedTemplate.id,
        doc_type: selectedTemplate.template_type,
        ...props
      };
      const { data } = await generateNewDocument({
        generated_document: {
          ...mergedInputs
        }
      });
      closeAllModals();
      if (!isDocumentPage) {
        setCookie({ name: CookieList.DOCUMENT_ENTRYPOINT, value: pathname });
      }

      push(`/documents/${data.document_id}/edit`);
    } catch (err) {
      const errors = err.response?.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  return (
    <ModalView header="Create Generated Document">
      <Form
        defaultValues={defaultValues}
        errorsServerValidation={errorsServerValidation}
        onEnterSubmit
        onSubmit={handleSubmit}
        updateValuesAfterSuccess
      >
        {({ onSubmitForm }) => {
          return (
            <>
              <div className="-mx-3">
                <FormInput
                  type="select"
                  label="Template Type"
                  name="name"
                  optionsList={typeMappings}
                  containerClass="px-3 mb-3"
                />
              </div>
              <ModalFooter
                loading={pdfState.loading}
                disabled={isLoading}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.GENERATE_DOCUMENT_FORM}
                mainButtonText="Create"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default GeneratedDocumentForm;
