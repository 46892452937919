import useUser from './use-user';

export interface PermissionProps {
  allowEdit?: true;
  allowUpload?: true;
}

export interface RoleProps {
  isAdminRole?: true;
  isGuestRole?: true;
  isAdminSignedInAsAnotherUserRole?: true;
}

export interface CheckTypeProps {
  or?: boolean;
  and?: boolean;
}

export type Props = PermissionProps & CheckTypeProps & RoleProps;

// TODO Better separation of roles and permissions
const useUserPolicy = ({
  allowEdit,
  allowUpload,
  isAdminRole,
  isGuestRole,
  isAdminSignedInAsAnotherUserRole,
  or = false,
  and = false
}: Props = {}) => {
  // hooks
  const { data } = useUser();

  // roles
  const isAdminSignedInAsAnotherUser = data && data.true_user_id && data.id !== data.true_user_id;
  const isGuest = data && data.role === 'guest';
  const isAdmin = data && data.role === 'admin';

  // permissions
  const isUploadPermitted = data && data.is_upload_permitted;
  const isEditAccess = data && data.is_edit_access;

  // const
  const multipleCheck = or || and;
  const mapping = {
    allowEdit: isEditAccess,
    allowUpload: isUploadPermitted,
    isAdminRole: isAdmin,
    isGuestRole: isGuest,
    isAdminSignedInAsAnotherUserRole: isAdminSignedInAsAnotherUser
  };
  const validate = {
    allowEdit,
    allowUpload,
    isAdminRole,
    isGuestRole,
    isAdminSignedInAsAnotherUserRole
  };
  let allow = false;

  switch (true) {
    case multipleCheck:
      const evaluateOrOperation = (args) =>
        args.reduce((acc, cur) => {
          if (typeof validate[cur] !== 'undefined') {
            return acc || mapping[cur];
          }
          return acc;
        }, false);
      const evaluateAndOperation = (args) =>
        args.reduce((acc, cur) => {
          if (typeof validate[cur] !== 'undefined') {
            return acc && mapping[cur];
          }
          return acc;
        }, true);

      allow = or
        ? evaluateOrOperation(Object.keys(validate))
        : and
        ? evaluateAndOperation(Object.keys(validate))
        : false;
      break;
    case allowEdit:
      allow = isEditAccess;
      break;
    case allowUpload:
      allow = isUploadPermitted;
      break;
    case isAdminRole:
      allow = isAdmin;
      break;
    case isGuestRole:
      allow = !isGuest;
      break;
    case isAdminSignedInAsAnotherUserRole:
      allow = isAdminSignedInAsAnotherUser;
      break;
    default:
      allow = false;
  }

  return {
    isAdminSignedInAsAnotherUser,
    isGuest,
    isUploadPermitted,
    isEditAccess,
    isAdmin,
    allow
  };
};

export default useUserPolicy;
