const ArrowUpRightIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="12"
      height="12"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1 11 11 1m0 0H3.5M11 1v7.5"
      />
    </svg>
  );
};

export default ArrowUpRightIcon;
