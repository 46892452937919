import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface BulkUpdateFieldDataType {
  ids_to_update: string;
  update_data: string;
  visible_fields?: string;
  entity_type?: string;
}

const bulkUpdateFieldData = () => {
  return useMutation((data: BulkUpdateFieldDataType) =>
    http.put(`${API_ENDPOINTS.FIELD_DATA}/bulk_update`, data)
  ) as any;
};

export default bulkUpdateFieldData;
