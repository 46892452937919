import { useContextUI } from 'states/ui';
import Drawer from './Drawer';
import Modal from './Modal';

function Portals() {
  const [{ Component, portal }] = useContextUI();
  const isDrawer = portal === 'drawer' && Boolean(Component);
  const isModal = portal === 'modal' && Boolean(Component);

  if (isModal) {
    return <Modal open={!!Component}>{Component}</Modal>;
  }

  return <Drawer open={isDrawer}>{Component}</Drawer>;
}

export default Portals;
