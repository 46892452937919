export const formatSelectKeyValueList = ({
  data,
  nameKey,
  valueKey
}: {
  data: any[];
  nameKey: string;
  valueKey: string;
}) =>
  data.map((cur) => ({
    name: cur[nameKey],
    value: cur[valueKey]
  }));
