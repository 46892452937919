import Button from '@components/general/button';
import Tooltip from '@components/general/tooltip';
import { CheckIcon } from '@heroicons/react/outline';
import { toastNotification } from '@hooks/toast-notification';
import useManageParams from '@hooks/use-manage-params';
import useTasksValidation from '@hooks/use-task-validation';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

const TaskButton = () => {
  // hooks
  const { params } = useManageParams();

  const taskId = params?.taskId || params?.id;
  // queries
  const { data } = useRequest.tasks.getById({ id: taskId });
  const { mutateAsync: completeTaskMutation, isLoading } = useRequest.tasks.complete();

  const queryClient = useQueryClient();

  // states
  const [isCompleted, setIsCompleted] = useState(data?.is_finished);
  const { isTaskComplete } = useTasksValidation();

  const toggleTaskComplete = async () => {
    if (!taskId) return;
    const isTaskCompleted = await isTaskComplete({ taskData: data });

    const inputs = {
      id: taskId,
      data: {
        is_milestone_completed: !isCompleted && isTaskCompleted ? true : false
      }
    };
    await completeTaskMutation(inputs, {
      onSuccess: () => {
        queryClient.invalidateQueries([API_ENDPOINTS.GET_TASK_BY_ID(taskId)]);
        queryClient.invalidateQueries([API_ENDPOINTS.TASKS]);
        if (isTaskCompleted)
          toastNotification({
            type: 'success',
            description: 'Task was successfully updated.'
          });
      },
      onError: () => {
        toastNotification({
          type: 'error',
          description: 'Something went wrong'
        });
      }
    });
  };

  useEffect(() => {
    if (!data) return;
    setIsCompleted(data?.is_finished);
  }, [data]);

  useEffect(() => {
    if (data?.status === 'Completed') return;
    setIsCompleted(false);
  }, [data]);

  if (isCompleted) {
    return (
      <Tooltip description={'Mark as undone'}>
        <Button
          loading={isLoading}
          variant="primary"
          icon={<CheckIcon />}
          onClick={toggleTaskComplete}
        >
          Completed
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      className="border border-semantic-success-500 text-semantic-success-500 hover:opacity-50 rounded-md"
      icon={<CheckIcon />}
      loading={isLoading}
      onClick={toggleTaskComplete}
    >
      Mark Completed
    </Button>
  );
};

export default TaskButton;
