import {
  ReactQueryOptionsType,
  ShipmentDetailsTabsQueryOptionsType,
  ShipmentProductsResponseType
} from '../../types';

import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { queryString } from '../utils/query-string';

export const fetchShipmentProducts = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}${queryString(_params)}`);
  return data;
};

export const useShipmentProductsQuery = (
  options?: ShipmentDetailsTabsQueryOptionsType,
  reactQueryOptions?: ReactQueryOptionsType
) => {
  return useQuery<ShipmentProductsResponseType, Error>(
    [API_ENDPOINTS.SHIPMENT_PRODUCTS, options],
    fetchShipmentProducts,
    reactQueryOptions
  );
};
