export const packingListData = [
  {
    label: 'Marks',
    name: 'marks',
    type: 'text',
    readOnly: true
  },
  {
    name: 'product_name',
    label: 'Product Code',
    type: 'text',
    subListKey: 'shipment_products'
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    type: 'textarea',
    rows: 1,
    maxLength: 60,
    limitRows: 4
  },
  {
    name: 'product_quantity',
    label: 'Quantity',
    type: 'text',
    subListKey: 'shipment_products'
  },
  { name: 'quantity', label: 'No of Packages', type: 'number' },
  {
    name: 'kind',
    label: 'Kind of Packing',
    placeholder: 'CARTONS / PALLETS / BOXES / etc...'
  },
  {
    name: 'net_weight',
    label: 'Net Weight (kg)',

    type: 'number',
    readOnly: true
  },
  {
    name: 'gross_weight',
    label: 'Gross Weight (kg)',

    type: 'number'
  },
  {
    name: 'volume',
    label: 'Measurements',
    unit_key: 'volume_unit',
    type: 'number'
  }
];

export const sliData = [
  {
    label: 'Marks',
    name: 'marks',
    type: 'text',
    readOnly: true
  },
  { name: 'quantity', label: 'No of Packages', type: 'number' },
  {
    name: 'kind',
    label: 'Kind of Packing',
    placeholder: 'CARTONS / PALLETS / BOXES / etc...'
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    type: 'textarea',
    rows: 1,
    maxLength: 60,
    limitRows: 4
  },
  {
    name: 'gross_weight',
    label: 'Gross Weight (kg)',

    type: 'number'
  },
  {
    name: 'volume',
    label: 'Measurements',
    unit_key: 'volume_unit',
    type: 'number'
  }
];

export const truckLoadBolData = [
  {
    label: 'Marks',
    name: 'marks',
    type: 'text',
    readOnly: true
  },
  {
    name: 'kind',
    label: 'Kind of Packing',
    placeholder: 'CARTONS / PALLETS / BOXES / etc...'
  },
  {
    name: 'description',
    label: 'Description',
    placeholder: 'Description',
    type: 'textarea',
    rows: 1,
    maxLength: 60,
    limitRows: 4
  },
  {
    name: 'gross_weight',
    label: 'Gross Weight (kg)',

    type: 'number'
  }
];
