import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createShipmentPackageMutation = () => {
  return useMutation((data) => http.post(API_ENDPOINTS.SHIPMENT_PACKAGES, data));
};

export default createShipmentPackageMutation;
