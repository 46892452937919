import RenderIf from '@components/common/render-if';
import Form from '@components/dataEntry/form';
import FormAutocomplete from '@components/dataEntry/form-autocomplete';
import FormFileUpload from '@components/dataEntry/form-file-upload';
import Select from '@components/dataEntry/select';
import ModalView from '@components/feedback/modal-view';
import LoaderWithIcon from '@components/loaders/loader-with-icon';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useDocTypesQuery } from '@services/api/documents/get-all-doc-types';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

export interface DocumentTypesProps {
  fileIndex: number;
}

const UploadDocumentForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const { closeModalByPosition } = useModal();
  const {
    query: { id: shipmentId }
  } = useRouter();
  const [defaultValues] = useState({
    owner_id: shipmentId || '',
    file: []
  });

  // queries
  const { mutateAsync: uploadDocument, isLoading: isUploading } =
    useRequest.documents.uploadDocuments();

  const validationSchema = yup.object({
    owner_id: yup.string().optional(),
    file: yup.array().min(1, 'A file is required')
  });

  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  // document types
  const [docTypeList, setDocTypeList] = useState([]);
  const queryClient = useQueryClient();

  const [filesLength, setFilesLength] = useState(0);

  const {
    query: { id }
  } = useRouter();

  const onSubmit = async (inputs: any) => {
    setFilesLength(inputs.file?.length);

    try {
      await uploadDocument(
        {
          documentData: {
            owner_class: 'Shipment',
            ...inputs,
            doc_type: docTypeList.length > 0 ? docTypeList : 'other'
          }
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries([API_ENDPOINTS.DOCUMENTS]);
            queryClient.refetchQueries([API_ENDPOINTS.GENERATED_DOCUMENTS]);
            queryClient.refetchQueries([API_ENDPOINTS.TASKS]);
            queryClient.invalidateQueries([API_ENDPOINTS.SHIPMENT_ACTIVITY(id)]);
            toastNotification({
              type: 'success',
              description: `${
                inputs.file.length > 1 ? 'Documents were' : 'Document was'
              } successfully uploaded.`
            });
          }
        }
      );
      closeModalByPosition();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  const onChangeDocumentType = (e, fileIndex) => {
    const docTypeValues = [...Object.values({ ...docTypeList, [fileIndex]: e.target.value })];
    setDocTypeList(docTypeValues);
  };

  const { data: typeMappings, isLoading } = useDocTypesQuery();

  const parsedTypeMappings = useMemo(() => {
    if (isLoading || typeMappings?.length === 0) return;
    const parsedTypeMappings = [...typeMappings];
    parsedTypeMappings?.unshift({ name: 'Other', value: 'other' });
    return parsedTypeMappings;
  }, [typeMappings]);

  const DocumentTypesItem = ({ fileIndex }: DocumentTypesProps) => {
    return (
      <Select
        name="doc_type"
        className="w-full"
        value={docTypeList[fileIndex]}
        optionsList={parsedTypeMappings}
        onChange={(e) => onChangeDocumentType(e, fileIndex)}
      />
    );
  };

  return (
    <ModalView header="Upload Documents">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        onEnterSubmit
        onSubmit={handleSubmit}
      >
        {({ onSubmitForm }) => {
          return (
            <div className="relative">
              <RenderIf isTrue={isUploading}>
                <div className="relative min-h-[17rem]">
                  <LoaderWithIcon
                    absolute
                    icon="document"
                    loadingText={`${
                      filesLength > 1 ? 'Documents are' : 'Document is'
                    } uploading...`}
                  />
                </div>
              </RenderIf>
              <RenderIf isTrue={!isUploading}>
                <>
                  <div className="flex flex-wrap -mx-3 mb-3">
                    <RenderIf isTrue={!shipmentId}>
                      <div className="w-full px-3">
                        <FormAutocomplete
                          name="owner_id"
                          containerClass="col-span-3"
                          label="Shipment"
                          entity="shipment"
                          icon="list"
                          hideNewEntryOnEntity
                        />
                      </div>
                    </RenderIf>
                  </div>
                  <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3 mb-3">
                      <FormFileUpload
                        name="file"
                        label="Files"
                        containerClass="col-span-3"
                        multiple={true}
                        alignment={'horizontal'}
                        DocumentTypesItem={DocumentTypesItem}
                      />
                    </div>
                  </div>
                </>
              </RenderIf>
              <ModalFooter
                disabled={isUploading}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.UPLOAD_DOCUMENT_FORM}
                mainButtonText="Upload"
              />
            </div>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default UploadDocumentForm;
