import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const deleteShipmentTrackingMutation = () => {
  return useMutation((inputs: { id: string; params?: any }) =>
    http.delete(API_ENDPOINTS.GET_SHIPMENT_TRACKING(inputs.id), {
      params: inputs.params
    })
  );
};

export default deleteShipmentTrackingMutation;
