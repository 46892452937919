import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface PostCommentType {
  comment: string;
  current_tab: string;
  id: string;
}

const uploadedDocumentPostCommentMutation = () => {
  return useMutation((data: PostCommentType) =>
    http.post(API_ENDPOINTS.DOCUMENT_POST_COMMENT(data.id), data)
  );
};

export default uploadedDocumentPostCommentMutation;
