import cn from 'classnames';
import { FC } from 'react';

interface IconWrapperProps {
  displayOptions?: 'absolute' | 'static';
  className?: string;
}

const IconWrapper: FC<IconWrapperProps> = ({ children, displayOptions = 'static', className }) => {
  const containerClassName = cn('w-full h-full flex justify-center items-center', {
    absolute: displayOptions === 'absolute'
  });
  return <div className={`${containerClassName} ${className}`}>{children}</div>;
};

export default IconWrapper;
