import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

async function bulkDeleteShipments(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHIPMENTS}/bulk_delete`, jsonToFormData({ inputs }));
}

export const useBulkDeleteShipmentsMutation = () => {
  return useMutation((input: any) => bulkDeleteShipments(input), {
    onSuccess: (data) => {
      toastNotification({
        type: 'success',
        description: data.data.message
      });
    }
  });
};
