import { ManageFieldOptionsType, ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getFieldDataByEntity = ({
  params,
  options
}: {
  params?: ManageFieldOptionsType;
  options?: ReactQueryOptionsType;
}) => {
  return useQuery([API_ENDPOINTS.FIELD_DATA, params], queryFetch, options);
};

export default getFieldDataByEntity;
