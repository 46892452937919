// https://segment.com/docs/connections/spec/track/
/* eslint no-console: ["error", { allow: ["info"] }] */
import { AnalyticsBrowser } from '@segment/analytics-next';
import { EventPayload, EventsType, IdentifyEventType } from 'types/events';

const isDebugger = process.env.NEXT_PUBLIC_EVENTS_TRACKING_DEBUGGER === 'true';
const isSegmentEnable = process.env.NEXT_PUBLIC_ENABLE_SEGMENT === 'true';
const isDevelopment = process.env.NODE_ENV === 'development';

let analytics;

const isBlocked = () => {
  if (isDevelopment && !isDebugger) return true;

  if (!isSegmentEnable) {
    return true;
  }

  if (isDebugger) {
    return false;
  }

  return typeof window === 'undefined';
};

if (typeof window !== 'undefined') {
  analytics = AnalyticsBrowser.load({
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_ID
  });
}

const consoleFakeMethod = (methodName: string, obj: any = {}) => {
  console.info(methodName, obj);
};

const analyticsTrack = async (event: string, payload: any = {}) => {
  if (isDebugger) {
    consoleFakeMethod('track', { event, payload });
  } else {
    analytics.track(event, payload);
  }
};

const analyticsIdentify = async (id: string, traits: any): Promise<void> => {
  if (isDebugger) {
    consoleFakeMethod('identify', { id, traits });
  } else {
    analytics.identify(id, traits);
  }
};

const analyticsPage = async (payload) => {
  if (isDebugger) {
    consoleFakeMethod('page', payload);
  } else {
    analytics.page(payload);
  }
};

export const appEvent = (event: EventsType, payload?: EventPayload) => {
  if (isBlocked()) return;
  analyticsTrack(event, payload);
};

export const identify = (id: string, traits: IdentifyEventType) => {
  if (isBlocked()) return;
  analyticsIdentify(id, traits);
};

export const appEventWrap =
  (fn: Function, event: EventsType, payload: EventPayload) =>
  (...args: any[]) => {
    fn(...args);
    appEvent(event, payload);
  };

export const appPageView = (payload) => {
  if (isBlocked()) return;
  analyticsPage(payload);
};
