import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const useGeneratePdfMutation = () => {
  return useMutation(
    (data: any) =>
      http.post(API_ENDPOINTS.GENERATED_DOCUMENTS_UPDATE_PDF, {
        id: data.id,
        generated_document: {
          html_template: document.getElementById(`document-${data.id}`)?.innerHTML
        }
      }),
    {
      retry: 3
    }
  );
};

export default useGeneratePdfMutation;
