import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { getOptionsForSelect } from '@utils/common';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { shipmentTypes } from 'pages/shipments/components/Tabs/AllShipments/@common/shipments.constants';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const GeneratedDocumentAddShipmentForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const modalState = useStore((state) => state.modalState);
  const router = useRouter();
  const [defaultValues] = useState({
    is_from_modal: true,
    is_from_document: false,
    name: '',
    ship_type: 'import'
  });

  // const
  const shipmentId = modalState?.[0]?.entityId;
  const options = getOptionsForSelect(shipmentTypes);
  const queryClient = useQueryClient();

  // hooks
  const { closeModalByPosition } = useModal();
  const { mutateAsync: updateShipment } = useRequest.shipments.update({ id: shipmentId });

  // queries
  const validationSchema = yup.object({
    name: yup.string().required('Name is a required field')
  });

  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        ...inputs,
        id: shipmentId
      };
      await updateShipment(modifiedInputs);
      closeModalByPosition();
      queryClient.resetQueries(API_ENDPOINTS.GET_SHIPMENT(shipmentId));
      if (router.isReady) {
        router.push(`/shipments/${shipmentId}?current_tab=documents`);
      }
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  return (
    <ModalView header="Create Shipment">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        onEnterSubmit
        onSubmit={handleSubmit}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="-mx-3">
                <FormInput label="Name" name="name" containerClass="px-3 mb-3" />
              </div>
              <div className="-mx-3">
                <FormInput
                  type="select"
                  label="Shipment Type"
                  name="ship_type"
                  optionsList={options}
                  containerClass="px-3 mb-3"
                />
              </div>
              <ModalFooter
                disabled={!isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.GENERATE_DOCUMENT_ADD_SHIPMENT_FORM}
                mainButtonText="Create"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default GeneratedDocumentAddShipmentForm;
