export enum CurrencySymbolMap {
  USD = '$',
  GBP = '£',
  CAD = '$',
  INR = '₹',
  EUR = '€',
  CNY = '¥'
}

export enum CurrencyNumberFormatMap {
  USD = 'en-US',
  GBP = 'en-GB',
  CAD = 'en-US',
  INR = 'en-IN',
  EUR = 'de-DE',
  CNY = 'zh-CN'
}
