import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getAllDocumentTemplate = (props?: queryOptions): any => {
  return useQuery([API_ENDPOINTS.DOCUMENT_TEMPLATE, props.params], queryFetch, props?.options);
};

export default getAllDocumentTemplate;
