import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function shareShipment(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHARES}`, inputs);
}

export const useShareShipmentMutation = () => {
  return useMutation((input: any) => shareShipment(input), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Shipment was shared'
      });
    }
  });
};
