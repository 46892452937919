import { XCircleIcon } from '@heroicons/react/solid';

type Props = {
  errorMessages: string[];
};

const ErrorList = ({ errorMessages }: Props) => {
  const errorCount = errorMessages.length;

  return (
    <div className="rounded-md bg-red-50 p-4 mb-4">
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {`There ${errorCount === 1 ? 'is' : 'are'} ${errorCount} ${
              errorCount === 1 ? 'error' : 'errors'
            } with your submission`}
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc pl-5 space-y-1">
              {errorMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorList;
