import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkReadStatusMutation = () => {
  return useMutation((inputs: any) =>
    http.put(`${API_ENDPOINTS.BULK_READ_NOTIFICATION_STATUS}`, inputs)
  );
};

export default bulkReadStatusMutation;
