import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function bulkDeleteShipmentProducts(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHIPMENT_PRODUCTS}/bulk_delete`, inputs);
}

export const useBulkDeleteShipmentProductsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((input: any) => bulkDeleteShipmentProducts(input), {
    onSuccess: (data) => {
      toastNotification({
        type: 'success',
        description: data.data.message
      });
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PRODUCTS);
    }
  });
};
