// Format object to match select component structure
const getOptionsForSelect = (types = {}) => {
  const options = Object.keys(types).map((key) => {
    return { name: key, value: types[key] };
  });

  return options;
};

export { getOptionsForSelect };
