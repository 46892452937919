import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getOrganizationUser = ({ id }, options?: ReactQueryOptionsType): any => {
  return useQuery([API_ENDPOINTS.ORGANIZATION_USER_BY_ID(id)], queryFetch, options);
};

export default getOrganizationUser;
