const InboxTabIcon = ({ showStatus, className }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        fill="none"
        className={className}
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.25 1.688H4.032c-.576 0-1.085.375-1.255.926L1.371 7.186a1.312 1.312 0 0 0-.058.386V10c0 .725.587 1.313 1.312 1.313h8.75c.725 0 1.313-.588 1.313-1.313V7.572c0-.13-.02-.26-.059-.386l-1.406-4.572a1.313 1.313 0 0 0-1.255-.926H8.75M1.312 7.375h2.252c.497 0 .951.28 1.174.726l.15.298c.222.445.676.726 1.173.726H7.94c.497 0 .951-.28 1.174-.726l.15-.298a1.313 1.313 0 0 1 1.173-.726h2.252M7 1.25v4.813m0 0-1.75-1.75M7 6.063l1.75-1.75"
        />
      </svg>
      <span
        className="absolute right-0 w-2.5 h-2.5 rounded-full"
        style={{
          backgroundColor: showStatus ? '#FF1C00' : '#D4D4D4',
          right: '-6px',
          bottom: '-8px'
        }}
      />
    </>
  );
};

export default InboxTabIcon;
