import { createRef, useEffect, useRef, useState } from 'react';

type FileType = {
  lastModified: number;
  lastModifiedDate: object;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  data?: object[];
};

type Props = {
  data?: object[];
  onChange?: (file) => void;
};

/**
 * @param data send {@link data} when it has to map react children and get their ref. Send the ref in the showOpenFileDialog function
 */

const useFileUpload = ({ data, onChange }: Props = {}) => {
  const fileRef = useRef();
  const refMappingSelection = useRef([]);
  refMappingSelection.current =
    data && data.map((el, i) => refMappingSelection.current[i] ?? createRef());
  const [selectedFile, setSelectedFile] = useState<HTMLInputElement | string | FileType | any>();

  const showOpenFileDialog = (ref?: any) => {
    if (ref?.current) {
      return ref.current.click();
    }
    // @ts-ignore
    fileRef.current.click();
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    onChange?.(file);
    setSelectedFile(file);
  };

  // Clean up the selection to avoid memory leak
  useEffect(() => {
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  return {
    fileRef,
    showOpenFileDialog,
    refMappingSelection: refMappingSelection.current,
    selectedFile,
    handleChange
  };
};

export default useFileUpload;
