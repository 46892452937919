import RenderIf from '@components/common/render-if';
import Badge from '@components/dataDisplay/badge';
import Typography from '@components/dataDisplay/typography';
import Form from '@components/dataEntry/form';
import FormAutocomplete from '@components/dataEntry/form-autocomplete';
import FormInput from '@components/dataEntry/form-input';
import FormInputCheckboxOrToggle from '@components/dataEntry/form-input-checkbox-or-toggle';
import MultiSelectDropdown from '@components/dataEntry/multi-select-dropdown';
import Button from '@components/general/button';
import { XIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import useManageParams from '@hooks/use-manage-params';
import useManageShipmentTasks from '@hooks/use-manage-tasks';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useAllUsersQuery } from '@services/api/user/get-all-users';
import { YEAR_MONTH_DAY_FORMAT } from '@services/constants';
import { TaskResponseType } from '@services/types';
import { useStore } from '@store/useStore';
import { createYupSchema } from '@utils/create-yup-schema';
import { formatUserNameSelectList } from '@utils/format-user-name-select-list';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { taskStatusBadgeVariantMap } from 'pages/tasks/components/@common/tasks.constants';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useContextUI } from 'states/ui';
import * as yup from 'yup';
import { Body } from '../components/Body';
import Footer from '../components/Footer';
import Header from '../components/Header';
import View from './View';

type taskTriggerTypeMutated = TaskResponseType & {
  email?: string;
};

export const removeFieldsList = [
  'Status',
  'Created At',
  'Updated At',
  'Upcoming',
  'Gate Out Date',
  'Arrival Date',
  'Departure Date',
  'Tracking Number',
  'Tracking Status',
  'Tracking Last Updated At',
  'Tracking Status',
  'Payment Total',
  'Payment Open',
  'Payment Due Date',
  'Name',
  'Carrier',
  'Vessel'
];

const assigneeAdditionalOptions = [
  { label: 'Consignee', value: 'consignee' },
  { label: 'Shipper', value: 'shipper' },
  { label: 'Buyer', value: 'buyer' },
  { label: 'Freight Forwarder', value: 'freight_forwarder' },
  { label: 'Email', value: 'email' },
  { label: 'Team Member', value: 'team_member' }
];

export const triggerAfterList = [
  { name: 'Immediately', value: 'immediately', disabled: false },
  { name: 'Previous Task', value: 'previous_task', disabled: false },
  { name: 'Cargo Ready Date', value: 'cargo_ready_date', disabled: false }
];

const Edit = () => {
  // Set states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [isDocumentRequest, setDocumentRequest] = useState(false);
  const [approvalNeeded, setApprovalNeeded] = useState(false);
  const [taskOptions, setTaskOptions] = useState([]);
  const [requestedFields, setRequestedFields] = useState(null);
  const [isRequestField, setIsRequestField] = useState(null);
  const [previousTaskSelected, setPreviousTaskSelected] = useState(false);
  const [assigneeType, setAssigneeType] = useState('none');
  const [assigneeTypeOptions, setAssigneeTypeOptions] = useState([...assigneeAdditionalOptions]);
  const [triggerAfter, setTriggerAfter] = useState('immediately');

  // hooks

  const { removeParams, getParamsByKey } = useManageParams();
  const { addOrUpdateTaskMutation } = useManageShipmentTasks();
  const { query, pathname } = useRouter();
  // TODO: workaround to support string. All Id should be string. Resolve this issue with the BE
  const shipmentId = query.id as string;

  // set data stores
  const isAutocompleteLoading = useStore((state) => state.autocompleteLoading);

  // queries
  const { data: taskData, isLoading: taskLoading } = useRequest.tasks.getById({
    id: String(query.taskId)
  });

  const { data: tasks } = useRequest.tasks.get({
    params: { shipment_id: shipmentId }
  });
  const allTasks = tasks?.data;
  const { data: allUsersData, isLoading: usersLoading } = useAllUsersQuery();
  const { data: allContactsData, isLoading: contactsLoading } = useRequest.contacts.get();
  const { data: allShipmentsData, isLoading: shipmentsLoading } = useRequest.shipments.get();
  const { data: currentUserData } = useRequest.user.get();
  const [_, { openPortal }] = useContextUI();
  const { closeAllModals } = useModal();
  const { data: fieldsData } = useRequest.field_data.getByEntityType({
    params: { entity_type: 'Shipment' }
  });
  const removeEmailFormatting = ({ string }) =>
    string ? string.split('<').pop().split('>')[0].trim() : '';
  const [defaultValues, setDefaultValues] = useState<taskTriggerTypeMutated>({
    ...(shipmentId && { shipment_id: parseInt(shipmentId) }),
    reviewer_id: parseInt(currentUserData.user.id),
    relative_start_days: '0',
    duration: '0',
    assignee_type: 'none',
    trigger_type: 'immediately',
    previous_milestone_id: taskOptions?.length > 0 ? taskOptions[0].value : '',
    assignee_email: currentUserData.user.email
  });
  // state variables
  const {
    addTaskMutation: { isLoading: addTaskLoading },
    updateTaskMutation: { isLoading: updateTaskLoading },
    addOrUpdateTask
  } = addOrUpdateTaskMutation();

  const fields = fieldsData ? [...fieldsData] : [];

  const requestedFieldsOptions = [
    ...fields
      .map((x) => ({ label: x.label, value: x.id }))
      .filter((x) => !removeFieldsList.includes(x.label))
  ];

  const portalMode = query?.mode || 'view';
  const isNewTask = portalMode === 'add' || portalMode === 'duplicate';
  const isPopulateInitialValues = portalMode === 'edit' || portalMode === 'duplicate';
  const fullLoading =
    isAutocompleteLoading || taskLoading || usersLoading || contactsLoading || shipmentsLoading;
  const mutationsLoading = updateTaskLoading || addTaskLoading;
  const teamMemberOptions = allUsersData ? allUsersData.data : [];
  const reviewerOptions = formatUserNameSelectList(teamMemberOptions);
  const assigneeTeamMemberOptions = formatUserNameSelectList(teamMemberOptions, true);
  const isATeamMember = (teamMember) =>
    assigneeTeamMemberOptions.find((member) => member.value === teamMember);
  const isShipmentDetailsPage = pathname.includes('/shipments/[id]');
  const triggerAfterHasDuration =
    triggerAfter === 'cargo_ready_date' || triggerAfter === 'previous_task';
  const triggerAfterHasDueDate = triggerAfter === 'immediately';

  const headerLabel =
    portalMode === 'edit' ? (
      <>
        Edit {defaultValues.name}
        <Badge classNames="ml-1" variant={taskStatusBadgeVariantMap[defaultValues.status]}>
          {parseInt(currentUserData.user.id) === defaultValues.reviewer_id &&
          defaultValues.status === 'In Review'
            ? 'Ready for Review'
            : defaultValues.status}
        </Badge>
      </>
    ) : (
      'Add Task'
    );
  const tab = getParamsByKey('current_tab');
  const isTasksTab = tab === 'tasks';
  const contactsData = allContactsData ? allContactsData.data : [];
  const shipmentsData = allShipmentsData ? allShipmentsData.data : [];

  // form validation
  const validationSchema = yup.object({
    has_reviewer: yup.string(),
    name: yup.string().required('Please enter a name for this task.'),
    shipment_id: shipmentId
      ? yup.string().optional()
      : yup.string().required('Tasks need to be tied to a shipment.'),
    ...[
      {
        name: 'reviewer_id',
        required: isDocumentRequest || approvalNeeded,
        message: 'Please select a reviewer.'
      },
      {
        name: 'filename',
        required: isDocumentRequest,
        message: "Can't be blank"
      },
      {
        name: 'assignee_email',
        required: assigneeType === 'team_member',
        message: 'Please fill this field in'
      },
      {
        name: 'email',
        required: assigneeType === 'email',
        message: 'Please fill this field in'
      },
      {
        name: 'previous_milestone_id',
        required: previousTaskSelected,
        message: 'Please select a previous task'
      }
    ].reduce(createYupSchema, {})
  });

  // helper functions
  const taskOptionsSetter = (shipmentId) => {
    const filteredTasks =
      portalMode === 'edit' ? allTasks?.filter((x) => x.id !== query.taskId) : allTasks;
    setTaskOptions(
      filteredTasks?.reduce((acc, task) => {
        if (
          task.shipment_id === shipmentId &&
          ((portalMode === 'edit' && task.id !== query.taskId) || portalMode === 'add') &&
          task.previous_milestone_id !== defaultValues.id
        ) {
          let description = '';

          if (task.target_date) {
            description += `Due on ${task.target_date}${
              task.trigger_type === 'previous_task'
                ? `, ${task.duration} days after ${task.previous_task_name}; `
                : '; '
            }`;
          }
          if (task.assignee_email) {
            description += `Assigned to ${task.assignee_email}`;
          }
          acc.push({ value: task.id, label: task.name, descriptionForLabel: description });
        }
        return acc;
      }, [])
    );
  };

  // actions
  const toggleDocumentRequest = (value) => {
    setDocumentRequest(value);
    setApprovalNeeded(value);
  };

  // actions
  const toggleRequestField = (value) => {
    setIsRequestField(value);
  };

  const onChangeRequestedFields = (selected) => {
    setRequestedFields(selected);
  };

  const setInitialValues = () => {
    setDefaultValues({
      ...(shipmentId && { shipment_id: parseInt(shipmentId) }),
      reviewer_id: parseInt(currentUserData.user.id),
      relative_start_days: '0',
      duration: '0',
      assignee_type: 'none',
      trigger_type: 'immediately',
      previous_milestone_id: taskOptions?.length > 0 ? taskOptions[0].value : '',
      assignee_email: currentUserData.user.email
    });
  };

  const closeModal = () => {
    if (portalMode !== 'edit') {
      return closeAllModals();
    }
  };

  const onHandleOpenTaskViewMode = () => {
    openPortal({
      portal: 'drawer',
      params: { taskId: query.taskId },
      Component: <View />
    });
  };

  const onSubmit = async (inputs) => {
    try {
      let modifiedInputs = {
        ...inputs,
        assignee_email:
          assigneeType === 'team_member'
            ? inputs.assignee_email
            : inputs.assignee_email
            ? inputs.email
            : '',
        is_file_upload: isDocumentRequest,
        has_reviewer: isDocumentRequest || approvalNeeded ? true : approvalNeeded,
        reviewer_id: isDocumentRequest || approvalNeeded ? inputs.reviewer_id : '',
        filename: isDocumentRequest ? inputs.filename : '',
        is_data_request: isRequestField && requestedFields.length > 0 ? true : false,
        duration: inputs.duration || 0,
        requested_data: requestedFields
      };
      delete modifiedInputs.email;
      if (triggerAfter === 'previous_task') {
        delete modifiedInputs.target_date;
      }

      if (triggerAfter === 'immediately') {
        delete modifiedInputs.previous_milestone_id;
        delete modifiedInputs.duration;
      }

      if (triggerAfter === 'cargo_ready_date') {
        delete modifiedInputs.previous_milestone_id;
      }

      if (inputs.assignee_type !== 'email' && inputs.assignee_type !== 'team_member') {
        delete modifiedInputs.assignee_email;
      }

      if (isNewTask) {
        delete modifiedInputs.id;
        await addOrUpdateTask({ inputs: modifiedInputs });
        removeParams(['add_task']);
        closeModal();
      } else {
        await addOrUpdateTask({ inputs: modifiedInputs, isNewTask: false });
        onHandleOpenTaskViewMode();
      }
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors?.assignee_email) {
        setErrorsServerValidation({
          ...errors,
          assignee_id: errors.assignee_email,
          email: errors.assignee_email
        });
      } else {
        setErrorsServerValidation(errors);
      }
    }
  };

  const toggleApprovalNeeded = (value) => {
    setApprovalNeeded(value);
  };

  const updateTaskState = (newAssigneeType?: string) => {
    const task = taskData;
    if (isNewTask) {
      delete task?.name;
    }
    if (shipmentId) setShipmentTasks(shipmentId);

    setDocumentRequest(task?.is_file_upload || false);
    setApprovalNeeded(Boolean(task?.reviewer_id) || task?.is_file_upload || false);
    setAssigneeType(newAssigneeType || task?.assignee_type);
    setRequestedFields(task?.requested_data || []);
    setIsRequestField(task?.is_data_request || false);
    setPreviousTaskSelected(task?.trigger_type === 'previous_task');
    setTriggerAfter(task?.trigger_type);
    setDefaultValues({
      ...task,
      ...(shipmentId && { shipment_id: parseInt(shipmentId) }),
      has_reviewer: task?.has_reviewer || Boolean(task?.reviewer_id),
      reviewer_id: Boolean(task?.reviewer_id)
        ? task?.reviewer_id
        : parseInt(currentUserData.user.id),
      assignee_type: newAssigneeType || task?.assignee_type,
      assignee_email: removeEmailFormatting({
        string:
          newAssigneeType === 'team_member' && !isNewTask && !isATeamMember(task.assignee_email)
            ? currentUserData.user.email
            : task?.assignee_email
      }),
      email:
        newAssigneeType === 'email'
          ? ''
          : task?.assignee_email
          ? task?.assignee_email
          : currentUserData.user.email
    });
  };

  const enablePreviousTaskOptions = (value) => {
    if (value === 'previous_task') {
      setPreviousTaskSelected(true);
      setTriggerAfter(value);
      return;
    }
    if (value === 'cargo_ready_date') {
      setTriggerAfter(value);
      setPreviousTaskSelected(false);
      return;
    }

    setTriggerAfter(value);
    setPreviousTaskSelected(false);
  };

  const setShipmentTasks = (shipmentId) => {
    if (shipmentId) {
      taskOptionsSetter(parseInt(shipmentId));
    } else {
      setTaskOptions([]);
    }
  };

  const assigneeTypeOptionsSetter = useCallback(
    (shipmentId) => {
      const shipment = shipmentsData.find((shipmentDatum) => shipmentDatum.id === shipmentId);
      const shipper = contactsData.find((contact) => shipment?.shipper_id === contact.id);
      const consignee = contactsData.find((contact) => shipment?.consignee_id === contact.id);
      const buyer = contactsData.find((contact) => shipment?.buyer_id === contact.id);
      const freight_forwarder = contactsData.find(
        (contact) => shipment?.freight_forwarder_id === contact.id
      );

      setAssigneeTypeOptions([
        { label: 'None', value: 'none' },
        {
          label: 'Shipper',
          value: 'shipper',
          ...(shipper && {
            descriptionForLabel: shipper.name + (shipper.email ? `, ${shipper.email}` : '')
          })
        },
        {
          label: 'Consignee',
          value: 'consignee',
          ...(consignee && {
            descriptionForLabel: consignee.name + (consignee.email ? `, ${consignee.email}` : '')
          })
        },
        {
          label: 'Buyer',
          value: 'buyer',
          ...(buyer && {
            descriptionForLabel: buyer.name + (buyer.email ? `, ${buyer.email}` : '')
          })
        },
        {
          label: 'Freight Forwarder',
          value: 'freight_forwarder',
          ...(freight_forwarder && {
            descriptionForLabel:
              freight_forwarder.name +
              (freight_forwarder.email ? `, ${freight_forwarder.email}` : '')
          })
        },
        { label: 'Email', value: 'email' },
        { label: 'Team Member', value: 'team_member' }
      ]);
    },
    [defaultValues.shipment_id, shipmentId, contactsData, shipmentsData]
  );

  const onShipmentChange = (shipment) => {
    setShipmentTasks(shipment);
    assigneeTypeOptionsSetter(parseInt(shipment));
    if (!shipment) {
      setPreviousTaskSelected(false);
    }
  };

  const onAssigneeTypeChange = (newAssigneeType) => {
    setAssigneeType(newAssigneeType);
    if (!isNewTask) {
      updateTaskState(newAssigneeType);
    }
  };

  const onClose = () => {
    closeModal();
    if (portalMode === 'edit') onHandleOpenTaskViewMode();
  };

  // effects
  useEffect(() => {
    if (taskData && isPopulateInitialValues) {
      updateTaskState();
    }
  }, [portalMode, taskData, isPopulateInitialValues]);

  useEffect(() => {
    if (defaultValues.shipment_id || shipmentId) {
      const shipment_to_match = defaultValues.shipment_id
        ? defaultValues.shipment_id
        : parseInt(shipmentId);
      taskOptionsSetter(shipment_to_match);
    } else {
      setTaskOptions([]);
    }
  }, [defaultValues.shipment_id, shipmentId, taskData]);

  useEffect(() => {
    if (!isPopulateInitialValues) {
      setInitialValues();
    }
  }, [isPopulateInitialValues, taskData]);

  useEffect(() => {
    if (
      (defaultValues.shipment_id || shipmentId) &&
      contactsData.length > 0 &&
      shipmentsData.length > 0 &&
      !fullLoading &&
      (!isNewTask || isTasksTab)
    ) {
      assigneeTypeOptionsSetter(defaultValues.shipment_id ?? parseInt(shipmentId));
    }
  }, [defaultValues.shipment_id, shipmentId, contactsData, shipmentsData, isTasksTab]);

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (taskData) methods.reset(defaultValues);
  }, [taskData, defaultValues]);

  return (
    <div>
      <Header>
        <div className="flex px-3 py-4 justify-between items-center">
          <Typography variant="text-base">{headerLabel}</Typography>
          <div>
            <Button onClick={onClose}>
              <XIcon className="h-6 w-6 text-gray-400" />
            </Button>
          </div>
        </div>
      </Header>
      <Body className={(portalMode === 'add' || portalMode === 'duplicate') && 'max-h-[80vh]'}>
        <Form
          methods={methods}
          errorsServerValidation={errorsServerValidation}
          className="pb-20 grid gap-4"
        >
          <div className="mt-14">
            <div className="flex flex-row gap-x-2">
              <FormInput
                label="Task Name"
                name="name"
                type="text"
                containerClass={`${shipmentId || isShipmentDetailsPage ? 'w-full' : 'w-1/2'}`}
              />
              <RenderIf isTrue={!isShipmentDetailsPage}>
                <div className="w-1/2">
                  <FormAutocomplete
                    name="shipment_id"
                    label="Shipment"
                    entity="shipment"
                    queryParams={{ shipment_status: 'active' }}
                    defaultValue={defaultValues.shipment_id || shipmentId}
                    disabled={isTasksTab || portalMode !== 'add'}
                    hideNewEntryOnEntity
                    customOnChange={onShipmentChange}
                  />
                </div>
              </RenderIf>
            </div>
          </div>

          <FormAutocomplete
            type="select"
            label="Assign to"
            name="assignee_type"
            icon="user"
            loading={fullLoading}
            customOptions={assigneeTypeOptions}
            defaultValue={assigneeType}
            isClearable={false}
            customOnChange={onAssigneeTypeChange}
          />

          <RenderIf isTrue={assigneeType === 'team_member'}>
            <FormInput
              type="select"
              label="Team Member"
              name="assignee_email"
              defaultValue={taskData?.assignee_email}
              optionsList={assigneeTeamMemberOptions}
              disabled={assigneeType !== 'team_member'}
              containerClass="transition ease-in-out duration-200"
            />
          </RenderIf>
          <RenderIf isTrue={assigneeType === 'email'}>
            <FormInput
              type="text"
              label="Email"
              name="email"
              optionsList={teamMemberOptions}
              disabled={assigneeType !== 'email'}
              containerClass="transition ease-in-out duration-200"
            />
          </RenderIf>

          <FormInput
            type="textarea"
            label="Description"
            rows={2}
            placeholder="Enter task description"
            name="description"
          />

          <FormInput
            type="select"
            label="Trigger After"
            name="trigger_type"
            optionsList={triggerAfterList}
            customOnChange={enablePreviousTaskOptions}
            disabled={taskOptions?.length == 0}
          />

          <RenderIf isTrue={taskOptions?.length > 0 && previousTaskSelected}>
            <FormAutocomplete
              type="select"
              icon="task"
              label="Select a Task"
              defaultValue={defaultValues.previous_milestone_id}
              name="previous_milestone_id"
              customOptions={taskOptions}
              disabled={taskOptions?.length == 0 || !previousTaskSelected}
              hideNewEntryOnEntity
            />
          </RenderIf>
          <RenderIf isTrue={triggerAfterHasDueDate}>
            <FormInput
              label={previousTaskSelected ? 'Duration' : 'Due Date'}
              containerClass="transition ease-in-out duration-200"
              name="target_date"
              type="date"
              max={dayjs().add(5, 'years').format(YEAR_MONTH_DAY_FORMAT)}
            />
          </RenderIf>
          <RenderIf isTrue={triggerAfterHasDuration}>
            <div>
              <div className="flex flex-row gap-x-2">
                <FormInput
                  containerClass="w-1/2"
                  name="duration"
                  type="number"
                  placeholder="0"
                  min={0}
                  step={1}
                />
                <FormInput
                  type="select"
                  name="time_unit"
                  optionsList={[
                    { name: 'Days', value: 'days' }
                    // { name: 'Weeks', value: 'weeks' }
                  ]}
                  containerClass="w-1/2"
                />
              </div>
            </div>
          </RenderIf>

          <div className="flex whitespace-nowrap gap-2">
            <FormInputCheckboxOrToggle
              label="Request Document"
              containerClass="w-1/2"
              name="is_file_upload"
              onChangeCheckbox={toggleDocumentRequest}
              checked={isDocumentRequest}
              type="checkbox"
              align="horizontal"
            />
            <FormInputCheckboxOrToggle
              label="Requires Approval"
              name="has_reviewer"
              containerClass="w-1/2"
              disabled={isDocumentRequest}
              onChangeCheckbox={toggleApprovalNeeded}
              checked={approvalNeeded}
              type="checkbox"
              align="horizontal"
            />
            <FormInputCheckboxOrToggle
              label="Request info"
              containerClass="w-1/2"
              name="is_data_request"
              onChangeCheckbox={toggleRequestField}
              checked={isRequestField}
              type="checkbox"
              align="horizontal"
            />
          </div>

          <RenderIf isTrue={isRequestField}>
            <MultiSelectDropdown
              name={'requested_data'}
              withCheckbox
              defaultValue={requestedFields && requestedFields?.map((x) => x.value)}
              options={requestedFieldsOptions}
              onChange={onChangeRequestedFields}
            />
          </RenderIf>
          <RenderIf isTrue={isDocumentRequest}>
            <FormInput
              label="Document Name"
              containerClass="transition ease-in-out duration-200"
              name="filename"
            />
          </RenderIf>
          <RenderIf isTrue={approvalNeeded}>
            <FormInput
              type="select"
              label="Reviewer"
              name="reviewer_id"
              optionsList={reviewerOptions}
              containerClass="transition ease-in-out duration-200"
            />
          </RenderIf>
        </Form>
      </Body>
      <Footer>
        <div className="flex justify-end">
          <Button className="text-primary-grey-500 text-xs" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={mutationsLoading}
            variant="primary"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {isNewTask ? 'Add' : 'Update'}
          </Button>
        </div>
      </Footer>
    </div>
  );
};

export default Edit;
