import { FC } from 'react';
interface Props {
  showError?: boolean;
  errors?: any;
  id?: string;
  errorMessageIndex?: number;
}

// TODO remove the errorMessageIndex and find an alternate way
const FormError: FC<Props> = ({ showError, errors, id, errorMessageIndex = 0 }) => {
  if (!showError || Object.keys(errors).length < 1 || !errors[id]) return <></>;
  return (
    <p className="w-full mt-1.5 sm:mt-0 col-start-2 text-sm text-red-600">
      {Array.isArray(errors[id]) ? errors[id][errorMessageIndex]?.message : errors[id].message}
    </p>
  );
};

export default FormError;
