import { LockClosedIcon } from '@heroicons/react/outline';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2 from './form-autocomplete-v2';

const FormUserRoleAutoComplete = ({
  label,
  name,
  customOnChange
}: {
  label: string;
  name: string;
  customOnChange: (item) => void;
}) => {
  // hooks
  const { setValue, getValues } = useFormContext();
  const onChange = setValue;

  const onHandleOnChange = (item, obj) => {
    if (obj.action === 'clear' || obj.action === 'remove-value') {
      onChange(name, '');
      return;
    }
    customOnChange(item);
    onChange(name, item.value);
  };

  // const
  const formattedData = [
    {
      value: 'user',
      label: 'Basic',
      descriptionForLabel: 'Access to everything but company-wide settings'
    },
    {
      value: 'admin',
      label: 'Admin',
      descriptionForLabel: 'Access to everything'
    },
    // {
    //   value: 'guest',
    //   label: 'Guest',
    //   descriptionForLabel: 'Read/Write access to shipments they are added to'
    // },
    {
      value: 'view_only',
      label: 'View only',
      descriptionForLabel: 'Read only access to everything'
    }
  ];

  return (
    <FormAutocompleteV2
      label={label}
      name={name}
      containerClass="w-full"
      labelClass="text-primary-navy-400"
      allowCreate={false}
      options={formattedData}
      icon={<LockClosedIcon className="w-4 h-4" />}
      value={getValues(name)}
      onChange={onHandleOnChange}
      allowClear={false}
    />
  );
};

export default FormUserRoleAutoComplete;
