import { useQuery } from 'react-query';
import { ReactQueryOptionsType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const getShipmentPackageFieldsToImport = (
  options?: ReactQueryOptionsType
): any /*TODO: Add response type */ => {
  return useQuery([API_ENDPOINTS.SHIPMENT_PACKAGES_FIELDS_TO_IMPORT], queryFetch, options);
};

export default getShipmentPackageFieldsToImport;
