import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import Button from '@components/general/button';
import { CreditCardIcon, XIcon } from '@heroicons/react/outline';
import useFileUpload from '@hooks/use-file-upload';
import useManageParams from '@hooks/use-manage-params';
import useManagePayments from '@hooks/use-manage-payments';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { YEAR_MONTH_DAY_FORMAT } from '@services/constants';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const ShipmentPaymentForm = () => {
  // states
  const modalState = useStore((state) => state.modalState);
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [isPercentage, setIsPercentage] = useState(false);
  const [isRemoveAttachment, setIsRemoveAttachment] = useState(false);

  // hooks
  const { closeModalByPosition } = useModal();
  const { params } = useManageParams();
  const { recordPaymentMutation, updatePaymentDetailsMutation } = useManagePayments();
  const { handleChange, selectedFile } = useFileUpload();
  const {
    query: { id: shipmentId }
  } = useRouter();

  // queries
  const { data, refetch } = useRequest.shipment_payments.get({
    params: { shipment_id: shipmentId as string, page: params?.page },
    options: { keepPreviousData: true }
  });

  const paymentData = data?.data;
  const paymentId = data?.payment?.id;
  const total_text = data?.payment?.total_text;
  const totalPayment = data?.payment?.total_number;
  const totalOpenText = data?.payment?.total_open_text;

  // const
  const {
    mutation: { isLoading: recordPaymentLoading },
    recordPayment
  } = recordPaymentMutation();
  const {
    mutation: { isLoading: updatePaymentDetailsLoading },
    updatePaymentDetails
  } = updatePaymentDetailsMutation();
  const modalMode = modalState?.[0]?.mode;
  const paymentDetailId = modalState?.[0]?.entityId;
  const isNewContact = modalMode === 'add';
  const headerLabel = modalMode === 'edit' ? 'Update Payment Record' : 'Record Payment';
  const isPopulateInitialValues = modalMode === 'edit';
  const header = (
    <div className="flex justify-between">
      <div className="w-auto">{headerLabel}</div>
      <div className="w-auto self-center">
        <div className="flex flex-row space-x-1 text-xs">
          <div>
            <CreditCardIcon className="h-4 w-4 cursor-pointer" />
          </div>
          <div>Open / Total</div>
          <div className="font-normal">
            {totalOpenText} / {total_text}
          </div>
        </div>
      </div>
    </div>
  );
  let paymentDetails: Record<string, any> = {};

  paymentDetails =
    (isPopulateInitialValues &&
      paymentData &&
      paymentData.find((data) => data['id'] === Number(paymentDetailId))) ||
    {};

  const validationSchema = yup.object({
    reference: yup.string().required('Reference is required'),
    date_paid: yup.string().required('Date is required'),
    paid_amount_text: yup.string().required('Paid amount is required')
  });

  // actions
  // TODO: Create a custom hook
  const getDefaultValues = () => {
    const defaultValues = {
      payment_id: paymentId,
      reference: isPopulateInitialValues ? paymentDetails?.reference || '' : '',
      date_paid: isPopulateInitialValues
        ? paymentDetails?.datePaid || ''
        : dayjs(new Date()).format(YEAR_MONTH_DAY_FORMAT),
      paid_amount_text: isPopulateInitialValues ? paymentDetails?.paidAmount || '' : '',
      notes: isPopulateInitialValues ? paymentDetails?.notes || '' : ''
    };

    return defaultValues;
  };

  const [defaultValues, setDefaultValues] = useState({
    ...getDefaultValues()
  });

  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        ...inputs,
        is_percentage: isPercentage,
        payment_proof: selectedFile,
        remove_payment_proof: isRemoveAttachment,
        ...(paymentDetailId && { id: paymentDetailId })
      };

      if (isPopulateInitialValues) {
        await updatePaymentDetails(modifiedInputs);
      } else {
        await recordPayment(modifiedInputs);
      }

      setDefaultValues({
        ...getDefaultValues()
      });
      closeModalByPosition();
      refetch();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors.recorded);
    }
  };

  return (
    <ModalView header={header}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
      >
        {({ onSubmitForm, isDirty, getValues, setValue }) => {
          const setPaidAmount = (isPercentage) => {
            const currentValue = getValues('paid_amount_text');
            const updatedValue = !isPercentage
              ? Math.round((currentValue / 100) * totalPayment)
              : ((currentValue / totalPayment) * 100).toFixed(2);
            setValue('paid_amount_text', updatedValue);
            setIsPercentage(isPercentage);
          };
          return (
            <>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-1/2 px-3">
                  <FormInput label="Reference" name="reference" containerClass="col-span-3" />
                </div>
                <div className="w-1/2 px-3">
                  <FormInput
                    type="date"
                    label="Date Paid"
                    name="date_paid"
                    containerClass="col-span-3"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-3">
                  <div className="w-full flex flex-row space-x-4 text-xs">
                    <FormInput
                      label="Paid Amount"
                      name="paid_amount_text"
                      adornmentProps={{
                        position: isPercentage ? 'end' : 'start',
                        ...(!isPercentage && { startText: '$' }),
                        ...(isPercentage && { endText: '%' }),
                        inputType: 'number',
                        inputClassName: isPercentage ? 'pr-7' : 'pl-7'
                      }}
                      type="adornment"
                      containerClass="col-span-3"
                    />
                    <div className="flex relative h-14 w-20 mb-[0.05rem]">
                      <div className="absolute bottom-0">
                        <button
                          type="button"
                          onClick={() => setPaidAmount(false)}
                          className={`relative inline-flex items-center h-8 px-2.5 py-1.5 rounded rounded-r-none text-xs font-medium border ${
                            isPercentage ? 'btn-white bg-white' : 'btn-primary text-white'
                          }`}
                        >
                          $
                        </button>
                        <button
                          type="button"
                          onClick={() => setPaidAmount(true)}
                          className={`-ml-px relative inline-flex items-center h-8 px-2.5 py-1.5 rounded rounded-l-none text-xs font-medium border ${
                            isPercentage ? 'btn-primary text-white' : 'btn-white bg-white'
                          }`}
                        >
                          %
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-3">
                  <FormInput
                    label="Payment Proof"
                    name="payment_proof"
                    type="file"
                    customOnChange={handleChange}
                    containerClass="col-span-3"
                  >
                    {paymentDetails?.paymentProofs?.name &&
                      paymentDetails?.paymentProofs?.link &&
                      !isRemoveAttachment && (
                        <div className="flex flex-row items-center mt-2" id="filename-container">
                          <Link href={paymentDetails.paymentProofs.link || ''}>
                            <a className="link text-sm">{paymentDetails.paymentProofs.name}</a>
                          </Link>
                          <Button
                            onClick={() => setIsRemoveAttachment(true)}
                            className="flex justify-center cursor-pointer text-red-500 ml-2"
                            icon={<XIcon className="h-4 w-4" />}
                            defaultClass={false}
                          />
                        </div>
                      )}
                  </FormInput>
                </div>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-3">
                  <FormInput
                    type="textarea"
                    label="Notes"
                    name="notes"
                    rows={3}
                    containerClass="col-span-3"
                    placeholder={'Note Line 1\nNote Line 2\nNote Line 3'}
                  />
                </div>
              </div>
              <ModalFooter
                disabled={recordPaymentLoading || updatePaymentDetailsLoading || !isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.SHIPMENT_PAYMENT_FORM}
                mainButtonText={isNewContact ? 'Add' : 'Update'}
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default ShipmentPaymentForm;
