import { useQuery } from 'react-query';
import { ReactQueryOptionsType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const getShipmentPackageStatus = (
  { shipment_id },
  options?: ReactQueryOptionsType
): any /*TODO: Add response type */ => {
  return useQuery([API_ENDPOINTS.SHIPMENT_PACKAGES_STATUS, { shipment_id }], queryFetch, options);
};

export default getShipmentPackageStatus;
