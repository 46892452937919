import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

export const getWorkflow = ({ id }, props?: queryOptions): any => {
  return useQuery([API_ENDPOINTS.GET_WORKFLOW(id)], queryFetch, props?.options);
};

export default getWorkflow;
