import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getStopSignInAs = (props?: queryOptions) => {
  return useQuery<any, Error>(
    [API_ENDPOINTS.STOP_SIGN_IN_AS, props?.params],
    queryFetch,
    props?.options
  );
};

export default getStopSignInAs;
