import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function updateTotalPayments(inputs: any) {
  const paymentId = inputs.payment.id;
  delete inputs.payment.id;
  return http.put(`${API_ENDPOINTS.PAYMENTS}/${paymentId}`, inputs);
}

export const useUpdateTotalPaymentsMutation = () => {
  return useMutation((inputs: any) => updateTotalPayments(inputs), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Total payment was successfully updated.'
      });
    }
  });
};
