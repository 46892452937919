import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useManageParams from '@hooks/use-manage-params';
import useManagePayments from '@hooks/use-manage-payments';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const EditDueDateForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [defaultValues, setDefaultValues] = useState({
    target_date: ''
  });

  // hooks
  const { closeModalByPosition } = useModal();
  const { params } = useManageParams();
  const { updatePaymentsDueDateMutation } = useManagePayments();
  const {
    query: { id: shipmentId }
  } = useRouter();

  // const
  const {
    mutation: { isLoading },
    updatePaymentsDueDate
  } = updatePaymentsDueDateMutation();

  // queries
  const { data, refetch, isSuccess } = useRequest.shipment_payments.get({
    params: { shipment_id: shipmentId as string, page: params?.page },
    options: { keepPreviousData: true }
  });

  const paymentId = data?.payment?.id;
  const targetDate = data?.payment?.target_date;

  const validationSchema = yup.object({
    target_date: yup.string().required('Due date is required')
  });

  // actions
  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        payment: {
          ...inputs,
          shipment_id: shipmentId,
          id: paymentId
        }
      };
      await updatePaymentsDueDate(modifiedInputs);
      closeModalByPosition();
      refetch();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  useEffect(() => {
    setDefaultValues({ target_date: targetDate });
  }, [targetDate]);

  return (
    <ModalView header="Update Due Date">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        updateValuesAfterSuccess={isSuccess}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <FormInput
                    label="Due Date"
                    name="target_date"
                    type="date"
                    containerClass="col-span-3"
                  />
                </div>
              </div>
              <ModalFooter
                disabled={isLoading || !isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.EDIT_DUE_DATE_FORM}
                mainButtonText="Update"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default EditDueDateForm;
