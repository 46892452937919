import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function unpackShipmentPackage(inputs: any) {
  const id = inputs.id;
  const shipmentProductId = inputs.shipmentProductId;
  delete inputs.id;
  delete inputs.shipmentProductId;
  return http.put(
    `${API_ENDPOINTS.SHIPMENT_PACKAGES}/${id}/unpack?shipment_product_id=${shipmentProductId}`,
    { package: inputs }
  );
}

export const useUnpackShipmentPackageMutation = () => {
  return useMutation((inputs: any) => unpackShipmentPackage(inputs));
};
