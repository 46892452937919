import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const forgotPasswordMutation = () => {
  return useMutation((data: any) => http.post(API_ENDPOINTS.FORGOT_PASSWORD, { user: data }));
};

export default forgotPasswordMutation;
