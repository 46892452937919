import { toastNotification } from '@hooks/toast-notification';
import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function updateShipmentProduct(inputs: any) {
  const id = inputs.id;
  return http.put(`${API_ENDPOINTS.SHIPMENT_PRODUCTS}/${id}`, inputs);
}

export const useUpdateShipmentProductMutation = () => {
  const queryClient = useQueryClient();

  const {
    query: { id }
  } = useRouter();

  return useMutation((inputs: any) => updateShipmentProduct(inputs), {
    onSuccess: () => {
      queryClient.refetchQueries(`${API_ENDPOINTS.SHIPMENT_PACKAGES}?shipment_id=${id}`);
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PRODUCTS, {
        queryKey: id
      });
      toastNotification({
        type: 'success',
        description: 'Product updated for shipment'
      });
    }
  });
};
