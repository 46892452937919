import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getSignInAs = ({ id, props }: { id: string | number; props?: queryOptions }) => {
  return useQuery<any, Error>(
    [API_ENDPOINTS.SIGN_IN_AS(id), props?.params],
    queryFetch,
    props?.options
  );
};

export default getSignInAs;
