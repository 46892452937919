import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface UpdateCustomFieldDataType {
  id: string;
  description: string;
  always_shown: boolean;
}

const updateCustomFieldData = () => {
  return useMutation((data: UpdateCustomFieldDataType) =>
    http.put(API_ENDPOINTS.CUSTOM_FIELD_DATA_BY_ID(data.id), data)
  ) as any;
};

export default updateCustomFieldData;
