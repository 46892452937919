import { Variant } from '@components/dataDisplay/typography';
import Input, { InputProps } from '@components/dataEntry/input';
import cn from 'classnames';
import React, { FC, ReactNode, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import FormInputContainer, { FormInputContainerProps } from './form-input-container';
import { FormLabelType } from './form-label';

type Types = FormInputContainerProps & InputProps;
export interface FormInputType extends Types {
  label?: string | boolean;
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  defaultValue?: any;
  inputRef?: any;
  children?: ReactNode;
  inputClass?: string;
  labelClass?: string;
  containerClass?: string;
  align?: 'block' | 'inline';
  pattern?: 'onlyNumbers';
  labelSize?: FormLabelType['labelSize'];
  readOnly?: boolean;
  formId?: string;
  showError?: boolean;
  tooltipIcon?: JSX.Element;
  tooltipText?: string;
  capitalize?: boolean;
  LabelItemAction?: any;
  FeatureLabelOptions?;
  showBlank?: boolean;
  typographyVariant?: Variant;
}

const validateNotWhitespaceOnly = (value) => {
  const regexp = /^\S*$/;
  return !regexp.test(value) && value.length === 1;
};

const FormInput: FC<FormInputType> = ({
  defaultValue,
  name,
  placeholder,
  type = 'text',
  label,
  disabled,
  hidden = false,
  children,
  inputRef,
  accept,
  pattern,
  customOnChange,
  inputClass,
  containerClass,
  align = 'block',
  labelSize,
  readOnly,
  showError = true,
  tooltipIcon,
  tooltipText,
  typographyVariant,
  ...rest
}) => {
  const {
    control,
    readOnly: readOnlyForm,
    formState: { errors },
    getValues
  } = useFormContext() as any;

  const containerRef = useRef();

  const {
    field: { onChange, onBlur }
  } = useController({
    name,
    control,
    rules: { required: rest.required },
    defaultValue: defaultValue
  });
  const value = getValues(name);
  //TODO: set errors por inputs outside of React Hook Form
  const isError = errors && errors[name];
  const fullReadOnly = readOnly || readOnlyForm;

  const classNameContainer = cn('w-full', containerClass, {
    'flex gap-2 truncate': align === 'inline',
    'file-input': type === 'file',
    'mt-1': hidden,
    'h-full': type === 'textarea'
  });

  const classNameFormInput = cn(`${type === 'checkbox' ? '' : 'w-full'}`, inputClass);

  const onChangeWrapper = (eventOrText, onChange) => {
    const value = eventOrText.target.checked || eventOrText?.target?.value || eventOrText;
    if (validateNotWhitespaceOnly(value)) return;
    if (type === 'file') {
      return customOnChange(eventOrText);
    }

    if (customOnChange) customOnChange(value);

    if (type === 'checkbox') {
      return onChange(eventOrText.target.checked);
    }

    if (pattern === 'onlyNumbers') {
      return onChange(parseInt(value, 10) || '');
    }

    if (customOnChange) customOnChange(value);
    onChange(value);
  };

  rest.style = {
    ...rest.style
  };

  return (
    <FormInputContainer
      name={name}
      label={label}
      labelSize={labelSize}
      className={rest.labelClass}
      readOnly={fullReadOnly}
      showError={showError}
      readOnlyClassName={classNameFormInput}
      tooltipText={tooltipText}
      tooltipIcon={tooltipIcon}
      containerClass={classNameContainer}
      type={type}
      capitalize={rest.capitalize}
      LabelItemAction={rest.LabelItemAction}
      showBlank={rest.showBlank}
      typographyVariant={typographyVariant}
      FormReadOnlyProps={rest.FormReadOnlyProps}
      containerRef={containerRef}
      {...rest}
    >
      <span ref={containerRef} className="w-full">
        <Input
          inputRef={inputRef}
          type={type}
          id={name}
          name={name}
          onBlur={onBlur}
          onChange={(text) => onChangeWrapper(text, onChange)}
          value={value || defaultValue || ''}
          placeholder={placeholder}
          inputClassName={classNameFormInput}
          disabled={disabled}
          hidden={hidden}
          error={isError}
          accept={accept}
          readOnly={fullReadOnly}
          {...rest}
        />
        {children && <div id={name}>{children}</div>}
      </span>
    </FormInputContainer>
  );
};

export default FormInput;
