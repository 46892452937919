import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import { FeatureLabelOptions } from '@components/documentTemplates/types';
import { UserIcon } from '@heroicons/react/outline';
import useContactMaster from '@hooks/use-contact-master';
import useManageParams from '@hooks/use-manage-params';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { EntitiesFieldType } from '@services/types';
import { CommonStoreState } from '@store/commons';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2, { FormAutocompleteV2Props } from './form-autocomplete-v2';

const generatedDocumentsPaths = {
  '/documents/[id]': true,
  '/documents/[id]/edit': true
};

const ContactAutocomplete: FC<
  FormAutocompleteV2Props & {
    entity?: EntitiesFieldType;
    includeArchived?: boolean;
    allowEdit?: boolean;
    getValues?: any;
    showUserAddress?: boolean;
    FeatureLabelOptions?: FeatureLabelOptions;
    addressAllow?: boolean;
    ContactMasterProps?: {
      updateValuesWithContactMaster?: (
        contactMasterState: CommonStoreState['contactMasterState']
      ) => void | boolean;
    };
  }
> = ({ entity, ...props }) => {
  const { openModal, setModalStateByPosition } = useModal();
  const { setValue, getValues } = useFormContext();
  const contextValue = getValues(props.name);
  const onChange = setValue;
  const [contactMasterId, setContactMasterId] = useState(null);
  const { pathname } = useManageParams();
  const setAutocompleteCreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteCreateNewEntityItemState
  );
  const contactMasterState = useStore((state) => state.contactMasterState);
  const { data: { data: contactData = [] } = {}, refetch } = useRequest.contacts.get({
    params: { pagination: false },
    options: { staleTime: 0 }
  });
  const data = contactData?.reduce((acc, curr) => {
    const obj = {
      ...curr,
      value: curr.id,
      label: curr.name,
      descriptionForLabel: curr.address?.replaceAll('\n', ', ')
    };
    acc.push(obj);
    return acc;
  }, []);
  const autocompleteCreateNewEntityItemState = useStore(
    (state) => state.autocompleteCreateNewEntityItemState
  );

  const contact = data.find((c) => c.value == contextValue);

  useContactMaster({ contactMasterId, getDataToCompare: getValues, ...props.ContactMasterProps });

  const modalIdsByEntities = {
    contact: ModalIds.CONTACT_FORM,
    product: generatedDocumentsPaths[pathname]
      ? ModalIds.PRODUCT_FORM_DOCUMENT
      : ModalIds.PRODUCT_FORM
  };

  const handleCreate = (value) => {
    openModal({
      id: modalIdsByEntities[entity],
      isOpen: true,
      formValues: getValues(),
      inlineEditable: props?.inlineEditable
    });
    setAutocompleteCreateNewEntityItemState({
      inputId: props.name,
      createItemName: value
    });
  };

  const onHandleOnChange = (item, obj) => {
    if (obj.action === 'clear' || obj.action === 'remove-value') {
      onChange(props.name, '');
      return;
    }

    if (obj.action === 'create-option') {
      onChange(props.name, '');
      handleCreate(item.value);
      return;
    }

    onChange(props.name, item.id);
    setModalStateByPosition({ id: ModalIds.ADD_SHIPMENT_FORM, formValues: getValues() });
    if (props?.ContactMasterProps?.updateValuesWithContactMaster) setContactMasterId(item.id);
  };

  const onHandleEdit = () => {
    setAutocompleteCreateNewEntityItemState({
      inputId: props.name,
      includeArchived: props?.includeArchived
    });
    openModal({
      id: modalIdsByEntities[entity],
      isOpen: true,
      mode: 'edit',
      entityId: contextValue,
      formValues: getValues(),
      inlineEditable: props?.inlineEditable
    });
  };

  useEffect(() => {
    if (Object.values(autocompleteCreateNewEntityItemState.data).length > 0) {
      const selectedData = data.find(
        (data) => data.id == autocompleteCreateNewEntityItemState.data.id
      );
      if (!selectedData) {
        refetch();
      } else {
        onChange(
          autocompleteCreateNewEntityItemState.inputId,
          autocompleteCreateNewEntityItemState.data.id
        );
        setAutocompleteCreateNewEntityItemState({ data: {}, createItemName: '', inputId: '' });
      }
    }
  }, [autocompleteCreateNewEntityItemState.data]);

  useEffect(() => {
    if (
      contactMasterState?.selectedValues &&
      typeof props?.ContactMasterProps?.updateValuesWithContactMaster === 'function'
    ) {
      props?.ContactMasterProps?.updateValuesWithContactMaster(contactMasterState);
      contactMasterState.onCleanState();
    }
  }, [contactMasterState?.selectedValues]);

  return (
    <>
      <FormAutocompleteV2
        options={data}
        icon={<UserIcon className="w-4 h-4" />}
        onChange={onHandleOnChange}
        value={contextValue}
        link={`/contacts/${contextValue}`}
        {...(props.allowEdit && { onEdit: onHandleEdit })}
        {...props}
      />
      <div
        className={`mt-2 whitespace-pre-wrap ${
          (props.FeatureLabelOptions?.hidden && 'text-gray-200', props.inputClass)
        }`}
      >
        <RenderIf isTrue={props.addressAllow}>
          <div className="flex flex-col">
            <Typography variant="text-xs" className={'text-black font-semibold'}>
              {contact?.company}
            </Typography>
            <Typography variant="text-xs" className={'text-black'}>
              {contact?.address}
            </Typography>
          </div>
        </RenderIf>
      </div>
    </>
  );
};

export default ContactAutocomplete;
