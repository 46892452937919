export const PREFIX = {
  API: '/api'
};

export const API_ENDPOINTS = {
  PAYMENTS: `${PREFIX.API}/payments`,
  PAYMENTS_DETAILS: `${PREFIX.API}/payment_details`,
  ACCOUNTS: `${PREFIX.API}/accounts`,
  LOGOUT: `${PREFIX.API}/logout`,
  SIGN_IN_AS: (id) => `${PREFIX.API}/sign_in_as/${id}`,
  STOP_SIGN_IN_AS: `${PREFIX.API}/stop_sign_in_as`,

  SETTINGS: `${PREFIX.API}/settings`,
  GET_MANAGE_USERS: `${PREFIX.API}/settings/ManageUsers`,
  CREATE_USER: `${PREFIX.API}/invitations`,
  SET_NEW_PASSWORD: `${PREFIX.API}/invitations`,
  FORGOT_PASSWORD: `${PREFIX.API}/forgot_password`,
  RESET_PASSWORD: `${PREFIX.API}/reset_password`,
  NOTIFICATIONS: `${PREFIX.API}/notifications`,
  READ_NOTIFICATION_STATUS: (id) => `${PREFIX.API}/notifications/${id}`,
  BULK_READ_NOTIFICATION_STATUS: `${PREFIX.API}/notifications/bulk_update`,
  SHIPMENTS: `${PREFIX.API}/shipments`,
  GET_SHIPMENT: (id) => `${PREFIX.API}/shipments/${id}`,
  GET_SHIPMENT_COMPLETED: (id) => `${PREFIX.API}/shipments/${id}/completed`,
  UPDATE_SHIPMENT_TRACING_STATUS: (id) => `${PREFIX.API}/shipments/${id}/update_tracing_status`,
  CONTACTS: `${PREFIX.API}/contacts`,
  GET_CONTACT: (id) => `${PREFIX.API}/contacts/${id}`,
  GET_CONTACT_RECENT_SHIPMENT: (id) => `${PREFIX.API}/contacts/${id}/recent_shipments`,
  IMPORT_CONTACTS: `${PREFIX.API}/contacts/import_csv`,
  UPDATE_ARCHIVE_CONTACT: (id) => `${PREFIX.API}/contacts/${id}/set_archived`,
  BULK_ARCHIVE_CONTACTS: `${PREFIX.API}/contacts/bulk_archive`,
  FIELD_DATA: `${PREFIX.API}/field_data`,
  FIELD_DATA_BY_ID: (id) => `${PREFIX.API}/field_data/${id}`,
  CUSTOM_FIELD_MAPPINGS: `${PREFIX.API}/custom_field_mappings`,
  CUSTOM_FIELD_MAPPINGS_FIELD_TYPES: `${PREFIX.API}/custom_field_mappings/get_field_types`,
  CUSTOM_FIELD_MAPPINGS_NAMES: `${PREFIX.API}/custom_field_mappings/get_autocomplete_options`,
  CUSTOM_FIELD_MAPPINGS_BULK_UPDATE: `${PREFIX.API}/custom_field_mappings/bulk_update`,
  CUSTOM_FIELD_DATA_BY_ID: (id) => `${PREFIX.API}/custom_field_mappings/${id}`,
  PRODUCTS: `${PREFIX.API}/products`,
  GET_PRODUCT: (id) => `${PREFIX.API}/products/${id}`,
  GET_PRODUCT_RECENT_SHIPMENT: (id) => `${PREFIX.API}/products/${id}/recent_shipments`,
  GET_LANDED_COST: (id) => `${PREFIX.API}/products/${id}/landed_costs`,
  GET_PRODUCT_FILES: (id) => `${PREFIX.API}/products/${id}/files`,
  DELETE_PRODUCT_FILE: `${PREFIX.API}/products/delete_file`,
  BULK_DELETE_PRODUCT_FILES: `${PREFIX.API}/products/bulk_delete_file`,
  BULK_UPLOAD_PRODUCTS: `${PREFIX.API}/products/bulk_upload`,
  INCOMING_PRODUCTS: `${PREFIX.API}/products/incoming-products`,
  OUTGOING_PRODUCTS: `${PREFIX.API}/products/outgoing-products`,
  SEARCH: `${PREFIX.API}/search`,
  TASKS: `${PREFIX.API}/milestones`,
  GET_TASK_BY_ID: (id) => `${PREFIX.API}/milestones/${id}`,
  UPDATE_TASK_STATUS: (id, token) =>
    `${PREFIX.API}/milestones/${id}/update_status${!!token ? `?token=${token}` : ''}`,
  COMPLETE_TASK: (id, token) =>
    `${PREFIX.API}/milestones/${id}/complete_milestone${!!token ? `?token=${token}` : ''}`,
  LOGIN: `${PREFIX.API}/login`,
  // /generated_documents
  GENERATED_DOCUMENTS: `${PREFIX.API}/generated_documents`,
  GENERATED_DOCUMENTS_UPDATE_PDF: `${PREFIX.API}/generated_documents/update_pdf`,
  BULK_CREATE_GENERATED_DOCUMENTS: `${PREFIX.API}/generated_documents/bulk_create`,
  AUTO_FILL_GENERATED_DOCUMENTS: `${PREFIX.API}/generated_documents/auto_fill`,
  GET_GENERATED_DOCUMENTS: (id) => `${PREFIX.API}/generated_documents/${id}`,
  UPDATE_GENERATED_DOCUMENT: (id) => `${PREFIX.API}/generated_documents/${id}`,
  DELETE_GENERATED_DOCUMENT: (id) => `${PREFIX.API}/generated_documents/${id}`,
  GENERATED_DOCUMENTS_ACTIVITY: (id) => `${PREFIX.API}/generated_documents/${id}/activities`,
  GENERATED_DOCUMENTS_PRODUCT_ATTACHMENT: (id) =>
    `${PREFIX.API}/generated_documents/${id}/product_attachments`,
  BULK_DELETE_GENERATED_DOCUMENTS: `${PREFIX.API}/generated_documents/bulk_delete`,
  GET_GENERATED_DOCUMENTS_DOC_TYPES: `${PREFIX.API}/generated_documents/doc_types`,
  GENERATED_DOCUMENT_POST_COMMENT: (id) => `${PREFIX.API}/generated_documents/${id}/comment`,
  DOCUMENT_SHARES: `${PREFIX.API}/document_shares`,
  DOCUMENTS: `${PREFIX.API}/documents`,
  GET_DOCUMENT: (id) => `${PREFIX.API}/documents/${id}`,
  UPDATE_DOCUMENT: (id) => `${PREFIX.API}/documents/${id}`,
  UPDATE_DOCUMENT_FILENAME: `${PREFIX.API}/documents/update_filename`,
  DOCUMENT_ACTIVITY: (id) => `${PREFIX.API}/documents/${id}/activities`,
  DOCUMENT_POST_COMMENT: (id) => `${PREFIX.API}/documents/${id}/comment`,
  UPLOAD_TASK_DOCUMENT: (id, token) =>
    `${PREFIX.API}/documents?id=${id}&token=${token}&source=Milestone`,
  INTERCOM: `${PREFIX.API}/intercom`,
  WORKFLOWS: `${PREFIX.API}/workflows`,
  GET_WORKFLOW: (id) => `${PREFIX.API}/workflows/${id}`,
  DUPLICATE_WORKFLOW: (id) => `${PREFIX.API}/workflows/${id}/duplicate`,
  PLANS: `${PREFIX.API}/plans`,
  PLANS_IMPORT_WORKFLOW: `${PREFIX.API}/plans/import`,
  SHIPMENT_PRODUCTS: `${PREFIX.API}/shipment_products`,
  SHIPMENT_PACKAGES: `${PREFIX.API}/packages`,
  SHIPMENT_TRACKINGS: `${PREFIX.API}/shipment_trackers`,
  SHIPMENT_TRACKING_DETAIL: (id) => `${PREFIX.API}/shipment_trackers/${id}/show_details`,
  TRACK_SHIPMENT: `${PREFIX.API}/shipment_trackers/track`,
  SHIPMENT_TRACKINGS_BULK_DELETE: `${PREFIX.API}/shipment_trackers/bulk_delete`,
  SHIPMENT_TRACKINGS_IMPORT_FIELDS: `${PREFIX.API}/shipment_trackers/import_fields`,
  IMPORT_SHIPMENT_TRACKINGS: `${PREFIX.API}/shipment_trackers/import_csv`,
  GET_SHIPMENT_TRACKING: (id) => `${PREFIX.API}/shipment_trackers/${id}`,
  BULK_ADD_SHIPMENT_TRACKING: `${PREFIX.API}/shipment_trackers/bulk_update`,
  SHARES: `${PREFIX.API}/shares`,
  MASTER_CUSTOM_FIELDS: (id) => `${PREFIX.API}/contacts/${id}/master_custom_fields`,
  WORKFLOWS_TASKS: `${PREFIX.API}/task_templates`,
  ORGANIZATION: `${PREFIX.API}/organization`,
  ORGANIZATION_BY_ID: (id) => `${PREFIX.API}/organization/${id}`,
  ORGANIZATION_USER: `${PREFIX.API}/organizations_users`,
  ORGANIZATION_USER_BY_ID: (id) => `${PREFIX.API}/organizations_users/${id}`,
  REMOVE_LOGO: (id) => `${PREFIX.API}/organization/remove_logo?file_id=${id}`,
  // /shipments
  SHIPMENT_ACTIVITY: (id) => `${PREFIX.API}/shipments/${id}/activities`,
  SHIPMENT_COMMENT: (id) => `${PREFIX.API}/shipments/${id}/comment`,
  // /document_templates
  DOCUMENT_TEMPLATE: `${PREFIX.API}/document_templates`,
  GET_DOCUMENT_TEMPLATE: (id) => `${PREFIX.API}/document_templates/${id}`,
  UPDATE_DOCUMENT_TEMPLATE: (id) => `${PREFIX.API}/document_templates/${id}`,
  CREATE_TEMPLATE: `${PREFIX.API}/document_templates`,
  SIMILAR_DOCUMENTS: (id) => `${PREFIX.API}/generated_documents/${id}/similar_documents`,
  SHIPMENT_PACKAGES_FIELDS_TO_IMPORT: `${PREFIX.API}/packages/fields_to_import`,
  SHIPMENT_PACKAGES_STATUS: `${PREFIX.API}/packages/packing_complete_status`,
  // shipment costs
  SHIPMENT_COSTS: `${PREFIX.API}/costs`,
  GET_COST_CONVERSION_RATES: `${PREFIX.API}/cost_currency_conversion/get_conversion_rates`,
  GET_COST_CONVERSION_DETAILS: `${PREFIX.API}/cost_currency_conversion`,
  // dashboard
  DASHBOARD: `${PREFIX.API}/dashboard`
};
