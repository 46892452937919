import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { TaskResponseType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function addTask(inputs: TaskResponseType) {
  return http.post(`${API_ENDPOINTS.TASKS}`, { milestone: inputs });
}
export const useAddTaskMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((input: TaskResponseType) => addTask(input), {
    onSuccess: (data) => {
      appEvent(EventsNameEnum.Task_CreateTask, {
        task_id: data.data.id,
        shipment_id: data.data.shipment_id
      });
      queryClient.refetchQueries([API_ENDPOINTS.TASKS]);
      toastNotification({ type: 'success', description: 'Task was created' });
    }
  });
};
