import useRequest from '@services/api';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const documentTemplatesId = {
  purchase_order: '48',
  commercial_invoice: '49',
  quotation: '50',
  packing_list: '51',
  proforma_invoice: '52',
  "shipper's_letter_of_instruction": '53',
  truckload_bol: '59'
};

const useCreateADocument = () => {
  const { mutateAsync: generateNewDocument } = useRequest.generated_documents.create();

  const createDocument = useCallback(async () => {
    return await generateNewDocument({
      generated_document: {
        document_template_id: documentTemplatesId[router.query?.create_document as string],
        doc_type: router.query?.create_document,
        is_html_document: true
      }
    });
  }, []);

  const router = useRouter();

  useEffect(() => {
    if (!router.query?.create_document) return;
    // declare the data fetching function
    const fetchData = async () => {
      const { data } = await createDocument();

      router.push(`/documents/${data.document_id}/edit`);
    };

    // call the function
    fetchData();
  }, []);
};

export default useCreateADocument;
