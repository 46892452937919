import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkDeleteShipmentTrackingMutation = () => {
  return useMutation((data: any) => http.post(API_ENDPOINTS.SHIPMENT_TRACKINGS_BULK_DELETE, data));
};

export default bulkDeleteShipmentTrackingMutation;
