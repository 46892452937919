import { withComponent } from '@components/@utils/withComponent';
import RenderIf from '@components/common/render-if';
import { YEAR_MONTH_DAY_FORMAT } from '@services/constants';
import cn from 'classnames';
import dayjs from 'dayjs';
import {
  CSSProperties,
  FC,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useEffect,
  useRef
} from 'react';
import NumberFormat from 'react-number-format';
import InputAdornment, { InputAdornmentProps } from './input-adornment';
import Select, { SelectProps } from './select';
import Textarea, { TextareaProps } from './textarea';
import Toggle from './toggle-v2';

type Props = InputAdornmentProps &
  TextareaProps &
  SelectProps &
  InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends Props {
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  name?: string;
  inputRef?: any;
  type?:
    | HTMLInputTypeAttribute
    | 'autocomplete'
    | 'textarea'
    | 'select'
    | 'currency'
    | 'adornment'
    | 'number-format'
    | 'password';
  error?: Error;
  rows?: number;
  style?: CSSProperties;
  isTable?: boolean;
  isCustomStyle?: boolean;
  showError?: boolean;
  isRequired?: boolean;
  IconProps?: {
    LeftIcon?: any;
    RightIcon?: any;
  };
}

const Input: FC<InputProps> = ({
  inputClassName,
  name,
  placeholder,
  type = 'text',
  disabled,
  hidden = false,
  inputRef,
  error,
  rows,
  style,
  IconProps: { LeftIcon = '', RightIcon = '' } = {},
  ...rest
}) => {
  const ref = useRef();
  const componentRef = inputRef ? inputRef : ref;
  // eslint-disable-next-line no-unused-vars
  const { isRequired, ...restProps } = rest;
  let rootClassName = cn(inputClassName, {
    'form-input':
      type === 'text' ||
      type === 'tel' ||
      type === 'email' ||
      type === 'number' ||
      type === 'number-format',
    autocomplete: type === 'autocomplete',
    'form-date block w-full sm:text-sm ': type === 'date',
    'file-input': type === 'file',
    'form-textarea block w-full sm:text-sm sm:leading-5 overflow-y-hidden resize-none whitespace-pre-wrap':
      type === 'textarea',
    'form-input-error': error,
    'form-checkbox transition duration-150 ease-in-out select-item w-4': type === 'checkbox',
    [rest.className]: type === 'number-format'
  });
  const getValue = () => {
    if (type === 'date' && !rest.value) {
      return dayjs().format(YEAR_MONTH_DAY_FORMAT);
    }

    if (type === 'date' && rest.value) {
      var date = new Date(rest.value);
      // To normalize the date and eliminate the unwanted offset
      date = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
      return dayjs(date).format(YEAR_MONTH_DAY_FORMAT);
    }

    return rest.value;
  };
  const getMaxValue = () => {
    let max = rest.max;
    if (type === 'date' && !rest.max) {
      max = dayjs().add(5, 'year').format(YEAR_MONTH_DAY_FORMAT);
    }

    return max;
  };
  const getMinValue = () => {
    let min = rest.min;
    if (type === 'date' && !rest.min) {
      min = dayjs().subtract(5, 'year').format(YEAR_MONTH_DAY_FORMAT);
    }

    return min;
  };

  useEffect(() => {
    if (type === 'number') {
      const ignoreScroll = (e) => {
        e.preventDefault();
      };
      componentRef?.current?.addEventListener('wheel', ignoreScroll);
    }
  }, [componentRef]);

  if (type === 'autocomplete') {
    type = 'text';
  }

  if (type === 'textarea') {
    return (
      <Textarea
        inputRef={componentRef}
        id={name}
        name={name}
        placeholder={placeholder}
        className={rootClassName}
        disabled={disabled}
        autoComplete="off"
        spellCheck="false"
        hidden={hidden}
        style={style}
        rows={rows}
        {...restProps}
      />
    );
  }

  if (type === 'toggle') {
    return (
      <Toggle
        inputRef={componentRef}
        name={name}
        id={name}
        checked={rest.checked}
        disabled={disabled}
        {...restProps}
      />
    );
  }

  if (type === 'select') {
    return (
      <Select
        inputRef={componentRef}
        id={name}
        name={name}
        placeholder={placeholder}
        className={rootClassName}
        disabled={disabled}
        hidden={hidden}
        style={style}
        rows={rows}
        {...restProps}
      />
    );
  }

  if (type === 'adornment') {
    return <InputAdornment {...restProps} />;
  }

  if (type === 'number-format') {
    return (
      <NumberFormat
        id={name}
        name={name}
        customInput={Input}
        ref={componentRef}
        placeholder={placeholder}
        allowNegative={false}
        mask="_"
        value={rest.value as string}
        thousandSeparator={true}
        prefix={rest?.prefix}
        decimalScale={3}
        defaultValue={rest?.defaultValue as string}
        className={rootClassName}
        onBlur={rest.onBlur}
        style={style}
        disabled={disabled}
        readOnly={rest?.readOnly}
        onValueChange={(values) => {
          const { value } = values;
          if (rest.onChange) {
            rest.onChange({ target: { value, name } });
          }
        }}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <input
        className={rest.isCustomStyle ? rest.className : rootClassName}
        ref={componentRef}
        type={type}
        id={name}
        name={name}
        value={rest.value}
        checked={rest.checked || Boolean(rest.value)}
        {...restProps}
      />
    );
  }

  const LeftIconComponent = withComponent(LeftIcon, LeftIcon);
  const RightIconComponent = withComponent(RightIcon, RightIcon);

  return (
    <>
      <RenderIf isTrue={!!LeftIconComponent || !!RightIconComponent}>
        <div className="relative">
          <RenderIf isTrue={!!LeftIconComponent}>
            <LeftIconComponent />
          </RenderIf>
          <input
            ref={componentRef}
            type={type}
            id={name}
            name={name}
            placeholder={placeholder || (type === 'number' ? '0.00' : '')}
            className={rest.isCustomStyle ? rest.className : rootClassName}
            disabled={disabled}
            autoComplete="off"
            spellCheck="false"
            style={style}
            hidden={hidden}
            min={getMinValue()}
            max={getMaxValue()}
            step="any"
            {...restProps}
            value={getValue()}
          />
          <RenderIf isTrue={!!RightIconComponent}>
            <RightIconComponent />
          </RenderIf>
        </div>
      </RenderIf>
      <RenderIf isTrue={!LeftIconComponent && !RightIconComponent}>
        <input
          ref={componentRef}
          type={type}
          id={name}
          name={name}
          placeholder={placeholder || (type === 'number' ? '0.00' : '')}
          className={rest.isCustomStyle ? rest.className : rootClassName}
          disabled={disabled}
          autoComplete="off"
          spellCheck="false"
          style={style}
          hidden={hidden}
          onKeyPress={(e) => {
            e.key === 'Enter' && e.preventDefault();
          }}
          min={getMinValue()}
          max={getMaxValue()}
          step="any"
          {...restProps}
          defaultValue={rest.defaultValue}
          value={getValue()}
        />
      </RenderIf>
    </>
  );
};

export default Input;
