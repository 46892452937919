import { FC } from 'react';

export interface CardProps {
  header: string | JSX.Element;
}

const Card: FC<CardProps> = ({ header, children }) => {
  return (
    <div className="bg-white sm:rounded-lg sm:shadow">
      <div className="bg-gray-50 p-3 border-b border-gray-200">
        <div className="font-medium text-xs text-gray-500">{header}</div>
      </div>
      {children}
    </div>
  );
};

export default Card;
