import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

export interface Type {
  name: string;
  role: string;
  type: string;
  id: string;
}

const updateOrganizationUserMutation = () => {
  return useMutation((data: Type) =>
    http.put(
      API_ENDPOINTS.ORGANIZATION_USER_BY_ID(data.id),
      jsonToFormData({ schema: 'organizations_user', inputs: data })
    )
  ) as any;
};

export default updateOrganizationUserMutation;
