import cn from 'classnames';
import { FC, SelectHTMLAttributes } from 'react';

export interface OptionsListProps {
  name: string;
  value: string | number;
}

const list = {
  'select-import-export': [
    { name: 'Import', value: 'import' },
    { name: 'Export', value: 'export' }
  ],
  'select-shipment-type': [
    { value: 'FCL', name: 'FCL' },
    { value: 'LCL', name: 'LCL' },
    { value: 'FTL', name: 'FTL' },
    { value: 'LTL', name: 'LTL' },
    { value: 'AIR', name: 'AIR' },
    { value: 'BREAK BULK', name: 'BREAK BULK' }
  ],
  'select-dispatch-method': [
    { value: 'SEA', name: 'SEA' },
    { value: 'AIR', name: 'AIR' },
    { value: 'TRUCK', name: 'TRUCK' },
    { value: 'RAIL', name: 'RAIL' }
  ],
  'select-currency': [
    { value: 'USD', name: 'USD' },
    { value: 'GBP', name: 'GBP' },
    { value: 'CAD', name: 'CAD' },
    { value: 'INR', name: 'INR' },
    { value: 'EUR', name: 'EUR' },
    { value: 'CNY', name: 'CNY' }
  ],
  'select-contact-type': [
    { value: 'customer', name: 'Customer' },
    { value: 'exporter', name: 'Exporter' },
    { value: 'importer', name: 'Importer' },
    { value: 'carrier', name: 'Carrier' },
    { value: 'freight_forwarder', name: 'Freight Forwarder' },
    { value: 'third_party_logistics', name: 'Third Party Logistics' },
    { value: 'supplier', name: 'Supplier' }
  ]
};

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  name?: string;
  inputRef?: any;
  error?: Error;
  rows?: number;
  optionsList?:
    | OptionsListProps[]
    | 'select-import-export'
    | 'select-dispatch-method'
    | 'select-shipment-type'
    | 'select-import-export'
    | 'select-currency';
  allowEmptyValue?: boolean;
  labelClass?: string;
  autoFocus?: boolean;
}

const Select: FC<SelectProps> = ({
  className,
  name,
  id,
  placeholder,
  disabled,
  hidden = false,
  inputRef,
  error,
  optionsList,
  allowEmptyValue = false,
  autoFocus,
  ...rest
}) => {
  const rootClassName = cn(className, 'form-select block sm:text-sm sm:leading-5', {
    'form-input-error': error,
    'text-gray-400': rest.value === ''
  });

  const options: OptionsListProps[] =
    typeof optionsList === 'string' ? list[optionsList] : optionsList;

  return (
    <select
      ref={inputRef}
      id={id}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      hidden={hidden}
      className={rootClassName}
      onChange={(e) => rest.onChange(e)}
      value={rest.value}
      autoFocus={autoFocus}
      {...rest}
    >
      {allowEmptyValue && <option value="">Select</option>}
      {options?.map(({ name, value }, i) => (
        <option key={name + i} value={value} selected={value === rest.defaultValue}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default Select;
