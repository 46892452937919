import RenderIf from '@components/common/render-if';
import useTrialSales from '@hooks/use-trial-sales';
import Link from 'next/link';

export default function FreeTrialBanner() {
  const { onDaysTrialValidation, freeTrialExpired, hasSubscription } = useTrialSales();

  if (hasSubscription) return <></>;

  const header = {
    title: 'Your free trial period has ended.',
    description:
      'Your free trial period has ended. Please upgrade your account to continue using Cargoflip.'
  };

  return (
    <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-primary-green-400 px-6 py-1.5 sm:px-3.5 justify-center">
      <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
        <RenderIf isTrue={!freeTrialExpired}>
          <p className="text-sm leading-6  text-white">
            Welcome to your free trial! You have{' '}
            <strong>{onDaysTrialValidation()?.remainingDays}</strong> days left to explore
            Cargoflip. &nbsp;
          </p>
        </RenderIf>
        <RenderIf isTrue={freeTrialExpired}>
          <p className="text-sm leading-6  text-white">
            {header.title} {header.description}&nbsp;
          </p>
        </RenderIf>
        <a
          href="https://www.cargoflip.com/free-demo"
          className="flex-none rounded-md bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Book your onboarding <span aria-hidden="true">&rarr;</span>
        </a>
        <RenderIf isTrue={!freeTrialExpired}>
          <Link passHref href="/settings/plans">
            <a className="flex-none rounded-md bg-white px-3.5 py-1 text-sm font-semibold text-black shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
              Upgrade your plan <span aria-hidden="true">&rarr;</span>
            </a>
          </Link>
        </RenderIf>
      </div>
    </div>
  );
}
