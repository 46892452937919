export enum EventsNameEnum {
  App_Open = 'App Open',
  //
  Shipment_CreateShipment = 'Create Shipment',
  Shipment_UpdateShipment = 'Update Shipment',
  Shipment_DeleteShipment = 'Delete Shipment',
  //
  Contact_CreateContact = 'Create Contact',
  Contact_UpdateContact = 'Update Contact',
  Contact_DeleteContact = 'Delete Contact',
  //
  Document_CreateDocument = 'Create Document',
  Document_UpdateDocument = 'Update Document',
  Document_DeleteDocument = 'Delete Document',
  Document_DownloadDocument = 'Download Document',
  Document_SendDocument = 'Send Document',
  Document_UploadDocument = 'Upload Document',
  //
  Product_CreateProduct = 'Create Product',
  Product_UpdateProduct = 'Update Product',
  Product_DeleteProduct = 'Delete Product',
  //
  ShipmentProduct_CreateShipmentProduct = 'Create Shipment Product',
  ShipmentProduct_UpdateShipmentProduct = 'Update Shipment Product',
  ShipmentProduct_DeleteShipmentProduct = 'Delete Shipment Product',
  //
  Tracking_CreateTracking = 'Create Tracking',
  //
  Task_CreateTask = 'Create Task',
  Task_UpdateTask = 'Update Task'
}

export type EventsType = EventsNameEnum;

export type IdentifyEventType = {
  email: string;
  role: string;
  organization: string;
  name: string;
  organization_id?: string;
  organization_created_at: Date;
};

export type EventPayload = {
  shipment_id?: string;
  contact_id?: string;
  document_id?: string;
  product_id?: string;
  shipment_product_id?: string;
  tracking_id?: string;
  task_id?: string;
};
