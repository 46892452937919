import { InputTypes } from 'constants/inputs-types';
import { DocumentSections, DocumentTemplateType } from '../types';
import { sliData } from './@common/packing.config';

export const ShippersLetterOfInstruction: DocumentTemplateType[] = [
  {
    name: 'Shipper',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowSpan: 2, rowStart: 1 }
  },
  {
    name: 'name',
    label: 'Reference',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 7 }
  },
  {
    name: 'Buyer Reference',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 7, colSpan: 3 }
  },
  {
    name: 'Export Declaration Number',
    label: 'Export Declaration No.',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 10 }
  },
  {
    name: 'Consignee',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, rowStart: 4, colSpan: 6 }
  },
  {
    name: 'freight_forwarder',
    label: 'Forwarding Agent',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { rowStart: 4, colSpan: 6, rowSpan: 2 }
  },
  {
    name: 'Notify Party',
    label: 'Notify Party (If not Consignee)',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, rowStart: 5, colSpan: 6 }
  },
  {
    name: 'method_of_shipping',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-dispatch-method',
      defaultValue: 'SEA'
    },
    GridProps: { colStart: 1, rowStart: 6, colSpan: 3 }
  },
  {
    name: 'type_of_shipment',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-shipment-type',
      defaultValue: 'FCL'
    },
    GridProps: { rowStart: 6, colSpan: 3 }
  },
  {
    name: 'country_of_origin',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 6, colSpan: 3, rowSpan: 2 }
  },
  {
    name: 'country_of_destination',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 6, colSpan: 3, rowSpan: 2 }
  },
  {
    name: 'Vessel',
    type: 'text',
    label: 'Vessel / Aircraft',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 7, colSpan: 3 }
  },
  {
    name: 'Place of Receipt',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 4, rowStart: 7, colSpan: 3 }
  },
  {
    name: 'departure_date',
    type: 'date',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 8, colSpan: 3 }
  },
  {
    name: 'port_of_loading',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 8, colSpan: 3 }
  },
  {
    name: 'Place of Receipt',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 4, rowStart: 7, colSpan: 3 }
  },
  {
    name: 'Freight Charges',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 7, rowStart: 8, colSpan: 3 }
  },
  {
    name: 'Document Instructions',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 10, rowStart: 8, colSpan: 3 }
  },
  {
    name: 'port_of_discharge',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 9, colSpan: 3 }
  },
  {
    name: 'Final Destination',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 9, colSpan: 3 }
  },
  {
    name: 'incoterms',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 7, rowStart: 9, colSpan: 3 }
  },
  {
    name: 'Declared Value',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 9, colSpan: 3 }
  },
  {
    name: 'product_table',
    type: InputTypes.PACKAGE_TABLE,
    section: DocumentSections.productTable,
    options: {
      entity: 'packages',
      TableProps: {
        type: 'sli',
        data: sliData
      }
    },
    GridProps: { rowStart: 9, colSpan: 12 }
  },
  {
    name: 'Special Instructions',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { rowStart: 1, colSpan: 12, bl: 1 }
  },
  {
    name: 'Declaration',
    type: 'textarea',
    section: DocumentSections.postProductTable,
    InputProps: {
      containerClass: 'h-full',
      maxLength: 960,
      limitRows: 12
    },
    GridProps: { colStart: 1, rowStart: 11, colSpan: 6, rowSpan: 4 }
  },
  {
    type: 'subtitle',
    name: 'Place & Date of Issue',
    label: 'Place & Date of Issue',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 11, colSpan: 3 }
  },
  {
    name: 'Place & Date of Issue',
    label: false,
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 10, rowStart: 11, colSpan: 3 }
  },
  {
    type: 'subtitle',
    name: 'Signatory Company',
    label: 'Signatory Company',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 12, colSpan: 3 }
  },
  {
    name: 'Signatory Company',
    label: false,
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 10, rowStart: 12, colSpan: 3 }
  },
  {
    type: 'subtitle',
    name: 'Name of Authorized Signatory',
    label: 'Name of Authorized Signatory',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 13, colSpan: 3 }
  },
  {
    name: 'Name of Authorized Signatory',
    label: false,
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 10, rowStart: 13, colSpan: 3 }
  },
  {
    name: 'signature',
    type: 'signature',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 14 }
  }
];
