import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getCurrencyConversionDetails = (props?: queryOptions) => {
  return useQuery(
    [API_ENDPOINTS.GET_COST_CONVERSION_DETAILS, props?.params],
    queryFetch,
    props?.options
  );
};

export default getCurrencyConversionDetails;
