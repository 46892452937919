import useRequest from '@services/api';
import { EntitiesFieldType } from '@services/types';
import { useEffect, useState } from 'react';

const useGetListByEntity = ({
  entity,
  enabled,
  params
}: {
  entity?: EntitiesFieldType;
  enabled?: boolean;
  params?: any;
}) => {
  if (!entity) return { data: [], loading: false };
  const [data, setData] = useState<any>([]);
  const hooksByEntities = {
    contact: useRequest.contacts.get,
    shipment: useRequest.shipments.get,
    document: useRequest.generated_documents.get,
    task: useRequest.tasks.get,
    product: useRequest.products.get,
    user: useRequest.user.getManageUsers,
    tracking: useRequest.shipment_trackings.get
  };

  const {
    data: entitiesData,
    isLoading: loading,
    refetch
  } = hooksByEntities[entity]({
    params: { pagination: false, ...params },
    options: { enabled, staleTime: 0 }
  });

  useEffect(() => {
    if (entitiesData?.data?.length > 0) {
      setData(entitiesData.data);
    }
  }, [entitiesData]);

  return {
    data,
    loading,
    refetch
  };
};

export default useGetListByEntity;
