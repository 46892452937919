import useRequest from '@services/api';
import {
  ContactsQueryOptionsType,
  EntitiesFieldType,
  ProductsQueryOptionsType,
  ShipmentsQueryOptionsType
} from '@services/types';
import { useStore } from '@store/useStore';
import { useEffect, useState } from 'react';
import { usePrevious } from './use-previous';
const fetch = async (fetch) => {
  try {
    await fetch();
  } catch (error) {
    console.error(error);
  }
};
const addValuesByEntity = {
  contact: ['id', 'name'],
  product: ['id', 'name'],
  user: ['id', 'email'],
  shipment: ['id', 'name'],
  customFieldTypes: ['value', 'name']
};

// Put the object item with entity key which you want to show as a description label
const getDescriptionByEntity = (data) => {
  return {
    product: data.description,
    customFieldTypes: data.description,
    contact: data.address?.replaceAll('\n', ', ')
  };
};

const getDescriptionLabel = (data: any = {}, entity = '') => {
  const conditionsForDescription = {
    product: !!data.description,
    customFieldTypes: !!data.description,
    contact: data.address?.replaceAll('\n', ', ')
  };

  return conditionsForDescription[entity] ? getDescriptionByEntity(data)[entity] : '';
};

const useGetAutocompleteListByEntity = ({
  entity,
  disabledList = [],
  isRefetch = false,
  queryParams = {},
  includeArchived = false
}: {
  entity?: EntitiesFieldType;
  disabledList?: string[];
  isRefetch?: boolean;
  queryParams?: ShipmentsQueryOptionsType | ContactsQueryOptionsType | ProductsQueryOptionsType;
  includeArchived?: boolean;
}) => {
  if (!entity) return { data: [], loading: false };
  const [data, setData] = useState([]);
  const [archivedData, setArchivedData] = useState([]);
  const previousDisabledList = usePrevious(disabledList);
  const isLoading = useStore((state) => state.autocompleteLoading);
  const setAutocompleteLoading = useStore((state) => state.setAutocompleteLoading);
  const hooksByEntities = {
    contact: useRequest.contacts.get,
    shipment: useRequest.shipments.get,
    product: useRequest.products.get,
    user: useRequest.user.getManageUsers,
    customFieldTypes: useRequest.custom_fields.getFieldTypes
  };

  const {
    data: entitiesData,
    isLoading: loading,
    refetch
  } = hooksByEntities[entity]({
    params: { pagination: false, ...queryParams },
    options: { staleTime: 0 }
  });
  const { data: archivedEntitiesData, isLoading: archivedLoading } = includeArchived
    ? hooksByEntities[entity]({
        params: { pagination: false, archived: true },
        options: { staleTime: 0 }
      })
    : { data: [], isLoading: false };
  const getIsDisabled = (item) => disabledList.length > 0 && disabledList.includes(item);

  const getUpdatedList = (res) => {
    const updateList = res?.reduce((acc, curr) => {
      const obj = {
        ...curr,
        value: String(curr[addValuesByEntity[entity][0]]),
        label: String(curr[addValuesByEntity[entity][1]]),
        isDisabled: getIsDisabled(String(curr[addValuesByEntity[entity][1]])),
        descriptionForLabel: getDescriptionLabel(curr, entity)
      };
      acc.push(obj);
      return acc;
    }, []);
    return updateList;
  };

  useEffect(() => {
    if (entity || isRefetch) {
      // setAutocompleteLoading(true);
      fetch(refetch);
      let res;
      // @ts-ignore
      res = !loading && entitiesData ? entitiesData.data : [];
      const updateList = getUpdatedList(res);
      setData(updateList);
      setAutocompleteLoading(false);
    }
  }, [entity, isLoading, loading, isRefetch]);

  useEffect(() => {
    if (includeArchived) {
      let res;
      // @ts-ignore
      res = !archivedLoading && archivedEntitiesData ? archivedEntitiesData.data : [];
      setArchivedData(res);
    }
  }, [entity, archivedLoading, loading]);

  useEffect(() => {
    const res = !loading && entitiesData ? entitiesData.data : [];
    if (
      (res &&
        previousDisabledList &&
        disabledList &&
        previousDisabledList.length !== disabledList.length) ||
      disabledList.length > 0
    ) {
      const updateList = getUpdatedList(res);
      setData(updateList);
    }
  }, [disabledList, isRefetch]);

  return {
    data,
    loading,
    refetch,
    archivedData
  };
};
export default useGetAutocompleteListByEntity;
