import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface UpdateFieldDataType {
  id: string;
  always_shown?: boolean;
  row_order_position?: number;
  sidebar_order_position?: number;
}

const updateFieldData = () => {
  return useMutation((data: UpdateFieldDataType) =>
    http.put(API_ENDPOINTS.FIELD_DATA_BY_ID(data.id), { field_data: data })
  );
};

export default updateFieldData;
