export const formatSelectStringList = (str) => {
  if (!str) return [];
  if (typeof str === 'string') {
    const reduce = Array.from((str && str?.split(',')) || []).reduce((acc: [], item) => {
      const obj = {
        value: item,
        name: item
      };
      // @ts-ignore
      acc.push(obj);
      return acc;
    }, []);
    return reduce;
  }
};
