import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const updateAllNotificationMutation = () => {
  return useMutation((data: any) =>
    http.put(`${API_ENDPOINTS.NOTIFICATIONS}/update_all_read`, data)
  );
};

export default updateAllNotificationMutation;
