import { InputTypes } from 'constants/inputs-types';
import { DocumentSections, DocumentTemplateType } from '../types';
import { truckLoadBolData } from './@common/packing.config';

export const TruckloadBoL: DocumentTemplateType[] = [
  {
    name: 'Shipper',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowSpan: 4, rowStart: 1 }
  },
  {
    name: 'shipper_reference',
    type: 'text',
    label: "Shipper's reference",
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 7, colSpan: 3 }
  },
  {
    name: 'doc_date',
    label: 'Date',
    type: 'date',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 10, colSpan: 3 }
  },
  {
    name: 'carrier_reference',
    type: 'text',
    label: "Carrier's reference",
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 7, colSpan: 3 }
  },
  {
    name: 'bl_no',
    label: 'B/L No.',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 10 }
  },

  {
    name: 'trailer_no',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 3, colStart: 7 }
  },

  {
    name: 'seal_no',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 4, colStart: 7 }
  },
  {
    name: 'Consignee',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, rowStart: 5, colSpan: 6 }
  },
  {
    name: 'carrier',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { rowStart: 5, colSpan: 6, rowSpan: 2 }
  },
  {
    name: 'freight_charge_terms',
    label: 'Freight Charge Terms (prepaid unless marked otherwise)',
    type: 'checkbox',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 7, colStart: 1, colSpan: 6 }
  },
  {
    name: 'freight_charges_bill_to',
    label: 'Freight Charges - Bill To',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { rowStart: 8, colStart: 1, colSpan: 6 }
  },

  {
    name: 'additional_information',
    type: 'textarea',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 7, colStart: 7, rowSpan: 2 }
  },
  {
    name: 'product_table',
    type: InputTypes.PACKAGE_TABLE,
    section: DocumentSections.productTable,
    options: {
      entity: 'packages',
      TableProps: {
        type: 'truckLoadBol',
        data: truckLoadBolData
      }
    },
    GridProps: { rowStart: 9, colSpan: 12 }
  },
  {
    type: 'subtitle',
    label:
      'NOTE - Where the rate is dependent on value, shippers are required to state specifically in writing the agreed or declared value of the property.',
    section: DocumentSections.postProductTable,
    GridProps: { rowStart: 1, colSpan: 12, bb: 0, bl: 1 },
    options: {
      disabledOptions: true
    }
  },
  {
    name: 'agreed_or_declared_value',
    type: 'subtitle',
    label:
      'The agreed or declared value of the property is hereby specifically stated by the shipper to be not exceeding:',
    section: DocumentSections.postProductTable,
    GridProps: { rowStart: 10, colStart: 1, colSpan: 10, br: 0 },
    options: {
      disabledOptions: true
    }
  },
  {
    name: 'agreed_or_declared_value',
    label: false,
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { rowStart: 10, colStart: 11, colSpan: 2 }
  },
  {
    name: 'carrier_shall_not_make_delivery',
    type: 'subtitle',
    label:
      'The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges.',
    section: DocumentSections.postProductTable,
    GridProps: { rowStart: 11, colStart: 1 },
    options: {
      disabledOptions: true
    }
  },
  {
    name: 'terms_and_conditions',
    type: 'textarea',
    section: DocumentSections.postProductTable,
    InputProps: {
      containerClass: 'h-full',
      maxLength: 960,
      limitRows: 12
    },
    GridProps: { colStart: 1, rowStart: 12, colSpan: 6, rowSpan: 4 }
  },
  {
    name: 'shipper_signature',
    label: 'Shipper’s Signature',
    type: 'signature',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 12 }
  },
  {
    name: 'Shipper_signature_date',
    type: 'date',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 13 }
  },
  {
    name: 'carrier_signature',
    label: 'Carrier’s Signature',
    type: 'signature',
    SignatureProps: {
      disableAutoSignature: true
    },
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 14 }
  },
  {
    name: 'carrier_signature_date',
    type: 'date',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 15 }
  }
];
