import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getContact = (
  { id },
  options?: ReactQueryOptionsType
): any /*TODO: Add Document response type */ => {
  return useQuery([API_ENDPOINTS.GET_CONTACT(id)], queryFetch, options);
};

export default getContact;
