import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function bulkDeleteWorkflows(inputs: any) {
  return http.post(`${API_ENDPOINTS.WORKFLOWS}/bulk_delete`, inputs);
}

export const useBulkDeleteWorkflowsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((input: any) => bulkDeleteWorkflows(input), {
    onSuccess: () => {
      queryClient.refetchQueries(`${API_ENDPOINTS.WORKFLOWS}`);
      toastNotification({
        type: 'success',
        description: 'Workflows were successfully deleted.'
      });
    }
  });
};
