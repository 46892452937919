import RenderIf from '@components/common/render-if';
import { removeNoSynced } from '@components/documentTemplates/logic';

import Tooltip from '@components/general/tooltip';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { capitalizeWords } from '@utils/capitaize-words';
import cn from 'classnames';
import { FC } from 'react';

export interface FormLabelType {
  className?: string;
  classNameContainer?: string;
  label?: string | boolean;
  labelSize?: 'xs' | 's' | 'm' | 'lg' | 'xl';
  readOnly?: boolean;
  name?: string;
  isDocument?: boolean;
  tooltipIcon?: JSX.Element;
  tooltipText?: string;
  isRequired?: boolean;
}

const FormLabel: FC<FormLabelType> = ({
  className,
  classNameContainer,
  label = true,
  name,
  labelSize = 's',
  readOnly = false,
  isDocument = false,
  tooltipIcon,
  tooltipText,
  isRequired
}) => {
  const labelClassName = cn('block self-center', className, {
    'text-sm': labelSize === 's',
    'text-xs': labelSize === 'xs',
    'text-md': labelSize === 'm',
    'text-lg': labelSize === 'lg',
    'text-xl': labelSize === 'xl'
  });

  const labelContainer = cn(classNameContainer, 'flex', {
    'ml-1': !readOnly && isDocument
  });
  const labelText =
    typeof label === 'boolean' && name ? capitalizeWords(removeNoSynced(name)) : label;
  const labelFormat = isRequired ? `${removeNoSynced(labelText)} *` : removeNoSynced(labelText);

  if (!label) return <></>;
  return (
    <div className={labelContainer}>
      <div className={`flex flex-row ${labelClassName ? labelClassName : ''}`}>
        <span>{labelFormat}</span>
        <RenderIf isTrue={!!tooltipText}>
          <span className="flex items-center justify-center ml-1 relative">
            <Tooltip description={tooltipText}>
              {tooltipIcon ? tooltipIcon : <InformationCircleIcon className="h-4 w-4" />}
            </Tooltip>
          </span>
        </RenderIf>
      </div>
    </div>
  );
};

export default FormLabel;
