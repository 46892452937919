import cn from 'classnames';
import { FC } from 'react';

export type BadgeVariant = 'danger' | 'warning' | 'info' | 'success';

export type BadgeBackgroundClass =
  | 'bg-red-200'
  | 'bg-yellow-200'
  | 'bg-emerald-200'
  | 'bg-gray-200';

export type BadgeBackgroundHoverClass =
  | 'hover:bg-red-300'
  | 'hover:bg-yellow-300'
  | 'hover:bg-emerald-300'
  | 'hover:bg-gray-300';

export type BadgeSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type TextSize = 'text-xs' | 'text-sm' | 'text-base' | 'h2' | 'h1';

export type SizeClass =
  | 'px-2 py-0.5'
  | 'px-2.5 py-1'
  | 'px-3 py-1.5'
  | 'px-3.5 py-2'
  | 'px-4 py-2.5';

export interface BadgeProps {
  variant: BadgeVariant;
  size?: BadgeSize;
  onClick?: () => void;
  classNames?: string;
}

export const badgeVariantToClassMap: Record<BadgeVariant, BadgeBackgroundClass> = {
  danger: 'bg-red-200',
  warning: 'bg-yellow-200',
  success: 'bg-emerald-200',
  info: 'bg-gray-200'
};

export const badgeHoverVariantToClassMap: Record<BadgeVariant, BadgeBackgroundHoverClass> = {
  danger: 'hover:bg-red-300',
  warning: 'hover:bg-yellow-300',
  success: 'hover:bg-emerald-300',
  info: 'hover:bg-gray-300'
};

export const sizeToClassMap: Record<BadgeSize, SizeClass> = {
  xs: 'px-2 py-0.5',
  sm: 'px-2.5 py-1',
  md: 'px-3 py-1.5',
  lg: 'px-3.5 py-2',
  xl: 'px-4 py-2.5'
};

export const fontsizeToClassMap: Record<BadgeSize, TextSize> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'h2',
  xl: 'h1'
};

const Badge: FC<BadgeProps> = ({ variant, classNames, children, size = 'xs', onClick }) => {
  const spanClassName = cn(
    `badge ${fontsizeToClassMap[size]} ${badgeVariantToClassMap[variant]} ${sizeToClassMap[size]} `,
    {
      [badgeHoverVariantToClassMap[variant]]: onClick,
      [classNames]: classNames
    }
  );

  return (
    <span className={spanClassName} onClick={onClick ? onClick : null}>
      {children}
    </span>
  );
};

export default Badge;
