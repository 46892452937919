export enum InputTypes {
  ARRAY = 'array',
  SUBTITLE = 'subtitle',
  SIGNATURE = 'signature',
  PRODUCT_TABLE = 'product-table',
  PACKAGE_TABLE = 'package-table',
  SELECT = 'select',
  DATE = 'date',
  AUTOCOMPLETE = 'autocomplete',
  SELECT_DISPATCH_METHOD = 'select-dispatch-method',
  SELECT_SHIPMENT_TYPE = 'select-shipment-type',
  SELECT_CURRENCY = 'select-currency',
  SELECT_COUNTRY = 'select-country'
}
