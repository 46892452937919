import { useQuery } from 'react-query';
import {
  ReactQueryOptionsType,
  ShipmentDetailsTabsQueryOptionsType,
  ShipmentPayments
} from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getShipmentPaymentDetails = ({
  params,
  options
}: {
  params?: ShipmentDetailsTabsQueryOptionsType;
  options?: ReactQueryOptionsType;
}) => {
  return useQuery<ShipmentPayments, Error>(
    [API_ENDPOINTS.PAYMENTS_DETAILS, params],
    queryFetch,
    options
  );
};

export default getShipmentPaymentDetails;
