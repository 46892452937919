import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const updateDocumentTemplatesMutation = (props?: { id?: string }) => {
  return useMutation((data: any) =>
    http.put(API_ENDPOINTS.UPDATE_DOCUMENT_TEMPLATE(data?.id || props?.id), data)
  );
};

export default updateDocumentTemplatesMutation;
