import { CollectionIcon } from '@heroicons/react/outline';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useStore } from '@store/useStore';
import { formatAutocompleteData } from '@utils/format-autocomplete-data';
import { ModalIds } from 'constants/modal-ids';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2 from './form-autocomplete-v2';

const FormShipmentAutoComplete = ({
  label,
  name,
  clearValue,
  disabledList
}: {
  label: string;
  name: string;
  clearValue?: boolean;
  disabledList?: any[];
}) => {
  // hook
  const { openModal } = useModal();

  const { setValue, getValues } = useFormContext();
  const onChange = setValue;

  // states
  const autocompleteV2CreateNewEntityItemState = useStore(
    (state) => state.autocompleteV2CreateNewEntityItemState
  );
  const setAutocompleteV2CreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteV2CreateNewEntityItemState
  );

  // query
  const {
    data: { data = [] } = {},
    refetch,
    isLoading
  } = useRequest.shipments.get({
    params: { pagination: false },
    options: { keepPreviousData: true }
  });

  // const
  const formattedData = formatAutocompleteData({
    entity: 'shipment',
    options: data,
    disabledList
  });

  const onHandleOnChange = (item, obj) => {
    if (obj.action === 'clear' || obj.action === 'remove-value') {
      onChange(name, '');
      return;
    }
    onChange(name, item.value);
  };

  const handleCreate = (value) => {
    setAutocompleteV2CreateNewEntityItemState({
      inputId: 'shipment_id',
      createItemName: value
    });
    openModal({
      id: ModalIds.ADD_SHIPMENT_FORM,
      isOpen: true,
      redirect: false,
      formValues: getValues()
    });
  };

  useEffect(() => {
    if (
      Object.keys(autocompleteV2CreateNewEntityItemState.data).length > 0 &&
      autocompleteV2CreateNewEntityItemState.inputId === 'shipment_id'
    ) {
      const selectedData = data.find(
        (data) => data.id == autocompleteV2CreateNewEntityItemState.data.id
      );
      if (selectedData) {
        onChange(
          autocompleteV2CreateNewEntityItemState.inputId,
          autocompleteV2CreateNewEntityItemState.data.id
        );
        setAutocompleteV2CreateNewEntityItemState({ data: {}, createItemName: '', inputId: '' });
      } else {
        refetch();
      }
    }
  }, [autocompleteV2CreateNewEntityItemState.data, data]);

  return (
    <FormAutocompleteV2
      label={label}
      name={name}
      containerClass="w-full"
      labelClass="text-primary-navy-400"
      options={formattedData}
      icon={<CollectionIcon className="w-4 h-4" />}
      loading={isLoading}
      onMenuOpen={refetch}
      onCreate={handleCreate}
      clearValue={clearValue}
      value={getValues(name)}
      onChange={onHandleOnChange}
    />
  );
};

export default FormShipmentAutoComplete;
