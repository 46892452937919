const initialSettingsState = {
  selectedUserIDs: [],
  inviteLink: ''
};

export const createSettingsSlice = (set) => ({
  ...initialSettingsState,
  setSelectedUserIDs: (userIDs) => set(() => ({ selectedUserIDs: userIDs })),
  setInviteLink: (inviteLink) => set(() => ({ inviteLink }))
});
