import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import Input from '@components/dataEntry/input';
import ModalView from '@components/feedback/modal-view';
import useGetListByEntity from '@hooks/use-get-list-by-entity';
import useModal from '@hooks/use-modal';
import useShipmentEntityCustomField from '@hooks/use-shipment-entity-custom-fields';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useState } from 'react';
import ModalFooter from '../modal-footer';

const removeUndefined = (data) =>
  Object.keys(data).reduce((acc, curr) => {
    if (data[curr] === null || data[curr] === undefined) {
      delete data[curr];
    }
    return data;
  }, {});

const ContactMasterForm = () => {
  const [entityType, setEntityType] = useState();
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const setContactMasterState = useStore((state) => state.setContactMasterState);
  const contactMasterState = useStore((state) => state.contactMasterState);

  const {
    query: { id: shipmentId },
    pathname
  } = useRouter();

  const shipmentEntityCustomFields = useShipmentEntityCustomField({
    entity_type: 'Shipment',
    shipmentId: pathname == '/shipments/[id]' ? shipmentId : null
  });

  const values: any = contactMasterState.currentValues;

  const { data: productEntityData } = useGetListByEntity({
    entity: 'product',
    enabled: entityType === 'product'
  });
  const { data: shipmentEntityData } = useGetListByEntity({
    entity: 'shipment',
    enabled: entityType === 'shipment'
  });
  const { data: contactEntityData } = useGetListByEntity({
    entity: 'contact',
    enabled: entityType === 'contact'
  });

  const onHandleSelectedValues = ({ name, value }) => {
    const isIncluded = Object.keys(selectedValues).includes(name);

    if (!isIncluded) {
      setSelectedValues({ ...selectedValues, ...{ [name]: value } });
    }

    if (isIncluded) {
      const newSelectedValues = { ...selectedValues };
      delete newSelectedValues[name];
      setSelectedValues(newSelectedValues);
    }
    setSelectedAll(false);
  };

  // State variables
  const headerLabel = 'Do you want to copy data from your contact?';
  const { closeModalByPosition } = useModal();

  const onSubmit = () => {
    const inputsWithoutSelectedAll = { ...selectedValues };
    setContactMasterState({
      ...contactMasterState,
      selectedValues: { ...inputsWithoutSelectedAll },
      fullValues: {
        ...contactMasterState.currentValues,
        ...removeUndefined(inputsWithoutSelectedAll),
        ...(selectedAll &&
          contactMasterState.matchedFields.reduce((acc, curr) => ({ ...acc, ...{ ...curr } }), {}))
      }
    });
    closeModalByPosition();
  };

  const hooksByEntities = {
    contact: contactEntityData,
    shipment: shipmentEntityData,
    product: productEntityData
  };

  const getFieldNameAndValue = (key: string, value: any) => {
    let prevField = values[key] ? values[key] : 'None';
    let nextField = value;
    let noCompare = false;

    const prevEntity = shipmentEntityCustomFields?.find(
      (entity) => entity.name === key && entity.entityId === prevField
    );

    const comparingEntity = shipmentEntityCustomFields?.find((entity) => entity.name === key);

    if (comparingEntity) {
      setEntityType(comparingEntity.key);
      const entitiesData = hooksByEntities[comparingEntity.key];

      prevField = entitiesData?.find((val) => val.id === prevField)?.name || 'None';
      nextField = entitiesData?.find((val) => val.id === nextField)?.name;
    }

    if (prevEntity?.key) {
      setEntityType(prevEntity?.key);
      const entitiesData = hooksByEntities[prevEntity?.key];

      prevField = entitiesData?.find((val) => val.id === prevEntity?.entityId)?.name;
      nextField = entitiesData?.find((val) => val.id === value)?.name;
    }

    if (!prevEntity) {
      noCompare = true;
    }

    return { prevField, nextField, noCompare };
  };

  const getRequiredField = (key) => contactMasterState?.requiredFields?.find((x) => key === x.name);
  const RequiredFieldTest = ({ entityType }) => (
    <Typography className="text-gray-400">- (Required Field on {entityType})</Typography>
  );

  const dataKey = (key) =>
    !values?.is_default_field ? key : key.replaceAll(' ', '_').toLowerCase();

  return (
    <ModalView header={headerLabel}>
      <form>
        <p className="text-sm">
          The following matching fields were found. <br />
          Choose which fields to update on your Contact.
        </p>
        <div className="grid space-y-2">
          <div className="flex items-center mt-3 border-b pb-3">
            <div className="w-4">
              <Input
                name={'select_all'}
                type="checkbox"
                checked={selectedAll}
                onChange={() => setSelectedAll(!selectedAll)}
              />
            </div>
            <p className="ml-2 text-sm font-medium">Select all</p>
          </div>
          {contactMasterState.matchedFields?.map((data) =>
            Object.entries(data).map(([key, value]) => (
              <>
                <div className="flex items-center mt-3">
                  <div className="w-4">
                    <Input
                      name={dataKey(key)}
                      type="checkbox"
                      showError={false}
                      checked={Object.keys(selectedValues).includes(key) || selectedAll}
                      onChange={() => onHandleSelectedValues({ name: key, value })}
                    />
                  </div>
                  <p className="capitalize ml-2 text-sm font-medium">
                    {dataKey(key)?.replaceAll('_', ' ')}
                  </p>
                </div>
                <p className="ml-6 text-sm text-gray-500">
                  {`${getFieldNameAndValue(dataKey(key), value).prevField} -> ${
                    getFieldNameAndValue(dataKey(key), value).nextField
                  }`}{' '}
                  &nbsp;
                  <RenderIf isTrue={getRequiredField(key)}>
                    <RequiredFieldTest entityType={getRequiredField(key)?.entityType} />
                  </RenderIf>
                </p>
              </>
            ))
          )}
        </div>
        <ModalFooter
          onClick={onSubmit}
          modalId={ModalIds.WORKFLOW_FORM}
          mainButtonText={'Update'}
        />
      </form>
    </ModalView>
  );
};

export default ContactMasterForm;
