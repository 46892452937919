import ArrowUpRightIcon from '@assets/svg/ArrowUpRightIcon';
import Tooltip from '@components/general/tooltip';
import useManageParams from '@hooks/use-manage-params';
import Link from 'next/link';

const ButtonWithLink = () => {
  const { params } = useManageParams();

  const links = {
    taskId: `/tasks?portal=drawer&taskId=${params.taskId}`
  };

  return (
    <Link href={params.taskId ? links.taskId : params?.rowLink ? params.rowLink : ''} passHref>
      <Tooltip description="View Tab">
        <button className="flex justify-center items-center w-7 h-7 rounded stroke bg-white">
          <ArrowUpRightIcon className="text-primary-grey-500" />
        </button>
      </Tooltip>
    </Link>
  );
};

export default ButtonWithLink;
