import {
  AUTOCOMPLETE_FIELD_TYPE,
  CHECKBOX_FIELD_TYPE,
  COUNTRY_DROPDOWN_FIELD_TYPE,
  IMAGE_FIELD_TYPE,
  MULTI_SELECT_AUTOCOMPLETE_FIELD_TYPE
} from '@services/constants';
import ContactAutocomplete from './ContactAutocomplete';
import CountryAutocomplete from './CountryAutocomplete';
import { FormAutocompleteV2Props } from './form-autocomplete-v2';
import FormInput from './form-input';
import FormInputCheckbox, { CheckboxProps } from './form-input-checkbox-or-toggle';
import FormInputImage, { ImageProps } from './form-input-image';
import FormMultiSelectDropdown, {
  FormMultiSelectDropdownProps
} from './form-multi-select-dropdown';

// type InputsProps = FormAutocompleteProps & CheckboxProps & ImageProps & FormInputContainerProps;

const FullInput = (props) => {
  const IS_IMAGE = IMAGE_FIELD_TYPE === props?.type;
  const IS_AUTOCOMPLETE = AUTOCOMPLETE_FIELD_TYPE === props?.type;
  const IS_CHECKBOX = CHECKBOX_FIELD_TYPE === props?.type;
  const IS_MULTI_SELECT_AUTOCOMPLETE = MULTI_SELECT_AUTOCOMPLETE_FIELD_TYPE === props?.type;
  const IS_COUNTRY_DROPDOWN = COUNTRY_DROPDOWN_FIELD_TYPE === props?.type;

  switch (true) {
    case IS_AUTOCOMPLETE:
      return <ContactAutocomplete {...(props as FormAutocompleteV2Props)} />;
    case IS_MULTI_SELECT_AUTOCOMPLETE:
      return <FormMultiSelectDropdown {...(props as FormMultiSelectDropdownProps)} />;
    case IS_IMAGE:
      return <FormInputImage {...(props as ImageProps)} />;
    case IS_CHECKBOX:
      return <FormInputCheckbox {...(props as CheckboxProps)} />;
    case IS_COUNTRY_DROPDOWN:
      return <CountryAutocomplete {...(props as FormAutocompleteV2Props)} />;
    default:
      return <FormInput {...props} />;
  }
};

export default FullInput;
