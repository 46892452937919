import { UserIcon } from '@heroicons/react/outline';
import { formatAutocompleteData } from '@utils/format-autocomplete-data';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2 from './form-autocomplete-v2';

const UserDropdown = ({
  onChange,
  disabledList = [],
  addedList = [],
  data
}: {
  onChange: (item: any) => void;
  disabledList?: string[];
  addedList?: string[];
  data: any;
}) => {
  // hooks
  const [clearValue, setClearValue] = useState(false);
  const context = useFormContext();
  const { setValue, setError, clearErrors } = context;

  // const
  const formattedData = formatAutocompleteData({
    entity: 'user',
    options: data,
    disabledList
  });
  const customOnChange = (item, props) => {
    if (props.action === 'clear' || props.action === 'remove-value') {
      setValue(props.name, '');
      setClearValue(true);
      clearErrors(props.name);
      return;
    }

    //eslint-disable-next-line
    const regex = /^\S+@\S+$/;
    const value = item.email || item.value;
    if (!regex.test(value)) {
      setClearValue(false);
      setError(props.name, { message: 'Email address is not valid' });
      setValue(props.name, value);
      return;
    }

    if (addedList.some((listItem) => listItem.toLowerCase() == value.toLowerCase())) {
      setClearValue(false);
      setValue(props.name, value);
      setError(props.name, { message: 'Email address has already been added' });
      setValue(props.name, value);
    } else {
      setClearValue(true);
      setValue(props.name, value);
      clearErrors(props.name);
      onChange?.(item);
    }
  };

  useEffect(() => {
    if (clearValue) {
      setClearValue(false);
    }
  }, [clearValue]);

  return (
    <FormAutocompleteV2
      label={false}
      name="emails"
      containerClass="w-full"
      labelClass="text-primary-navy-400"
      options={formattedData}
      icon={<UserIcon className="w-4 h-4" />}
      returnItemKey="email"
      onChange={customOnChange}
      placeholder="Add people or emails"
      clearValue={clearValue}
      customCreateLabel={'Add external email:'}
    />
  );
};

export default UserDropdown;
