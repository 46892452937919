import { InputTypes } from 'constants/inputs-types';
import { DocumentSections, DocumentTemplateType } from '../types';

import { productInputListData } from './@common/product.table.config';

export const CommercialInvoice: DocumentTemplateType[] = [
  {
    name: 'Shipper',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowSpan: 3, rowStart: 1 }
  },
  {
    name: 'Invoice Number',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 7, colSpan: 2, rowSpan: 2 }
  },
  {
    name: 'BL Number',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 11, colSpan: 2, rowSpan: 2 }
  },
  {
    name: 'Invoice Date',
    section: DocumentSections.preProductTable,
    type: 'date',
    GridProps: { colStart: 9, rowStart: 1, colSpan: 2, rowSpan: 2 }
  },
  {
    name: 'Buyer Reference',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 3, colStart: 10 }
  },
  {
    name: 'name',
    type: 'text',
    section: DocumentSections.preProductTable,
    label: 'Reference',
    GridProps: { rowStart: 3, colStart: 7, colSpan: 3 }
  },
  {
    name: 'Consignee',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, rowStart: 4, colSpan: 6 }
  },
  {
    name: 'Buyer',
    type: 'autocomplete',
    label: 'Buyer (if not Consignee)',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 4, colStart: 7, colSpan: 6 },
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    }
  },
  {
    name: 'method_of_shipping',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-dispatch-method',
      defaultValue: 'SEA'
    },
    GridProps: { colStart: 1, rowStart: 5, colSpan: 3 }
    // classes: { bt: 0 }
  },
  {
    name: 'type_of_shipment',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-shipment-type',
      defaultValue: 'FCL'
    },
    GridProps: { rowStart: 5, colSpan: 3 }
    // classes: { bt: 0 }
  },
  {
    name: 'country_of_origin',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 5, colSpan: 3 }
  },
  {
    name: 'country_of_destination',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 5, colSpan: 3 }
  },
  {
    name: 'Vessel',
    type: 'text',
    label: 'Vessel / Aircraft',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 6, colSpan: 4 }
  },
  {
    name: 'Voyage Number',
    type: 'text',
    label: 'Voyage No',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 6, colSpan: 4 }
  },
  {
    name: 'departure_date',
    type: 'date',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 7, colSpan: 4 }
  },
  {
    name: 'port_of_loading',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 7, colSpan: 4 }
  },
  {
    name: 'terms_of_payment',
    section: DocumentSections.preProductTable,
    type: 'textarea',
    GridProps: { rowStart: 6, rowSpan: 2, colSpan: 4 },
    InputProps: { maxLength: 160, limitRows: 4, containerClass: 'h-full' }
  },
  {
    name: 'port_of_discharge',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 8, colSpan: 3 }
  },
  {
    name: 'Final Destination',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 8, colSpan: 3 }
  },
  {
    name: 'Marine Cover Policy No',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 8, colSpan: 3 }
  },
  {
    name: 'Letter of Credit No',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 8, colSpan: 3 }
  },
  {
    name: 'product_table',
    type: InputTypes.PRODUCT_TABLE,
    section: DocumentSections.productTable,
    options: {
      entity: 'products',
      TableProps: {
        data: productInputListData
      }
    },
    GridProps: { rowStart: 9, colSpan: 12 }
  },
  {
    name: 'Additional Info',
    type: 'textarea',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 1, rowStart: 11, colSpan: 6, rowSpan: 5 },
    InputProps: { bt: 1, maxLength: 520, limitRows: 8 }
  },
  {
    name: 'Bank Details',
    section: DocumentSections.postProductTable,
    type: 'textarea',
    GridProps: { colStart: 1, rowStart: 16, colSpan: 6, rowSpan: 4 },
    InputProps: { maxLength: 900, limitRows: 10 }
  },
  {
    type: 'subtitle',
    section: DocumentSections.postProductTable,
    name: 'Additional Charges or Discounts',
    label: 'Additional Charges or Discounts',
    GridProps: { rowStart: 11, colSpan: 6 }
  },
  {
    groupName: 'Additional Charges or Discounts',
    name: 'Additional Charges or Discounts',
    label: false,
    section: DocumentSections.postProductTable,
    type: 'array',
    GridProps: { colSpan: 2, rowMappingStart: 12, colSpanInputsGroupArray: [4, 2] }
  },
  {
    type: 'subtitle',
    name: 'Tax',
    section: DocumentSections.postProductTable,
    label: 'Tax %',
    GridProps: { rowStart: 14, colSpan: 4 }
  },
  {
    name: 'Tax',
    type: 'number',
    section: DocumentSections.postProductTable,
    label: false,
    GridProps: { rowStart: 14, colSpan: 2 },
    InputProps: {
      inputClass: 'text-right text-xs',
      containerClass: 'w-full h-full flex items-center justify-end'
    }
  },
  {
    type: 'subtitle',
    name: 'total',
    section: DocumentSections.postProductTable,
    label: 'TOTAL',
    GridProps: { colStart: 7, colSpan: 4, rowStart: 15 },
    options: {
      disabledOptions: true
    }
  },
  {
    name: 'total',
    type: 'number',
    section: DocumentSections.postProductTable,
    label: false,
    GridProps: { colStart: 11, rowStart: 15, pl: 3 },
    InputProps: {
      readOnly: true,
      containerClass: 'w-full h-full flex items-center justify-end pr-2'
    }
  },
  {
    name: 'incoterms',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 16, colSpan: 4 }
  },
  {
    name: 'currency',
    section: DocumentSections.postProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-currency'
    },
    GridProps: { colStart: 11, rowStart: 16, colSpan: 2 }
  },
  {
    name: 'Signatory Company',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 17 }
  },
  {
    name: 'Name of Authorized Signatory',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 18 }
  },
  {
    name: 'signature',
    type: 'signature',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 19 }
  }
];
