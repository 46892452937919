import { ArchiveEntityQueryOptionsType } from '@services/types';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const updateProductArchivedMutation = () => {
  return useMutation((data: ArchiveEntityQueryOptionsType) =>
    http.put(API_ENDPOINTS.GET_PRODUCT(data.id) + '/set_archived', data)
  );
};

export default updateProductArchivedMutation;
