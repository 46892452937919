export const jsonToFormData = ({ schema, inputs }: { schema?: string; inputs: any }) => {
  let formData = new FormData();

  for (let key in inputs) {
    if (inputs[key] === undefined) inputs[key] = '';

    if (schema) {
      formData.append(`${schema}[${key}]`, inputs[key]);
    } else {
      formData.append(key, inputs[key]);
    }

    formData.append;
  }
  return formData;
};
