import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import Tooltip from '@components/general/tooltip';
import { ArrowRightIcon, PencilIcon } from '@heroicons/react/outline';
import useManageParams from '@hooks/use-manage-params';
import useUserPolicy from '@hooks/use-user-policy';
import Header from '../../components/Header';
import ButtonWithLink from '../../components/HeaderButton/ButtonWithLink';
import TaskButton from './TaskButton';

const ViewHeader = (props) => {
  const { params } = useManageParams();
  const { isAdmin } = useUserPolicy();
  return (
    <Header>
      <div className="relative flex items-center justify-between px-3 py-4 bg-white">
        <RenderIf isTrue={!props.headerTitle}>
          <button
            onClick={() => props.closePortal({ current_tab: params?.current_tab })}
            className="pr-1 border-r-2 border-primary-grey-500 "
          >
            <ArrowRightIcon className="w-5 h-5 text-primary-grey-500" />
          </button>
        </RenderIf>
        <RenderIf isTrue={props.headerTitle}>
          <Typography variant="h1" className="text-primary-navy-500 text-base font-medium">
            {props.headerTitle}
          </Typography>
        </RenderIf>
        <div className="flex items-center gap-3">
          <TaskButton />
          <RenderIf isTrue={isAdmin || props.isCreator}>
            <Tooltip description="Edit">
              <button
                onClick={props.onHandleDrawer}
                className="flex justify-center items-center w-7 h-7 rounded stroke bg-white"
              >
                <PencilIcon className="h-4 w-4 text-primary-grey-500" />
              </button>
            </Tooltip>
          </RenderIf>
          <RenderIf isTrue={false}>
            <ButtonWithLink />
          </RenderIf>
        </div>
      </div>
    </Header>
  );
};

export default ViewHeader;
