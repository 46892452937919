import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

export const useThirdPartyApps = () => {
  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.NEXT_PUBLIC_INTERCOM_APP_ID !== undefined
    ) {
      const script = document.createElement('script');
      const intercom_app_id = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
      script.insertAdjacentHTML(
        'beforeend',
        `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercom_app_id}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
      );
      document.head.appendChild(script);
      window.Intercom('boot', {
        // TODO: Add user identify
        app_id: intercom_app_id
      });
    }

    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(1883644, 6);
    }
  }, []);
};
