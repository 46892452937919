const initialUIState = {
  isModalOpen: false,
  isUserSearching: false,
  isLeftNavigation: true,
  modalWidth: '',
  modalState: [],
  resetSearchClicked: false,
  isReactSelectClearableAction: false,
  updateSideBar: false,
  updateFieldData: false,
  scrollToSidebarLastChild: false,
  modalRef: null,
  pageHeader: '',
  search: {
    focus: false
  }
};

export const createUISlice = (set) => ({
  ...initialUIState,
  closeModal: () => set(() => ({ modalState: [] }), false, 'closeModal'),
  setResetSearchClicked: (value) =>
    set(() => ({ resetSearchClicked: value }), false, 'setResetSearchClicked'),
  setModalWidth: (width = '') => set(() => ({ modalWidth: width }), false, 'setModalWidth'),
  setReactSelectClearableAction: (action = false) =>
    set(() => ({ isReactSelectClearableAction: action }), false, 'setReactSelectClearableAction'),
  setModalState: (modalState) => set(() => ({ modalState }), false, 'setModalState'),
  setUpdateSideBar: (action = false) =>
    set(() => ({ updateSideBar: action }), false, 'setUpdateSideBar'),
  setUpdateFieldData: (action = false) =>
    set(() => ({ updateFieldData: action }), false, 'setUpdateFieldData'),
  setScrollToSidebarLastChild: (action = false) =>
    set(() => ({ scrollToSidebarLastChild: action }), false, 'setScrollToSidebarLastChild'),
  setModalRef: (ref = null) => set(() => ({ modalRef: ref }), false, 'setModalRef'),
  closeModalByPosition: (modalState) => set(() => ({ modalState }), false, 'closeModalByPosition'),
  setLeftNavigation: (action = false) =>
    set(() => ({ isLeftNavigation: action }), false, 'setLeftNavigation'),
  setSearchState: (state) =>
    set((x) => ({ search: { ...x.search, ...state } }), false, 'setSearchState')
});
