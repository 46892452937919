import { useShipmentProductsQuery } from '@services/api/shipment-products/get-all-shipment-products';
import { useAddShipmentProductMutation } from '@services/api/shipment-products/use-add-shipment-product';
import { useBulkDeleteShipmentProductsMutation } from '@services/api/shipment-products/use-bulk-delete-shipment-products';
import { useDeleteShipmentProductMutation } from '@services/api/shipment-products/use-delete-shipment-product';
import { useImportShipmentProductMutation } from '@services/api/shipment-products/use-import-shipment-product';
import { useUpdateShipmentProductMutation } from '@services/api/shipment-products/use-update-shipment-product';
import { useStore } from '@store/useStore';
import useManageParams from './use-manage-params';

const useManageShipmentProducts = () => {
  // states
  const selectedShipmentProductIDs = useStore((state) => state.selectedShipmentProductIDs);

  // hooks
  const { params } = useManageParams();

  // const
  const shipmentProductsQueryParams = {
    page: params?.page,
    shipment_id: ''
  };

  const getShipmentProducts = (id) => {
    const { data, isLoading, refetch } = useShipmentProductsQuery(
      { ...shipmentProductsQueryParams, shipment_id: id },
      {
        keepPreviousData: true
      }
    );

    const importFields = !isLoading && data ? data.importFields : [];
    const pagination = !isLoading && data && data.pagination;
    const dataTable = !isLoading && data ? data.data : [];
    const totals =
      !isLoading && data ? { pageTotal: data.page_total, shipmentTotal: data.shipment_total } : {};
    const missingNetWeightsProducts = !isLoading && data ? data.missingNetWeightsProducts : [];
    const showMissingNetWeightBanner = !isLoading && data ? data.showMissingNetWeightBanner : false;

    return {
      data: dataTable,
      totals,
      isLoading,
      refetch,
      importFields,
      pagination,
      missingNetWeightsProducts,
      showMissingNetWeightBanner
    };
  };

  const bulkDeleteShipmentProductsMutation = () => {
    const mutation = useBulkDeleteShipmentProductsMutation();
    const { mutateAsync: bulkDeleteMutation } = mutation;

    const bulkDeleteShipmentProducts = async () => {
      const inputs = {
        delete_data: selectedShipmentProductIDs
      };

      await bulkDeleteMutation(inputs);
    };

    return { mutation, bulkDeleteShipmentProducts };
  };

  const deleteShipmentProductMutation = () => {
    const mutation = useDeleteShipmentProductMutation();
    const { mutateAsync: deleteShipmentProductMutation } = mutation;

    const deleteShipmentProduct = async (id) => {
      await deleteShipmentProductMutation(id);
    };

    return { mutation, deleteShipmentProduct };
  };

  const addOrUpdateShipmentProductMutation = () => {
    const addShipmentProductMutation = useAddShipmentProductMutation();
    const { mutateAsync: addShipmentProduct } = addShipmentProductMutation;
    const updateShipmentProductMutation = useUpdateShipmentProductMutation();
    const { mutateAsync: updateShipmentProduct } = updateShipmentProductMutation;

    const addOrUpdateShipmentProduct = async ({ inputs, isNewShipmentProduct = true }) => {
      if (isNewShipmentProduct) {
        delete inputs.shipment_product.id;
        await addShipmentProduct(inputs);
      } else {
        await updateShipmentProduct(inputs);
      }
    };

    return {
      addShipmentProductMutation,
      updateShipmentProductMutation,
      addOrUpdateShipmentProduct
    };
  };

  const importShipmentProductMutation = () => {
    const mutation = useImportShipmentProductMutation();
    const { mutateAsync: importCSV } = mutation;

    const importShipmentProduct = async ({ data }) => {
      await importCSV(data);
    };

    return { mutation, importShipmentProduct };
  };

  return {
    shipmentProductsQueryParams,
    getShipmentProducts,
    bulkDeleteShipmentProductsMutation,
    deleteShipmentProductMutation,
    addOrUpdateShipmentProductMutation,
    importShipmentProductMutation
  };
};

export default useManageShipmentProducts;
