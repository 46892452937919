import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useManageParams from '@hooks/use-manage-params';
import useManagePayments from '@hooks/use-manage-payments';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const EditTotalForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [defaultValues, setDefaultValues] = useState({
    total_text: 0
  });

  // hooks
  const { closeModalByPosition } = useModal();
  const { params } = useManageParams();
  const { updateTotalPaymentsMutation } = useManagePayments();
  const {
    query: { id: shipmentId }
  } = useRouter();

  // const
  const {
    mutation: { isLoading },
    updateTotalPayments
  } = updateTotalPaymentsMutation();

  // queries
  const { data, refetch, isSuccess } = useRequest.shipment_payments.get({
    params: { shipment_id: shipmentId as string, page: params?.page },
    options: { keepPreviousData: true }
  });

  const currencySymbol = data?.currencySymbol;
  const paymentId = data?.payment?.id;
  const totalNumber = data?.payment?.total_number;

  const validationSchema = yup.object({
    total_text: yup.string().required('Total is required')
  });

  // actions
  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        payment: {
          ...inputs,
          shipment_id: shipmentId,
          id: paymentId
        }
      };
      await updateTotalPayments(modifiedInputs);
      closeModalByPosition();
      refetch();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  useEffect(() => {
    setDefaultValues({ total_text: totalNumber });
  }, [totalNumber]);

  return (
    <ModalView header="Update Total">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        updateValuesAfterSuccess={isSuccess}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <FormInput
                    label="Total"
                    name="total_text"
                    adornmentProps={{
                      position: 'start',
                      startText: currencySymbol,
                      inputType: 'number',
                      inputClassName: 'pl-11'
                    }}
                    type="adornment"
                    containerClass="col-span-3"
                  />
                </div>
              </div>
              <ModalFooter
                disabled={isLoading || !isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.EDIT_TOTAL_FORM}
                mainButtonText="Update"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default EditTotalForm;
