const initialShipmentDetailsState = {
  // Shipment Products
  shipmentProductCount: 0,
  selectedShipmentProductIDs: [],
  // Shipment Packages
  shipmentPackageCount: 0,
  selectedShipmentPackageIDs: [],
  updateShipmentPackageTable: false,

  // Shipment Trackings
  selectedShipmentTrackingIDs: [],
  shipmentTrackingCount: 0,
  // Shipment Trackings
  selectedShipmentPaymentIDs: [],
  shipmentPaymentCount: 0,
  // Shipment Activities
  activityCount: 0,
  updateActivityFeed: false,
  updateComment: false,
  scrollToNextItem: false,
  scrollToNextItemCount: 1
};

export const createShipmentDetailsSlice = (set) => ({
  ...initialShipmentDetailsState,
  // Shipment Products
  setShipmentProductCount: (count) =>
    set(() => ({ shipmentProductCount: count }), false, 'setShipmentProductCount'),
  setSelectedShipmentProductIDs: (ids) => {
    return set(() => ({ selectedShipmentProductIDs: ids }), false, 'setSelectedShipmentProductIDs');
  },
  // Shipment Packages
  setShipmentPackageCount: (count) =>
    set(() => ({ shipmentPackageCount: count }), false, 'setShipmentPackageCount'),
  setSelectedShipmentPackageIDs: (ids) => {
    return set(() => ({ selectedShipmentPackageIDs: ids }), false, 'setSelectedShipmentPackageIDs');
  },
  setUpdateShipmentPackageTable: (update) => {
    return set(
      () => ({ updateShipmentPackageTable: update }),
      false,
      'setUpdateShipmentPackageTable'
    );
  },
  // Shipment Trackings
  setSelectedShipmentTrackingIDs: (ids) => {
    return set(
      () => ({ selectedShipmentTrackingIDs: ids }),
      false,
      'setSelectedShipmentTrackingIDs'
    );
  },
  setShipmentTrackingCount: (count = 0) =>
    set(() => ({ shipmentTrackingCount: count }), false, 'setShipmentTrackingCount'),
  // Shipment Payments
  setSelectedShipmentPaymentIDs: (ids) => {
    return set(() => ({ selectedShipmentPaymentIDs: ids }), false, 'setSelectedShipmentPaymentIDs');
  },
  setShipmentPaymentCount: (count = 0) =>
    set(() => ({ shipmentPaymentCount: count }), false, 'setShipmentPaymentCount'),
  // Shipment Activities
  setActivityCount: (count = 0) => set(() => ({ activityCount: count }), false, 'setActivityCount'),
  setUpdateActivityFeed: (update) =>
    set(() => ({ updateActivityFeed: update }), false, 'setUpdateActivityFeed'),
  setUpdateComment: (update) => set(() => ({ updateComment: update }), false, 'setUpdateComment'),
  setScrollToNextItem: (update, count) =>
    set(
      () => ({ scrollToNextItem: update, scrollToNextItemCount: count }),
      false,
      'setScrollToNextItem'
    )
});
