import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createShipmentProduct = () => {
  return useMutation((data: any) =>
    http.post(API_ENDPOINTS.SHIPMENT_PRODUCTS, { shipment_product: data })
  );
};

export default createShipmentProduct;
