const initialProductsState = {
  selectedProductIDs: [],
  selectedProductFileIDs: []
};

export const createProductsSlice = (set) => ({
  ...initialProductsState,
  setSelectedProductIDs: (ids) => {
    return set(() => ({ selectedProductIDs: ids }), false, 'setSelectedProductIDs');
  },
  setSelectedProductFileIDs: (ids) => {
    return set(() => ({ selectedProductFileIDs: ids }), false, 'setSelectedProductFileIDs');
  }
});
