import React, { FC, TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  placeholder?: string;
  name?: string;
  rows?: number;
  limitRows?: number;
  onChange?: (e) => void;
  value?: string;
  inputRef?: any;
}

const Textarea: FC<TextareaProps> = ({
  name,
  placeholder,
  disabled,
  hidden = false,
  rows,
  inputRef,
  onChange,
  ...rest
}) => {
  const ref = useRef();
  const textAreaRef = inputRef ? inputRef : ref;
  const [textAreaHeight, setTextAreaHeight] = useState('auto');
  const limitLines = (value) => value.replace(/\r\n/g, '\n').split('\n').length;

  useEffect(() => {
    if (rest.value && textAreaRef.current?.scrollHeight > 0) {
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
    } else {
      setTextAreaHeight('calc(100% - 1.25rem)');
    }
  }, [rest.value, textAreaRef.current]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (limitLines(event.target.value) > rest.limitRows) return false;
    if (event.target.value.length >= rest.maxLength) return false;
    setTextAreaHeight('auto');
    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyDown = (e) => {
    if (limitLines(e.target.value) > rest.limitRows) return false;
    e.target.style.height = textAreaHeight;
  };
  const value = rest.maxLength ? rest.value?.slice(0, rest.maxLength - 10) : rest.value;

  return (
    <textarea
      {...rest}
      value={value}
      ref={textAreaRef}
      id={name}
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      autoComplete="off"
      spellCheck="false"
      hidden={hidden}
      onKeyDown={handleKeyDown}
      rows={rows}
      style={{
        ...rest.style,
        wordBreak: 'break-word',
        minHeight: 'calc(100% - 1.25rem)',
        height: (!placeholder || rest.value) && textAreaHeight
      }}
      onChange={onChangeHandler}
    />
  );
};

export default Textarea;
