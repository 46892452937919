import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface Type {
  name: string;
  logo: string;
  id: string;
}

const updateOrganizationMutation = () => {
  return useMutation((data: { id: string; input: Type }) =>
    http.put(API_ENDPOINTS.ORGANIZATION_BY_ID(data.id), data.input)
  ) as any;
};

export default updateOrganizationMutation;
