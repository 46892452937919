import InboxTabIcon from '@assets/svg/InboxTabIcon';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Dropdown, DropdownBox, DropdownButton } from '../dropdown';

const Row = ({ notification, length }) => {
  const { mutateAsync: updateNotification } = useRequest.notifications.update();
  const queryClient = useQueryClient();
  const router = useRouter();

  const markAsRead = async () => {
    const id = notification?.id;

    try {
      await updateNotification(
        { id, params: { id, read_status: true } },
        {
          onSuccess: () => {
            queryClient.refetchQueries(API_ENDPOINTS.NOTIFICATIONS);
          }
        }
      );
    } catch (err) {
      console.error(err.response.data.errors);
    }
  };

  const handleClickRow = () => {
    markAsRead();
    router.push('/notifications');
  };

  return (
    <div
      onClick={handleClickRow}
      className={`flex flex-row items-center break-keep	px-3 py-2 ${
        length === 1 ? 'border-none' : 'border-b'
      } gap-x-4 bg-white leading-none text-primary-navy-400 hover:bg-gray-100 cursor-pointer`}
    >
      <span className="w-full text-sm">{notification?.body}</span>
    </div>
  );
};

const InboxNotificationStatus = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const router = useRouter();
  const { data: notificationsData } = useRequest.notifications.get({
    params: { read_type: false }
  });

  const handleClickNotification = () => {
    setOpen(!open);
    router.push('/notifications');
  };

  const rowItemMap = notificationsData?.data?.slice(0, 5);

  return (
    <Dropdown target={ref} isOpen={open} onOpen={setOpen} className="relative shrink-0">
      <DropdownButton
        onClick={handleClickNotification}
        className="flex rounded-full text-white focus:outline-none transition duration-150 ease-in-out justify-center"
      >
        <div className={`group text-[#99ABBA]`}>
          <InboxTabIcon
            showStatus={notificationsData?.pagination?.count > 0}
            className={`group-hover:text-neutral-300 text-[#99ABBA]`}
          />
        </div>
      </DropdownButton>
      <DropdownBox>
        <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg z-50">
          <div
            className="py-1 rounded-md bg-white shadow-xs w-auto min-w-[28rem]"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <div className="flex flex-col px-4 py-2">
              <p
                className={`px-3 text-sm font-medium ${
                  notificationsData?.pagination?.count === 0
                    ? 'text-center text-primary-grey-500'
                    : 'mb-4 text-primary-navy-400'
                }`}
              >
                {notificationsData?.pagination?.count} unread notifications
              </p>
              {rowItemMap?.map((notification, index) => (
                <Row
                  key={index}
                  notification={notification}
                  length={notification?.pagination?.length}
                />
              ))}
            </div>
            <Link href={'/notifications'} passHref>
              <p
                className="text-sm text-center mb-4 text-primary-grey-700 hover:text-primary-navy-500 cursor-pointer"
                onClick={() => setOpen(false)}
              >
                Click to view all notifications in inbox
              </p>
            </Link>
          </div>
        </div>
      </DropdownBox>
    </Dropdown>
  );
};

export default InboxNotificationStatus;
