import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const fetchRecentShipmentForProduct = (productId, options?: ReactQueryOptionsType): any => {
  return useQuery(
    [API_ENDPOINTS.GET_PRODUCT_RECENT_SHIPMENT(productId)],
    queryFetch,
    options
  ) as any;
};
export default fetchRecentShipmentForProduct;
