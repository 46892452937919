import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getCustomFieldMappingsNameOptionsQuery = ({
  params,
  options
}: {
  params?: { entity_type: 'Contact' | 'Shipment' | 'Product' };
  options?: ReactQueryOptionsType;
}): any => {
  return useQuery([API_ENDPOINTS.CUSTOM_FIELD_MAPPINGS_NAMES, params], queryFetch, options);
};

export default getCustomFieldMappingsNameOptionsQuery;
