import RenderIf from '@components/common/render-if';
import React, { useEffect, useRef, useState } from 'react';
import Select, { components, ValueContainerProps } from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row items-center text-sm">
        <input
          type="checkbox"
          checked={props.isSelected}
          readOnly
          className="form-checkbox transition duration-150 ease-in-out select-item w-4"
        />{' '}
        <label className="pl-2">{props.label}</label>
      </div>
      <RenderIf isTrue={!!props?.data?.descriptionForLabel}>
        <p className="text-xs ml-6">{props?.data?.descriptionForLabel}</p>
      </RenderIf>
    </components.Option>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<OptionType>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = `${values.length} Product${plural} Contained`;
  }

  return (
    <components.ValueContainer {...props} isMulti={true}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export interface MultiSelectDropdownProps {
  name: string;
  id?: string;
  options: any;
  defaultValue?: any;
  withCheckbox?: boolean;
  placeholder?: string;
  showSelectionSum?: boolean;
  onChange?: (selected: any) => void;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  name,
  options,
  defaultValue,
  withCheckbox = true,
  placeholder,
  showSelectionSum,
  onChange
}) => {
  const ref = useRef();
  const [selected, setSelected] = useState([]);

  const onChangeWrapper = (selected) => {
    setSelected(() => selected);
    onChange?.(selected);
  };

  const components = {
    ...(showSelectionSum && { ValueContainer }),
    ...(withCheckbox && { Option })
  };

  const customStyles = {
    control: (provided, { isDisabled, isFocused }) => ({
      ...provided,
      fontSize: '.875rem',
      boxShadow: 'none',
      borderColor: isFocused
        ? 'rgba(138, 213, 189, var(--tw-border-opacity));'
        : 'rgba(239, 239, 239, var(--tw-border-opacity))',
      '&:hover': {
        borderColor: isFocused ? 'rgba(138, 213, 189, var(--tw-border-opacity));' : 'inherit'
      },
      backgroundColor: 'rgba(245, 247, 248, 1)',
      color: 'rgba(153, 171, 186)',
      cursor: isDisabled ? 'not-allowed' : 'default',
      pointerEvents: isDisabled && 'auto'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'rgb(107 114 128)'
    }),

    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderBottom: '1px solid #F5F7F8',
        '&:last-child': {
          border: 'none'
        },
        fontSize: '.875rem',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        backgroundColor: isFocused || isSelected ? 'rgba(243,244,246,1)' : null,
        '&:hover': {
          backgroundColor: 'rgba(243,244,246,1)'
        },
        color: `rgba(107, 114, 128, ${isDisabled ? 0.5 : 1})`
      };
    },
    dropdownIndicator: (styles) => {
      return {
        ...styles,
        paddingLeft: '0.375rem',
        paddingRight: '0.375rem',
        cursor: 'pointer',
        color: 'rgb(156 163 175)',
        svg: {
          height: '1rem',
          width: '1rem'
        }
      };
    },
    clearIndicator: (styles) => {
      return {
        ...styles,
        paddingLeft: '0.375rem',
        paddingRight: '0.375rem',
        cursor: 'pointer',
        color: 'rgb(156 163 175)',
        svg: {
          height: '1rem',
          width: '1rem'
        }
      };
    },
    menuPortal: (base) => ({ ...base, zIndex: 9999 })
  };

  useEffect(() => {
    // if defaultValue exists, set values to be selected once when rendered.
    if (!defaultValue) return;

    const defaultSelected = options.filter((option) => defaultValue.includes(option.value));
    setSelected(defaultSelected);
  }, [defaultValue]);

  return (
    <Select
      id={name}
      ref={ref}
      inputId={name}
      name={name}
      isMulti
      options={options}
      onChange={onChangeWrapper}
      closeMenuOnSelect={false}
      openMenuOnFocus={true}
      classNamePrefix="form-select"
      placeholder={placeholder}
      hideSelectedOptions={false}
      value={selected || ''}
      components={components}
      styles={customStyles}
      menuPortalTarget={document.querySelector('body')}
      menuShouldBlockScroll={true}
    />
  );
};

export default MultiSelectDropdown;
