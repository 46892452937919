import { useMutation } from 'react-query';

import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createShipmentMutation = () => {
  return useMutation((data) => http.post(API_ENDPOINTS.SHIPMENTS, { shipment: data }), {
    onSuccess: (data) => {
      appEvent(EventsNameEnum.Shipment_CreateShipment, {
        shipment_id: data.data.id
      });
    }
  });
};

export default createShipmentMutation;
