import { GridItemType } from '@components/common/grid-item';
import { SignatureProps } from '@components/dataEntry/form-signature-stamp';
import { InputProps } from 'react-select';
import { ItemsArrayTypes } from '../components/ItemsArray';

export type DocumentTableInputType = {
  label?: string;
  customLabel?: null | string;
  name: string;
  hidden?: boolean;
};

export enum DocumentSections {
  preProductTable = 'pre-product-table',
  productTable = 'product-table',
  productTableHeader = 'product-table-header',
  productTableTotal = 'product-table-total',
  productTableRow = 'product-table-row',
  postProductTable = 'post-product-table'
}

type DocumentSectionsMap<DocumentSections> = DocumentSections[keyof DocumentSections];

export type PDFPage = {
  sections?: DocumentSections[];
  pageNumber?: number;
  tableHeight?: number;
  numberOfPages?: number;
  tableIndexesAfterSort?: number[];
};

export type PDFpagesConfig = PDFPage[];

export type TableProps = {
  type?:
    | 'commercialInvoice'
    | 'packingList'
    | 'proformaInvoice'
    | 'purchaseOrder'
    | 'quotation'
    | 'sli'
    | 'truckLoadBol';
  data?: DocumentTableInputType[];
  rowOrderByID?: string[];
};

export type FeatureLabelOptions = {
  customLabel?: null | string;
  hidden?: boolean;
  disabledOptions?: boolean;
  entity?: 'packages' | 'products';
  required?: false;
  TableProps?: TableProps;
};

export type TemplateType = {
  name: string;
  orgName?: string;
  orgAddress?: string;
  isViewMode?: boolean;
};

export type DocumentTemplateType = {
  name?: string;
  section?: DocumentSectionsMap<DocumentSections>;
  groupName?: string;
  label?: string | boolean;
  type?: InputProps['type'];
  InputProps?: any;
  SignatureProps?: SignatureProps;
  GridProps: GridItemType | ItemsArrayTypes;
  options?: FeatureLabelOptions;
  isViewMode?: boolean;
  data?: any; // document data
};
