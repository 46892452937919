import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useState } from 'react';

export const UIContext = React.createContext<any>(null);

export const UIProvider = (props) => {
  const router = useRouter();
  const [Component, setComponent] = useState(null);

  // add params to open drawer
  const openDrawer = (props: {
    isOpenDrawer: boolean;
    params?: object;
    Component: ReactElement;
  }) => {
    router.push(
      { query: { isOpenDrawer: props.isOpenDrawer, ...router.query, ...props.params } },
      undefined,
      { shallow: true }
    );
    setComponent(props.Component);
  };

  const closePortal = () => {
    router.replace(
      {
        pathname: router.pathname,
        query:
          {
            ...(router?.query?.id ? { id: router?.query?.id } : {}),
            ...(router?.query?.template_id ? { template_id: router?.query?.template_id } : {}),
            ...(router.query?.current_tab && { current_tab: router.query?.current_tab })
          } ?? {}
      },
      undefined,
      { shallow: true }
    );
    setComponent(null);
  };

  const openPortal = (props: {
    portal: 'modal' | 'drawer';
    params?: object;
    Component: ReactElement;
  }) => {
    router.push({ query: { ...router.query, ...props.params, portal: props.portal } }, undefined, {
      shallow: true
    });
    setComponent(props.Component);
  };

  return (
    <UIContext.Provider
      value={[
        {
          Component,
          portal: router.query.portal
        },
        {
          openDrawer,
          closePortal,
          openPortal
        },
        {}
      ]}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export default UIProvider;

export const useContextUI = () => {
  const [state, dispatch] = useContext(UIContext);
  return [state, dispatch];
};
