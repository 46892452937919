import { FC } from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

const options = {
  size: {
    xs: '20px',
    s: '30px',
    m: '40px',
    l: '50px'
  },
  color: {
    green: '#10b981',
    white: '#FFFFFF',
    gray: '#DFE5EA'
  }
};

interface Props {
  size?: 'xs' | 's' | 'm' | 'l';
  className?: string;
  color?: 'white' | 'green' | 'gray';
}

const SpinnerLoader: FC<Props> = ({ size = 's', className, color = 'green' }) => {
  return (
    <div
      className={`flex content-center items-center ${className}`}
      style={{ justifyContent: 'center' }}
    >
      <MoonLoader color={options.color[color]} size={options.size[size]} />
    </div>
  );
};

export default SpinnerLoader;
