import { User } from '@services/types';

export const formatUserNameSelectList = (users: User[], emailAsValue: boolean = false) => {
  return users.map((user) => {
    const name = user.name === null ? user.email : `${user.name} <${user.email}>`;

    if (!emailAsValue) {
      return {
        name: name,
        value: user.id
      };
    } else {
      return {
        name: name,
        value: user.email
      };
    }
  });
};
