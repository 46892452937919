import useRequest from '@services/api';

export type EntityCustomFieldsKeyType = 'product' | 'contact' | 'shipment';
export type EntityCustomFieldValuesType = {
  key: string;
  entityId: number;
  name: string;
};

const useShipmentEntityCustomField = ({ entity_type, shipmentId }) => {
  if (!shipmentId) return [];
  const { data } = useRequest.shipments.getById(
    { id: shipmentId },
    { options: { enabled: !!shipmentId } }
  );
  const { data: customFields = [] } = useRequest.custom_fields.getByEntityType({
    params: { entity_type },
    options: { keepPreviousData: true }
  });

  const matchValuesAndCustomFields = customFields?.reduce((acc, curr) => {
    acc.push({
      ...curr,
      name: curr?.name,
      type: curr?.fieldType === 'dropdown' ? 'select' : curr.fieldType,
      entity: curr.fieldType,
      value: data?.[curr?.name]
    });
    return acc;
  }, []);

  const getEntityIdFromCustomField = (type: EntityCustomFieldsKeyType) => {
    return matchValuesAndCustomFields
      ?.map((field) =>
        field.type === type ? { key: type, entityId: field.value, name: field.name } : ''
      )
      .filter((item) => item);
  };

  let typeFields = {
    product: [],
    shipment: [],
    contact: []
  };

  matchValuesAndCustomFields?.forEach((field) => {
    const typesField = getEntityIdFromCustomField(field.type);
    typeFields[field.type] = typesField;
  });

  return [...typeFields.product, ...typeFields.contact, ...typeFields.shipment];
};

export default useShipmentEntityCustomField;
