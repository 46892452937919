const initialPaymentsState = {
  selectedPaymentIDs: []
};

export const createPaymentsSlice = (set) => ({
  ...initialPaymentsState,
  setSelectedPaymentIDs: (paymentIDs) => {
    return set(() => ({ selectedPaymentIDs: paymentIDs }), false, 'setSelectedPaymentIDs');
  }
});
