import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function deleteWorkflow(id: string) {
  return http.delete(`${API_ENDPOINTS.WORKFLOWS}/${id}`);
}

export const useDeleteWorkflowMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: string) => deleteWorkflow(id), {
    onSuccess: () => {
      queryClient.refetchQueries(`${API_ENDPOINTS.WORKFLOWS}`);
      toastNotification({
        type: 'success',
        description: 'Workflow was successfully removed.'
      });
    }
  });
};
