import RenderIf from '@components/common/render-if';
import AdminModeBanner from '@components/general/AdminModeBanner';
import FreeTrialBanner from '@components/general/FreeTrialBanner';
import InboxNotificationStatus from '@components/navigation/InboxNotificationStatus';
import ProfileDropdown from '@components/navigation/profile-dropdown';
import Search from '@components/navigation/search';
import HeadwayWidget from '@headwayapp/react-widget';
import { LightBulbIcon } from '@heroicons/react/outline';
import useCreateADocument from '@hooks/use-create-a-document';
import useRequest from '@services/api';
import { useStore } from '@store/useStore';
import Link from 'next/link';
import CompanyLogo from './CompanyLogo';
import NavbarToggler from './NavbarToggler';
import Navigation from './Navigation';

const MainLayout = ({ children }: any) => {
  const { data } = useRequest.user.get();
  const setLeftNavigation = useStore((x) => x.setLeftNavigation);
  const searchState = useStore((x) => x.search);

  // create a document using params
  useCreateADocument();

  const isLeftNavigation = useStore((x) => x.isLeftNavigation);
  const userData = data?.user;

  return (
    <div className="flex h-screen overflow-hidden">
      <div
        className={`bg-primary-grey-50 h-screen p-5 pt-8 relative duration-300`}
        style={{
          width: isLeftNavigation ? '13rem' : '4.5rem'
        }}
      >
        <NavbarToggler
          setOpen={() => setLeftNavigation(!isLeftNavigation)}
          open={isLeftNavigation}
        />
        <Link href={'/dashboard'} passHref>
          <span className="cursor-pointer hover:bg-gray-500">
            <CompanyLogo open={isLeftNavigation} userData={userData} />
          </span>
        </Link>
        <Navigation open={isLeftNavigation} />
      </div>
      <div
        className="flex flex-1 flex-col"
        style={{ width: isLeftNavigation ? 'calc(100vw - 13rem)' : 'calc(100vw - 4.5rem)' }}
      >
        <RenderIf isTrue={searchState.focus}>
          <div
            className="absolute w-full h-full z-50"
            style={{
              background:
                'linear-gradient(115.68deg, rgba(208, 213, 221, 0.4) 10.24%, rgba(255, 255, 255, 0.4) 76.82%)',
              backdropFilter: 'blur(5px)'
            }}
          />
        </RenderIf>
        <AdminModeBanner />
        <div
          className={`${
            !searchState.focus && 'sticky top-0'
          } flex h-16 flex-shrink-0 bg-white border border-common-stroke`}
          style={{ zIndex: !searchState.focus && 15 }}
        >
          <div className="flex flex-1 justify-between" style={{ paddingLeft: '1rem' }}>
            <div className="flex flex-1 justify-center">
              <Search />
            </div>
            <div className="flex items-center mx-2">
              <HeadwayWidget account="7vYejx">
                <LightBulbIcon className="h-6 w-6 text-[#99ABBA]" aria-hidden="true" />
              </HeadwayWidget>
            </div>
            <div className="flex items-center mx-4">
              <InboxNotificationStatus />
            </div>
            <div className="flex items-center h-full py-4">
              <div className="h-full" style={{ background: '#DFE5EA', width: '2px' }}></div>
            </div>
            <div className="flex items-center mx-4">
              <ProfileDropdown userData={userData} />
            </div>
          </div>
        </div>
        <FreeTrialBanner />

        <main className="flex-1 w-full overflow-auto">
          <div className="mx-auto h-full">
            <div className="rounded-lg border-4 border-dashed border-gray-200 h-full">
              {children}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
