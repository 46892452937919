import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';

import { useRouter } from 'next/router';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function bulkDeleteShipmentPackages(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHIPMENT_PACKAGES}/bulk_delete`, inputs);
}

export const useBulkDeleteShipmentPackagesMutation = () => {
  const queryClient = useQueryClient();
  const { query } = useRouter();

  return useMutation((input: any) => bulkDeleteShipmentPackages(input), {
    onSuccess: (data) => {
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PACKAGES, {
        queryKey: query.id
      });
      toastNotification({
        type: 'success',
        description: data.data.message
      });
    }
  });
};
