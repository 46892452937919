import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const updateDocumentMutation = () => {
  return useMutation((data: any) =>
    http.put(API_ENDPOINTS.UPDATE_DOCUMENT(data.id), { document: data })
  );
};

export default updateDocumentMutation;
