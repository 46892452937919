import ModalView from '@components/feedback/modal-view';
import Button from '@components/general/button';
import useModal from '@hooks/use-modal';
import useTrialSales from '@hooks/use-trial-sales';
import { useStore } from '@store/useStore';

const ShipmentUploadModal = () => {
  const { closeModalByPosition } = useModal();
  const setShipmentType = useStore((state) => state.setShipmentType);
  const { onShipmentLimitValidation, toastFeedBack } = useTrialSales();
  const hasExceed = onShipmentLimitValidation();

  const handleClick = (type) => {
    if (hasExceed) {
      return toastFeedBack();
    }
    closeModalByPosition();
    if (type) {
      setShipmentType(type);
    }
  };

  const Footer = () => (
    <div
      id="modal-footer"
      className="flex flex-row-reverse text-right w-full px-4 py-3 border-t border-gray-200 bg-white"
    >
      <Button
        onClick={() => handleClick('export')}
        className="text-semantic-success-500 text-xs ml-2"
      >
        Export Shipments
      </Button>
      <Button
        onClick={() => handleClick('import')}
        className="text-semantic-success-500 text-xs ml-2"
      >
        Import Shipments
      </Button>
      <Button onClick={() => handleClick('')} className="text-primary-grey-500 text-xs">
        Close
      </Button>
    </div>
  );

  return (
    <ModalView header="Upload" footer={<Footer />}>
      <div>
        <div className="flex">
          <div className="relative flex items-stretch flex-grow focus-within:z-10 text-primary-navy-400">
            Are you uploading Import or Export shipments?
          </div>
        </div>
      </div>
    </ModalView>
  );
};

export default ShipmentUploadModal;
