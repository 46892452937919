import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const deleteFileMutation = () => {
  return useMutation((params?: any) => http.delete(API_ENDPOINTS.DELETE_PRODUCT_FILE, { params }));
};

export default deleteFileMutation;
