const PdfIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none">
      <path
        fill="#99ABBA"
        d="M7.574 7.617c-.13.038-.32.043-.524.013a2.5 2.5 0 0 1-.663-.197c.392-.057.696-.04.956.052.062.022.163.08.231.132Zm-2.186-.36-.047.014c-.106.028-.208.056-.307.081l-.133.034c-.268.068-.543.137-.813.22.103-.249.198-.5.292-.745.07-.181.14-.367.213-.55a3.59 3.59 0 0 0 .795.947ZM4.706 4.46c.017.306-.049.6-.145.883-.12-.35-.175-.735-.026-1.047a.601.601 0 0 1 .09-.144.678.678 0 0 1 .081.308Zm-1.4 3.878a5.29 5.29 0 0 1-.205.338c-.17.256-.447.529-.589.529-.014 0-.03-.002-.055-.028-.016-.017-.019-.03-.018-.046.005-.093.129-.26.309-.415.163-.14.348-.266.558-.378Zm4.721-.708c-.022-.312-.548-.513-.553-.514a1.993 1.993 0 0 0-.675-.108c-.27 0-.559.04-.931.126a3.24 3.24 0 0 1-.831-.854 5.223 5.223 0 0 1-.253-.427c.18-.432.343-.896.313-1.416-.023-.417-.211-.697-.467-.697-.175 0-.326.13-.45.387-.218.457-.16 1.042.172 1.74-.12.28-.231.572-.339.855-.134.35-.272.713-.427 1.057a3.778 3.778 0 0 0-1.094.64c-.196.167-.431.424-.445.692a.443.443 0 0 0 .125.335c.093.099.21.15.34.151.427 0 .837-.586.915-.704.157-.236.304-.5.448-.804.362-.13.748-.228 1.122-.323l.134-.034c.1-.025.205-.054.313-.083.113-.03.23-.063.349-.093.384.244.797.404 1.2.462.34.05.64.02.845-.086.183-.095.193-.243.189-.302Zm.826 2.688c0 .572-.504.607-.606.608H1.74c-.57 0-.604-.508-.605-.608V1.671c0-.573.505-.608.605-.61h4.396l.003.003v1.716c0 .344.208.996.996.996H8.84l.014.015v6.526ZM8.45 3.386H7.134c-.57 0-.604-.505-.605-.605V1.458l1.92 1.928Zm.795 6.932V3.63L6.53.904V.89h-.013l-.218-.22H1.739c-.345 0-.996.21-.996 1v8.647c0 .346.208 1 .996 1h6.509c.344 0 .996-.21.996-1"
      />
    </svg>
  );
};

export default PdfIcon;
