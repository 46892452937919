import React from 'react';

export const ToastSuccessIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="13"
      height="12"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m.875 6.438 4.219 4.218L12.125.812"
        stroke="currentColor"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ToastErrorIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="13"
      height="13"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m5.094 5.094 2.812 2.812m0-2.812L5.094 7.906M12.125 6.5a5.625 5.625 0 1 1-11.25 0 5.625 5.625 0 0 1 11.25 0Z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ToastWarningIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="15"
      height="13"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m1.685 10.016-.433-.25.433.25Zm11.63 0 .433-.25-.434.25ZM6.281 2.049l.433.25-.433-.25Zm2.436 0 .433-.25-.433.25ZM8 5.563a.5.5 0 0 0-1 0h1ZM7 7.906a.5.5 0 1 0 1 0H7Zm.5 1.875v-.5a.5.5 0 0 0-.5.5h.5Zm.005 0h.5a.5.5 0 0 0-.5-.5v.5Zm0 .005v.5a.5.5 0 0 0 .5-.5h-.5Zm-.005 0H7a.5.5 0 0 0 .5.5v-.5Zm-6.248-.02c-.733 1.271.184 2.859 1.651 2.859v-1a.906.906 0 0 1-.785-1.36l-.866-.499Zm11.63.5a.906.906 0 0 1-.786 1.359v1c1.467 0 2.385-1.588 1.652-2.859l-.867.5ZM6.714 2.299a.906.906 0 0 1 1.57 0l.866-.5C8.418.527 6.582.527 5.85 1.799l.866.5ZM7 5.563v2.343h1V5.563H7Zm.5 4.718h.005v-1H7.5v1Zm-.495-.5v.005h1v-.005h-1Zm.5-.495H7.5v1h.005v-1Zm.495.5v-.005H7v.005h1Zm-5.882.48 4.597-7.967-.866-.5-4.597 7.967.866.5Zm6.167-7.967 4.596 7.967.867-.5L9.15 1.8l-.866.5Zm3.811 9.326H2.903v1h9.193v-1Z"
        stroke="currentColor"
        fill="none"
      />
    </svg>
  );
};
