import { toastNotification } from '@hooks/toast-notification';
import { useRouter } from 'next/router';
import { useMutation, useQueryClient } from 'react-query';
import { TaskResponseType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function updateWorkflowsTasks(inputs: TaskResponseType) {
  return http.put(`${API_ENDPOINTS.WORKFLOWS_TASKS}/${inputs.id}`, inputs);
}

export const useUpdateWorkflowsTasksMutation = () => {
  const queryClient = useQueryClient();
  const { query } = useRouter();

  return useMutation((inputs: TaskResponseType) => updateWorkflowsTasks(inputs), {
    onSuccess: () => {
      queryClient.refetchQueries(API_ENDPOINTS.GET_WORKFLOW(query.id));
      toastNotification({
        type: 'success',
        description: 'Task was successfully updated.'
      });
    }
  });
};
