import useRequest from '@services/api';
import { useEffect, useRef } from 'react';
import { identify } from 'tools/segment';

export const useIdentify = () => {
  const { data, isLoading } = useRequest.user.get();
  const ref = useRef(1);

  useEffect(() => {
    // fire the event once per page.
    // TODO: it must be fire once the user is logged.
    if (!isLoading && ref.current === 1) {
      ++ref.current;
      const traits = data.user;
      identify(data.user.id, {
        email: traits.email,
        name: traits.name,
        organization: traits.organization,
        organization_id: traits.organization_id,
        organization_created_at: traits.organization_created_at,
        role: traits.role
      });
    }
  }, [data]);
};
