import { usePrevious } from '@hooks/use-previous';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { appEvent, appPageView } from 'tools/segment';
import { EventsNameEnum } from 'types/events';

const useSegment = () => {
  const router = useRouter();
  const previousUrl = usePrevious(router);
  const fullUrl = router.asPath;
  const prevFullUrl = previousUrl !== undefined ? previousUrl.asPath : '';

  // send event page to Segment once the url has changed
  useEffect(() => {
    if (fullUrl !== prevFullUrl) {
      appPageView(location);
    }
  }, [router, prevFullUrl]);

  useEffect(() => {
    appEvent(EventsNameEnum.App_Open);
  }, []);
};

export default useSegment;
