import FileUpload from '@components/dataEntry/file-upload';
import ModalView from '@components/feedback/modal-view';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import ModalFooter from '../modal-footer';

export interface DocumentTypesProps {
  fileIndex: number;
}

const UploadProductFilesModal = () => {
  // states
  const [files, setFiles] = useState([]);
  const setSelectedProductFileIDs = useStore((state) => state.setSelectedProductFileIDs);

  // hooks
  const {
    query: { id: productId }
  } = useRouter();
  const queryClient = useQueryClient();
  const { closeModalByPosition } = useModal();

  const { mutateAsync: updateProductMutation, isLoading } = useRequest.products.update(productId);

  const onChangeFile = async (files) => {
    setFiles(files);
  };

  const onSubmit = async () => {
    try {
      let formData = new FormData();
      files.map((file) => {
        formData.append('product[files][]', file);
      });
      await updateProductMutation(formData, {
        onSuccess: () => {
          setSelectedProductFileIDs([]);
          queryClient.invalidateQueries([API_ENDPOINTS.GET_PRODUCT_FILES(productId)]);
          toastNotification({
            type: 'success',
            description: 'Files uploaded successfully'
          });
          closeModalByPosition();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalView header="Upload files">
      <div>
        <FileUpload multiple onChangeFile={onChangeFile} />
        <ModalFooter type="submit" mainButtonText="Upload" onClick={onSubmit} loading={isLoading} />
      </div>
    </ModalView>
  );
};

export default UploadProductFilesModal;
