import RenderIf from '@components/common/render-if';
import Button, { ButtonPropsType } from '@components/general/button';
import useModal from '@hooks/use-modal';
import { ModalIds } from 'constants/modal-ids';
import { FC } from 'react';

export type ModalFooterProps = {
  modalId?: ModalIds | string;
  disabled?: boolean;
  onClick?: () => void;
  mainButtonText?: string;
  mainButtonType?: 'submit' | 'reset' | 'button';
  mainButtonVariant?: ButtonPropsType['variant'];
  buttonsSize?: 'xs';
  addContinue?: boolean;
  onClickAddContinue?: () => void;
} & ButtonPropsType;

const ModalFooter: FC<ModalFooterProps> = ({
  modalId,
  disabled = false,
  onClick,
  mainButtonText = '',
  mainButtonType = 'button',
  buttonsSize = 'xs',
  addContinue,
  onClickAddContinue,
  mainButtonVariant = 'primary',
  ...rest
}) => {
  const { openModal, closeModalByPosition } = useModal();

  return (
    <div className="px-4 py-3 fixed bottom-0 left-0 flex flex-row-reverse text-right w-full border-t border-gray-200 bg-white">
      <RenderIf isTrue={mainButtonText}>
        <Button
          disabled={disabled}
          onClick={onClick}
          variant={mainButtonVariant}
          size={buttonsSize}
          className="ml-3"
          type={mainButtonType}
          loading={rest.loading}
          formId={rest.formId}
        >
          {mainButtonText}
        </Button>
      </RenderIf>
      {addContinue && (
        <Button
          disabled={disabled}
          onClick={() => {
            openModal({ id: modalId, isOpen: true, mode: 'continue' });
            onClickAddContinue?.();
          }}
          className="text-semantic-success-500 text-xs"
          type={mainButtonType}
          loading={rest.loading}
        >
          Add and Create Another
        </Button>
      )}
      <Button className="text-primary-grey-500 text-xs" onClick={() => closeModalByPosition()}>
        Cancel
      </Button>
    </div>
  );
};

export default ModalFooter;
