import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const updateNotificationMutation = () => {
  return useMutation(({ id, params }: { id: any; params?: any }) =>
    http.put(API_ENDPOINTS.READ_NOTIFICATION_STATUS(id), params)
  );
};

export default updateNotificationMutation;
