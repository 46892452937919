import { FC, useEffect, useRef, useState } from 'react';

import Input from '@components/dataEntry/input';
import { FolderAddIcon } from '@heroicons/react/outline';
import useFileUpload from '@hooks/use-file-upload';
import Signature from 'signature_pad';

export interface Props {
  view: 'type' | 'draw' | 'upload';
  onChange?: (e: HTMLInputElement) => void;
  className?: string;
}

const SignaturePad: FC<Props> = ({ view = 'draw', onChange, className }) => {
  const canvasRef = useRef();
  const inputRef = useRef();
  const [signature, setSignature] = useState('');
  const [pad, setPad] = useState<Signature>();
  const [canvas, setCanvas] = useState<any>();
  const input: HTMLInputElement = inputRef.current;
  const { handleChange, fileRef, selectedFile, showOpenFileDialog } = useFileUpload();

  let signatureCanvas;

  useEffect(() => {
    if (canvasRef.current) {
      signatureCanvas = canvasRef.current;
      const signaturePad = new Signature(canvasRef.current, {
        backgroundColor: 'transparent'
      });
      setPad(signaturePad);
      setCanvas(signatureCanvas);
      signaturePad.backgroundColor = 'transparent';

      signaturePad.addEventListener('endStroke', () => {
        signatureCanvas.value = signatureCanvas.toDataURL();
        if (onChange) onChange(signatureCanvas.toDataURL());
      });

      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        signatureCanvas.width = signatureCanvas.offsetWidth * ratio;
        signatureCanvas.height = signatureCanvas.offsetHeight * ratio;
        signatureCanvas.getContext('2d').scale(ratio, ratio);
        signaturePad.clear(); // otherwise isEmpty() might return incorrect value
        signaturePad.backgroundColor = 'transparent';
      };

      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();

      if (view === 'type') {
        signaturePad.off();
        signaturePad.clear();
        const context = signatureCanvas.getContext('2d');
        context.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);
        context.font = 'italic 40px Segoe Script, Brush Script MT';
        context.fillText(signature, 10, 80);
        if (input) window.setTimeout(() => input.focus(), 50);
      }

      if (view === 'draw') {
        signaturePad.on();
        signaturePad.clear();
        signatureCanvas.value = '';
        if (input) input.value = '';
      }

      if (view === 'upload') {
        clear();
        resizeCanvas();
      }
    }
  }, [view, signature, canvas]);

  const clear = () => {
    pad.clear();
    canvas.value = '';
    if (input) input.value = '';
  };

  const onChangeText = (text) => {
    setSignature(text);
  };

  useEffect(() => {
    if (onChange) onChange(canvas?.toDataURL());
  }, [signature]);

  useEffect(() => {
    if (selectedFile) {
      if (onChange) onChange(selectedFile);
    }
  }, [selectedFile]);

  return (
    <div className={` ${className}`}>
      {view === 'draw' && (
        <canvas
          ref={canvasRef}
          className="shadow-sm relative w-full block sm:text-sm border-gray-300 rounded-md border bg-white mt-4 h-40"
        ></canvas>
      )}
      {view === 'type' && (
        <canvas
          ref={canvasRef}
          style={{ backgroundColor: 'transparent' }}
          className="shadow-sm relative w-full block sm:text-sm border-gray-300 rounded-md border bg-gray-400 mt-4 h-40"
        ></canvas>
      )}
      {view === 'draw' && (
        <div className="flex justify-center p-2">
          <button type="button" onClick={clear} className="btn btn-white btn-sm" id="clear-button">
            Clear Signature
          </button>
        </div>
      )}
      {view === 'type' && (
        <div className="flex justify-center py-2">
          <Input
            inputRef={inputRef}
            type="text"
            placeholder="Type your name..."
            onChange={(e) => onChangeText(e.target.value)}
            className="form-input block w-full text-sm"
            accept="image/*"
          />
        </div>
      )}
      {view === 'upload' && (
        <div
          onClick={showOpenFileDialog}
          className="flex justify-center px-6 pt-5 pb-6 mb-2 border-2 border-gray-300 bg-gray-50 border-dashed rounded-md mt-4 h-40 cursor-pointer"
        >
          <div className="flex flex-col gap-2 my-6 text-center">
            <FolderAddIcon className="mx-auto h-10 w-10 text-gray-400 text-sm" strokeWidth={2} />
            <div className="text-sm text-gray-600">
              <div className="relative cursor-pointer font-medium grow-0 text-blue-600 hover:text-primary-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <span>Upload a file</span>
                <Input
                  inputRef={fileRef}
                  type="file"
                  onChange={handleChange}
                  hidden
                  accept="image/*"
                />
              </div>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
            {selectedFile && (
              <p>
                <strong>File Name:</strong> {selectedFile.name}
              </p>
            )}
          </div>
        </div>
      )}
      <p className="font-medium text-xs text-gray-400">
        By signing any document with an electronic signature, I agree that such signature will be as
        valid as a wet ink or handwritten signature and considered original to the extent allowed by
        the applicable law.
      </p>
    </div>
  );
};

export default SignaturePad;
