export const productInputListData = [
  { type: 'text', label: 'Product Code', name: 'name', readOnly: true },
  {
    type: 'textarea',
    rows: 1,
    maxLength: 60,
    limitRows: 4,
    label: 'Description',
    name: 'description',
    inputClassName: 'resize-y'
  },
  { type: 'number', label: 'Net Weight (kg)', name: 'net_weight', placeholder: 'Net Weight' },
  { type: 'number', label: 'Quantity', name: 'quantity' },
  { type: 'text', label: 'Unit Type', name: 'unit_type' },
  { type: 'number-format', label: 'Unit Price', name: 'unit_price_text' },
  { type: 'text', label: 'Amount', name: 'amount', readOnly: true }
];
