import { toastNotification } from '@hooks/toast-notification';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';

export const useMutationSideEffects = ({
  onSubmit,
  setErrorsServerValidation,
  showToastError = false,
  showToastMessage = true,
  message = null
}) => {
  const [formId] = useState(uuid());
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (form) => {
    try {
      setLoading(true);
      await onSubmit(form);

      if (showToastMessage) {
        toastNotification({ description: message ?? 'Action successful!', type: 'success' });
      }
    } catch (err) {
      const errors = err.response?.data?.errors;
      setErrorsServerValidation?.(errors);
      if (showToastError) toastNotification({ description: 'Something went wrong', type: 'error' });
    }

    setLoading(false);
  };

  return { handleSubmit, loading, formId };
};
