import Loading from '@components/loaders/loading';
import { useIdentify } from '@hooks/use-identify';
import useRequest from '@services/api';
import { ReactChild } from 'react';

export function AuthGuard({ children }: { children: ReactChild }) {
  const { data, isLoading, isError } = useRequest.user.get({ retry: false });
  useIdentify();

  /* show loading indicator while the auth provider is still initializing */
  if (isLoading) {
    return <Loading centerFullScreen />;
  }

  if (!isLoading && (data === undefined || isError)) {
    window.location.href = '/sign-in';
  }

  // if auth initialized with a valid user show protected page
  if (!isLoading && data !== undefined && data.user) {
    return <>{children}</>;
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null;
}
