import { FC, ReactNode } from 'react';

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'text-base'
  | 'text-default'
  | 'text-sm'
  | 'text-xs'
  | 'text-link-default'
  | 'text-link-small'
  | 'text-link-xs'
  | 'link-base'
  | 'link-sm'
  | 'link-xs'
  | 'button';

interface Props {
  variant?: Variant;
  className?: string;
  children?: ReactNode;
}

const Typography: FC<Props> = ({ variant = 'text-default', className, children }: Props) => {
  return <span className={`${variant} ${className ? className : ''}`}>{children}</span>;
};

export default Typography;
