import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function addPaymentToShipment(inputs: any) {
  return http.post(`${API_ENDPOINTS.PAYMENTS}`, inputs);
}

export const useAddPaymentToShipmentMutation = () => {
  return useMutation((input: any) => addPaymentToShipment(input), {
    onSuccess: () => {
      toastNotification({ type: 'success', description: 'Payment was added.' });
    }
  });
};
