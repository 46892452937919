import { AuthGuard } from '@components/authentication/AuthGuard';
import RenderIf from '@components/common/render-if';
import ModalWrapper from '@components/sharing/modal-wrapper';
import useRequest from '@services/api';
import MainLayout from 'layout/MainLayout';
import { NextPage } from 'next';
import { Toaster } from 'react-hot-toast';
import { sentryInit } from 'sentry.client.config';
// styles
import Portals from '@components/sharing/portals/Container';
import useTrialSales from '@hooks/use-trial-sales';
import Script from 'next/script';
import '../styles/share-styles.scss';
import '../styles/tailwind.css';
import AppProviders from './AppProviders';
import FreeTrialExpired from './FreeTrialExpired/index.page';

sentryInit();

const isDevelopment = process.env.NODE_ENV === 'development';

const NoopComponent = (props) => <>{props.children}</>;

const Scripts = () => {
  if (isDevelopment) return <></>;
  return (
    <Script id="microsoft-clarity">{`(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hjaprbsqgp");`}</Script>
  );
};

export type PageType = NextPage & {
  PageStateProvider?: any;
  PageLayoutContainer?: any;
  WithoutAuth?: boolean;
};

function AppMain({ Component, pageProps }) {
  const { PageStateProvider = NoopComponent, PageLayoutContainer = MainLayout } = Component;
  const { onDaysTrialValidation, hasSubscription } = useTrialSales();

  if (onDaysTrialValidation()?.isLimitExceeded && !hasSubscription)
    return (
      <AuthGuard>
        <PageStateProvider>
          <PageLayoutContainer>
            <FreeTrialExpired />
          </PageLayoutContainer>
        </PageStateProvider>
      </AuthGuard>
    );

  return (
    <PageStateProvider>
      <Scripts />
      <RenderIf isTrue={!Component.WithoutAuth}>
        <AuthGuard>
          <PageLayoutContainer>
            <Component {...pageProps} />
          </PageLayoutContainer>
        </AuthGuard>
      </RenderIf>
      <RenderIf isTrue={Component.WithoutAuth}>
        <PageLayoutContainer>
          <Component {...pageProps} />
        </PageLayoutContainer>
      </RenderIf>
      <ModalWrapper />
      <Portals />
      <Toaster position="top-right" />
    </PageStateProvider>
  );
}

const App = (props) => {
  const { Component } = props;

  // run first cache
  useRequest.user.get({ enabled: !Component.WithoutAuth, retry: false });

  return <AppMain {...props} />;
};

const CargoflipApp = (props) => (
  <AppProviders {...props}>
    <App {...props} />
  </AppProviders>
);

export default CargoflipApp;
