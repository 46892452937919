import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getTask = ({ id, props }: { id: string | number; props?: queryOptions }) => {
  return useQuery<any, Error>(
    [API_ENDPOINTS.GET_TASK_BY_ID(id), props?.params],
    queryFetch,
    props?.options
  );
};

export default getTask;
