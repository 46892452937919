import RenderIf from '@components/common/render-if';
import Button from '@components/general/button';
import { TrashIcon } from '@heroicons/react/outline';
import useRequest from '@services/api';
import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormInput from './form-input';
import FormInputContainer, { FormInputContainerProps } from './form-input-container';

export interface FormCustomFieldDropdownOptionsProps extends FormInputContainerProps {
  labelClass?: string;
  entityType: 'Contact' | 'Shipment' | 'Product';
  defaultValues?: string[];
}

const FormCustomFieldDropdownOptions: FC<FormCustomFieldDropdownOptionsProps> = ({
  name,
  label,
  labelSize,
  disabled,
  labelClass,
  entityType,
  defaultValues = []
}) => {
  // states
  const [options, setOptions] = useState([]);

  // hooks
  const { getValues, setValue } = useFormContext();
  const fieldTypeWatch = useWatch({ name: 'field_type' });
  const fieldNameWatch = useWatch({ name: 'name' });

  // query
  const { data } = useRequest.custom_fields.getNameOptions({
    params: { entity_type: entityType },
    options: { keepPreviousData: true }
  });

  // const
  const isDropdown = fieldTypeWatch === 'dropdown';
  const handleRemove = (index) => {
    const currentOptions = getValues(name);
    currentOptions.splice(index, 1);
    setOptions(currentOptions);
    setValue(name, currentOptions);
  };
  const handleAdd = () => {
    const currentOptions = getValues(name);
    setOptions([...currentOptions, '']);
    setValue(name, [...currentOptions, '']);
  };
  const getIconClassName = (index, disabled) =>
    cn('h-3.5 w-3.5  ml-1', {
      'mt-9': index === 0,
      'mt-3': index !== 0,
      'opacity-50 cursor-not-allowed': options.length === 1 || disabled,
      'cursor-pointer': options.length > 1
    });

  useEffect(() => {
    if (isDropdown) {
      if (fieldTypeWatch && disabled) {
        const options = data?.find((d) => d.name === fieldNameWatch)?.dropdownOptions;
        setOptions(options || ['']);
        setValue(name, options || ['']);
      } else if (options.length === 0) {
        setOptions(['']);
        setValue(name, ['']);
      }
    } else {
      setOptions([]);
      setValue(name, []);
    }
  }, [fieldTypeWatch, fieldNameWatch]);

  useEffect(() => {
    if (defaultValues?.length > 0) {
      setOptions(defaultValues);
    }
  }, [defaultValues]);

  return !isDropdown ? (
    <></>
  ) : (
    <div className="flex flex-col items-start w-full space-y-2">
      {options.map((option, index) => (
        <div key={index} className="flex w-full text-sm gap-x-2">
          <div className="w-full">
            <FormInputContainer
              name={name}
              label={index === 0 ? label : false}
              labelSize={labelSize}
              className={labelClass}
              errorMessageIndex={index}
              containerClass="w-full"
            >
              <FormInput
                name={`${name}[${index}]`}
                label={false}
                disabled={disabled || defaultValues.includes(option)}
              />
            </FormInputContainer>
          </div>
          <RenderIf isTrue={!disabled && isDropdown}>
            <div className="w-6 h-4">
              <span
                onClick={() =>
                  options.length > 1 &&
                  !disabled &&
                  !defaultValues.includes(option) &&
                  handleRemove(index)
                }
              >
                <TrashIcon
                  className={getIconClassName(index, disabled || defaultValues.includes(option))}
                />
              </span>
            </div>
          </RenderIf>
        </div>
      ))}
      <RenderIf isTrue={!disabled && isDropdown}>
        <Button
          className="text-primary-blue-500 text-sm underline"
          onClick={handleAdd}
          defaultClass={false}
        >
          Add another choice
        </Button>
      </RenderIf>
    </div>
  );
};

export default FormCustomFieldDropdownOptions;
