import RenderIf from '@components/common/render-if';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import FormUserRoleAutoComplete from '@components/dataEntry/FormUserRoleAutoComplete';
import ModalView from '@components/feedback/modal-view';
import { yupResolver } from '@hookform/resolvers/yup';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const UserForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [showType, setShowType] = useState(false);
  const modalState = useStore((state) => state.modalState);

  // const
  const modalMode = modalState?.[0]?.mode;
  const orgUserId = modalState?.[0]?.entityId;
  const isNewUser = modalMode === 'add';
  const headerLabel = modalMode === 'edit' ? 'Update User' : 'Add User';

  // hooks
  const { closeModalByPosition } = useModal();
  const { mutateAsync: createUser, isLoading: userLoading } = useRequest.user.create();
  const { mutateAsync: updateOrganizationUser, isLoading: orgUserLoading } =
    useRequest.organization.updateOrganizationUser();

  // queries
  const { data: orgUserDetails, isLoading: updateOrgUserLoading } =
    useRequest.organization.getOrganizationUser(
      { id: orgUserId },
      {
        keepPreviousData: true,
        enabled: !!orgUserId
      }
    );

  const defaultValues = {
    id: '',
    email: '',
    name: '',
    role: 'user',
    org_user_type: 'carrier'
  };

  const typeList = [
    { name: 'Carrier', value: 'carrier' },
    { name: 'Freight Forwarder', value: 'freight_forwarder' },
    { name: 'Third Party Logistics', value: 'third_party_logistics' },
    { name: 'Supplier', value: 'supplier' }
  ];

  const validationSchema = yup.object({
    email:
      modalMode === 'edit'
        ? yup.string().optional()
        : yup.string().email('Invalid email format').required('Email is a required field'),
    name:
      modalMode === 'edit'
        ? yup.string().optional()
        : yup.string().required('Name is a required field'),
    role: yup.string().required('Name is a required field')
  });

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const roleOnChange = (item) => {
    if (item?.value === 'guest') {
      setShowType(true);
    } else {
      setShowType(false);
    }
  };

  // actions
  const queryClient = useQueryClient();

  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs: any) => {
      if (isNewUser) {
        await createUser(inputs, {
          onSuccess: (data) => {
            queryClient.refetchQueries([API_ENDPOINTS.GET_MANAGE_USERS]);
            toastNotification({ type: 'success', description: data?.data?.message });
            closeModalByPosition();
          }
        });
      } else {
        const modifiedInputs = { ...inputs, id: orgUserId };
        await updateOrganizationUser(modifiedInputs, {
          onSuccess: () => {
            queryClient.refetchQueries([API_ENDPOINTS.GET_MANAGE_USERS]);
            toastNotification({
              type: 'success',
              description: 'User role has been successfully updated'
            });
            closeModalByPosition();
          }
        });
      }
    }
  });

  // Effects
  useEffect(() => {
    if (!updateOrgUserLoading && modalMode === 'edit') {
      if (orgUserDetails.role === 'guest') {
        setShowType(true);
      } else {
        setShowType(false);
      }
    }
  }, [modalMode, orgUserDetails, updateOrgUserLoading]);

  useEffect(() => {
    methods.reset({
      id: orgUserDetails?.user_id,
      email: '',
      name: '',
      role: orgUserDetails?.role || 'user',
      org_user_type: orgUserDetails?.org_user_type || 'carrier'
    });
  }, [orgUserDetails]);

  return (
    <ModalView header={headerLabel} loading={orgUserLoading}>
      <Form
        methods={methods}
        errorsServerValidation={errorsServerValidation}
        onSubmit={handleSubmit}
        formId={formId}
        updateGlobalStorage={!!orgUserDetails}
      >
        <div className="flex flex-wrap">
          <RenderIf isTrue={isNewUser}>
            <div className="w-full mb-3">
              <FormInput label="Email" name="email" containerClass="text-primary-navy-400" />
            </div>
          </RenderIf>
          <RenderIf isTrue={isNewUser}>
            <div className="w-full mb-3">
              <FormInput label="Name" name="name" containerClass="text-primary-navy-400" />
            </div>
          </RenderIf>
          <div className="w-full mb-3">
            <FormUserRoleAutoComplete label="Role" name="role" customOnChange={roleOnChange} />
          </div>
          <RenderIf isTrue={showType}>
            <div className="w-full mb-3">
              <FormInput
                type="select"
                label="Type"
                name="org_user_type"
                optionsList={typeList}
                defaultValue={defaultValues?.org_user_type}
                containerClass="text-primary-navy-400"
              />
            </div>
          </RenderIf>
        </div>
        <ModalFooter
          disabled={userLoading || orgUserLoading || updateOrgUserLoading}
          modalId={ModalIds.USER_FORM}
          mainButtonText={isNewUser ? 'Add' : 'Update'}
          mainButtonType="submit"
        />
      </Form>
    </ModalView>
  );
};

export default UserForm;
