import RenderIf from '@components/common/render-if';
import FormShipmentAutoComplete from '@components/dataEntry/FormShipmentAutoComplete';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import { yupResolver } from '@hookform/resolvers/yup';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import useTrialSales from '@hooks/use-trial-sales';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { removeNullValues } from '@utils/remove-null-values';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const TrackingForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [reset, setReset] = useState(false);
  const modalState = useStore((state) => state.modalState);
  const formValues = modalState?.[0]?.formValues;
  const modalId = modalState?.[0]?.id;
  const { onShipmentLimitValidation, toastFeedBack } = useTrialSales();
  const isExceededTrackingLimit = onShipmentLimitValidation();

  const [defaultValues, setDefaultValues] = useState({
    choose: 'shipment_container_number',
    carrier: 'Unknown / Not Listed',
    container_number: '',
    bol_number: '',
    shipment_id: ''
  });

  // hooks
  const { closeModalByPosition } = useModal();
  const ref = useRef<HTMLInputElement>();
  const {
    pathname,
    query: { id: shipmentId }
  } = useRouter();
  const queryClient = useQueryClient();
  const isShipmentTab = pathname === '/shipments/[id]';

  // const
  const { mutateAsync: addShipmentTracking, isLoading: addTrackingLoading } =
    useRequest.shipment_trackings.create();
  const fullLoading = addTrackingLoading;
  const chooseOptions = [
    {
      name: 'Use Container Number',
      value: 'shipment_container_number'
    },
    {
      name: 'Use MBL Number',
      value: 'shipment_bol_number'
    }
  ];

  const validationSchema = yup.object({
    choose: yup.string().required(),
    carrier: yup.string(),
    container_number: yup.string().when('choose', {
      is: (choose) => choose === 'shipment_container_number',
      then: yup.string().required('Container Number is required'),
      otherwise: yup.string()
    }),
    bol_number: yup.string().when('choose', {
      is: (choose) => choose === 'shipment_bol_number',
      then: yup.string().required('Master Bill of Lading Number is required'),
      otherwise: yup.string()
    })
  });

  // actions
  const handleSubmit = async (inputs, closeModal = false) => {
    try {
      if (isExceededTrackingLimit) {
        return toastFeedBack();
      }
      const containerNumber =
        inputs.choose === 'shipment_bol_number' ? '' : inputs.container_number;
      const bolNumber = inputs.choose === 'shipment_container_number' ? '' : inputs.bol_number;
      const modifiedInputs = {
        ...inputs,
        carrier: 'Unknown / Not Listed',
        container_number: containerNumber,
        bol_number: bolNumber,
        shipment_id: inputs.shipment_id || shipmentId
      };
      let toastId;
      if (bolNumber) {
        closeModalByPosition();
        toastId = toastNotification({
          type: 'loading',
          description:
            'Searching for containers. You will be notified when tracking is ready to view.'
        });
      }
      await addShipmentTracking(
        { shipment_tracker: modifiedInputs },
        {
          onSuccess: () => {
            setReset(true);
            setDefaultValues({
              choose: 'shipment_container_number',
              carrier: 'Unknown / Not Listed',
              container_number: '',
              bol_number: '',
              shipment_id: ''
            });
            queryClient.refetchQueries([API_ENDPOINTS.SHIPMENT_TRACKINGS]);
            queryClient.refetchQueries([API_ENDPOINTS.SHIPMENTS]);
            queryClient.refetchQueries([
              API_ENDPOINTS.GET_SHIPMENT(shipmentId),
              { enabled: shipmentId }
            ]);
            if (bolNumber) toast.remove(toastId);
            toastNotification({
              type: 'success',
              description: 'Tracking was successfully added'
            });
            if (closeModal) {
              closeModalByPosition();
            }
          }
        }
      );
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors.recorded);
    }
  };

  const onSubmit = (inputs: any) => {
    handleSubmit(inputs, true);
  };

  const onSubmitContinue = async (inputs: any) => {
    handleSubmit(inputs, false);
  };

  const methods = useForm({
    defaultValues: removeNullValues(defaultValues),
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const setValues = (values) => {
    methods.reset(values);
    setDefaultValues(values);
  };

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (formValues && modalId === ModalIds.TRACKING_FORM) setValues(formValues);
  }, [formValues]);

  return (
    <ModalView header="Add New Tracking">
      <Form methods={methods} errorsServerValidation={errorsServerValidation}>
        <>
          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-1/2 px-3">
              <FormInput
                type="select"
                label="Track by"
                name="choose"
                optionsList={chooseOptions}
                containerClass="col-span-3"
                inputRef={ref}
              />
            </div>
            <div className="w-1/2 px-3">
              {ref.current?.value === 'shipment_container_number' && (
                <FormInput
                  label="Container No."
                  name="container_number"
                  containerClass="col-span-3"
                  placeholder="Container Number"
                />
              )}
              {ref.current?.value === 'shipment_bol_number' && (
                <FormInput
                  label="MBL/Booking No."
                  name="bol_number"
                  containerClass="col-span-3"
                  placeholder="Master Bill of Lading Number"
                />
              )}
            </div>
          </div>
          <RenderIf isTrue={!isShipmentTab}>
            <div className="flex flex-wrap -mx-3 mb-3">
              <div className="w-full px-3">
                <FormShipmentAutoComplete
                  label="Add to Shipment (optional)"
                  name="shipment_id"
                  clearValue={reset}
                />
              </div>
            </div>
          </RenderIf>
          <ModalFooter
            disabled={fullLoading}
            onClick={methods.handleSubmit(onSubmit)}
            modalId={ModalIds.TRACKING_FORM}
            loading={fullLoading}
            mainButtonText="Add"
            addContinue={true}
            onClickAddContinue={methods.handleSubmit(onSubmitContinue)}
          />
        </>
      </Form>
    </ModalView>
  );
};

export default TrackingForm;
