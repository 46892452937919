import ErrorList from '@components/dataDisplay/error-list';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from '@store/useStore';
import { removeNullValues } from '@utils/remove-null-values';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const scrollTo = (errors) => {
  const elements = Object.keys(errors).map((name) => document.getElementById(name));
  const removeNull = elements.filter((element) => {
    return element !== null;
  });

  const currentElements = removeNull.filter((element) => {
    return element !== undefined;
  });

  currentElements.sort((a, b) => b.scrollHeight - a.scrollHeight);
  currentElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

interface Props {
  children: any;
  defaultValues?: any;
  className?: string;
  validationSchema?: any;
  forceUpdate?: boolean;
  isTouched?: boolean;
  onEnterSubmit?: boolean;
  onSubmit?: (inputs: any) => void;
  formId?: string;
  errorsServerValidation?: object | string[];
  readOnly?: boolean;
  inlineEdition?: boolean;
  preventEnterSubmit?: boolean;
  updateGlobalStorage?: boolean;
  methods?: any;
  updateValuesAfterSuccess?: boolean;
}
/**
 * @param forceUpdate use  {@link forceUpdate} when it can not memorize form values
 */
const Form: FC<Props> = ({
  children,
  defaultValues = null,
  validationSchema,
  className,
  forceUpdate = false,
  isTouched,
  formId,
  readOnly = false,
  inlineEdition = false,
  errorsServerValidation,
  onSubmit,
  onEnterSubmit,
  preventEnterSubmit,
  methods,
  updateValuesAfterSuccess = false,
  ...rest
}) => {
  // states
  const [errorMessages, setErrorMessages] = useState([]);
  // TODO Remove formMethods once all form container are using useForm and passing the methods object
  const formMethods = useForm({
    defaultValues: defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const methodsObject = methods ?? formMethods;

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isDirty: isFormDirty, errors },
    setValue,
    getValues,
    watch
  } = methodsObject;

  const setRootFormValues = useStore((state) => state.setRootFormValues);
  const currentFormValues = watch();
  const [isDirty, setIsDirty] = useState(false);
  const data = removeNullValues(defaultValues);
  const isChildrenAFunction = typeof children === 'function';
  const formRef = useRef();

  const resetValues = useCallback(() => {
    reset(data);
  }, [data]);

  const setErrors = useCallback(() => {
    if (Array.isArray(errorsServerValidation)) {
      setErrorMessages(errorsServerValidation);
    } else {
      Object.keys(errorsServerValidation).map((e) => {
        setError(e, {
          type: 'server',
          message: errorsServerValidation[e][0]
        });
      });
    }
  }, [errorsServerValidation]);

  const onSubmitForm = (onSubmit: (inputs: any) => void) => handleSubmit(onSubmit);

  const onSubmitWrapper = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDirty(false);
    onSubmitForm(onSubmit)();
  };

  const enterSubmit = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && onEnterSubmit) {
      onSubmitWrapper(e);
    }
  };

  const onSubmitFromAnyPlace = async (e) => {
    onSubmitWrapper(e);
  };

  // Using solution found here: https://github.com/react-hook-form/react-hook-form/discussions/2549#discussioncomment-143178
  const checkKeyDown = (e) => {
    const nodeName = e.target.nodeName.toLowerCase();
    const isTextArea = nodeName === 'textarea';
    if (e.code === 'Enter' && preventEnterSubmit && !isTextArea) e.preventDefault();
  };

  useEffect(() => {
    // First load is undefined, so it will update the form values after request has done.
    if (updateValuesAfterSuccess) {
      resetValues();
    }
  }, [updateValuesAfterSuccess, defaultValues]);

  useEffect(() => {
    if (errorsServerValidation) setErrors();
  }, [errorsServerValidation]);

  useEffect(() => {
    if (isTouched || isFormDirty) setIsDirty(true);
  }, [isTouched, isFormDirty]);

  useEffect(() => {
    if (errors || errorsServerValidation) {
      scrollTo(errors || errorsServerValidation);
    }
  }, [errors, errorsServerValidation]);

  // root form values
  useEffect(() => {
    if (rest?.updateGlobalStorage && formId) {
      setRootFormValues(formId, currentFormValues);
    }
  }, [currentFormValues, rest?.updateGlobalStorage]);

  const fullMethods: any = {
    ...methodsObject,
    inlineEdition,
    isDirty,
    readOnly,
    formRef,
    onSubmitForm
  };

  const defaultFieldsMemorized = useMemo(
    () => (isChildrenAFunction ? children({ onSubmitForm, resetValues, isDirty }) : null),
    [data, forceUpdate]
  );

  const childrenComp = forceUpdate
    ? children({ onSubmitForm, resetValues, isDirty, readOnly, getValues, setValue, errors })
    : defaultFieldsMemorized;

  const Children = isChildrenAFunction ? childrenComp : children;

  return (
    <FormProvider {...fullMethods}>
      <form
        id={formId}
        ref={formRef}
        onKeyPress={enterSubmit}
        onSubmit={(e) => onSubmitFromAnyPlace(e)}
        className={className}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        {/* TODO: Remove the following when new design is implemented.
        The following part is rendered when we have generic error messages. */}
        {errorMessages.length > 0 && <ErrorList errorMessages={errorMessages} />}
        {Children}
      </form>
    </FormProvider>
  );
};

export default Form;
