import Form from '@components/dataEntry/form';
import FormAutocomplete from '@components/dataEntry/form-autocomplete';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useModal from '@hooks/use-modal';
import { useShipmentProductsQuery } from '@services/api/shipment-products/get-all-shipment-products';
import { useAddShipmentProductMutation } from '@services/api/shipment-products/use-add-shipment-product';
import { useUpdateShipmentProductMutation } from '@services/api/shipment-products/use-update-shipment-product';
import { ProductInitialValueType } from '@services/types';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const ShipmentProductForm = () => {
  // hooks
  const { query } = useRouter();
  const modalState = useStore((state) => state.modalState);
  const { closeModalByPosition } = useModal();

  // Set states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const isAutocompleteLoading = useStore((state) => state.autocompleteLoading);

  // Queries
  const { mutateAsync: addShipmentProduct } = useAddShipmentProductMutation();
  const { mutateAsync: updateShipmentProduct } = useUpdateShipmentProductMutation();
  const {
    data: shipmentProducts,
    isLoading: productsLoading,
    isSuccess
  } = useShipmentProductsQuery({
    shipment_id: Number(query.id)
  });

  // State variables
  const modalMode = modalState?.[0]?.mode;
  const formValues = modalState?.[0]?.formValues;
  const shipmentProductId = modalState?.[0]?.entityId;
  const [defaultValues, setDefaultValues] = useState<ProductInitialValueType>(
    formValues || {
      product_id: '',
      quantity: '',
      unit_price: ''
    }
  );
  const isNewShipmentProduct = modalMode === 'add';
  const fullLoading = isAutocompleteLoading;
  const headerLabel = modalMode === 'edit' ? 'Update Product' : 'Add Product';
  const isPopulateInitialValues = modalMode === 'edit';
  const shipmentProductsDataTable =
    !productsLoading && shipmentProducts ? shipmentProducts?.data : [];

  // form validation
  const validationSchema = yup.object({
    product_id: yup.string().required('Product must exist.'),
    quantity: yup.string().required(`Quantity can't be blank.`),
    unit_price: yup.string().required('Unit price must be greater than 0.')
  });

  // actions
  const onSubmit = async (inputs) => {
    const shipmentProducts = shipmentProductsDataTable.find(
      (data) => data.id === shipmentProductId
    );
    const id = shipmentProducts?.id;

    let modifiedInputs = {
      ...inputs,
      unit_price_text: inputs.unit_price,
      product_id: inputs.product_id,
      shipment_id: query.id
    };

    delete modifiedInputs.product;

    let modifiedInputsWhenUpdate = {
      ...modifiedInputs,
      id,
      quantity: modifiedInputs.quantity.split('.')[0],
      unit_price_text: modifiedInputs.unit_price
    };

    delete modifiedInputsWhenUpdate.product;

    try {
      if (isNewShipmentProduct) {
        await addShipmentProduct(modifiedInputs);
      } else {
        await updateShipmentProduct(modifiedInputsWhenUpdate);
      }

      closeModalByPosition();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  const updateProductsState = () => {
    const products = shipmentProductsDataTable.find((data) => data.id === shipmentProductId);
    setDefaultValues(
      formValues || {
        product_id: products?.product_id,
        quantity: products?.quantity,
        unit_price: products?.unit_price_number
      }
    );
  };

  // effects
  useEffect(() => {
    if (
      shipmentProductsDataTable &&
      shipmentProductsDataTable.length > 0 &&
      isPopulateInitialValues
    ) {
      updateProductsState();
    }
  }, [modalMode, shipmentProductsDataTable]);

  useEffect(() => {
    if (!isPopulateInitialValues) {
      setDefaultValues({
        ...defaultValues
      });
    }
  }, [isPopulateInitialValues]);

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        updateValuesAfterSuccess={isSuccess}
      >
        {({ onSubmitForm }) => {
          return (
            <>
              <div className="w-full mb-3">
                <FormAutocomplete
                  name="product_id"
                  label="Product"
                  entity="product"
                  icon="list"
                  defaultValue={Number(defaultValues.product_id)}
                  isCreateNew
                  isEditable
                />
              </div>
              <div className="w-full mb-3">
                <div className="flex flex-row gap-x-4">
                  <FormInput
                    label="Quantity"
                    containerClass="w-1/2"
                    name="quantity"
                    type="number"
                  />
                  <FormInput
                    label="Unit Price"
                    containerClass="w-1/2"
                    name="unit_price"
                    type="number"
                  />
                </div>
              </div>
              <ModalFooter
                onClick={onSubmitForm(onSubmit)}
                modalId={ModalIds.SHIPMENT_PRODUCT_FORM}
                mainButtonText={isNewShipmentProduct ? 'Add' : 'Update'}
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default ShipmentProductForm;
