const addValuesByEntity = {
  customFieldNames: ['name', 'name'],
  shipment: ['id', 'name'],
  user: ['email', 'name']
};

const getDescriptionByEntity = (data) => {
  return { customFieldNames: data.description };
};

export const formatAutocompleteData = ({
  entity,
  options = [],
  disabledList = []
}: {
  entity: 'customFieldNames' | 'shipment' | 'user';
  options: any[];
  disabledList?: string[];
}) => {
  const getIsDisabled = (item) => disabledList.length > 0 && disabledList.includes(item);

  const getDescriptionLabel = (data: any = {}, entity = '') => {
    const conditionsForDescription = {
      customFieldNames: !!data.description
    };

    return conditionsForDescription[entity] ? getDescriptionByEntity(data)[entity] : '';
  };

  const getLabel = (value) => {
    if (entity !== 'user') return String(value[addValuesByEntity[entity][1]]);

    const label = value?.name ? `${value?.name} <${value?.email}>` : value?.email;
    return label;
  };

  const updateList = options?.reduce((acc, curr) => {
    const obj = {
      ...curr,
      value: String(curr[addValuesByEntity[entity][0]]),
      label: getLabel(curr),
      isDisabled: getIsDisabled(curr[addValuesByEntity[entity][0]]),
      descriptionForLabel: getDescriptionLabel(curr, entity)
    };
    acc.push(obj);
    return acc;
  }, []);

  return updateList;
};
