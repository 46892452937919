import { createContactsSlice } from '@store/contacts';
import { createPaymentsSlice } from '@store/payments';
import { createSettingsSlice } from '@store/settings';
import { createTasksSlice } from '@store/tasks';
import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { createAutocompleteSlice } from './autocomplete';
import { createCommonsSlice } from './commons';
import { createGeneratedDocumentSlice } from './generated-documents';
import { createManageFieldsSlice } from './manage-fields';
import { createNotificationsSlice } from './notifications';
import { createProductsSlice } from './products';
import { createShipmentDetailsSlice } from './shipment-details';
import { createShipmentsSlice } from './shipments';
import { createUISlice } from './ui';
import { createUserSlice } from './user';
import { createWorkflowSlice } from './workflows';

const env = process.env.NODE_ENV || 'development';

export const store = (set) => ({
  ...createUISlice(set),
  ...createUserSlice(set),
  ...createPaymentsSlice(set),
  ...createSettingsSlice(set),
  ...createContactsSlice(set),
  ...createTasksSlice(set),
  ...createShipmentsSlice(set),
  ...createGeneratedDocumentSlice(set),
  ...createShipmentDetailsSlice(set),
  ...createWorkflowSlice(set),
  ...createProductsSlice(set),
  ...createCommonsSlice(set),
  ...createAutocompleteSlice(set),
  ...createManageFieldsSlice(set),
  ...createNotificationsSlice(set)
});

export const useStore = create(
  env === 'development' ? devtools(store, { name: 'Cargoflip' }) : store
);
