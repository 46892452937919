type autocompleteCreateNewEntityItemStateType = {
  data: any;
  createItemName: string;
  inputId: string;
};

const initialAutocompleteCreateNewEntityItemState = {
  autocompleteLoading: false,
  autocompleteCreateNewEntityItemState: {
    data: {},
    createItemName: '',
    inputId: ''
  } as autocompleteCreateNewEntityItemStateType,
  autocompleteV2CreateNewEntityItemState: {
    data: {},
    createItemName: '',
    inputId: ''
  } as autocompleteCreateNewEntityItemStateType
};

export const createAutocompleteSlice = (set) => ({
  ...initialAutocompleteCreateNewEntityItemState,
  setAutocompleteLoading: (value) =>
    set(() => ({ autocompleteLoading: value }), false, 'setAutocompleteLoading'),
  setAutocompleteCreateNewEntityItemState: (values) =>
    set(
      (state) => ({
        autocompleteCreateNewEntityItemState: {
          ...state.autocompleteCreateNewEntityItemState,
          ...values
        }
      }),
      false,
      'setAutocompleteCreateNewEntityItemState'
    ),
  setAutocompleteV2CreateNewEntityItemState: (values) =>
    set(
      (state) => ({
        autocompleteV2CreateNewEntityItemState: {
          ...state.autocompleteV2CreateNewEntityItemState,
          ...values
        }
      }),
      false,
      'setAutocompleteV2CreateNewEntityItemState'
    )
});
