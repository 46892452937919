import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const deleteDocumentMutation = () => {
  return useMutation((props: { id: string }) =>
    http.post(`${API_ENDPOINTS.DOCUMENTS}/${props.id}`, props)
  );
};

export default deleteDocumentMutation;
