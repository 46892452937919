import { FC } from 'react';

export interface ListProps {
  label: string;
  link?: string;
  icon?: JSX.Element;
  onClick?: (item: any) => void;
  className?: string;
  disabled?: boolean;
}

export interface DropdownListProps {
  data: ListProps[];
  disabled?: boolean;
  onClick?: (item: any) => void;
}

const DropdownList: FC<DropdownListProps> = ({ data = [], onClick }) => {
  return (
    <div className="relative flex text-left">
      <div className="absolute right-0 min-w-full max-w-xs truncate  shadow-lg">
        <ul className="relative z-10 mt-1 w-auto bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm max-h-80">
          {data.map((item, index) => (
            <li
              key={index}
              className={`relative overflow-y-auto truncate ${
                item.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
              }`}
              id={item.label}
              role="option"
              aria-selected
              onClick={() =>
                onClick ? onClick(item) : item.onClick && !item.disabled ? item.onClick(item) : null
              }
            >
              <div className="relative py-2 hover:bg-gray-100 ">
                <span className={`font-normal px-3 block ${item.className ? item.className : ''}`}>
                  <p className="truncate">{item.label}</p>
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownList;
