export enum ModalIds {
  CONTACT_FORM = 'contact_form',
  CONTACT_MASTER_FORM = 'contact_master_form',
  SHIPMENT_FORM = 'shipment_form',
  INVITE_LINK_FORM = 'invite_link_modal',
  SHIPMENT_UPLOAD_MODAL = 'shipment_upload_modal',
  GENERATE_DOCUMENT_FORM = 'generated_document_form',
  UPLOAD_DOCUMENT_FORM = 'upload_document_form',
  GENERATE_DOCUMENT_ADD_SHIPMENT_FORM = 'generate_document_add_shipment_form',
  GENERATE_DOCUMENT_SHARE_FORM = 'generate_document_share_form',
  TASK_FORM = 'task_form',
  BULK_ASSIGN_TASKS_FORM = 'bulk_assign_task_form',
  WORKFLOW_FORM = 'workflow_form',
  WORKFLOW_TASK_FORM = 'workflow_task_form',
  ADD_PRODUCT_TO_SHIPMENT = 'add_product_to_shipment',
  ADD_STAMP_FORM = 'add_stamp_form',
  ADD_SIGNATURE_FORM = 'add_signature_form',
  TRACKING_FORM = 'tracking_form',
  PRODUCT_FORM = 'product_form',
  EDIT_TOTAL_FORM = 'edit_total_form',
  EDIT_DUE_DATE_FORM = 'edit_due_date_form',
  ADD_PAYMENT_TO_SHIPMENT_FORM = 'add_payment_to_shipment_form',
  SHIPMENT_PAYMENT_FORM = 'shipment_payment_form',
  SHIPMENT_DETAIL_SHARE_FORM = 'shipment_detail_share_form',
  SHIPMENT_PRODUCT_FORM = 'shipment_product_form',
  PACKING_FORM = 'packing_form',
  ADD_SHIPMENT_FORM = 'add_shipment_form',
  ADD_CUSTOM_FIELD = 'add_custom_field',
  ADD_TRACKING_TO_SHIPMENT_FORM = 'add_tracking_to_shipment_form',
  USER_FORM = 'user_form',
  CROP_PROFILE_IMAGE_MODAL = 'crop_profile_image_modal',
  REQUIRE_FIELDS_WARNING_MODAL = 'require_fields_warning_modal',

  // generated documents
  ADD_PRODUCT_TO_SHIPMENT_DOCUMENT_VIEW = 'add_product_to_shipment_document_view',
  LOST_CHANGES_DOCUMENT_VIEW = 'lost_changes_document_view',
  PRODUCT_FORM_DOCUMENT = 'product_form_document',
  SAVE_DOCUMENT_MODAL = 'save_document_modal',
  ADD_PACKAGE_TO_SHIPMENT_DOCUMENT_VIEW = 'add_package_to_shipment_document_view',
  UPLOAD_PRODUCT_FILES_MODAL = 'upload_product_files_modal',
  ADD_DOCUMENT_CUSTOM_FIELD = 'add_document_custom_field'
}
