import Loading from '@components/loaders/loading';

export const Body = (props) => {
  if (props.loading) {
    return <Loading />;
  }

  return (
    <div className={`overflow-x-hidden overflow-y-scroll p-4 ${props.className}`}>
      {props.children}
    </div>
  );
};
