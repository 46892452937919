import { Dialog, Transition } from '@headlessui/react';
import useModal from '@hooks/use-modal';
import { useStore } from '@store/useStore';
import React, { Fragment, useEffect, useRef } from 'react';

type DivElementRef = React.MutableRefObject<HTMLDivElement>;

const Modal = ({
  children,
  open,
  inlineEditable
}: {
  open: boolean;
  inlineEditable?: boolean;
  children: any;
}) => {
  const modalRootRef = useRef() as DivElementRef;
  const modalInnerRef = useRef() as DivElementRef;
  const { closeModalByPosition } = useModal();
  const setRootFormValues = useStore((state) => state.setRootFormValues);
  const modalWidth = useStore((state) => state.modalWidth);
  const setModalWidth = useStore((state) => state.setModalWidth);
  const setModalRef = useStore((state) => state.setModalRef);

  useEffect(() => {
    if (!open) {
      setModalWidth('');
      setRootFormValues();
      setModalWidth('');
    }
  }, [open]);

  useEffect(() => {
    if (inlineEditable) {
      setModalRef(modalRootRef);
    }
  }, [inlineEditable]);

  return (
    <>
      {open && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="fixed z-100" onClose={() => closeModalByPosition()}>
            <div
              ref={modalRootRef}
              className="flex justify-center items-center fixed left-0 top-0 w-full h-full z-50 transition-opacity duration-300 opacity-100"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

              <div
                ref={modalInnerRef}
                className={`inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle overflow-hidden ${
                  modalWidth ? modalWidth : 'sm:max-w-lg'
                } sm:w-full`}
              >
                {children}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default Modal;
