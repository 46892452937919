const initialWorkflowState = {
  workflowsCount: 0,
  updateWorkflowsTable: false,
  selectedWorkflowIDs: [],
  selectedWorkflowTaskIDs: []
};

export const createWorkflowSlice = (set) => ({
  ...initialWorkflowState,
  setWorkflowsCount: (count = 0) =>
    set(() => ({ workflowsCount: count }), false, 'setWorkflowsCount'),
  setUpdateWorkflowsTable: (update) =>
    set(() => ({ updateShipmentsTable: update }), false, 'setUpdateWorkflowsTable'),
  setSelectedWorkFlowIds: (ids) => {
    return set(() => ({ selectedWorkflowIDs: ids }), false, 'setSelectedWorkFlowIds');
  },
  setSelectedWorkFlowTaskIds: (ids) => {
    return set(() => ({ selectedWorkflowTaskIDs: ids }), false, 'setSelectedWorkFlowTaskIds');
  }
});
