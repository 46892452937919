import { CollectionIcon } from '@heroicons/react/outline';
import useRequest from '@services/api';
import { formatAutocompleteData } from '@utils/format-autocomplete-data';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2 from './form-autocomplete-v2';

const FormCustomFieldNames = ({
  onChange,
  entityType
}: {
  onChange: (disableFieldType?: boolean) => void;
  entityType: 'Contact' | 'Shipment' | 'Product';
}) => {
  // states
  const [disabled, setDisabled] = useState(false);

  // hooks
  const [localValue, setLocalValue] = useState('');
  const { setValue } = useFormContext();

  // query
  const { data, refetch, isLoading } = useRequest.custom_fields.getNameOptions({
    params: { entity_type: entityType },
    options: { keepPreviousData: true }
  });

  // const
  const formattedData = formatAutocompleteData({
    entity: 'customFieldNames',
    options: data
  });
  const customOnChange = (item, props) => {
    if (props.action === 'clear' || props.action === 'remove-value') {
      setValue(props.name, '');
      setLocalValue('');
      return;
    }
    setLocalValue(item.value);
    if (item?.type) {
      setValue('field_type', item.type);
      setValue(props.name, item.value);
      setDisabled(true);
      onChange(true);
    } else {
      setValue(props.name, item.value);
    }
  };

  return (
    <FormAutocompleteV2
      label="Field Name"
      name="name"
      value={localValue}
      containerClass="w-full"
      labelClass="text-primary-navy-400"
      options={formattedData}
      icon={<CollectionIcon className="w-4 h-4" />}
      loading={isLoading}
      onMenuOpen={refetch}
      returnItemKey="name"
      onChange={customOnChange}
      disabled={disabled}
    />
  );
};

export default FormCustomFieldNames;
