import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';

import { useRouter } from 'next/router';
import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function addShipmentProduct(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHIPMENT_PRODUCTS}`, inputs);
}

export const useAddShipmentProductMutation = () => {
  const queryClient = useQueryClient();
  const {
    query: { id }
  } = useRouter();
  return useMutation((input: any) => addShipmentProduct(input), {
    onSuccess: (data) => {
      appEvent(EventsNameEnum.ShipmentProduct_CreateShipmentProduct, {
        shipment_id: String(id),
        shipment_product_id: data?.data?.[data?.data?.length - 1].id
      });
      queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PRODUCTS, {
        queryKey: id
      });
      toastNotification({ type: 'success', description: 'Product added to shipment.' });
    }
  });
};
