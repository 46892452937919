export const AUTOCOMPLETE_FIELD_TYPE = 'autocomplete';

export const MULTI_SELECT_AUTOCOMPLETE_FIELD_TYPE = 'multi-select-autocomplete';

export const ENTITY_FIELD_TYPES = ['shipment', 'contact', 'product'];

export const DROPDOWN_FIELD_TYPE = 'dropdown';

export const IMAGE_FIELD_TYPE = 'image';

export const CHECKBOX_FIELD_TYPE = 'boolean';

export const YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';

export const MONTH_DAY_YEAR_FORMAT = 'MMM DD YYYY';
export const MONTH_DAY_FORMAT = 'MMM DD';

export const ENTITY = {
  Shipment: 'shipment',
  Contact: 'contact',
  Product: 'product'
};

export const COUNTRY_DROPDOWN_FIELD_TYPE = 'country-dropdown';
