import { DocumentTemplatesTypes } from 'constants/document-templates-names';
import { CommercialInvoice } from './CommercialInvoice';
import { PackingList } from './PackingList';
import { ProformaInvoice } from './ProformaInvoice';
import { PurchaseOrder } from './PurchaseOrder';
import { Quotation } from './Quotation';
import { ShippersLetterOfInstruction } from './ShippersLetterOfInstruction';
import { TruckloadBoL } from './TruckLoadBoL';

export const DocumentTemplate = {
  [DocumentTemplatesTypes.COMMERCIAL_INVOICE]: CommercialInvoice,
  [DocumentTemplatesTypes.PURCHASE_ORDER]: PurchaseOrder,
  [DocumentTemplatesTypes.QUOTATION]: Quotation,
  [DocumentTemplatesTypes.PROFORMA_INVOICE]: ProformaInvoice,
  [DocumentTemplatesTypes.PACKING_LIST]: PackingList,
  [DocumentTemplatesTypes.TRUCKLOAD_BOL]: TruckloadBoL,
  [DocumentTemplatesTypes["SHIPPER'S_LETTER_OF_INSTRUCTION"]]: ShippersLetterOfInstruction
} as const;
