import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const resetPasswordMutation = () => {
  return useMutation((data: any) => http.put(API_ENDPOINTS.RESET_PASSWORD, { user: data }));
};

export default resetPasswordMutation;
