import { useRef, useEffect } from 'react';

export function usePrevious<T>(value: T): T {
  const ref = useRef<T>();
  if (value) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      ref.current = value;
    });
  }
  return ref.current;
}
