import Form from '@components/dataEntry/form';
import FormShipmentAutoComplete from '@components/dataEntry/FormShipmentAutoComplete';
import ModalView from '@components/feedback/modal-view';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const AddTrackingToShipmentForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const modalState = useStore((state) => state.modalState);
  const autocompleteV2CreateNewEntityItemState = useStore(
    (state) => state.autocompleteV2CreateNewEntityItemState
  );
  const selectedShipmentTrackingIDs = useStore((state) => state.selectedShipmentTrackingIDs);
  const setSelectedShipmentTrackingIDs = useStore((state) => state.setSelectedShipmentTrackingIDs);

  // hooks
  const { mutateAsync: addTrackingToShipment, isLoading: addTrackingToShipmentLoading } =
    useRequest.shipment_trackings.addToShipment();
  const { mutateAsync: bulkAddTrackingToShipment, isLoading: bulkAddTrackingToShipmentLoading } =
    useRequest.shipment_trackings.bulkAddToShipment();
  const { closeModalByPosition } = useModal();
  const queryClient = useQueryClient();

  // const
  const trackingId = modalState?.[0]?.entityId;
  const fullLoading = addTrackingToShipmentLoading || bulkAddTrackingToShipmentLoading;

  const validationSchema = yup.object({
    shipment_id: yup.string().required('Shipment is a required field')
  });
  const shipmentId = useMemo(
    () =>
      Object.keys(autocompleteV2CreateNewEntityItemState?.data).length > 1
        ? autocompleteV2CreateNewEntityItemState.data.id
        : '',
    []
  );
  const defaultValues = {
    shipment_id: shipmentId
  };

  // actions
  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs: any) => {
      if (selectedShipmentTrackingIDs?.length > 0) {
        const modifiedInputs = {
          ...inputs,
          update_data: selectedShipmentTrackingIDs
        };
        await bulkAddTrackingToShipment(modifiedInputs, {
          onSuccess: (data) => {
            queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_TRACKINGS);
            setSelectedShipmentTrackingIDs([]);
            toastNotification({
              type: 'success',
              description: data.data['message']
            });
            closeModalByPosition();
          }
        });
      } else {
        const modifiedInputs = {
          id: trackingId,
          inputs: { shipment_tracker: inputs }
        };
        await addTrackingToShipment(modifiedInputs, {
          onSuccess: () => {
            queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_TRACKINGS);
            toastNotification({
              type: 'success',
              description: 'Tracking was successfully added'
            });
            closeModalByPosition();
          }
        });
      }
    }
  });

  return (
    <ModalView header="Add Shipment" loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        errorsServerValidation={errorsServerValidation}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        formId={formId}
        updateValuesAfterSuccess={true}
      >
        <div className="flex flex-wrap gap-y-3">
          <div className="w-full mb-3">
            <FormShipmentAutoComplete label="Add to shipments" name="shipment_id" />
          </div>
        </div>
        <ModalFooter
          disabled={fullLoading}
          modalId={ModalIds.ADD_TRACKING_TO_SHIPMENT_FORM}
          mainButtonText="Add"
          mainButtonType="submit"
        />
      </Form>
    </ModalView>
  );
};

export default AddTrackingToShipmentForm;
