import { Dialog, Transition } from '@headlessui/react';
import { useStore } from '@store/useStore';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { Fragment, useRef } from 'react';
import { useContextUI } from 'states/ui';

type DivElementRef = React.MutableRefObject<HTMLDivElement>;

const Modal = ({ children, open }: { open: boolean; inlineEditable?: boolean; children: any }) => {
  const modalRootRef = useRef() as DivElementRef;
  const modalInnerRef = useRef() as DivElementRef;
  const router = useRouter();
  const [_, { closePortal }] = useContextUI();
  const modalWidth = useStore((state) => state.modalWidth);
  const classNameParams = router.query.className;

  const className = classNames(
    `inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:align-middle overflow-hidden`,
    {
      [modalWidth]: modalWidth,
      'sm:max-w-lg': !modalWidth,
      [`${classNameParams}`]: router.query.className
    }
  );

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-100" onClose={() => closePortal()}>
          <div
            ref={modalRootRef}
            className="flex justify-center items-center fixed left-0 top-0 w-full h-full z-50 transition-opacity duration-300 opacity-100"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div ref={modalInnerRef} className={className}>
              {children}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Modal;
