import RenderIf from '@components/common/render-if';
import { CalendarIcon } from '@heroicons/react/outline';

const Date = ({ rowItem }) => {
  return (
    <div className="flex items-center gap-2">
      <RenderIf isTrue={rowItem}>
        <>
          <CalendarIcon className="text-primary-navy-400 w-4 h-4" />
          <p className="text-sm text-primary-navy-400">{rowItem}</p>
        </>
      </RenderIf>
      <RenderIf isTrue={!rowItem}>
        <p className="text-sm text-primary-navy-400">-</p>
      </RenderIf>
    </div>
  );
};

export default Date;
