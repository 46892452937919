import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkArchiveProductsMutation = () => {
  return useMutation((data: any) => http.post(`${API_ENDPOINTS.PRODUCTS}/bulk_archive`, data));
};

export default bulkArchiveProductsMutation;
