import axios from 'axios';

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REST_API_ENDPOINT,
  headers: {
    Accept: 'application/json'
  }
});

// Change request data/error here
// Middleware
http.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      ...config.headers
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
