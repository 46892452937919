import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { queryString } from '../utils/query-string';

export const fetchAllUsers = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}${queryString(_params)}`);
  return data;
};
export const useAllUsersQuery = (reactQueryOptions?: ReactQueryOptionsType) => {
  return useQuery<{ data }, Error>(
    [`${API_ENDPOINTS.ACCOUNTS}/all_org_users`],
    fetchAllUsers,
    reactQueryOptions
  );
};
