import { FC, useEffect } from 'react';

import { FolderAddIcon } from '@heroicons/react/outline';
import useFileUpload from '@hooks/use-file-upload';
import Link from 'next/link';
import FormInput from './form-input';
import { InputProps } from './input';

export interface ImageProps extends InputProps {
  defaultImageUrl?: string;
  defaultImageName?: string;
  label?: string;
  onChangeImage: (obj: any) => void;
}

const FormInputImage: FC<ImageProps> = ({
  label,
  name,
  onChangeImage,
  className,
  defaultImageUrl,
  defaultImageName
}) => {
  const { handleChange, fileRef, selectedFile, showOpenFileDialog } = useFileUpload();

  useEffect(() => {
    if (selectedFile) {
      onChangeImage({ [name]: selectedFile });
    }
  }, [selectedFile]);

  return (
    <FormInput
      inputRef={fileRef}
      label={label}
      name={name}
      type="file"
      hidden
      customOnChange={handleChange}
      accept="image/*"
      containerClass={className}
    >
      <div className="space-y-2">
        {defaultImageUrl && (
          <div className="link">
            <Link href={defaultImageUrl || ''}>
              <a className="text" rel="noreferrer" target="_blank">
                {defaultImageName}
              </a>
            </Link>
          </div>
        )}
        <div
          onClick={showOpenFileDialog}
          className="flex justify-center px-6 py-5 border-2 border-gray-300 bg-gray-50 border-dashed rounded-md h-36 cursor-pointer items-center"
        >
          <div className="flex flex-col text-center">
            <FolderAddIcon className="mx-auto h-6 w-6 text-gray-400 text-sm" strokeWidth={2} />
            <div className="text-sm text-gray-600">
              <div className="relative cursor-pointer font-medium grow-0 text-blue-600 hover:text-primary-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <span>Upload a image</span>
              </div>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG up to 10MB</p>
            {selectedFile && (
              <p>
                <strong>File Name:</strong> {selectedFile?.name}
              </p>
            )}
          </div>
        </div>
      </div>
    </FormInput>
  );
};

export default FormInputImage;
