import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const bulkDeleteGeneratedDocumentsMutation = () => {
  return useMutation((data: { delete_data: any }) =>
    http.post(API_ENDPOINTS.BULK_DELETE_GENERATED_DOCUMENTS, data)
  );
};

export default bulkDeleteGeneratedDocumentsMutation;
