import { useMutation } from 'react-query';

import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

const addContactMutation = () => {
  return useMutation(
    (inputs: any) =>
      http.post(API_ENDPOINTS.CONTACTS, jsonToFormData({ schema: 'contact', inputs })),
    {
      onSuccess: (data) => {
        appEvent(EventsNameEnum.Contact_CreateContact, {
          contact_id: data.data.id
        });
      }
    }
  );
};

export default addContactMutation;
