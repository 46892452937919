import RenderIf from '@components/common/render-if';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import cn from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Typography from './typography';

type AccordionProps = {
  title: string | JSX.Element;
  children: ReactNode;
  open?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  borderLess?: boolean;
  className?: string;
  showHelperText?: boolean;
  hiddenCrevron?: boolean;
  style?: any;
};

const Accordion = ({
  title,
  children,
  open,
  disabled,
  className,
  defaultOpen = false,
  borderLess = false,
  showHelperText = false,
  hiddenCrevron = false,
  style
}: AccordionProps) => {
  const [isOpened, setOpened] = useState<boolean>(defaultOpen);
  const [height, setHeight] = useState<string>('0px');
  const contentElement = useRef(null);

  const handleOpening = () => {
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : '0px');
    setOpened(open || !isOpened);
  };

  const accordionClass = cn('flex justify-between items-center overflow-hidden gap-x-2', {
    'border-b border-primary-grey-100': isOpened,
    'px-2.5 py-3.5': !borderLess,
    'cursor-pointer': !disabled,
    'pb-1.5': borderLess,
    [className]: className
  });

  useEffect(() => {
    if (open) {
      handleOpening();
    }
  }, [open]);

  return (
    <div
      className={cn({
        stroke: !borderLess
      })}
      style={style}
    >
      <div onClick={() => !disabled && handleOpening()} className={accordionClass}>
        <Typography className="text-primary-navy-500 font-medium">{title}</Typography>
        <div className={cn('pl-2 flex', { 'border-l border-primary-grey-300': !borderLess })}>
          <RenderIf isTrue={isOpened}>
            <span className="inline-flex gap-x-1.5">
              <RenderIf isTrue={showHelperText}>
                <Typography className="text-primary-grey-300">View less</Typography>
              </RenderIf>
              <RenderIf isTrue={hiddenCrevron}>
                <ChevronUpIcon className="text-primary-grey-300 h-5 w-5" />
              </RenderIf>
            </span>
            <span className="inline-flex gap-x-1.5">
              <RenderIf isTrue={showHelperText}>
                <Typography className="text-primary-grey-300">View more</Typography>
              </RenderIf>
              <RenderIf isTrue={hiddenCrevron}>
                <ChevronDownIcon className="text-primary-grey-300 h-5 w-5" />
              </RenderIf>
            </span>
          </RenderIf>
        </div>
      </div>
      <div
        ref={contentElement}
        style={{ height: !isOpened && height }}
        className={`overflow-hidden ${isOpened && 'overflow-visible'}`}
        // TODO: add transition effect
        // className={`transition-all duration-300  ${!isOpened && 'overflow-hidden'}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
