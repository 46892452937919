import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useAssignToSomeoneMutation } from '@services/api/tasks/use-assign-to-someone-tasks';
import { useAllUsersQuery } from '@services/api/user/get-all-users';
import { useStore } from '@store/useStore';
import { formatUserNameSelectList } from '@utils/format-user-name-select-list';
import { ModalIds } from 'constants/modal-ids';
import { useState } from 'react';
import ModalFooter from '../modal-footer';

const BulkAssignTaskForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const selectedTaskIds = useStore((state) => state.selectedTaskIds);
  const setSelectedTaskIds = useStore((state) => state.setSelectedTaskIds);

  // hooks
  const { closeModalByPosition } = useModal();
  const { mutateAsync: bulkAssignTasks } = useAssignToSomeoneMutation();
  const { data: allUsersData } = useAllUsersQuery();
  const { data: currentUserData } = useRequest.user.get();

  // Populate reviewer options
  const teamMemberOptions = allUsersData ? allUsersData.data : [];
  const reviewerOptions = formatUserNameSelectList(teamMemberOptions);

  // Set default values based off of current user id
  const [defaultValues] = useState({
    assignee_id: parseInt(currentUserData.user.id)
  });

  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        ...inputs,
        task_ids: selectedTaskIds
      };
      await bulkAssignTasks(modifiedInputs);
      closeModalByPosition();
      setSelectedTaskIds([]);
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  return (
    <ModalView header="Choose Assignee">
      <Form
        defaultValues={defaultValues}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        onEnterSubmit
        onSubmit={handleSubmit}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <FormInput
                type="select"
                label="Assignee"
                name="assignee_id"
                optionsList={reviewerOptions}
              />
              <ModalFooter
                disabled={!isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.BULK_ASSIGN_TASKS_FORM}
                mainButtonText="Assign"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default BulkAssignTaskForm;
