import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import SignaturePad from '@components/dataEntry/signature-pad';
import ModalView from '@components/feedback/modal-view';
import Tab from '@components/navigation/tab';
import Tabs from '@components/navigation/tabs';
import useModal from '@hooks/use-modal';
import { useUserProfile } from '@services/api/user/get-user-profile';
import { useUpdateUserMutation } from '@services/api/user/use-update-user';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import ModalFooter from '../modal-footer';

type ViewT = 'type' | 'draw' | 'upload';

const AddSignatureForm = (props) => {
  const [rerender, setRerender] = useState(false);
  const [tab, setTab] = useState<ViewT>('draw');
  const [errorsServerValidation, setErrorsServerValidation] = useState({});

  const [signature, setSignature] = useState({
    signature: '',
    signature_upload: ''
  });

  // hooks
  const { closeModalByPosition } = useModal();
  const queryClient = useQueryClient();
  const {
    query: { id: documentId }
  } = useRouter();

  // queries && mutations
  const { data, isLoading } = useUserProfile();
  const { id, signature: dbSignature } = !isLoading && data;

  const [defaultValues, setDefaultValues] = useState({
    signature: dbSignature || ''
  });

  const { mutateAsync: updateUser } = useUpdateUserMutation(id);

  // actions
  const setInitialStates = (tabName?: ViewT) => {
    setSignature({
      signature: '',
      signature_upload: ''
    });
    setTab(tabName || 'draw');
  };

  const onChangeSignature = (input) => {
    if (tab === 'upload') {
      setSignature({ ...signature, signature_upload: input });
    } else {
      setSignature({ ...signature, signature: input });
    }
  };

  const onSubmit = async () => {
    try {
      const fullFormInput = { ...signature };
      const { data } = await updateUser(fullFormInput);
      props?.setValue(data.signature);
      queryClient.invalidateQueries([API_ENDPOINTS.GENERATED_DOCUMENTS, { id: documentId }]);
      setInitialStates('draw');
      closeModalByPosition();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
      setInitialStates(tab);
    }
  };

  const handleUpdate = () => {
    try {
      onSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  // effects
  useEffect(() => {
    if (!isLoading) {
      return setDefaultValues({
        ...defaultValues,
        signature: dbSignature
      });
    }
  }, [data]);

  useEffect(() => {
    setRerender(true);

    return () => {
      setRerender(false);
    };
  }, [tab]);

  return (
    <ModalView header="Add Signature">
      <Form
        defaultValues={defaultValues}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate={rerender}
      >
        {({ onSubmitForm }) => {
          return (
            <>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-3">
                  <FormInput
                    label="Signature"
                    name="signature_upload"
                    type="file"
                    hidden
                    containerClass="col-span-6 sm:col-span-6"
                  >
                    <div className="my-2">
                      <div className="flex justify-center">
                        <div className="grid gap-2">
                          <Tabs>
                            <Tab name="Draw" onClick={() => setInitialStates('draw')} />
                            <Tab name="Type" onClick={() => setInitialStates('type')} />
                            <Tab name="Upload" onClick={() => setInitialStates('upload')} />
                          </Tabs>
                          <SignaturePad view={tab} onChange={onChangeSignature} />
                        </div>
                      </div>
                    </div>
                  </FormInput>
                </div>
              </div>
              <ModalFooter
                disabled={Object.values(signature).every((data) => data === '')}
                onClick={onSubmitForm(handleUpdate)}
                modalId={ModalIds.ADD_SIGNATURE_FORM}
                mainButtonText="Add Signature"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default AddSignatureForm;
