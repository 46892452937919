import {
  CHECKBOX_FIELD_TYPE,
  DROPDOWN_FIELD_TYPE,
  ENTITY_FIELD_TYPES,
  IMAGE_FIELD_TYPE
} from '@services/constants';
import { FC } from 'react';
import FormInputCheckbox, { CheckboxProps } from './form-input-checkbox-or-toggle';

import { EntitiesFieldType } from '@services/types';
import FormAutocomplete, { FormAutocompleteProps } from './form-autocomplete';
import FormFileUpload, { FormFileUploadProps } from './form-file-upload';
import FormInput, { FormInputType } from './form-input';
import { FormReadOnlyProps } from './form-read-only';
import { SelectProps } from './select';

interface Props {
  data: any;
  autocompleteProps?: FormAutocompleteProps;
  imageProps?: FormFileUploadProps;
  selectProps?: SelectProps;
  checkboxProps?: CheckboxProps;
  inputProps?: FormInputType;
  InputContainerProps?: {
    inlineEditable?: boolean;
    readOnly?: boolean;
    onInlineSubmit?: ({ name }: { name?: string }) => void;
    setInputName?: (empty: string) => void;
    containerClass?: string;
    isRequired?: boolean;
    FormReadOnlyProps?: FormReadOnlyProps;
  };
}

const FormInputCustomFields: FC<Props> = ({
  data,
  autocompleteProps,
  imageProps,
  selectProps,
  checkboxProps,
  inputProps,
  InputContainerProps
}) => {
  const { fieldType, name } = data;

  // Input Types
  const IS_IMAGE = IMAGE_FIELD_TYPE.includes(fieldType);
  const IS_DROPDOWN = DROPDOWN_FIELD_TYPE.includes(fieldType);
  const IS_ENTITY = ENTITY_FIELD_TYPES.includes(fieldType);
  const IS_CHECKBOX = CHECKBOX_FIELD_TYPE.includes(fieldType);

  if (IS_ENTITY)
    return (
      <FormAutocomplete
        name={name}
        label={name}
        entity={fieldType as EntitiesFieldType}
        {...autocompleteProps}
        {...InputContainerProps}
      />
    );

  if (IS_IMAGE)
    return <FormFileUpload label={name} name={name} {...imageProps} {...InputContainerProps} />;

  if (IS_DROPDOWN) {
    return (
      <FormInput
        type="select"
        allowEmptyValue={true}
        label={name}
        name={name}
        optionsList={selectProps?.optionsList}
        labelClass={selectProps?.labelClass}
        autoFocus={selectProps?.autoFocus}
        {...InputContainerProps}
      />
    );
  }

  if (IS_CHECKBOX)
    return (
      <FormInputCheckbox
        type="checkbox"
        label={name}
        name={name}
        autoFocus={selectProps?.autoFocus}
        {...checkboxProps}
        {...InputContainerProps}
      />
    );

  return (
    <FormInput type={fieldType} label={name} name={name} {...inputProps} {...InputContainerProps} />
  );
};

export default FormInputCustomFields;
