import { FC } from 'react';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type SizeClass =
  | 'h-6 w-6 text-xs'
  | 'h-8 w-8 text-sm'
  | 'h-10 w-10 text-md'
  | 'h-12 w-12 text-lg'
  | 'h-14 w-14 text-xl';
export type Type = 'image' | 'text';

export const sizeToClassMap: Record<Size, SizeClass> = {
  xs: 'h-6 w-6 text-xs',
  sm: 'h-8 w-8 text-sm',
  md: 'h-10 w-10 text-md',
  lg: 'h-12 w-12 text-lg',
  xl: 'h-14 w-14 text-xl'
};

export interface AvatarProps {
  title?: string;
  value: string;
  size: Size;
  type: Type;
  className?: string;
}

const Avatar: FC<AvatarProps> = ({ title, value, size, type, className }) => {
  return (
    <>
      {type === 'text' ? (
        <span
          title={title}
          className={`inline-flex items-center justify-center rounded-full bg-blue-300 hover:bg-blue-200 ${sizeToClassMap[size]} ${className}`}
        >
          <span className="font-medium leading-none text-white">{value}</span>
        </span>
      ) : (
        <img
          alt="avatar"
          className={`inline-block rounded-full ring-1 ring-white ${sizeToClassMap[size]}`}
          src={value}
        />
      )}
    </>
  );
};

export default Avatar;
