const initialManageFieldsState = {
  selectedManageFieldIds: []
};

export const createManageFieldsSlice = (set) => ({
  ...initialManageFieldsState,
  setSelectedManageFieldsIds: (ids) => {
    return set(() => ({ selectedManageFieldIds: ids }), false, 'setSelectedManageFieldsIds');
  }
});
