import { useQuery } from 'react-query';
import { ReactQueryOptionsType, Tasks, TasksQueryOptionsType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { queryFetch } from '../utils/query-fetch';
import { queryString } from '../utils/query-string';
import { queryOptions } from '../utils/use-query-wrapper';

export const fetchTasks = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}${queryString(_params)}`);
  return data;
};
export const useTasksQuery = (
  options?: TasksQueryOptionsType,
  reactQueryOptions?: ReactQueryOptionsType
) => {
  return useQuery<Tasks, Error>([API_ENDPOINTS.TASKS, options], fetchTasks, reactQueryOptions);
};

export const getAllTasks = (props: queryOptions) => {
  return useQuery<any, Error>([API_ENDPOINTS.TASKS, props?.params], queryFetch, props?.options);
};
