import useRequest from '@services/api';

const useUser = () => {
  const { data, isLoading } = useRequest.user.get();
  const { user } = !isLoading && (data || {});

  return {
    data: user,
    isLoading
  };
};

export default useUser;
