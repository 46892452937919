const initialNotificationsState = {
  selectedNotificationsIDs: []
};

export const createNotificationsSlice = (set) => ({
  ...initialNotificationsState,
  setSelectedNotificationsIDs: (notificationsIDs) => {
    return set(
      () => ({ selectedNotificationsIDs: notificationsIDs }),
      false,
      'setSelectedNotificationsIDs'
    );
  }
});
