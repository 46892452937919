import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import useManageParams from '@hooks/use-manage-params';
import useManagePayments from '@hooks/use-manage-payments';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const AddPaymentToShipmentForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [defaultValues] = useState({
    total_text: '',
    target_date: ''
  });

  // hooks
  const { addPaymentToShipmentMutation } = useManagePayments();
  const { params } = useManageParams();
  const { closeModalByPosition } = useModal();
  const {
    query: { id: shipmentId }
  } = useRouter();

  // const
  const {
    mutation: { isLoading },
    addPaymentToShipment
  } = addPaymentToShipmentMutation();

  // queries
  const { data, refetch } = useRequest.shipment_payments.get({
    params: { shipment_id: shipmentId as string, page: params?.page },
    options: { keepPreviousData: true }
  });

  const currencySymbol = data?.currencySymbol;

  const validationSchema = yup.object({
    total_text: yup.string().required('Total is required'),
    target_date: yup.string().required('Due date is required')
  });

  // actions
  const handleSubmit = (inputs: any) => {
    try {
      onSubmit(inputs);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      const modifiedInputs = {
        payment: {
          ...inputs,
          shipment_id: shipmentId
        }
      };
      await addPaymentToShipment(modifiedInputs);
      closeModalByPosition();
      refetch();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  return (
    <ModalView header="Add Payment to Shipment">
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <FormInput
                    label="Total"
                    name="total_text"
                    adornmentProps={{
                      position: 'start',
                      startText: currencySymbol,
                      inputType: 'number',
                      inputClassName: 'pl-11'
                    }}
                    type="adornment"
                    containerClass="col-span-3"
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-3">
                <div className="w-full px-3">
                  <FormInput
                    label="Due Date"
                    name="target_date"
                    type="date"
                    containerClass="col-span-3"
                  />
                </div>
              </div>
              <ModalFooter
                disabled={isLoading || !isDirty}
                onClick={onSubmitForm(handleSubmit)}
                modalId={ModalIds.ADD_PAYMENT_TO_SHIPMENT_FORM}
                mainButtonText="Add"
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default AddPaymentToShipmentForm;
