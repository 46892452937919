import RenderIf from '@components/common/render-if';
import useManageParams from '@hooks/use-manage-params';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { FC, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  children: ReactElement[];
  queryName?: string;
  type?: ItemProps['type'];
};

type ItemProps = {
  name: string;
  index: number;
  active: boolean;
  setSelectedTab: (index: number) => void;
  onClick: () => void;
  length: number;
  classNames;
  type: 'highlighted' | 'default';
  itemCount?: number;
};

const ItemWrapper: FC<ItemProps> = ({
  name,
  setSelectedTab,
  index,
  active,
  onClick,
  length,
  classNames,
  type = 'default',
  itemCount
}) => {
  const onHandleClick = useCallback(() => {
    if (onClick) onClick();
    setSelectedTab(index);
  }, []);

  const itemStyle = {
    highlighted: cn(
      'group relative min-w-0 flex-1 overflow-hidden bg-gray-50 py-2 px-2 text-sm text-center focus:z-10 cursor-pointer',
      active ? 'btn-primary text-white' : 'text-primary-navy-400 hover:text-primary-navy-700',
      !active && 'hover:bg-gray-50',
      index === 0 ? 'rounded-l-lg' : '',
      index === length - 1 ? 'rounded-r-lg' : ''
    ),
    default: cn(
      'group inline-flex justify-center items-center p-2 border-b text-sm hover:text-primary-green-500 hover:border-primary-green-500/50',
      active
        ? 'border-primary-green-500 text-primary-green-500 hover:text-primary-green-700 hover:border-primary-green-700'
        : 'border-transparent text-primary-navy-400',
      classNames ? classNames : ''
    )
  };

  return (
    <>
      <a key={name} className={itemStyle[type]} onClick={onHandleClick}>
        <span className="mr-2">{name}</span>
        <span aria-hidden="true" className={cn('absolute inset-x-0 bottom-0 h-0.5')} />
        <RenderIf isTrue={itemCount}>
          <span className="leading-none bg-gray-100 text-gray-600 hidden ml-1 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
            {itemCount}
          </span>
        </RenderIf>
      </a>
    </>
  );
};

const Tabs: FC<Props> = ({ children, queryName, type }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { params, addParams, removeParams } = useManageParams();
  const { query } = useRouter();
  const isMounted = useRef(false);

  const isHighlighted = type === 'highlighted';

  useEffect(() => {
    const param = children[selectedTab]?.props.name.toLowerCase();
    const paramsToReset = Object.keys(params).filter(
      (param) => param !== 'id' && param !== 'current_tab'
    );
    if (param && queryName && isMounted.current) {
      removeParams(paramsToReset);
      setTimeout(() => {
        addParams({ [queryName]: param });
      }, 50);
    }
  }, [selectedTab]);

  useEffect(() => {
    const currentTabIndex = children.findIndex(
      (child) => child.props.name.toLowerCase() === query?.current_tab
    );
    if (currentTabIndex > -1) {
      setSelectedTab(currentTabIndex);
    }
  }, [query?.current_tab]);

  useEffect(() => {
    if (query[queryName]) {
      const position = children.findIndex(
        (item) => item.props?.name?.toLowerCase() === query[queryName]
      );
      setSelectedTab(position);
    }
    isMounted.current = true;
  }, []);

  return (
    <div className="">
      <nav
        className={cn(
          isHighlighted
            ? 'relative z-0 rounded-lg shadow flex divide-x divide-gray-200'
            : '-mb-px flex cursor-pointer border-b border-primary-grey-100'
        )}
        aria-label="Tabs"
      >
        {children?.map((item, index) => (
          <ItemWrapper
            key={item.props.name}
            name={item.props.name}
            type={type}
            index={index}
            active={item.props.active || selectedTab === index}
            setSelectedTab={setSelectedTab}
            onClick={item.props.onClick}
            length={children.length}
            classNames={item.props.classNames}
            itemCount={item.props.itemCount}
          />
        ))}
      </nav>
      {children && children[selectedTab]}
    </div>
  );
};

export default Tabs;
