import RenderIf from '@components/common/render-if';
import FullInput from '@components/dataEntry/FullInput';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import FormInputCustomFields from '@components/dataEntry/form-input-custom-fields';
import { OptionsListProps } from '@components/dataEntry/select';
import ModalView from '@components/feedback/modal-view';
import { PencilIcon, PlusIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { toastNotification } from '@hooks/toast-notification';
import useFileUpload from '@hooks/use-file-upload';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { jsonToFormData } from '@services/api/utils/json-to-formData';
import { useStore } from '@store/useStore';
import { createYupSchema } from '@utils/create-yup-schema';
import { formatCustomFieldData } from '@utils/format-custom-field-data';
import { formatSelectStringList } from '@utils/format-select-string-list';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const ProductForm = () => {
  // states
  const modalState = useStore((state) => state.modalState);
  const setAutocompleteCreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteCreateNewEntityItemState
  );
  const autocompleteCreateNewEntityItemState = useStore(
    (state) => state.autocompleteCreateNewEntityItemState
  );
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [productImage, setProductImage] = useState('');

  // const
  const router = useRouter();
  const modalMode = modalState?.[0]?.mode || router.query?.mode;
  const productId = modalState?.[0]?.entityId;
  const isNewProduct = modalMode === 'add' || modalMode === 'duplicate';
  const headerLabel = modalMode === 'edit' ? 'Edit Product' : 'Add Product';
  const queryParams = {
    entity_type: 'Product'
  };
  const handleFileUpload = (file) => {
    methods.setValue('product_image', file, { shouldDirty: true });
    setProductImage(URL.createObjectURL(file));
  };

  // hooks
  const { closeModalByPosition } = useModal();
  const { mutateAsync: addProductMutation, isLoading: addProductLoading } =
    useRequest.products.create();
  const { mutateAsync: updateProductMutation, isLoading: updateProductLoading } =
    useRequest.products.update(productId);
  const { handleChange, fileRef, showOpenFileDialog } = useFileUpload({
    onChange: handleFileUpload
  });

  // queries
  const { data: customFieldMappingDataTable = [], isLoading: isCustomFieldLoading } =
    useRequest.custom_fields.getByEntityType({
      params: queryParams,
      options: { keepPreviousData: true }
    });
  const {
    data: productDetails,
    isLoading: productDetailsLoading,
    isSuccess
  } = useRequest.products.getById(
    { id: productId },
    { keepPreviousData: false, enabled: !!productId }
  );

  const hasCustomFields = customFieldMappingDataTable.length > 0;

  const validationSchema = yup.object({
    name: yup.string().required('SKU is a required field'),
    ...customFieldMappingDataTable.reduce(createYupSchema, {})
  });

  const fullLoading = productDetailsLoading || isCustomFieldLoading;
  const customFieldNames = formatCustomFieldData({
    data: customFieldMappingDataTable,
    details: productDetails
  });

  const values = {
    id: productDetails?.id,
    name:
      modalMode === 'edit'
        ? productDetails?.name
        : autocompleteCreateNewEntityItemState?.createItemName || '',
    hs_code: productDetails?.hs_code,
    net_weight: productDetails?.net_weight,
    unit_type: productDetails?.unit_type,
    gross_weight: productDetails?.gross_weight,
    cubic_volume: productDetails?.cubic_volume,
    description: productDetails?.description,
    country_of_origin: productDetails?.country_of_origin,
    product_image: '',
    ...customFieldNames
  };

  const [defaultValues, setDefaultValues] = useState<any>();

  const queryClient = useQueryClient();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  });

  // actions
  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs) => {
      if (isNewProduct) {
        if (inputs.product_image === '') {
          delete inputs.product_image;
        }
        const { data } = await addProductMutation(inputs, {
          onSuccess: () => {
            queryClient.invalidateQueries([API_ENDPOINTS.PRODUCTS]);
            queryClient.refetchQueries([API_ENDPOINTS.SHIPMENT_PACKAGES]);
            toastNotification({ type: 'success', description: 'Product was successfully added' });
          }
        });
        setAutocompleteCreateNewEntityItemState({ data });
      }

      if (!isNewProduct) {
        let { product_image: _, ...modifiedInputs } = inputs;
        const formData = jsonToFormData({ schema: 'product', inputs: modifiedInputs });
        formData.append('product[product_image]', inputs.product_image);
        const { data } = await updateProductMutation(formData, {
          onSuccess: () => {
            queryClient.invalidateQueries([API_ENDPOINTS.PRODUCTS]);
            queryClient.refetchQueries([API_ENDPOINTS.SHIPMENT_PACKAGES]);
            toastNotification({
              type: 'success',
              description: 'Product was successfully updated'
            });
          }
        });
        setAutocompleteCreateNewEntityItemState({ data });
      }

      closeModalByPosition();
    }
  });

  const inputs = [
    {
      label: 'SKU',
      name: 'name',
      containerClass: 'w-1/2 pr-1.5'
    },
    {
      label: 'HS Code',
      name: 'hs_code',

      containerClass: 'w-1/2 pl-1.5'
    },
    {
      label: 'Unit Type (packaging)',
      name: 'unit_type',
      containerClass: 'w-1/2 pr-1.5',
      placeholder: 'Pieces (PCS), Cartons...'
    },
    {
      label: 'Net Weight (kg)',
      name: 'net_weight',
      type: 'number',
      containerClass: 'w-1/2 pl-1.5'
    },
    {
      label: 'Gross Weight (kg)',
      name: 'gross_weight',
      type: 'number',
      containerClass: 'w-1/2 pr-1.5'
    },
    {
      label: 'Cubic Volume (m³)',
      name: 'cubic_volume',
      type: 'number',
      containerClass: 'w-1/2 pl-1.5'
    },
    {
      name: 'country_of_origin',
      type: 'country-dropdown',
      defaultValue: defaultValues?.country_of_origin,
      containerClass: 'w-full'
    }
  ];

  // handle form
  useEffect(() => {
    setProductImage(productDetails?.product_image);
    methods.reset(values);
    setDefaultValues(values);
  }, [isSuccess, productDetails]);

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        methods={methods}
        errorsServerValidation={errorsServerValidation}
        onSubmit={handleSubmit}
        formId={formId}
      >
        <div className="flex flex-wrap gap-y-3">
          {inputs.map((data, index) => (
            <FullInput key={index} register={methods.register} {...data} />
          ))}
          <FormInput
            label={false}
            name="files"
            type="file"
            hidden
            inputRef={fileRef}
            customOnChange={handleChange}
            accept="image/*"
            labelClass="text-primary-navy-400"
          >
            <div className="flex flex-row gap-x-4">
              <RenderIf isTrue={productImage}>
                <div className="w-48 h-24 relative">
                  <div
                    className="rounded-md bg-primary-grey-50 cursor-pointer p-1 absolute right-[10px] top-[5px]"
                    onClick={showOpenFileDialog}
                  >
                    <PencilIcon className="h-3 w-3 self-center text-semantic-success-500" />
                  </div>
                  <img
                    className="object-contain w-full h-full"
                    src={productImage}
                    alt="productImage"
                  />
                </div>
              </RenderIf>
              <RenderIf isTrue={!productImage}>
                <div
                  className="flex flex-col gap-y-2 items-center justify-center border w-48 h-24 border-gray-200 object-contain cursor-pointer"
                  onClick={showOpenFileDialog}
                >
                  <div className="rounded-full bg-primary-grey-50 p-2">
                    <PlusIcon className="h-4 w-4 self-center text-semantic-success-500" />
                  </div>
                  <p className="text-primary-grey-500 text-xs">Add product image</p>
                </div>
              </RenderIf>
              <div className="self-center w-full">
                <FormInput
                  name="description"
                  label="Description"
                  type="textarea"
                  rows={3}
                  placeholder={'Description Line 1\nDescription Line 2\nDescription Line 3'}
                />
              </div>
            </div>
          </FormInput>
        </div>
        <div className="flex flex-wrap -mx-3 mt-2">
          <RenderIf isTrue={hasCustomFields}>
            <div
              className="text-gray-600 font-bold text-m truncate w-full border-b border-gray-200 ml-2.5 mr-2.5 mb-2 mt-4 relative"
              style={{ marginRight: '0.625rem' }}
            >
              Custom Fields
            </div>
          </RenderIf>

          {customFieldMappingDataTable.map((customField, index) => (
            // TODO: Remove image check when custom field image upload is fixed
            <RenderIf key={customField.name} isTrue={customField.fieldType !== 'image'}>
              <div className="w-full px-3 mb-3">
                <FormInputCustomFields
                  data={customField}
                  autocompleteProps={{
                    lastElement: customFieldMappingDataTable.length - 1 === index,
                    defaultValue: defaultValues?.[customField.name] || ''
                  }}
                  selectProps={{
                    optionsList: formatSelectStringList(customField.value) as OptionsListProps[]
                  }}
                  checkboxProps={{
                    checked: defaultValues?.[customField.name] || false
                  }}
                  imageProps={{
                    accept: ['image/png', 'image/jpeg'],
                    message: 'PNG, JPG up to 10MB',
                    maxFiles: 1,
                    maxSizeBytes: 10485760
                  }}
                />
              </div>
            </RenderIf>
          ))}
        </div>
        <ModalFooter
          disabled={addProductLoading || updateProductLoading}
          modalId={ModalIds.PRODUCT_FORM}
          mainButtonText={isNewProduct ? 'Add' : 'Update'}
          mainButtonType="submit"
        />
      </Form>
    </ModalView>
  );
};

export default ProductForm;
