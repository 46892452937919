import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getShipmentCompleted = (
  { id },
  props?: queryOptions
): any /*TODO: Add shipment response type */ => {
  return useQuery([API_ENDPOINTS.GET_SHIPMENT_COMPLETED(id)], queryFetch, props?.options);
};

export default getShipmentCompleted;
