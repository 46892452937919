import { ArchiveEntityQueryOptionsType } from '@services/types';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const updateContactArchivedMutation = () => {
  return useMutation((data: ArchiveEntityQueryOptionsType) =>
    http.put(API_ENDPOINTS.UPDATE_ARCHIVE_CONTACT(data.id), data)
  );
};

export default updateContactArchivedMutation;
