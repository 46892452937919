const initialShipmentsState = {
  selectedShipmentIDs: [],
  shipmentType: ''
};

export const createShipmentsSlice = (set) => ({
  ...initialShipmentsState,
  setSelectedShipmentIDs: (shipmentIDs) => {
    return set(() => ({ selectedShipmentIDs: shipmentIDs }), false, 'setSelectedShipmentIDs');
  },
  setShipmentType: (type) => set(() => ({ shipmentType: type }), false, 'setShipmentType')
});
