import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function importShipmentPackages(inputs: any) {
  const { shipmentId, inputs: payload } = inputs;
  return http.post(`${API_ENDPOINTS.SHIPMENT_PACKAGES}/import_csv?shipment_id=${shipmentId}`, {
    import_data: payload
  });
}

export const useImportShipmentPackagesMutation = () => {
  return useMutation((inputs: any) => importShipmentPackages(inputs), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Package data was successfully imported to shipment.'
      });
    },
    onError: () => {
      toastNotification({
        type: 'error',
        description: 'Missing Product(s), failed to import.'
      });
    }
  });
};
