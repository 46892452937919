import { InputTypes } from 'constants/inputs-types';
import { DocumentSections, DocumentTemplateType } from '../types';
import { packingListData } from './@common/packing.config';

export const PackingList: DocumentTemplateType[] = [
  {
    name: 'Shipper',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowSpan: 3, rowStart: 1 }
  },
  {
    name: 'Date',
    type: 'date',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 7 }
  },
  {
    name: 'Invoice Number',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 7, colSpan: 3 }
  },
  {
    name: 'BL Number',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 2, colStart: 10 }
  },

  {
    name: 'Buyer Reference',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 3, colStart: 10 }
  },
  {
    name: 'name',
    type: 'text',
    section: DocumentSections.preProductTable,
    label: 'Reference',
    GridProps: { rowStart: 3, colStart: 7, colSpan: 3 }
  },
  {
    name: 'Consignee',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, rowStart: 4, colSpan: 6 }
  },
  {
    name: 'Buyer',
    type: 'autocomplete',
    label: 'Buyer (if not Consignee)',
    section: DocumentSections.preProductTable,
    GridProps: {
      rowStart: 4,
      colStart: 7,
      colSpan: 6
    },
    InputProps: {
      entity: 'contact',
      isCreateNew: true,
      isEditable: true,
      showUserAddress: true,
      hasContactMasterOptions: true
    }
  },
  {
    name: 'method_of_shipping',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-dispatch-method',
      defaultValue: 'SEA'
    },
    GridProps: { colStart: 1, rowStart: 5, colSpan: 3 }
  },
  {
    name: 'type_of_shipment',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-shipment-type',
      defaultValue: 'FCL'
    },
    GridProps: { rowStart: 5, colSpan: 3 }
  },
  {
    name: 'country_of_origin',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 5, colSpan: 3 }
  },
  {
    name: 'country_of_destination',
    type: 'select-country',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 5, colSpan: 3 }
  },
  {
    name: 'Vessel',
    type: 'text',
    label: 'Vessel / Aircraft',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 6, colSpan: 4 }
  },
  {
    name: 'Voyage Number',
    type: 'text',
    label: 'Voyage No',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 6, colSpan: 4 }
  },
  {
    name: 'departure_date',
    type: 'date',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 7, colSpan: 4 }
  },
  {
    name: 'port_of_loading',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 7, colSpan: 4 }
  },
  {
    name: 'Packing Information',
    section: DocumentSections.preProductTable,
    type: 'textarea',
    GridProps: { rowStart: 6, rowSpan: 4, colSpan: 4 },
    InputProps: { maxLength: 160, limitRows: 4, containerClass: 'h-full' }
  },
  {
    name: 'port_of_discharge',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 8, colSpan: 4 }
  },
  {
    name: 'Final Destination',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 8, colSpan: 4 }
  },

  {
    name: 'product_table',
    type: InputTypes.PACKAGE_TABLE,
    section: DocumentSections.productTable,
    options: {
      entity: 'packages',
      TableProps: {
        data: packingListData
      }
    },
    GridProps: { rowStart: 9, colSpan: 12 }
  },

  {
    name: 'Additional Info',
    type: 'textarea',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 1, rowStart: 11, colSpan: 6, rowSpan: 5 },
    InputProps: { bt: 1, maxLength: 755, limitRows: 12 }
  },

  {
    name: 'Signatory Company',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 11 }
  },
  {
    name: 'Name of Authorized Signatory',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 12 }
  },
  {
    name: 'signature',
    type: 'signature',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 13, rowSpan: 3 }
  }
];
