import classNames from 'classnames';
import { FC } from 'react';
import Input, { InputProps } from './input';

export interface Props extends InputProps {
  name?: string;
  checked?: boolean;
  inputRef?: any;
  disabled?: boolean;
}

const ToggleV2: FC<Props> = ({ name, checked, inputRef, disabled, ...rest }) => {
  const classNameRoot = classNames(
    checked
      ? 'bg-primary-green-500 hover:bg-blue-700 active:bg-blue-700 focus:outline-none focus:ring focus:bg-blue-400'
      : 'bg-gray-300 hover:bg-gray-200 ',
    'pl-0.5 relative inline-flex flex-shrink-0 w-11 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-blue-500 ',
    {
      'bg-blue-200 hover:bg-blue-100 pointer-events-none cursor-not-allowed': disabled
    }
  );

  return (
    <label
      htmlFor={name}
      style={{
        placeContent: 'center'
      }}
      className="toggle-focus flex items-center cursor-pointer w-[3.2rem] h-8 rounded-full"
    >
      <div className={classNameRoot}>
        <Input
          inputRef={inputRef}
          name={name}
          id={name}
          checked={checked}
          type="checkbox"
          disabled={disabled}
          hidden
          className="sr-only"
          {...rest}
        />
        <div
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'absolute inset-y-0.5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        ></div>
      </div>
    </label>
  );
};

export default ToggleV2;
