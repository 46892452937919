import { FC, ReactElement } from 'react';

type Props = {
  name: string;
  icon?: ReactElement;
  onClick?: () => void;
  active?: boolean;
  tabName?: string;
  isNewTab?: boolean;
  classNames?: string;
  itemCount?: number;
};

const Tab: FC<Props> = ({ children }) => <div>{children}</div>;

export default Tab;
