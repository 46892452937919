import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getSimilarDocuments = ({ id }, props?: queryOptions) /*TODO: Add response type */ => {
  return useQuery([API_ENDPOINTS.SIMILAR_DOCUMENTS(id)], queryFetch, props?.options);
};

export default getSimilarDocuments;
