const CargoflipLogoIcon = ({ className = 'text-primary-navy-500' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7632 20.1309H4.80758C4.26885 20.1309 3.83203 19.7205 3.83203 19.2144V7.36762C3.83203 7.13372 3.98346 6.92307 4.21497 6.8369C4.44939 6.75209 4.71148 6.80407 4.88184 6.97368L17.2044 19.1652C17.3718 19.3307 17.4184 19.5742 17.3223 19.7834C17.2277 19.9927 17.0078 20.1295 16.7646 20.1295L16.7632 20.1309ZM5.41621 18.9887H14.4903C14.8135 18.9887 14.9795 18.6249 14.7567 18.4047L5.68267 9.42758C5.45407 9.20188 5.04783 9.35371 5.04783 9.66558V18.644C5.04783 18.8355 5.21236 18.9901 5.41621 18.9901V18.9887Z"
        // fill="#041C3F"
      />
      <path
        d="M19.5532 17.209C19.3887 17.209 19.2285 17.1475 19.1106 17.0312L6.79681 4.83151C6.63082 4.666 6.58423 4.42253 6.68033 4.21325C6.77497 4.00397 6.99483 3.86719 7.23799 3.86719H19.1848C19.7236 3.86719 20.1604 4.27754 20.1604 4.78364V16.6373C20.1604 16.8712 20.009 17.0818 19.7775 17.168C19.7032 17.1954 19.6275 17.209 19.5518 17.209H19.5532ZM9.24297 5.59339L18.3112 14.576C18.5398 14.803 18.9461 14.6498 18.9461 14.338V5.35539C18.9461 5.16389 18.7815 5.00933 18.5777 5.00933H9.50942C9.18618 5.00933 9.02019 5.3718 9.24297 5.59203V5.59339Z"
        // fill="#041C3F"
      />
      <path
        d="M18.8427 23.9986H13.1918C12.8554 23.9986 12.5831 23.7428 12.5831 23.4269C12.5831 23.1109 12.8554 22.8551 13.1918 22.8551H18.2341C20.7457 22.8551 22.7827 20.9415 22.7827 18.582V5.41525C22.7827 3.05574 20.7457 1.14214 18.2341 1.14214H5.76448C3.25281 1.14214 1.2158 3.05574 1.2158 5.41525V18.582C1.2158 20.9415 3.25281 22.8551 5.76448 22.8551H10.8228C11.1591 22.8551 11.4314 23.1109 11.4314 23.4269C11.4314 23.7428 11.1591 23.9986 10.8228 23.9986H5.15731C2.30929 23.9986 0 21.8292 0 19.1538V4.8435C0 2.16938 2.30929 0 5.15731 0H18.8427C21.6907 0 24 2.16938 24 4.84487V19.1551C24 21.8306 21.6907 24 18.8427 24V23.9986Z"
        // fill="#041C3F"
      />
    </svg>
  );
};

export default CargoflipLogoIcon;
