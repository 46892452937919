import { ENTITIES } from 'constants/entities';
import addContactMutation from './contacts/add-contact';
import bulkArchiveContactMutation from './contacts/bulk-archive-contacts';
import getAllContacts from './contacts/get-all-contacts';
import getContact from './contacts/get-contact';
import fetchRecentShipmentForContact from './contacts/get-recent-shipment-for-contact';
import importContactsMutation from './contacts/import-contacts';
import updateContactMutation from './contacts/update-contact';
import updateContactArchivedMutation from './contacts/update-contact-archived';
import getCustomFieldMapping from './custom-field-mappings/get-custom-field-mapping';
import getCustomFieldMappingsFieldTypesQuery from './custom-field-mappings/get-custom-field-mapping-field-types';
import getCustomFieldMappingsNameOptionsQuery from './custom-field-mappings/get-custom-field-mapping-name-options';
import getCustomFieldMappingsByEntity from './custom-field-mappings/get-custom-filed-mappings-by-entity';
import createCustomFieldMappingMutation from './custom-field-mappings/use-add-custom-field-mapping';
import bulkUpdateCustomFieldData from './custom-field-mappings/use-bulk-update-custom-field-data';
import deleteCustomFieldData from './custom-field-mappings/use-delete-custom-field-data';
import updateCustomFieldData from './custom-field-mappings/use-update-custom-field-data';
import getDashboardStatus from './dashboard/get-dashboard-status';
import shareDocumentMutation from './document-shares/share-document';
import getAllDocumentTemplate from './document_templates/get-all-document-template';
import getDocumentTemplate from './document_templates/get-document-template';
import createDocumentTemplatesMutation from './document_templates/use-create-document-template';
import updateDocumentTemplatesMutation from './document_templates/use-update-document-template';
import deleteDocumentMutation from './documents/use-delete-document';
import uploadDocumentMutation from './documents/use-upload-document';
import getFieldDataByEntity from './field-data/get-field-data-by-entity';
import bulkDeleteFieldData from './field-data/use-bulk-delete-field-data';
import bulkUpdateFieldData from './field-data/use-bulk-update-field-data';
import deleteFieldData from './field-data/use-delete-field-data';
import updateFieldData from './field-data/use-update-field-data';
import bulkCreateGeneratedDocumentsMutation from './generated-documents/bulk-create-generated-documents';
import getGeneratedDocumentsDocTypes from './generated-documents/get-all-doc-types';
import getAllDocuments from './generated-documents/get-all-documents';
import getGeneratedDocument from './generated-documents/get-generated-document';
import getGeneratedDocumentActivities from './generated-documents/get-generated-document-activities';
import getGeneratedDocumentProductAttachments from './generated-documents/get-generated-document-product-attachments';
import getSimilarDocuments from './generated-documents/get-similar-generated-documents';
import generatedDocumentsPostCommentMutation from './generated-documents/post-comment';
import autoFillGeneratedDocumentMutation from './generated-documents/use-autofill-generated-documents';
import bulkDeleteGeneratedDocumentsMutation from './generated-documents/use-bulk-delete-generated-documents';
import createGeneratedDocumentMutation from './generated-documents/use-create-generated-document';
import deleteGeneratedDocumentMutation from './generated-documents/use-delete-generated-document';
import useGeneratePdfMutation from './generated-documents/use-generate-pdf';
import updateGeneratedDocumentMutation from './generated-documents/use-update-generated-document';
import getNotifications from './notifications/get-notification';
import bulkReadStatusMutation from './notifications/use-bulk-update-read-status';
import useUpdateAllNotificationMutation from './notifications/use-update-all-notifications';
import useUpdateNotificationMutation from './notifications/use-update-notification';
import deleteOrganizationLogoMutation from './organization/delete-organization-logo';
import getOrganization from './organization/get-organization';
import getOrganizationUser from './organization/get-organization-user';
import updateOrganizationMutation from './organization/update-organization';
import updateOrganizationUserMutation from './organization/update-organization_user';
import getShipmentPaymentDetails from './payments/get-shipment-payment-details';
import importWorkflowsMutation from './plans/use-import-workflows';
import addProductMutation from './products/add-product';
import bulkDeleteFilesMutation from './products/bulk-delete-files';
import bulkUploadProductsMutation from './products/bulk-upload-products';
import deleteFileMutation from './products/delete-file';
import getAllProducts from './products/get-all-products';
import getIncomingProducts from './products/get-incoming-products';
import fetchLandedCost from './products/get-landed-cost';
import getOutgoingProducts from './products/get-outgoing-products';
import getProduct from './products/get-product';
import getProductFiles from './products/get-product-files';
import fetchRecentShipmentForProduct from './products/get-recent-shipments-for-product';
import bulkArchiveProductsMutation from './products/use-bulk-archive-products';
import updateProductMutation from './products/use-update-product';
import updateProductArchivedMutation from './products/use-update-product-archived';
import getShipmentPackageFieldsToImport from './shipment-packages/get-fields-to-import';
import getShipmentPackageStatus from './shipment-packages/get-package-status';
import createShipmentPackageMutation from './shipment-packages/use-add-shipment-package';
import createShipmentProduct from './shipment-products/use-add-product-to-shipment';
import addTrackingToShipmentMutation from './shipment-trackings/add-tracking-to-shipment';
import bulkAddTrackingToShipmentMutation from './shipment-trackings/bulk-add-tracking-to-shipment';
import bulkDeleteShipmentTrackingMutation from './shipment-trackings/bulk-delete-shipment-trackings';
import createShipmentTackingMutation from './shipment-trackings/create-shipment-tracking';
import deleteShipmentTrackingMutation from './shipment-trackings/delete-shipment-tracking';
import getTrackings from './shipment-trackings/get-all-shipment-trackings';
import getShipmentTracking from './shipment-trackings/get-shipment-tracking';
import getTrackingsImportFields from './shipment-trackings/get-shipment-tracking-import-fields';
import importShipmentTrackingMutation from './shipment-trackings/import-shipment-tracking';
import getAllShipments from './shipments/get-all-shipments';
import getCurrencyConversionDetails from './shipments/get-currency-conversion-details';
import getCurrencyConversionRates from './shipments/get-currency-conversion-rates';
import getShipment from './shipments/get-shipment';
import getShipmentCompleted from './shipments/get-shipment-complete';
import getTrackingDetail from './shipments/get-tracking-detail';
import createShipmentMutation from './shipments/use-add-shipment';
import updateShipmentMutation from './shipments/use-update-shipment';
import updateShipmentTracingStatusMutation from './shipments/use-update-shipment-tracing-status';
import getSignInAs from './sign_in_as/get-sign_in_as';
import getStopSignInAs from './sign_in_as/get-stop-sign-in-as';
import completeTaskMutation from './tasks/complete-task';
import { getAllTasks } from './tasks/get-all-tasks';
import getTask from './tasks/get-task';
import updateTaskStatusMutation from './tasks/update-task-status';
import uploadTaskDocumentMutation from './tasks/upload-task-document';
import getUploadedDocumentActivities from './uploaded-documents/get-activities';
import getDocument from './uploaded-documents/get-document';
import uploadedDocumentPostCommentMutation from './uploaded-documents/post-comment';
import updateDocumentMutation from './uploaded-documents/update-document';
import updateFileNameMutation from './uploaded-documents/update-filename';
import createUserMutation from './user/create-user';
import forgotPasswordMutation from './user/forgot-password';
import getManageUsers from './user/get-manage-users';
import getUser from './user/get-user';
import resetPasswordMutation from './user/reset-password';
import setNewPasswordMutation from './user/set-new-password';
import { useLoginMutation } from './user/use-login';
import useLogoutMutation from './user/use-logout';
import updateSettingsNotifications from './user/use-update-notifications';
import createTaskTemplateMutation from './workflows-tasks/create-task-template';
import getAllWorkflows from './workflows/get-all-workflows';
import getWorkflow from './workflows/get-workflow';
import duplicateWorkflowMutation from './workflows/use-duplicate-workflow';
import updateWorkflowMutation from './workflows/use-update-workflow';

const useRequest = {
  [ENTITIES.user]: {
    get: getUser,
    getManageUsers: getManageUsers,
    create: createUserMutation,
    signOut: useLogoutMutation,
    login: useLoginMutation,
    setPassword: setNewPasswordMutation,
    forgotPassword: forgotPasswordMutation,
    resetPassword: resetPasswordMutation
  },
  [ENTITIES.shipments]: {
    get: getAllShipments,
    create: createShipmentMutation,
    getById: getShipment,
    shipmentCompleted: getShipmentCompleted,
    update: updateShipmentMutation,
    updateTracingStatus: updateShipmentTracingStatusMutation,
    getCurrencyConversionRates: getCurrencyConversionRates,
    getCurrencyConversionDetails: getCurrencyConversionDetails,
    getTrackingDetailsById: getTrackingDetail
  },
  [ENTITIES.documents_templates]: {
    get: getAllDocumentTemplate,
    getById: getDocumentTemplate,
    create: createDocumentTemplatesMutation,
    update: updateDocumentTemplatesMutation
  },
  [ENTITIES.generated_documents]: {
    get: getAllDocuments,
    create: createGeneratedDocumentMutation,
    bulkCreate: bulkCreateGeneratedDocumentsMutation,
    getById: getGeneratedDocument,
    update: updateGeneratedDocumentMutation,
    getDocTypes: getGeneratedDocumentsDocTypes,
    delete: deleteGeneratedDocumentMutation,
    bulkDelete: bulkDeleteGeneratedDocumentsMutation,
    getSimilar: getSimilarDocuments,
    getActivities: getGeneratedDocumentActivities,
    autoFill: autoFillGeneratedDocumentMutation,
    generatePdf: useGeneratePdfMutation,
    postComment: generatedDocumentsPostCommentMutation,
    share: shareDocumentMutation,
    getProductAttachments: getGeneratedDocumentProductAttachments
  },
  [ENTITIES.uploaded_documents]: {
    getById: getDocument,
    updateFileName: updateFileNameMutation,
    postComment: uploadedDocumentPostCommentMutation,
    getActivities: getUploadedDocumentActivities,
    update: updateDocumentMutation
  },
  [ENTITIES.documents]: {
    uploadDocuments: uploadDocumentMutation,
    deleteDocument: deleteDocumentMutation
  },
  [ENTITIES.shipment_payments]: {
    get: getShipmentPaymentDetails
  },
  [ENTITIES.shipment_products]: {
    create: createShipmentProduct
  },
  [ENTITIES.shipment_trackings]: {
    create: createShipmentTackingMutation,
    get: getTrackings,
    getById: getShipmentTracking,
    getImportFields: getTrackingsImportFields,
    delete: deleteShipmentTrackingMutation,
    bulkDelete: bulkDeleteShipmentTrackingMutation,
    addToShipment: addTrackingToShipmentMutation,
    bulkAddToShipment: bulkAddTrackingToShipmentMutation,
    importTrackings: importShipmentTrackingMutation
  },
  [ENTITIES.shipment_packages]: {
    create: createShipmentPackageMutation,
    fieldsToImport: getShipmentPackageFieldsToImport,
    getCompleteStatus: getShipmentPackageStatus
  },
  [ENTITIES.contacts]: {
    get: getAllContacts,
    getById: getContact,
    create: addContactMutation,
    update: updateContactMutation,
    getRecentShipment: fetchRecentShipmentForContact,
    updateArchived: updateContactArchivedMutation,
    bulkArchive: bulkArchiveContactMutation,
    import: importContactsMutation
  },
  [ENTITIES.custom_fields]: {
    getById: getCustomFieldMapping,
    getByEntityType: getCustomFieldMappingsByEntity,
    create: createCustomFieldMappingMutation,
    bulkUpdate: bulkUpdateCustomFieldData,
    update: updateCustomFieldData,
    delete: deleteCustomFieldData,
    getNameOptions: getCustomFieldMappingsNameOptionsQuery,
    getFieldTypes: getCustomFieldMappingsFieldTypesQuery
  },
  [ENTITIES.field_data]: {
    getByEntityType: getFieldDataByEntity,
    update: updateFieldData,
    delete: deleteFieldData,
    bulkUpdate: bulkUpdateFieldData,
    bulkDelete: bulkDeleteFieldData
  },
  [ENTITIES.products]: {
    get: getAllProducts,
    getById: getProduct,
    create: addProductMutation,
    update: updateProductMutation,
    getRecentShipment: fetchRecentShipmentForProduct,
    getLandedCost: fetchLandedCost,
    updateArchived: updateProductArchivedMutation,
    bulkArchive: bulkArchiveProductsMutation,
    getOutgoing: getOutgoingProducts,
    getIncoming: getIncomingProducts,
    bulkUpload: bulkUploadProductsMutation,
    getFiles: getProductFiles,
    deleteFile: deleteFileMutation,
    bulkDeleteFiles: bulkDeleteFilesMutation
  },
  [ENTITIES.dashboard]: {
    getDashboardStatus: getDashboardStatus
  },
  [ENTITIES.plans]: {
    import: importWorkflowsMutation
  },
  [ENTITIES.tasks_templates]: {
    create: createTaskTemplateMutation
  },
  [ENTITIES.workflows]: {
    get: getAllWorkflows,
    getById: getWorkflow,
    update: updateWorkflowMutation,
    duplicate: duplicateWorkflowMutation
  },
  [ENTITIES.settings]: {
    update: updateSettingsNotifications
  },
  [ENTITIES.notifications]: {
    get: getNotifications,
    update: useUpdateNotificationMutation,
    updateAll: useUpdateAllNotificationMutation,
    bulkReadStatusUpdate: bulkReadStatusMutation
  },
  [ENTITIES.tasks]: {
    get: getAllTasks,
    getById: getTask,
    uploadTaskDocument: uploadTaskDocumentMutation,
    updateStatus: updateTaskStatusMutation,
    complete: completeTaskMutation
  },
  [ENTITIES.organization]: {
    get: getOrganization,
    update: updateOrganizationMutation,
    deleteLogo: deleteOrganizationLogoMutation,
    getOrganizationUser: getOrganizationUser,
    updateOrganizationUser: updateOrganizationUserMutation
  },
  [ENTITIES.sign_in_as]: {
    get: getSignInAs,
    stop: getStopSignInAs
  }
};

export default useRequest;
