import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createDocumentTemplatesMutation = () => {
  return useMutation((data: any) =>
    http.post(API_ENDPOINTS.CREATE_TEMPLATE, { document_template: data })
  );
};

export default createDocumentTemplatesMutation;
