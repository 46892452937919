import RenderIf from '@components/common/render-if';
import FullInput from '@components/dataEntry/FullInput';
import Form from '@components/dataEntry/form';
import FormInputCustomFields from '@components/dataEntry/form-input-custom-fields';
import { OptionsListProps } from '@components/dataEntry/select';
import ModalView from '@components/feedback/modal-view';
import Divider from '@components/general/Divider';
import { toastNotification } from '@hooks/toast-notification';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { createYupSchema } from '@utils/create-yup-schema';
import { formatCustomFieldData } from '@utils/format-custom-field-data';
import { formatSelectStringList } from '@utils/format-select-string-list';
import { FormIds } from 'constants/form-ids';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const contactOptions = [
  { name: 'Customer', value: 'customer' },
  { name: 'Exporter', value: 'exporter' },
  { name: 'Importer', value: 'importer' },
  { name: 'Carrier', value: 'carrier' },
  { name: 'Freight Forwarder', value: 'freight_forwarder' },
  { name: 'Third Party Logistics', value: 'third_party_logistics' },
  { name: 'Supplier', value: 'supplier' }
];

const ContactForm = () => {
  // states
  const modalState = useStore((state) => state.modalState);
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const autocompleteCreateNewEntityItemState = useStore(
    (state) => state.autocompleteCreateNewEntityItemState
  );
  const setAutocompleteCreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteCreateNewEntityItemState
  );

  // const
  const router = useRouter();
  const modalMode = modalState?.[0]?.mode || router.query?.mode;
  const contactId = modalState?.[0]?.entityId;
  const isNewContact = modalMode === 'add' || modalMode === 'duplicate';
  const headerLabel = modalMode === 'edit' ? 'Edit Contact' : 'Add Contact';
  const queryParams = {
    entity_type: 'Contact'
  };

  // hooks
  const { closeModalByPosition } = useModal();
  const { mutateAsync: addContactMutation, isLoading: addContactLoading } =
    useRequest.contacts.create();
  const { mutateAsync: updateContactMutation, isLoading: updateContactLoading } =
    useRequest.contacts.update(contactId);

  // queries
  const { data: customFieldMappingDataTable = [], isLoading: isCustomFieldLoading } =
    useRequest.custom_fields.getByEntityType({
      params: queryParams,
      options: { keepPreviousData: true }
    });
  const {
    data: contactDetails,
    isLoading: contactDetailsLoading,
    isSuccess
  } = useRequest.contacts.getById(
    { id: contactId },
    {
      keepPreviousData: true,
      enabled: !!contactId
    }
  );

  const hasCustomFields = customFieldMappingDataTable.length > 0;

  const validationSchema = yup.object({
    name: yup.string().required('Contact name is a required field'),
    company: yup.string().required('Company name is a required field'),
    ...customFieldMappingDataTable.reduce(createYupSchema, {})
  });

  const fullLoading = contactDetailsLoading || isCustomFieldLoading;
  const customFieldNames = formatCustomFieldData({
    data: customFieldMappingDataTable,
    details: contactDetails
  });

  const defaultValues = useMemo(
    () => ({
      id: contactDetails?.id,
      name:
        modalMode === 'edit'
          ? contactDetails?.name
          : autocompleteCreateNewEntityItemState.createItemName || '',
      contact_type: contactDetails?.contact_type || 'customer',
      email: contactDetails?.email || '',
      phone_number: contactDetails?.phone_number || '',
      address: contactDetails?.address || '',
      company: contactDetails?.company || '',
      ...customFieldNames
    }),
    [contactDetails]
  );

  // actions

  const queryClient = useQueryClient();

  const { handleSubmit } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs: any) => {
      if (isNewContact) {
        const { data } = await addContactMutation(inputs, {
          onSuccess: () => {
            queryClient.resetQueries([API_ENDPOINTS.CONTACTS]);
            toastNotification({ type: 'success', description: 'Contact was successfully added' });
          }
        });
        setAutocompleteCreateNewEntityItemState({ data });
      } else {
        const { data } = await updateContactMutation(
          { contact: inputs },
          {
            onSuccess: () => {
              queryClient.resetQueries([API_ENDPOINTS.CONTACTS]);
              toastNotification({
                type: 'success',
                description: 'Contact was successfully updated'
              });
            }
          }
        );
        setAutocompleteCreateNewEntityItemState({ data });
      }
      closeModalByPosition();
    }
  });

  const companyFields = [
    {
      label: 'Name',
      name: 'company',
      containerClass: 'w-full pr-1.5'
    },
    {
      name: 'address',
      type: 'textarea',
      label: 'Address',
      containerClass: 'col-span-full',
      rows: 3,
      placeholder: 'Address Line 1\nAddress Line 2\nAddress Line 3'
    }
  ];

  const contactInputs = [
    {
      label: 'Name',
      name: 'name',
      containerClass: 'w-1/2 pr-1.5'
    },
    {
      name: 'contact_type',
      type: 'select',
      label: 'Type',
      optionsList: contactOptions,
      containerClass: 'w-1/2 pl-1.5'
    },
    {
      label: 'Email',
      name: 'email',
      containerClass: 'w-1/2 pr-1.5 '
    },
    {
      label: 'Contact Phone Number',
      name: 'phone_number',
      containerClass: 'w-1/2 pl-1.5'
    }
  ];

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        errorsServerValidation={errorsServerValidation}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        formId={FormIds.CONTACT_FORM}
        updateValuesAfterSuccess={isSuccess}
      >
        <Divider title="Contact" className="py-2" />
        <div className="flex flex-wrap gap-y-3">
          {contactInputs.map((data, index) => (
            <FullInput key={index} {...data} />
          ))}
        </div>
        <div className="mt-4">
          <Divider title="Company" className="py-2" />
          <div className="flex flex-wrap gap-y-3 ">
            {companyFields.map((data, index) => (
              <FullInput key={index} {...data} />
            ))}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mt-4">
          <RenderIf isTrue={hasCustomFields}>
            <div className=" truncate w-full mx-2.5 relative" style={{ marginRight: '0.625rem' }}>
              <Divider title=" Custom Fields" className="py-2" />
            </div>
          </RenderIf>

          {customFieldMappingDataTable.map((customField, index) => (
            // TODO: Remove image check when custom field image upload is fixed
            <RenderIf key={customField.name} isTrue={customField.fieldType !== 'image'}>
              <div className="w-full px-3 mb-3">
                <FormInputCustomFields
                  data={customField}
                  autocompleteProps={{
                    lastElement: customFieldMappingDataTable.length - 1 === index,
                    defaultValue: defaultValues?.[customField.name] || ''
                  }}
                  selectProps={{
                    optionsList: formatSelectStringList(customField.value) as OptionsListProps[]
                  }}
                  checkboxProps={{
                    checked: defaultValues?.[customField.name] || false
                  }}
                  imageProps={{
                    accept: ['image/png', 'image/jpeg'],
                    message: 'PNG, JPG up to 10MB',
                    maxFiles: 1,
                    maxSizeBytes: 10485760
                  }}
                />
              </div>
            </RenderIf>
          ))}
        </div>
        <ModalFooter
          disabled={addContactLoading || updateContactLoading}
          modalId={ModalIds.CONTACT_FORM}
          mainButtonText={isNewContact ? 'Add' : 'Update'}
          mainButtonType="submit"
        />
      </Form>
    </ModalView>
  );
};

export default ContactForm;
