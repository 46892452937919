import { ManageFieldOptionsType, ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const getCustomFieldMappingsByEntity = ({
  params,
  options
}: {
  params?: ManageFieldOptionsType;
  options?: ReactQueryOptionsType;
}) => {
  return useQuery([API_ENDPOINTS.CUSTOM_FIELD_MAPPINGS, params], queryFetch, options);
};

export default getCustomFieldMappingsByEntity;
