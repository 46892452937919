import RenderIf from '@components/common/render-if';
import Avatar from '@components/dataDisplay/avatar';

const AccountNameWithAvatar = ({ avatarValue, accountName }) => {
  return (
    <>
      <RenderIf isTrue={accountName}>
        <div className="flex gap-3">
          <Avatar size="xs" value={avatarValue} type="text" />
          <p className="text-sm text-primary-navy-400">{accountName}</p>
        </div>
      </RenderIf>
      <RenderIf isTrue={!accountName}>
        <p className="text-sm text-primary-navy-400">-</p>
      </RenderIf>
    </>
  );
};

export default AccountNameWithAvatar;
