import { withComponent } from '@components/@utlils/withComponent';
import RenderIf from '@components/common/render-if';
import CustomFieldsNameAutocomplete from '@components/dataEntry/CustomFieldsNameAutocomplete';
import FullInput from '@components/dataEntry/FullInput';
import Form from '@components/dataEntry/form';
import FormAutocomplete from '@components/dataEntry/form-autocomplete';
import FormCustomFieldDropdownOptions from '@components/dataEntry/form-custom-field-dropdown-options';
import FormInput from '@components/dataEntry/form-input';
import useManageParams from '@hooks/use-manage-params';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { useStore } from '@store/useStore';
import { FunctionComponent, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import { ModalFooterProps } from '../modal-footer';

const AddCustomFieldForm = ({
  Footer,
  entityType,
  containerClass,
  onOpen
}: {
  Footer?: FunctionComponent<ModalFooterProps>;
  entityType: 'Contact' | 'Shipment' | 'Product';
  containerClass?: string;
  onOpen?: (value: boolean) => void;
}) => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const setUpdateSideBar = useStore((state) => state.setUpdateSideBar);
  const setUpdateFieldData = useStore((state) => state.setUpdateFieldData);
  const [disabledFieldType, setDisabledFieldType] = useState(false);
  const setScrollToSidebarLastChild = useStore((state) => state.setScrollToSidebarLastChild);
  const modalState = useStore((state) => state.modalState);

  // hooks
  const { removeParams } = useManageParams();
  const { mutateAsync: createCustomField } = useRequest.custom_fields.create();
  const { mutateAsync: updateCustomFieldData } = useRequest.custom_fields.update();
  const { refetch: refetchFieldData } = useRequest.field_data.getByEntityType({
    params: { entity_type: entityType, rank_by: 'sidebar_order' }
  });

  // const
  const isShipmentPage = entityType === 'Shipment';
  const isContactPage = entityType === 'Contact';
  const isProductPage = entityType === 'Product';
  const modalMode = modalState?.[0]?.mode;
  const customFieldMappingId = modalState?.[0]?.entityId;
  const { data: customFieldMappingDetails } = useRequest.custom_fields.getById(
    { id: customFieldMappingId },
    { keepPreviousData: true, enabled: !!customFieldMappingId }
  );

  const FooterComponent = withComponent(Footer, Footer);

  const defaultValues = useMemo(
    () => ({
      entity_type: entityType,
      name: customFieldMappingDetails?.name || '',
      field_type: customFieldMappingDetails?.field_type || '',
      description: customFieldMappingDetails?.description || '',
      value: customFieldMappingDetails?.value?.split(',') || [],
      required: false,
      always_shown: true,
      ...((isShipmentPage || isContactPage) && { copy_to_all_entities: false }),
      ...(isProductPage && {
        properties: {
          shown_on_order_page: false
        }
      })
    }),
    [entityType, customFieldMappingDetails]
  );
  const validationSchema =
    modalMode !== 'edit'
      ? yup.object({
          name: yup
            .string()
            .required('Field name is required')
            .test(
              'special character',
              'These special characters are not allowed . [ ] \' "',
              (val) => !['.', '[', ']', "'", '"'].some((character) => val.includes(character))
            ),
          field_type: yup.string().required('Field type is required'),
          value: yup
            .array()
            .of(yup.string().required('Choice is required'))
            .test('Unique', 'Choices must be unique', (values) => {
              return new Set(values).size === values.length;
            })
        })
      : yup.object({
          ...(customFieldMappingDetails?.field_type === 'dropdown' && {
            value: yup
              .array()
              .of(yup.string().required('Choice is required'))
              .test('Unique', 'Values must be unique', (values) => {
                return new Set(values).size === values.length;
              })
          })
        });

  const checkboxInputs = [
    {
      name: 'required',
      label: 'Required',
      type: 'boolean',
      labelClass: 'text-primary-navy-400',
      align: 'horizontal',
      tooltipText: `Must be filled for any new ${entityType}`
    },
    ...(isShipmentPage || isContactPage
      ? [
          {
            name: 'copy_to_all_entities',
            label: 'Copy to all entities',
            type: 'boolean',
            labelClass: 'text-primary-navy-400',
            align: 'horizontal',
            tooltipText: `Create this field for all ${
              isShipmentPage ? 'contacts' : 'shipments'
            } as well`
          }
        ]
      : []),
    ...(isProductPage
      ? [
          {
            name: 'properties.shown_on_order_page',
            label: 'Shown on Order Page',
            type: 'boolean',
            labelClass: 'text-primary-navy-400',
            align: 'horizontal',
            tooltipText: 'Show this field when viewing products tied to a shipment'
          }
        ]
      : [])
  ];

  const onSuccess = () => {
    setUpdateSideBar(true);
    setUpdateFieldData(true);
    onOpen?.(false);
    setScrollToSidebarLastChild(true);
  };

  const queryClient = useQueryClient();
  // actions
  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    message: `A ${entityType.toLowerCase()} field was successfully ${
      modalMode === 'edit' ? 'updated' : 'added'
    }.`,
    onSubmit: async (inputs) => {
      const modifiedInputs = {
        id: customFieldMappingId,
        custom_field_mapping: {
          ...(modalMode === 'edit'
            ? { description: inputs.description, value: inputs.value || [] }
            : inputs)
        }
      };
      if (modalMode === 'edit') {
        await updateCustomFieldData(modifiedInputs as any, {
          onSuccess
        });
        return;
      }

      await createCustomField(modifiedInputs as any, {
        onSuccess
      });
      queryClient.invalidateQueries('shipments');
      refetchFieldData();
      setTimeout(() => {
        onSuccess();
      }, 500);
      removeParams(['add_field']);
    }
  });

  return (
    <Form
      defaultValues={defaultValues}
      errorsServerValidation={errorsServerValidation}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      formId={formId}
      updateValuesAfterSuccess={true}
    >
      <div className="flex flex-col">
        <div className={containerClass ? containerClass : 'flex flex-col p-3 divide-y'}>
          <div className="flex flex-col gap-3">
            <RenderIf isTrue={modalMode !== 'edit'}>
              <CustomFieldsNameAutocomplete
                onChange={(isDisabled) => setDisabledFieldType(isDisabled)}
                entityType={entityType}
              />
            </RenderIf>
            <RenderIf isTrue={modalMode !== 'edit'}>
              <FormAutocomplete
                name="field_type"
                label="Field Type"
                entity="customFieldTypes"
                labelClass="text-primary-navy-400"
                icon="list"
                disabled={disabledFieldType}
              />
            </RenderIf>
            <FormCustomFieldDropdownOptions
              name="value"
              label="Choices"
              labelClass="text-primary-navy-400"
              entityType={entityType}
              disabled={disabledFieldType}
              defaultValues={defaultValues?.value || []}
            />
            <FormInput
              name="description"
              label="Description"
              type="textarea"
              labelClass="text-primary-navy-400"
              rows={3}
            />
            <RenderIf isTrue={modalMode !== 'edit'}>
              <>
                <div className="border-t w-full"></div>
                {checkboxInputs.map((x, index) => (
                  <FullInput key={index} {...x} />
                ))}
              </>
            </RenderIf>
          </div>
        </div>
        <FooterComponent formId={formId} />
      </div>
    </Form>
  );
};

export default AddCustomFieldForm;
