import { useShipmentPackagesQuery } from '@services/api/shipment-packages/get-all-shipment-packages';
import { useBulkDeleteShipmentPackagesMutation } from '@services/api/shipment-packages/use-bulk-delete-shipment-packages';
import { useDeleteShipmentPackageMutation } from '@services/api/shipment-packages/use-delete-shipment-package';
import { useImportShipmentPackagesMutation } from '@services/api/shipment-packages/use-import-shipment-package';
import { useUnpackShipmentPackageMutation } from '@services/api/shipment-packages/use-unpack-shipment-package';
import { ShipmentPackageTotalType } from '@services/types';
import { useStore } from '@store/useStore';
import useManageParams from './use-manage-params';

const useManageShipmentPackages = () => {
  // states
  const selectedShipmentPackageIDs = useStore((state) => state.selectedShipmentPackageIDs);

  // hooks
  const { params } = useManageParams();

  // const
  const shipmentPackagesQueryParams = {
    page: params?.page,
    shipment_id: ''
  };

  const getShipmentPackages = (id) => {
    const { data, isLoading, refetch } = useShipmentPackagesQuery(
      { ...shipmentPackagesQueryParams, shipment_id: id },
      {
        keepPreviousData: true
      }
    );

    const importFields = !isLoading && data ? data.importFields : [];
    const isPackingComplete = !isLoading && data ? data.isPackingComplete : false;
    const missingNetWeightsProducts = !isLoading && data ? data.missingNetWeightsProducts : [];
    const packedShipmentProductIds = !isLoading && data ? data.packedShipmentProductIds : [];
    const pagination = !isLoading && data && data.pagination;
    const dataTable = !isLoading && data ? data.data : [];
    const total = !isLoading && data ? data.total : ({} as ShipmentPackageTotalType);

    return {
      data: dataTable,
      total,
      isLoading,
      refetch,
      importFields,
      pagination,
      isPackingComplete,
      missingNetWeightsProducts,
      packedShipmentProductIds
    };
  };

  const bulkDeleteShipmentPackagesMutation = () => {
    const mutation = useBulkDeleteShipmentPackagesMutation();
    const { mutateAsync: bulkDeleteMutation } = mutation;

    const bulkDeleteShipmentPackages = async () => {
      const inputs = {
        delete_data: selectedShipmentPackageIDs
      };

      await bulkDeleteMutation(inputs);
    };

    return { mutation, bulkDeleteShipmentPackages };
  };

  const deleteShipmentPackageMutation = () => {
    const mutation = useDeleteShipmentPackageMutation();
    const { mutateAsync: deleteShipmentPackageMutation } = mutation;

    const deleteShipmentPackage = async (id) => {
      await deleteShipmentPackageMutation(id);
    };

    return { mutation, deleteShipmentPackage };
  };

  const importShipmentPackagesMutation = () => {
    const mutation = useImportShipmentPackagesMutation();
    const { mutateAsync: importCSV } = mutation;

    const importShipmentPackages = async (data) => {
      await importCSV(data);
    };

    return { mutation, importShipmentPackages };
  };

  const unpackShipmentPackageMutation = () => {
    const mutation = useUnpackShipmentPackageMutation();
    const { mutateAsync: unpackShipmentPackageMutation } = mutation;

    const unpackShipmentPackage = async (inputs) => {
      await unpackShipmentPackageMutation(inputs);
    };

    return { mutation, unpackShipmentPackage };
  };

  return {
    shipmentPackagesQueryParams,
    getShipmentPackages,
    bulkDeleteShipmentPackagesMutation,
    deleteShipmentPackageMutation,
    importShipmentPackagesMutation,
    unpackShipmentPackageMutation
  };
};

export default useManageShipmentPackages;
