import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function addWorkflows(inputs: any) {
  return http.post(`${API_ENDPOINTS.WORKFLOWS}`, inputs);
}

export const useAddWorkflowsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((inputs: any) => addWorkflows(inputs), {
    onSuccess: () => {
      queryClient.refetchQueries(API_ENDPOINTS.WORKFLOWS);
      toastNotification({ type: 'success', description: 'Workflow was added' });
    }
  });
};
