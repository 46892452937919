// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import http from '@services/api/utils/http';

const getUserData = async () => {
  try {
    const { data } = await http.get(`${API_ENDPOINTS.ACCOUNTS}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const sentryInit = async () => {
  if (
    (process.env.NODE_ENV === 'production' && SENTRY_DSN !== undefined) ||
    (process.env.NEXT_PUBLIC_APP_ENV !== undefined &&
      process.env.NEXT_PUBLIC_APP_ENV === 'staging') ||
    process.env.NEXT_PUBLIC_SENTRY_LOCAL_TEST === 'true'
  ) {
    const data = await getUserData();

    const sentryOptions = {
      enabled:
        process.env.NODE_ENV === 'production' ||
        (process.env.NEXT_PUBLIC_APP_ENV !== undefined &&
          process.env.NEXT_PUBLIC_APP_ENV === 'staging') ||
        process.env.NEXT_PUBLIC_SENTRY_LOCAL_TEST === 'true',
      environment:
        process.env.NEXT_PUBLIC_APP_ENV !== undefined
          ? process.env.NEXT_PUBLIC_APP_ENV
          : process.env.NODE_ENV,
      dsn: SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      release: process.env.NEXT_PUBLIC_HEROKU_RELEASE_VERSION,
      initialScope: {
        user: {
          id: String(data?.id) || '',
          email: data?.email || '',
          organization: data?.organization || ''
        }
      },
      beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        if (hint) {
          // Filter out some errors we don't care about
          const error = hint.originalException;
          let errorMessage: string | undefined;
          if (typeof error === 'string') {
            errorMessage = error;
          } else if (error instanceof Error) {
            errorMessage = error.message;
          }

          switch (errorMessage) {
            case 'Connection destroyed':
            case "Cannot read properties of null (reading 'postMessage')":
              return null;
            default:
              break;
          }
        }

        return event;
      },

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    };

    Sentry.init(sentryOptions);
  }
};
