import { UserProfileResponseType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export const fetchProfile = async () => {
  const { data } = await http.get(API_ENDPOINTS.SETTINGS);
  return data as UserProfileResponseType;
};
export const useUserProfile = () => {
  return useQuery([API_ENDPOINTS.SETTINGS], fetchProfile);
};
