import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface NotificationsPermissionsType {
  email_notification_setting?: boolean;
  notification_enabled?: boolean;
  in_app_notification_setting?: boolean;
}

const updateSettingsNotifications = () => {
  return useMutation((data: NotificationsPermissionsType) =>
    http.put(`${API_ENDPOINTS.SETTINGS}/notification`, data)
  );
};

export default updateSettingsNotifications;
