import { GlobeAltIcon } from '@heroicons/react/outline';
import { countriesOptions } from 'constants/countriesOptions';
import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutocompleteV2, { FormAutocompleteV2Props } from './form-autocomplete-v2';

const CountryAutocomplete: FC<FormAutocompleteV2Props> = (props) => {
  const [value, setLocalValue] = useState('');
  const { setValue, getValues } = useFormContext();
  const contextValue = getValues(props.name);
  const onChange = setValue;
  const optionsValues = [
    ...countriesOptions,
    ...(countriesOptions.filter((option) => option.value === contextValue).length === 0
      ? [{ value: contextValue, label: contextValue }]
      : [{}]),
    ...(value ? [{ value: value, label: value }] : [{}])
  ];
  const onHandleOnChange = (item, obj) => {
    if (obj.action === 'clear' || obj.action === 'remove-value') {
      onChange(props.name, '');
      setLocalValue('');
      return;
    }
    onChange(props.name, item.value);
    setLocalValue(item.value);
  };

  return (
    <FormAutocompleteV2
      options={optionsValues}
      value={contextValue}
      icon={<GlobeAltIcon className="w-4 h-4" />}
      onChange={onHandleOnChange}
      {...props}
    />
  );
};

export default CountryAutocomplete;
