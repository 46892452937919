import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const deleteFieldData = () => {
  return useMutation((id) => http.delete(API_ENDPOINTS.FIELD_DATA_BY_ID(id)));
};

export default deleteFieldData;
