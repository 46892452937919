import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const autoFillGeneratedDocumentMutation = () => {
  return useMutation((data: { source_document_id: string; destination_document_id: string }) =>
    http.post(API_ENDPOINTS.AUTO_FILL_GENERATED_DOCUMENTS, data)
  );
};

export default autoFillGeneratedDocumentMutation;
