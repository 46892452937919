import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { queryFetch } from './query-fetch';

export type queryOptions = { params?: {}; options?: ReactQueryOptionsType };

const useQueryWrapper = (API, props?: queryOptions) => {
  return useQuery([API, props?.params], queryFetch, props?.options);
};

export default useQueryWrapper;
