import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const getDocument = ({ id, options }: { id: string; options: ReactQueryOptionsType }) => {
  return useQuery([API_ENDPOINTS.GET_DOCUMENT(id)], queryFetch, options);
};

export default getDocument;
