import RenderIf from '@components/common/render-if';
import { useEffect, useState } from 'react';

export default function AdminModeBanner() {
  const [isAdminMode, setIsAdminMode] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsAdminMode(!!localStorage.getItem('admin-mode'));
    }
  }, []);

  return (
    <RenderIf isTrue={isAdminMode}>
      <div className="flex justify-center bg-semantic-warning-400 p-6 py-0.5 sm:px-3.5">
        <p className="text-sm leading-6 text-white">You are in admin mode. </p>
      </div>
    </RenderIf>
  );
}
