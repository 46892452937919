import RenderIf from '@components/common/render-if';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline';

const NavbarToggler = ({ open, setOpen }) => {
  return (
    <div
      className="absolute cursor-pointer bg-white border border-common-stroke rounded-md p-1"
      onClick={() => setOpen(!open)}
      style={{
        right: '-0.75rem',
        top: '3rem',
        zIndex: 100
      }}
    >
      <RenderIf isTrue={open}>
        <ChevronDoubleLeftIcon className={`w-4 text-primary-grey-500`} />
        <ChevronDoubleRightIcon className={`w-4 text-primary-grey-500`} />
      </RenderIf>
    </div>
  );
};

export default NavbarToggler;
