import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export type ImportWorkflowsMutationValues = { workflowId: string; shipmentId: string | string[] };

const importWorkflowsMutation = () => {
  return useMutation((data: ImportWorkflowsMutationValues) =>
    http.post(API_ENDPOINTS.PLANS_IMPORT_WORKFLOW, {
      shipment_id: data.shipmentId,
      plan_template_id: data.workflowId
    })
  );
};

export default importWorkflowsMutation;
