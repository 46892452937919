import {
  ActivityLoaderIcon,
  CostLoaderIcon,
  DocumentLoaderIcon,
  PackingLoaderIcon,
  PaymentLoaderIcon,
  TaskLoaderIcon,
  TrackingLoaderIcon,
  WorldLoaderIcon
} from '@assets/svg/Loader';
import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import cn from 'classnames';
import { FC, ReactNode } from 'react';
import SpinnerLoader from './spinner-loader';

export type LoaderType =
  | 'activity'
  | 'document'
  | 'tracking'
  | 'payment'
  | 'cost'
  | 'task'
  | 'packing'
  | 'worldShipping';

export type LoaderWithIconProps = {
  height?: string;
  removeBackground?: boolean;
  centerFullScreen?: boolean;
  icon?: string;
  absolute?: boolean;
  opacity?: boolean;
  fixed?: boolean;
  loadingText?: string;
  helpfulGuide?: string;
  isError?: boolean;
};

const IconMapper: Record<LoaderType, ReactNode> = {
  activity: ActivityLoaderIcon,
  document: DocumentLoaderIcon,
  tracking: TrackingLoaderIcon,
  payment: PaymentLoaderIcon,
  cost: CostLoaderIcon,
  task: TaskLoaderIcon,
  packing: PackingLoaderIcon,
  worldShipping: WorldLoaderIcon
};

const LoaderWithIcon: FC<LoaderWithIconProps> = ({
  height,
  removeBackground = false,
  centerFullScreen = false,
  icon = 'activity',
  absolute = false,
  fixed = false,
  opacity,
  loadingText = null,
  helpfulGuide = null,
  isError = false
}) => {
  const containerClassName = cn('min-h-full flex flex-col z-[9999]', {
    'bg-white': !removeBackground,
    'flex h-screen': centerFullScreen,
    'absolute w-full h-full': absolute,
    'fixed w-full h-full': fixed,
    'opacity-60': opacity
  });

  const Icon = IconMapper[icon];

  return (
    <div className={containerClassName} style={{ height }}>
      <div className="m-auto">
        <div className="flex items-center justify-center relative">
          <Icon isError={isError} />
          {!isError && <SpinnerLoader color="green" size="l" />}
        </div>
        <div className="flex flex-col items-center pt-4">
          <RenderIf isTrue={loadingText}>
            <Typography className="text-primary-navy-500">{loadingText}</Typography>
          </RenderIf>
          <RenderIf isTrue={helpfulGuide}>
            <Typography variant="text-xs" className="text-primary-grey-500">
              {helpfulGuide}
            </Typography>
          </RenderIf>
        </div>
      </div>
    </div>
  );
};

export default LoaderWithIcon;
