import cn from 'classnames';
import { useRouter } from 'next/router';
import { FC, MouseEvent, ReactNode } from 'react';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'tertiary'
  | 'transparent'
  | 'link';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type TextSize = 'text-[0.75rem]' | 'text-[0.875rem]' | 'text-[1rem]';
export type IconSize = 'h-3 w-3' | 'h-4 w-4' | 'h-5 w-5';
export type IconPosition = 'right' | 'left';
export type IconMargin = '-ml-0.5 mr-1' | '-ml-1 mr-2' | 'ml-1 -mr-0.5' | 'ml-2 -mr-1';

export const sizeToTextSizeMap: Record<Size, TextSize> = {
  xs: 'text-[0.75rem]',
  sm: 'text-[0.875rem]',
  md: 'text-[0.875rem]',
  lg: 'text-[0.875rem]',
  xl: 'text-[1rem]'
};

export const sizeToIconSizeMap: Record<Size, IconSize> = {
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-5 w-5',
  xl: 'h-5 w-5'
};

export const sizeToLeftPositionMap: Record<Size, IconMargin> = {
  xs: '-ml-0.5 mr-1',
  sm: '-ml-0.5 mr-1',
  md: '-ml-1 mr-2',
  lg: '-ml-1 mr-2',
  xl: '-ml-1 mr-2'
};

export const sizeToRightPositionMap: Record<Size, IconMargin> = {
  xs: 'ml-1 -mr-0.5',
  sm: 'ml-1 -mr-0.5',
  md: 'ml-2 -mr-1',
  lg: 'ml-2 -mr-1',
  xl: 'ml-2 -mr-1'
};

export interface ButtonPropsType {
  onClick?: (e?: MouseEvent<HTMLElement>) => void;
  href?: string;
  variant?: ButtonVariant | string;
  className?: string;
  children?: string | ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  size?: Size;
  icon?: JSX.Element;
  // Send list of icons if you want icons on both size of th text
  iconList?: { name: string; icon: JSX.Element }[];
  iconPosition?: IconPosition;
  defaultClass?: boolean;
  link?: string;
  formId?: string;
  iconWrapperClass?: string;
  id?: string;
  style?: any;
}

const Button: FC<ButtonPropsType> = ({
  onClick,
  variant,
  className,
  children,
  type = 'button',
  disabled,
  size = 'sm',
  loading = false,
  href,
  icon,
  iconList = [],
  iconPosition = 'left',
  defaultClass = true,
  formId,
  link,
  iconWrapperClass,
  ...props
}) => {
  const router = useRouter();
  const btnDefaultClasses = defaultClass ? `btn-${size} ${sizeToTextSizeMap[size]}` : '';
  const rootClassName = cn(className, 'whitespace-nowrap justify-center', {
    'btn btn-primary': variant === 'primary',
    'btn btn-secondary': variant === 'secondary',
    'btn btn-danger': variant === 'danger',
    'btn btn-success': variant === 'success',
    'btn btn-warning': variant === 'warning',
    'btn btn-tertiary': variant === 'tertiary',
    'btn btn-transparent': variant === 'transparent',
    link: variant === 'link',
    [btnDefaultClasses]: defaultClass
  });

  const iconWrapperClassName = iconWrapperClass ? iconWrapperClass : 'inline-flex items-center';

  const getClassName = (position) => {
    const iconClassName = `${sizeToIconSizeMap[size]} ${
      children
        ? position === 'left'
          ? sizeToLeftPositionMap[size]
          : sizeToRightPositionMap[size]
        : ''
    }`;

    return iconClassName;
  };

  const onClickWrapper = (e) => {
    onClick?.(e);
    if (link) router.push(link);
    if (href) window.location.href = href;
  };

  const childrenElement = loading ? 'Loading...' : children;

  const getButtonChild = () => {
    if (iconList.length > 1) {
      return (
        <span className="inline-flex items-center">
          <span className={getClassName('left')}>{iconList[0].icon}</span>
          {childrenElement}
          <span className={getClassName('right')}>{iconList[1].icon}</span>
        </span>
      );
    }
    if (icon && iconPosition === 'left') {
      return (
        <span className={iconWrapperClassName}>
          <span className={getClassName(iconPosition)}>{icon}</span>
          {childrenElement}
        </span>
      );
    }
    if (icon && iconPosition === 'right') {
      return (
        <span className={iconWrapperClassName}>
          {childrenElement}
          <span className={getClassName(iconPosition)}>{icon}</span>
        </span>
      );
    }

    return childrenElement;
  };

  return (
    <button
      id={props.id}
      type={type}
      className={rootClassName}
      onClick={onClickWrapper}
      onFocus={(e: any) => e.target.blur()}
      disabled={disabled}
      form={formId}
      style={props.style}
    >
      {getButtonChild()}
    </button>
  );
};

export default Button;
