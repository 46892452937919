import { useStore } from '@store/useStore';
import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

export default function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) {
  const modalRef = useStore((state) => state.modalRef);
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current;
      if (
        !el ||
        el.contains((event?.target as Node) || null) ||
        modalRef?.current?.contains((event?.target as Node) || null)
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
