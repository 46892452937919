import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

export const getTrackings = (props?: queryOptions) => {
  return useQuery([API_ENDPOINTS.SHIPMENT_TRACKINGS, props?.params], queryFetch, props?.options);
};

export default getTrackings;
