import { toastNotification } from '@hooks/toast-notification';
import { useRouter } from 'next/router';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function importShipmentProduct(inputs: any) {
  const { shipmentId, inputs: payload } = inputs;
  return http.post(`${API_ENDPOINTS.SHIPMENT_PRODUCTS}/import_csv?shipment_id=${shipmentId}`, {
    import_data: payload
  });
}

export const useImportShipmentProductMutation = () => {
  const { query } = useRouter();
  const shipmentId = query.id;

  return useMutation((inputs: any) => importShipmentProduct({ inputs, shipmentId }), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Product data was successfully imported to shipment.'
      });
    }
  });
};
