import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormInputContainer from './form-input-container';
import MultiSelectDropdown, { MultiSelectDropdownProps } from './multi-select-dropdown';

export interface FormMultiSelectDropdownProps extends MultiSelectDropdownProps {
  label: string;
  showError?: boolean;
  onChangeMultiSelect: (selected: any) => void;
}

const FormMultiSelectDropdown: React.FC<FormMultiSelectDropdownProps> = ({
  name,
  label,
  showError,
  onChangeMultiSelect,
  ...rest
}) => {
  const { setValue } = useFormContext();

  const onChangeWrapper = (selected) => {
    const selectedValues = selected.map(({ value }) => value);
    setValue(name, selectedValues, { shouldValidate: true });
    onChangeMultiSelect(selected);
  };

  return (
    <FormInputContainer name={name} label={label} labelSize={'s'} showError={showError}>
      <MultiSelectDropdown name={name} onChange={onChangeWrapper} {...rest} />
    </FormInputContainer>
  );
};

export default FormMultiSelectDropdown;
