const initialContactsState = {
  selectedContactIDs: [],
  contactMasterId: ''
};

export const createContactsSlice = (set) => ({
  ...initialContactsState,
  setSelectedContactIDs: (contactIDs) => {
    return set(() => ({ selectedContactIDs: contactIDs }), false, 'setSelectedContactIDs');
  },
  setSelectedContactMasterId: (id) =>
    set(() => ({ contactMasterId: id }), false, 'setSelectedContactMasterId')
});
