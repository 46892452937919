import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function deleteShipment(id: string) {
  return http.delete(`${API_ENDPOINTS.SHIPMENTS}/${id}`);
}

export const useDeleteShipmentMutation = () => {
  return useMutation((id: string) => deleteShipment(id), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Shipment was successfully removed.'
      });
    }
  });
};
