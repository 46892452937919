import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useContextUI } from 'states/ui';
import useManageParams from './use-manage-params';

const useModal = () => {
  const modalState = useStore((state) => state.modalState);
  const setModalState = useStore((state) => state.setModalState);
  const { removeParams, addParams } = useManageParams();
  const [_, { closePortal }] = useContextUI();

  const openModal = ({
    id,
    isOpen,
    hide = false,
    mode = 'add',
    entityId,
    entityType,
    inlineEditable,
    redirect = true,
    formValues,
    persistForm,
    notifyMessage,
    type
  }: {
    id: ModalIds | string;
    isOpen: boolean;
    hide?: boolean;
    mode?: 'add' | 'edit' | 'duplicate' | 'continue';
    entityId?: any;
    entityType?: any;
    formValues?: any;
    inlineEditable?: boolean;
    redirect?: boolean;
    notifyMessage?: string;
    persistForm?: boolean;
    type?: string;
  }) => {
    if (modalState.length > 0) {
      const lastModal = modalState[0];
      modalState[0] = {
        id: lastModal.id,
        isOpen: true,
        hide: true,
        mode: lastModal.mode,
        entityId: lastModal.entityId,
        formValues,
        inlineEditable,
        notifyMessage,
        persistForm,
        redirect
      };
    }
    const modifiedState = modalState.some((modal) => modal.id === id)
      ? modalState.map((obj) =>
          obj.id === id
            ? {
                ...obj,
                isOpen,
                hide,
                mode,
                entityId,
                entityType,
                redirect,
                inlineEditable,
                notifyMessage,
                persistForm,
                type
              }
            : obj
        )
      : [
          {
            id,
            isOpen,
            hide,
            mode,
            entityId,
            entityType,
            redirect,
            inlineEditable,
            notifyMessage,
            persistForm,
            type
          },
          ...modalState
        ];

    addParams(mode ? { mode } : {});
    setModalState(modifiedState);
  };

  const getModalStateById = ({ id }: { id: ModalIds | string }) =>
    modalState.find((state) => state.id === id);

  const closeModalByPosition = () => {
    const modifiedState = [...modalState];
    removeParams(['modalId']);
    removeParams(['mode']);
    modifiedState.splice(0, 1);
    if (modifiedState.length > 0) {
      modifiedState[0].hide = false;
    }

    setModalState(modifiedState);
    closePortal();
  };

  const setModalStateByPosition = ({ id, formValues }) => {
    const modifiedState = [...modalState];
    const index = modifiedState.findIndex((x) => x.id === id);
    if (modifiedState.length > 0) {
      modifiedState[index].formValues = formValues;
    }

    setModalState(modifiedState);
  };

  const closeAllModals = () => {
    closePortal();
    setModalState([]);
  };

  return {
    openModal,
    closeModalByPosition,
    closeAllModals,
    getModalStateById,
    setModalStateByPosition
  };
};

export default useModal;
