import { withComponent } from '@components/@utlils/withComponent';
import RenderIf from '@components/common/render-if';
import Card from '@components/dataDisplay/card';
import Typography from '@components/dataDisplay/typography';
import Button from '@components/general/button';
import { DocumentTypesProps } from '@components/sharing/forms/upload-document-form';
import { DocumentAddIcon, TrashIcon } from '@heroicons/react/outline';
import { formatBytes } from '@utils/math';
import cx from 'classnames';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

export interface FileUploadProps {
  accept?: string[];
  multiple?: boolean;
  minSizeBytes?: number;
  maxSizeBytes?: number;
  maxFiles?: number;
  disabled?: boolean;
  message?: string;
  alignment?: 'horizontal' | 'vertical';
  onChangeFile?: (files) => void;
  DocumentTypesItem?: (props: DocumentTypesProps) => JSX.Element;
  hideUploader?: boolean;
}

const FileUpload: FC<FileUploadProps> = ({
  accept = ['*'],
  multiple = false,
  minSizeBytes,
  maxSizeBytes,
  maxFiles,
  disabled = false,
  message,
  alignment = 'horizontal',
  onChangeFile,
  DocumentTypesItem = '',
  hideUploader = false
}) => {
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '3rem',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#8ABEFF',
    borderStyle: 'dashed',
    backgroundColor: '#F9FAFF',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    height: '100%'
  };

  const dragActiveStyle = {
    backgroundColor: '#B0D3FF',
    color: '#FFFFFF !important'
  };

  const [files, setFiles] = useState([]);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const progressBarRef = useRef(null);
  const isHorizontalAlignment = alignment === 'horizontal';
  const acceptParams = accept.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: []
    }),
    {}
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return;

      setShowProgressBar(true);
      let progress = 0;
      const intervalSpeed = 10;
      const incrementSpeed = 4;
      const progressInterval = setInterval(function () {
        progress += incrementSpeed;
        progressBarRef.current.style.width = progress + '%';
        if (progress >= 100) {
          setShowProgressBar(false);
          let newFiles = [];
          if (maxFiles === 1) {
            newFiles = [...acceptedFiles];
            setFiles(newFiles);
          } else {
            newFiles = [...files, ...acceptedFiles];
            setFiles(newFiles);
          }

          if (onChangeFile) {
            onChangeFile(newFiles);
          }
          clearInterval(progressInterval);
        }
      }, intervalSpeed);
    },
    [files]
  );

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    if (onChangeFile) {
      onChangeFile(newFiles);
    }
  };

  const removeAll = () => {
    setFiles([]);
    if (onChangeFile) {
      onChangeFile([]);
    }
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptParams,
    multiple,
    minSize: minSizeBytes,
    maxSize: maxSizeBytes,
    maxFiles,
    disabled,
    onDrop
  });
  const errorFiles = fileRejections.reduce((acc, cur, index) => {
    acc = `${acc}${cur?.file?.name}`;
    if (index !== fileRejections.length - 1) {
      acc = `${acc}, `;
    }
    return acc;
  }, '');

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? dragActiveStyle : {})
    }),
    [isDragActive]
  );

  const header = (
    <div className="flex items-center justify-between">
      <div>{`Added Documents(${files.length})`}</div>
      <div>
        <Button onClick={() => removeAll()} variant="transparent" size="xs" disabled={disabled}>
          Clear all
        </Button>
      </div>
    </div>
  );

  const DocumentTypeComponent = withComponent(DocumentTypesItem, DocumentTypesItem);
  const hasFiles = files?.length > 0;

  const className = cx('w-full', {
    'w-1/2': isHorizontalAlignment && hasFiles
  });

  return (
    <div className={`flex relative ${isHorizontalAlignment ? 'flex-row' : 'flex-col'} gap-4`}>
      <div className={`container flex ${className} ${hideUploader ? 'hidden' : 'block'}`}>
        <div className="flex-1 text-center">
          <div {...getRootProps({ style })}>
            <div className="h-auto m-auto">
              <DocumentAddIcon className="mx-auto h-9 w-9 text-gray-400 text-sm" strokeWidth={1} />
              <div className="text-sm text-gray-600">
                <div className="text-gray-500">
                  <Typography variant="text-base">
                    Drag and drop your files here or click to upload
                  </Typography>
                  <RenderIf isTrue={!!message}>
                    <p className="text-xs text-gray-500">{message}</p>
                  </RenderIf>
                  <RenderIf isTrue={showProgressBar}>
                    <div className="h-3 relative max-w-xl rounded-full overflow-hidden mt-4">
                      <div className="w-full h-full bg-gray-200 absolute"></div>
                      <div ref={progressBarRef} className="h-full bg-blue-400 relative w-0"></div>
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={fileRejections.length > 0}>
                    <p className="text-sm text-red-600">{`${errorFiles} ${
                      fileRejections.length > 1 ? 'were' : 'was'
                    } rejected as ${
                      fileRejections.length > 1 ? 'they' : 'it'
                    } failed one or more criteria`}</p>
                  </RenderIf>
                  <input {...getInputProps()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RenderIf isTrue={hasFiles}>
        <div className={isHorizontalAlignment ? 'w-1/2' : 'w-full'}>
          <Card header={header}>
            {files.map((file: FileWithPath, index) => (
              <div key={index} role="list" className="divide-y divide-gray-200">
                <div className="px-3 py-2 bg-white text-xs text-gray-500 font-medium">
                  <div className="flex items-center justify-between">
                    <div
                      className={`flex flex-col ${DocumentTypeComponent ? 'w-4/12' : 'w-11/12'}`}
                    >
                      <div className="truncate">
                        <span className="font-bold">Name: </span>
                        {file.path}
                      </div>
                      <div className="truncate mt-1">
                        <span className="font-bold">Size: </span>
                        {formatBytes(file?.size)}
                      </div>
                    </div>
                    <div
                      className={`flex justify-center items-center ${
                        DocumentTypeComponent ? 'w-8/12' : 'w-1/12'
                      }`}
                    >
                      <div className="flex flex-shrink-0">
                        <Button
                          disabled={disabled}
                          onClick={removeFile(file)}
                          className="h-4 w-4 ml-1 border-transparent rounded-full focus:outline-none"
                          icon={<TrashIcon className="h-3 w-3 cursor-pointer" />}
                          defaultClass={false}
                        />
                      </div>

                      <RenderIf isTrue={!!DocumentTypeComponent}>
                        <div className="ml-2">
                          <DocumentTypeComponent fileIndex={index} />
                        </div>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        </div>
      </RenderIf>
    </div>
  );
};

export default FileUpload;
