import { useMutation } from 'react-query';

import { appEvent } from 'tools/segment';
import { EventsNameEnum } from 'types/events';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const createShipmentTackingMutation = () => {
  return useMutation((data: any) => http.post(`${API_ENDPOINTS.TRACK_SHIPMENT}`, data), {
    onSuccess: (data) => {
      appEvent(EventsNameEnum.Tracking_CreateTracking, {
        tracking_id: data.data.id
      });
    }
  });
};

export default createShipmentTackingMutation;
