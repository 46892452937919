// TODO: remove useManageXXXXX hooks because they are not breaking the react/hooks rule and creating boilerplate code
import { useAddWorkflowsMutation } from '@services/api/workflows/use-add-workflows';
import { useBulkDeleteWorkflowsMutation } from '@services/api/workflows/use-bulk-delete-workflows';
import { useDeleteWorkflowMutation } from '@services/api/workflows/use-delete-workflow';
import { useStore } from '@store/useStore';

const useManageWorkflows = () => {
  // states
  const selectedWorkflowIds = useStore((state) => state.selectedWorkflowIDs);

  // hooks
  const { mutateAsync: deleteWorkflowMutation } = useDeleteWorkflowMutation();
  const { mutateAsync: bulkDeleteWorkflowsMutation } = useBulkDeleteWorkflowsMutation();
  const { mutateAsync: addWorkflow } = useAddWorkflowsMutation();

  const deleteWorkflow = async (id) => {
    await deleteWorkflowMutation(id);
  };

  const bulkDeleteWorkflows = async () => {
    const inputs = {
      delete_data: JSON.stringify(selectedWorkflowIds)
    };

    await bulkDeleteWorkflowsMutation(inputs);
  };

  const addWorkflows = async ({ inputs }) => {
    let modifiedInputs = { ...inputs };
    delete modifiedInputs.id;

    return await addWorkflow(modifiedInputs);
  };

  return { addWorkflows, deleteWorkflow, bulkDeleteWorkflows };
};

export default useManageWorkflows;
