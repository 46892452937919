import { useQuery } from 'react-query';
import { Payments, PaymentsQueryOptionsType, ReactQueryOptionsType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { queryString } from '../utils/query-string';

export const fetchPayments = async ({ queryKey }: any) => {
  const [_key, _params] = queryKey;
  const { data } = await http.get(`${_key}${queryString(_params)}`);
  return data;
};
export const usePaymentsQuery = (
  options?: PaymentsQueryOptionsType,
  reactQueryOptions?: ReactQueryOptionsType
) => {
  return useQuery<Payments, Error>(
    [API_ENDPOINTS.PAYMENTS, options],
    fetchPayments,
    reactQueryOptions
  );
};
