const shipmentDefaultFieldMapping = {
  Name: 'name',
  Status: 'status',
  Upcoming: 'Upcoming Task',
  Shipper: 'Shipper',
  Consignee: 'Consignee',
  'Tracking Number': 'Tracking Number',
  Carrier: 'Carrier',
  'Arrival Date': 'Arrival Date',
  'Gate Out Date': 'Gate Out Date',
  'Tracking Status': 'Tracking Status',
  'Tracking Last Updated At': 'Tracking Last Updated At',
  'Created At': 'created_at',
  'Updated At': 'updated_at',
  Buyer: 'Buyer',
  'Method of Shipping': 'method_of_shipping',
  'Type of Shipment': 'type_of_shipment',
  Incoterms: 'incoterms',
  'Terms of Payment': 'terms_of_payment',
  'Cargo Ready Date': 'cargo_ready_date',
  Currency: 'currency',
  'Country of Origin': 'country_of_origin',
  'Country of Destination': 'country_of_destination',
  'Port of Loading': 'port_of_loading',
  'Port of Discharge': 'port_of_discharge',
  'Departure Date': 'departure_date',
  'Payment Total': 'payment_total',
  'Payment Open': 'payment_open',
  'Payment Due Date': 'payment_due_date',
  Vessel: 'Vessel',
  'Freight Forwarder': 'Freight Forwarder',
  'Place of Delivery': 'place_of_delivery'
};

const paymentStatus = {
  Unpaid: 'unpaid',
  'Fully Paid': 'fully_paid'
};

const shipmentTypes = {
  Import: 'import',
  Export: 'export'
};

const ignoredFilterTypes = ['direction', 'sort', 'page', 'shipment_name', 'current_tab'];

const allTrackingFilterTypes = {
  direction: 'direction',
  sort: 'sort',
  page: 'page',
  search_query: 'search_query'
};

const hiddenColumnsDefault = [
  'id',
  'inputId',
  'Upcoming Task',
  'Upcoming Task_url',
  'Shipper_url',
  'Shipper_archived',
  'Consignee_url',
  'Consignee_archived',
  'Buyer_url',
  'Buyer_archived',
  'Freight Forwarder_url',
  'Freight Forwarder_archived',
  'Tracking Number_url'
];

export {
  shipmentDefaultFieldMapping,
  paymentStatus,
  shipmentTypes,
  ignoredFilterTypes,
  allTrackingFilterTypes,
  hiddenColumnsDefault
};
