import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

async function updatePaymentDetails(inputs: any) {
  const id = inputs.id;
  delete inputs.id;
  return http.put(
    `${API_ENDPOINTS.PAYMENTS_DETAILS}/${id}`,
    jsonToFormData({ schema: 'payment_detail', inputs })
  );
}

export const useUpdatePaymentDetailsMutation = () => {
  return useMutation((inputs: any) => updatePaymentDetails(inputs), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Payment details were updated.'
      });
    }
  });
};
