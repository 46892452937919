export const taskStatusBadgeVariantMap = {
  Upcoming: 'info',
  'In Progress': 'warning',
  'In Review': 'danger',
  Completed: 'success'
};

export const tasksMapping = {
  'Task Actions': 'task_actions',
  Name: 'name',
  'Shipment Name': 'shipment_name',
  Status: 'status',
  'Completed On': 'finished_date',
  'Due Date': 'target_date',
  'Assigned Email': 'assignee_email',
  Reviewer: 'reviewer_email'
};

export const hiddenColumns = [
  'shipment_id',
  'id',
  'url',
  'is_finished',
  'is_file_upload',
  'filename_url',
  'description',
  'start_date',
  'assignee_id',
  'reviewer_id',
  'creator_user_id',
  'filename',
  'able_to_edit',
  'able_to_delete',
  'plan_id'
];
