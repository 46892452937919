import useRequest from '@services/api';
import Script from 'next/script';
import { FC } from 'react';

declare global {
  export namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': {
        'pricing-table-id': string;
        'publishable-key': string;
        'client-reference-id'?: string;
      };
    }
  }
}

export const StripePricingTable: FC<{
  pricingTableId: string;
  publishableKey: string;
  clientReferenceId?: string;
}> = ({ pricingTableId, publishableKey }) => {
  let { data } = useRequest.user.get();

  if (!pricingTableId || !publishableKey) return null;
  return (
    <div className="py-10">
      <Script async strategy="lazyOnload" src="https://js.stripe.com/v3/pricing-table.js" />
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
        client-reference-id={data.user.id}
      />
    </div>
  );
};
