import cn from 'classnames';
import { FC } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

interface Props {
  height?: string;
  width?: string;
  removeBackground?: boolean;
  centerFullScreen?: boolean;
  absolute?: boolean;
  opacity?: boolean;
  fixed?: boolean;
}

const Loading: FC<Props> = ({
  height,
  width,
  removeBackground = false,
  centerFullScreen = false,
  absolute = false,
  fixed = false,
  opacity
}) => {
  const containerClassName = cn('min-h-full flex flex-col z-[9999]', {
    'bg-white': !removeBackground,
    'flex h-screen': centerFullScreen,
    'absolute w-full h-full': absolute,
    'fixed w-full h-full': fixed,
    'opacity-60': opacity
  });
  return (
    <>
      <div className={containerClassName} style={{ height, width }}>
        <div className="m-auto">
          <div className="flex items-center justify-center">
            <ScaleLoader color="#34d399" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
