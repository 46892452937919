const CloseIcon = ({ className }: { className: string }) => {
  return (
    <svg width="8" height="8" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1 7 6-6M1 1l6 6"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
