import ModalView from '@components/feedback/modal-view';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { FC } from 'react';
import AddCustomFieldForm from '../forms/add-custom-field-form';
import ModalFooter, { ModalFooterProps } from '../modal-footer';

const AddCustomFieldModal = () => {
  // states
  const modalState = useStore((state) => state.modalState);

  // const
  const entityType = modalState?.[0]?.entityType;
  const modalMode = modalState?.[0]?.mode;

  const Footer: FC<ModalFooterProps> = (props) => (
    <ModalFooter
      modalId={ModalIds.ADD_CUSTOM_FIELD}
      mainButtonText={`${modalMode === 'edit' ? 'Update' : 'Add'} Custom Field`}
      mainButtonType="submit"
      {...props}
    />
  );

  return (
    <ModalView
      header={`${
        modalMode === 'edit' ? 'Update' : 'Add new'
      } ${entityType.toLowerCase()} custom field`}
    >
      <AddCustomFieldForm
        Footer={Footer}
        entityType={entityType}
        containerClass="flex flex-col divide-y"
      />
    </ModalView>
  );
};

export default AddCustomFieldModal;
