export interface CommonStoreState {
  rootFormValues?: any;
  contactMasterState?: {
    matchedFields: object[];
    selectedValues: object;
    currentValues?: object;
    onCleanState?: () => void;
    fullValues?: object;
    allowCustomFields?: boolean;
    requiredFields?: any;
  };
}

const initialCommonsState: CommonStoreState = {
  rootFormValues: [],
  contactMasterState: {
    matchedFields: null,
    selectedValues: null,
    currentValues: null,
    fullValues: null,
    allowCustomFields: true,
    requiredFields: null,
    onCleanState: () => null
  }
};

export const createCommonsSlice = (set) => ({
  ...initialCommonsState,
  setRootFormValues: (formId = '', values = {}) =>
    set(
      (state) => ({
        rootFormValues: state.rootFormValues.map((item) =>
          Object.keys(item).find((id) => id === formId)
        )
          ? [
              ...state.rootFormValues.filter((item) =>
                Object.keys(item).find((id) => id !== formId)
              ),
              { [formId]: values }
            ]
          : [{ [formId]: values }]
      }),
      false,
      'setRootFormValues'
    ),
  setContactMasterState: (update) =>
    set(
      (state) => ({
        contactMasterState: { ...state.contactMasterState, ...update }
      }),
      false,
      'setContactMasterState'
    )
});
