import { WorkflowsResponseType } from '../../types';

import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

export const getAllWorkflows = (props?: queryOptions) => {
  return useQuery<WorkflowsResponseType, Error>(
    [API_ENDPOINTS.WORKFLOWS, props?.params],
    queryFetch,
    props?.options
  );
};

export default getAllWorkflows;
