import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const fetchLandedCost = (
  { productId, params: { currency } },
  options?: ReactQueryOptionsType
): any => {
  return useQuery(
    [API_ENDPOINTS.GET_LANDED_COST(productId), { currency }],
    queryFetch,
    options
  ) as any;
};
export default fetchLandedCost;
