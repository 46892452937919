import { Children } from 'react';

const RenderIf = ({ children, isTrue }) => {
  const thereAreTwoChildren = Children.toArray(children).length === 2;

  if (thereAreTwoChildren) {
    const ChildOne = Children.toArray(children)[0];
    const ChildTwo = Children.toArray(children)[1];
    return isTrue ? ChildOne : ChildTwo;
  }

  return isTrue ? children : null;
};

export default RenderIf;
