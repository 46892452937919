import TooltipIcon from '@assets/svg/TooltipIcon';
import RenderIf from '@components/common/render-if';
import Tooltip from '@components/general/tooltip';
import { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormInput from './form-input';
import { InputProps } from './input';

export interface CheckboxProps extends InputProps {
  label?: string;
  labelClass?: string;
  tooltipText?: string;
  tooltipIcon?: JSX.Element;
  onChangeCheckbox?: (value: boolean) => void;
  checked?: boolean;
  type?: 'checkbox' | 'toggle';
  containerClass?: string;
  inputClass?: string;
  value?: any;
  align?: 'vertical' | 'horizontal';
  inlineEditable?: boolean;
  onInlineSubmit?: ({ name }: { name?: string }) => void;
  setInputName?: (empty: string) => void;
  isSelected?: boolean;
}

const FormInputCheckboxOrToggle: FC<CheckboxProps> = ({
  label,
  labelClass,
  tooltipText,
  tooltipIcon,
  name,
  type,
  containerClass,
  inputClass,
  onChangeCheckbox,
  checked = false,
  disabled = false,
  value,
  align = 'vertical',
  isSelected = false,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>();
  const [isChecked, setIsChecked] = useState(checked);
  const { setValue } = useFormContext();
  const isHorizontalAlignment = align === 'horizontal';

  const onChangeWrapper = () => {
    setValue(name, !isChecked, { shouldValidate: true });
    setIsChecked(!isChecked);
    if (onChangeCheckbox) onChangeCheckbox(!isChecked);
  };

  useEffect(() => {
    if (!isSelected) return;
    setIsChecked(true);
    setValue(name, value, { shouldValidate: true });

    return () => {
      setIsChecked(false);
    };
  }, [isSelected]);

  useEffect(() => {
    setIsChecked(checked);
    return () => {
      setIsChecked(false);
    };
  }, [checked]);

  const CheckBoxInput = ({ label, type, containerClass }) => (
    <FormInput
      label={label}
      name={name}
      id={name}
      inputRef={ref}
      hidden={rest.hidden}
      disabled={disabled}
      customOnChange={onChangeWrapper}
      checked={isChecked}
      type={type}
      containerClass={containerClass}
      inputClass={inputClass}
      showError={rest.showError}
      value={value}
      inlineEditable={rest.inlineEditable}
      readOnly={rest.readOnly}
      onInlineSubmit={rest.onInlineSubmit}
      setInputName={rest.setInputName}
      labelClass={labelClass}
      autoFocus={rest.autoFocus}
    />
  );

  return isHorizontalAlignment ? (
    <div className={`${containerClass ? containerClass : ''} flex flex-row items-center text-sm`}>
      <CheckBoxInput label={false} type="checkbox" containerClass="w-4 mt-0" />
      <label className={`pl-2 ${labelClass}`}>{label}</label>
      <RenderIf isTrue={!!tooltipText}>
        <Tooltip className="pl-1" description={tooltipText}>
          {tooltipIcon ? tooltipIcon : <TooltipIcon />}
        </Tooltip>
      </RenderIf>
    </div>
  ) : (
    <CheckBoxInput label={label} type={type} containerClass={containerClass} />
  );
};

export default FormInputCheckboxOrToggle;
