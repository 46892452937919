import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';
import { queryOptions } from '../utils/use-query-wrapper';

const getGeneratedDocument = (
  { id },
  props?: queryOptions
): any /*TODO: Add Document response type */ => {
  return useQuery(
    [API_ENDPOINTS.GET_GENERATED_DOCUMENTS(id), props?.params],
    queryFetch,
    props?.options
  );
};

export default getGeneratedDocument;
