import RenderIf from '@components/common/render-if';
import { PencilIcon } from '@heroicons/react/solid';
import { FC, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { default as CreatableSelect } from 'react-select/creatable';
import FormInputContainer, { FormInputContainerProps } from './form-input-container';

const customStyles = {
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    fontSize: '.875rem',
    boxShadow: 'none',
    borderColor: isFocused
      ? 'rgba(138, 213, 189, var(--tw-border-opacity));'
      : 'rgba(239, 239, 239, var(--tw-border-opacity))',
    '&:hover': {
      borderColor: isFocused ? 'rgba(138, 213, 189, var(--tw-border-opacity));' : 'inherit'
    },
    backgroundColor: 'rgba(245, 247, 248, 1)',
    paddingLeft: '2.5rem',
    color: 'rgba(153, 171, 186)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    pointerEvents: isDisabled && 'auto'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'rgb(54 73 101)'
  }),
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      fontSize: '.875rem',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: isFocused ? 'rgba(243,244,246,1)' : null,
      '&:hover': {
        backgroundColor: 'rgba(243,244,246,1)'
      },
      color: `rgba(107, 114, 128, ${isDisabled ? 0.5 : 1})`
    };
  },
  dropdownIndicator: (styles, { isDisabled }) => {
    return {
      ...styles,
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: 'rgb(156 163 175)',
      svg: {
        height: '1rem',
        width: '1rem'
      }
    };
  },
  clearIndicator: (styles) => {
    return {
      ...styles,
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      cursor: 'pointer',
      color: 'rgb(156 163 175)',
      svg: {
        height: '1rem',
        width: '1rem'
      }
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
};

// Custom Option component for adding details
const Option = (props) => {
  const { label, data } = props;
  return (
    <components.Option {...props}>
      <p>{label}</p>

      <div className="flex-col font-semibold">
        <p>{data?.company}</p>
        <p className="text-xs">{data?.descriptionForLabel}</p>
      </div>
    </components.Option>
  );
};

export interface FormAutocompleteV2Props extends FormInputContainerProps {
  labelClass?: string;
  inputClass?: string;
  readOnlyChildren?: JSX.Element | string;
  readOnlyLink?: string;
  returnItemKey?: string;
  icon?: JSX.Element;
  allowClear?: boolean;
  required?: boolean;
  loading?: boolean;
  allowCreate?: boolean;
  customCreateLabel?: string | boolean;
  options: any[];
  defaultValue?: any;
  placeholder?: string;
  autoFocus?: boolean;
  clearValue?: boolean;
  ref?: any;
  onBlur?: () => void;
  onCreate?: (value) => void;
  onEdit?: (value) => void;
  onMenuOpen?: () => void;
  onChange?: (item, props) => void;
}

const FormAutocompleteV2: FC<FormAutocompleteV2Props> = ({
  containerClass = 'w-full',
  name,
  label,
  labelSize,
  labelClass,
  readOnly,
  inputClass,
  disabled,
  readOnlyChildren,
  readOnlyLink,
  returnItemKey = 'value',
  icon,
  allowClear = true,
  loading,
  allowCreate = true,
  options = [],
  placeholder,
  autoFocus,
  clearValue,
  onCreate,
  onEdit,
  onMenuOpen,
  onChange,
  value,
  customCreateLabel = false,
  ref,
  ...rest
}) => {
  // state
  const [selectValue, setSelectValue] = useState('');

  // // hooks
  const containerRef = useRef();
  const timeRef = useRef<NodeJS.Timeout>();
  const getValueName = options.find((item) => item.id === Number(value))?.name;
  const SelectComponent = allowCreate ? CreatableSelect : Select;
  const createOptionProps = allowCreate
    ? {
        ...(options?.length === 0 && { isValidNewOption: () => true }),
        ...(onCreate && { onCreateOption: (selectValue) => onCreate(selectValue) }),
        ...(customCreateLabel && {
          formatCreateLabel: (inputText) =>
            `${typeof customCreateLabel === 'string' ? `${customCreateLabel}` : ''} "${inputText}"`
        })
      }
    : null;

  const onChangeHandle = (item, props) => {
    setSelectValue(item);
    onChange(item, props);

    if (props.action === 'clear' || props.action === 'remove-value') {
      onHandleClearValue();
      return;
    }
  };
  const onHandleClearValue = () => {
    setSelectValue('');
  };

  const ReadOnly = () => (
    <div>
      <div>{getValueName || value}</div>
      <RenderIf isTrue={readOnlyChildren}>
        <div className="mt-2">{readOnlyChildren}</div>
      </RenderIf>
    </div>
  );

  useEffect(() => {
    if (value && options.length > 0) {
      const selectedData = options.find((data) => data[returnItemKey] == value);
      if (selectedData) setSelectValue(selectedData);
    }
  }, [value, options]);

  useEffect(() => {
    if (clearValue) {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      timeRef.current = setTimeout(() => {
        onHandleClearValue();
      }, 50);
    }
  }, [clearValue]);

  return (
    <FormInputContainer
      name={name}
      label={label}
      labelSize={labelSize}
      className={labelClass}
      readOnly={readOnly}
      readOnlyClassName={inputClass}
      readOnlyChildren={<ReadOnly />}
      value={getValueName || value}
      link={readOnlyLink}
      containerClass={containerClass}
      containerRef={containerRef}
      {...rest}
    >
      <div ref={containerRef} className={`relative w-full ${inputClass}`}>
        <SelectComponent
          classNamePrefix="form-select"
          inputId={name}
          name={name}
          ref={ref}
          isLoading={loading}
          id="react-select"
          placeholder={placeholder}
          isSearchable
          onMenuOpen={onMenuOpen}
          onBlur={rest.onBlur}
          options={options}
          value={selectValue}
          onChange={onChangeHandle}
          isDisabled={disabled}
          styles={customStyles}
          isClearable={allowClear}
          components={{
            Option
          }}
          menuPortalTarget={document.querySelector('body')}
          menuShouldBlockScroll={true}
          autoFocus={autoFocus}
          {...createOptionProps}
        />
        <RenderIf isTrue={icon}>
          <div
            className={`absolute top-[0.05rem] left-[0.05rem] h-9 flex items-center px-3 rounded-l-md border border-l-0 border-t-0 border-b-0 border-primary-grey-100 ${
              disabled ? 'cursor-not-allowed' : ''
            }`}
          >
            {icon}
          </div>
        </RenderIf>
        <RenderIf isTrue={selectValue && !disabled && onEdit}>
          <div className="absolute top-[0.05rem] right-11 h-9 flex items-center px-3">
            <a type="button" className="cursor-pointer" onClick={() => onEdit(selectValue)}>
              <PencilIcon className="h-3 w-3 text-gray-400" />
            </a>
          </div>
        </RenderIf>
      </div>
      <RenderIf isTrue={readOnlyChildren}>
        <div className="mt-2 whitespace-pre-wrap">{readOnlyChildren}</div>
      </RenderIf>
    </FormInputContainer>
  );
};
export default FormAutocompleteV2;
