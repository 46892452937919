import { WorkflowsDetailQueryOptionsType } from '@services/types';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const duplicateWorkflowMutation = () => {
  return useMutation((data: WorkflowsDetailQueryOptionsType) =>
    http.post(`${API_ENDPOINTS.DUPLICATE_WORKFLOW(data.id)}`, data)
  ) as any;
};

export default duplicateWorkflowMutation;
