// TODO: remove useManageXXXXX hooks because they are not breaking the react/hooks rule and creating boilerplate code
import useRequest from '@services/api';
import { useBulkDeleteShipmentsMutation } from '@services/api/shipments/use-bulk-delete-shipments';
import { useDeleteShipmentMutation } from '@services/api/shipments/use-delete-shipment';
import { useDeleteShipmentShareMutation } from '@services/api/shipments/use-delete-shipment-share';
import { useImportShipmentCSVMutation } from '@services/api/shipments/use-import-shipment-csv';
import { useShareShipmentMutation } from '@services/api/shipments/use-share-shipment';
import { useStore } from '@store/useStore';
import useManageParams from './use-manage-params';

const useManageShipments = () => {
  // states
  const selectedShipmentIDs = useStore((state) => state.selectedShipmentIDs);

  // hooks
  const { params } = useManageParams();

  // const
  const shipmentsQueryParams = {
    page: params?.page,
    shipment_name: params?.shipment_name,
    shipment_status: params?.shipment_status,
    shipment_type: params?.shipment_type,
    shipment_tracings: params?.shipment_tracings,
    payment_status: params?.payment_status,
    direction: params?.direction,
    sort: params?.sort
  };

  const getShipments = () => {
    const { data, isLoading, refetch } = useRequest.shipments.get({
      params: shipmentsQueryParams,
      options: { keepPreviousData: true }
    });

    const pagination = !isLoading && data && data.pagination;
    const dataTable = !isLoading && data ? data.data : [];
    const importFields = !isLoading && data ? data.importFields : [];

    return { data: dataTable, isLoading, refetch, importFields, pagination };
  };

  const bulkDeleteShipmentsMutation = () => {
    const mutation = useBulkDeleteShipmentsMutation();
    const { mutateAsync: bulkDeleteShipmentsMutation } = mutation;

    const bulkDeleteShipments = async () => {
      try {
        const inputs = {
          delete_data: JSON.stringify(selectedShipmentIDs)
        };
        await bulkDeleteShipmentsMutation(inputs);
      } catch (err) {
        console.error(err);
      }
    };

    return { mutation, bulkDeleteShipments };
  };

  const deleteShipmentMutation = () => {
    const mutation = useDeleteShipmentMutation();
    const { mutateAsync: deleteShipmentMutation } = mutation;

    const deleteShipment = async (id) => {
      try {
        await deleteShipmentMutation(id);
      } catch (err) {
        console.error(err);
      }
    };

    return { mutation, deleteShipment };
  };

  const importShipmentCSVMutation = () => {
    const mutation = useImportShipmentCSVMutation();
    const { mutateAsync: importShipmentCSVMutation } = mutation;

    const importShipmentCSV = async ({ data, shipmentType }) => {
      try {
        const inputs = {
          import_data: data,
          shipment_type: shipmentType
        };
        await importShipmentCSVMutation(inputs);
      } catch (error) {
        console.error(error);
      }
    };

    return { mutation, importShipmentCSV };
  };

  const shareShipmentMutation = () => {
    const mutation = useShareShipmentMutation();
    const { mutateAsync: shareShipmentMutation } = mutation;

    const shareShipment = async (inputs) => {
      await shareShipmentMutation(inputs);
    };

    return { mutation, shareShipment };
  };

  const deleteShipmentShareMutation = () => {
    const mutation = useDeleteShipmentShareMutation();
    const { mutateAsync: deleteShipmentShareMutation } = mutation;

    const deleteShipmentShare = async (id) => {
      await deleteShipmentShareMutation(id);
    };

    return { mutation, deleteShipmentShare };
  };

  return {
    shipmentsQueryParams,
    getShipments,
    bulkDeleteShipmentsMutation,
    deleteShipmentMutation,
    importShipmentCSVMutation,
    shareShipmentMutation,
    deleteShipmentShareMutation
  };
};

export default useManageShipments;
