import cn from 'classnames';
import { FC, ReactNode } from 'react';

export interface TooltipPropsType {
  className?: string;
  children?: string | ReactNode;
  disabled?: boolean;
  description?: string | ReactNode;
  descriptionTopMargin?: string;
  descriptionClass?: string;
  onClick?: () => void;
  align?: 'right' | 'left' | 'top' | 'bottom';
  style?: any;
}

const Tooltip: FC<TooltipPropsType> = ({
  disabled,
  className,
  children,
  description,
  descriptionTopMargin,
  descriptionClass,
  onClick,
  align,
  style
}) => {
  const rootClassName = cn(className, {
    'has-tooltip': !disabled,
    relative: !!align
  });

  const descriptionClassName = cn(
    `tooltip rounded-md px-2 py-1 text-primary-navy-400 bg-white border stroke text-xs z-100 mb-2 ${descriptionClass}`,
    {
      invisible: !!disabled,
      '-mt-8': !descriptionTopMargin,
      [descriptionTopMargin]: descriptionTopMargin,
      'left-0': align === 'left',
      'right-0': align === 'right',
      'top-0': align === 'top',
      descriptionClass: !!descriptionClass,
      'w-max': !descriptionClass?.includes('w-')
    }
  );

  return (
    <span onClick={onClick} className={rootClassName}>
      <span className={descriptionClassName} style={style}>
        {description}
      </span>
      {children}
    </span>
  );
};

export default Tooltip;
