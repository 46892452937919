import Form from '@components/dataEntry/form';
import FormInputImage from '@components/dataEntry/form-input-image';
import ModalView from '@components/feedback/modal-view';
import { useUserProfile } from '@services/api/user/get-user-profile';
import { useUpdateUserMutation } from '@services/api/user/use-update-user';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useContextUI } from 'states/ui';
import ModalFooter from '../modal-footer';

const AddStampForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [selectedFile, setSelectedFile] = useState({});
  const [defaultValues] = useState({
    stamp: ''
  });

  // hooks
  const { data, isLoading } = useUserProfile();
  const [_, { closePortal }] = useContextUI();

  const queryClient = useQueryClient();
  const {
    query: { id: documentId }
  } = useRouter();
  const { id } = !isLoading && data;
  const { mutateAsync: updateUser } = useUpdateUserMutation(id);

  // TODO: add validation schema
  // const
  // const validationSchema = yup.object().shape({
  //   stamp: yup.mixed().test('required', 'Stamp is required', (file) => {
  //     if (file) return true;
  //     return false;
  //   })
  // });

  // actions
  const handleSubmit = () => {
    try {
      onSubmit(selectedFile);
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (inputs: any) => {
    try {
      await updateUser(inputs);
      queryClient.refetchQueries([API_ENDPOINTS.GENERATED_DOCUMENTS, { id: documentId }]);
      closePortal();
    } catch (err) {
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  const methods = useForm({
    defaultValues
    // resolver: yupResolver(validationSchema)
  });

  const onSetStamp = (stamp) => {
    setSelectedFile(stamp);
  };

  return (
    <ModalView header="Add Stamp">
      <Form methods={methods} errorsServerValidation={errorsServerValidation}>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full px-3 mb-3">
            <FormInputImage label="Stamp/Seal" name="stamp" onChangeImage={onSetStamp} />
          </div>
        </div>
        <ModalFooter
          onClick={methods.handleSubmit(handleSubmit)}
          modalId={ModalIds.ADD_STAMP_FORM}
          mainButtonText="Add Stamp/Seal"
        />
      </Form>
    </ModalView>
  );
};

export default AddStampForm;
