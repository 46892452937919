import CloseIcon from '@assets/svg/CloseIcon';
import { ToastErrorIcon, ToastSuccessIcon, ToastWarningIcon } from '@assets/svg/ToastIcons';
import { ReactNode } from 'react';
import toast, { LoaderIcon, Renderable, Toast, ValueOrFunction } from 'react-hot-toast';

type Message = ValueOrFunction<Renderable, Toast>;

export type NotificationItem = {
  icon?: ReactNode;
  entity?: string;
  description?: Message;
  type: 'error' | 'success' | 'warning' | 'custom' | 'loading';
};

export const toastNotification = ({ entity, type, description }: NotificationItem) => {
  // Hard remove: react-hot-notification bug
  // https://github.com/timolins/react-hot-toast/issues/128
  setTimeout(() => {
    toast.remove();
  }, 2000);
  return toastNotificationByType({ entity, type, description });
};

const toastNotificationByType = ({ type, entity, description }: NotificationItem) => {
  const colorSetByType = {
    success: {
      iconBackground: '#E6F6F1',
      semanticBackground: '#00A46F'
    },
    error: {
      iconBackground: '#FFE8E6',
      semanticBackground: '#FF1C00'
    },
    warning: {
      iconBackground: '#FFF7E6',
      semanticBackground: '#FFAB00'
    },
    loading: {
      iconBackground: '#DFE5EA',
      semanticBackground: '#DFE5EA'
    }
  };

  const iconSetByType = {
    success: <ToastSuccessIcon className="text-primary-green-500" />,
    error: <ToastErrorIcon className="text-semantic-danger-500" />,
    warning: <ToastWarningIcon className="text-semantic-warning-500" />,
    loading: <LoaderIcon />
  };

  const onErrorStyles = {
    width: '20px',
    height: '20px',
    backgroundColor: '#FFE8E6',
    border: '1px solid #FFB9B0'
  };

  toast.custom((t) => (
    <div
      className={`absolute w-full bg-neutral-white-500 rounded flex 
        ${t.visible ? 'animate-enter' : 'animate-leave'}
        `}
      style={{
        maxWidth: '387px',
        boxShadow: '0px 4px 24px rgba(64, 64, 64, 0.15)',
        cursor: 'pointer'
      }}
    >
      <div className="flex-1 p-4">
        <div className="flex items-start">
          <div
            className="rounded flex justify-center items-center p-1.5"
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: colorSetByType[type].iconBackground
            }}
          >
            {iconSetByType[type]}
          </div>
          <div className="ml-3 flex-1">
            <p className="text-sm font-medium text-primary-navy-400">
              {entity ? `${entity} ${type}` : type.charAt(0).toUpperCase() + type.slice(1)}
            </p>
            <p className="text-xs text-primary-grey-500">{description}</p>
          </div>
          <div
            onClick={() => toast.remove()}
            className="rounded flex justify-center items-center p-1.5"
            style={
              type === 'error'
                ? { ...onErrorStyles }
                : { width: '20px', height: '20px', backgroundColor: '#F5F7F8' }
            }
          >
            <CloseIcon
              className={type === 'error' ? 'text-semantic-danger-500' : 'text-primary-grey-500'}
            />
          </div>
        </div>
      </div>
      <div>
        <span
          className="absolute rounded-bl rounded-br left-0 bottom-0"
          style={{
            backgroundColor: colorSetByType[type].semanticBackground,
            width: '100%',
            height: '2px'
          }}
        />
      </div>
    </div>
  ));
};
