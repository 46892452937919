import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import LoaderWithIcon, { LoaderWithIconProps } from '@components/loaders/loader-with-icon';
import cn from 'classnames';
import { FC } from 'react';

export type ModalViewProps = {
  header?: string | JSX.Element;
  children: JSX.Element;
  footer?: JSX.Element;
  loading?: boolean;
  headerClass?: string;
  removePadding?: boolean;
} & LoaderWithIconProps;

const ModalView: FC<ModalViewProps> = ({
  header,
  children,
  footer,
  loading = false,
  icon,
  headerClass,
  loadingText = null,
  removePadding = false // remove after change modal view structure.
}) => {
  const divHeaderClass = cn(`text-primary-navy-400 truncate w-xl`, headerClass);
  return (
    <div>
      <RenderIf isTrue={header}>
        <div className="w-full px-4 py-3 border-b border-gray-200 ">
          <Typography variant="text-base" className={divHeaderClass}>
            {header}
          </Typography>
        </div>
      </RenderIf>
      <RenderIf isTrue={loading}>
        <LoaderWithIcon absolute icon={icon} loadingText={loadingText} />
      </RenderIf>
      <div
        id="modal-body"
        className={`overflow-x-hidden overflow-y-scroll p-4 ${
          footer || removePadding ? '' : 'pb-16'
        }`}
        style={{ maxHeight: '70vh' }}
      >
        {children}
      </div>
      <RenderIf isTrue={footer}>{footer}</RenderIf>
    </div>
  );
};

export default ModalView;
