import { useMasterCustomFieldsQuery } from '@services/api/master-custom-fields/get-master-custom-fields';
import { MasterCustomFieldsResponseType } from '@services/types';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useModal from './use-modal';

const useContactMaster = (props: {
  contactMasterId;
  getDataToCompare?: any;
  notifyMessage?: string;
  updateValues?: () => void;
  allowCustomFields?: boolean;
  requiredFields?: object;
}) => {
  const setContactMasterState = useStore((state) => state.setContactMasterState);
  const { openModal } = useModal();
  const setSelectedContactMasterId = useStore((state) => state.setSelectedContactMasterId);
  const data = props?.getDataToCompare();

  const { pathname } = useRouter();

  const isGeneratedDocuments = pathname?.includes('/documents');

  const setDefaultStates = () => {
    setSelectedContactMasterId('');
    setContactMasterState({
      matchedFields: null,
      selectedValues: null,
      currentValues: null,
      notify: false,
      notifyMessage: null,
      fullValues: null,
      allowCustomFields: true
    });
  };

  const onHandleCleanState = () => {
    setDefaultStates();
  };

  // master custom field
  const { data: masterCustomFields, isLoading } = useMasterCustomFieldsQuery(
    {
      id: props.contactMasterId,
      is_react: true
    },
    {
      enabled: !!props.contactMasterId
    }
  );

  useEffect(() => {
    if (!data && !masterCustomFields) return;
    const masterCustomFieldData =
      !isLoading && masterCustomFields
        ? masterCustomFields
        : ({} as MasterCustomFieldsResponseType);
    const masterContactFields = Object.entries(masterCustomFieldData).reduce(
      (acc, [key, { value, is_default_field }]) => {
        const customFieldsKey = !is_default_field;
        const dataKey = customFieldsKey ? key : key.replaceAll(' ', '_').toLowerCase();

        // pick default fields only in generated documents page
        if (isGeneratedDocuments) {
          if (!is_default_field) {
            return acc;
          }
        }

        if (data[dataKey] === value) {
          acc = acc.filter((item) => Object.values(item).map((item) => item !== value));
        }
        acc = [...acc, { [dataKey]: value }];
        return acc;
      },
      []
    );

    const getInputsWithDifferentValues = masterContactFields.filter(
      (x) => Object.values(x)[0] !== data[Object.keys(x)[0]]
    );
    const hasField = getInputsWithDifferentValues.length > 0;
    if (hasField) {
      setContactMasterState({
        matchedFields: getInputsWithDifferentValues,
        onCleanState: onHandleCleanState,
        currentValues: data,
        requiredFields: props?.requiredFields
      });

      openModal({
        id: ModalIds.CONTACT_MASTER_FORM,
        isOpen: true
      });
    }
  }, [masterCustomFields]);
};

export default useContactMaster;
