export const ShipmentInputsMappedObject = {
  Name: {
    name: 'name',
    label: 'Name',
    type: 'text',
    autoFocus: true,
    isRequired: true
  },
  Shipper: {
    name: 'shipper_id',
    entity: 'contact',
    label: 'Shipper',
    type: 'autocomplete',
    ContactMasterProps: {
      updateValuesWithContactMaster: true
    },
    allowClear: true,
    allowEdit: true,
    isCreateNew: true,
    isEditable: true,
    readonlyLink: true,
    includeArchived: true,
    autoFocus: true
  },
  Consignee: {
    name: 'consignee_id',
    entity: 'contact',
    type: 'autocomplete',
    label: 'Consignee',
    allowEdit: true,
    isCreateNew: true,
    allowClear: true,
    isEditable: true,
    readonlyLink: true,
    includeArchived: true,
    autoFocus: true
  },
  Buyer: {
    name: 'buyer_id',
    entity: 'contact',
    type: 'autocomplete',
    label: 'Buyer',
    allowEdit: true,
    isCreateNew: true,
    isEditable: true,
    readonlyLink: true,
    includeArchived: true,
    autoFocus: true
  },
  'Freight Forwarder': {
    name: 'freight_forwarder_id',
    entity: 'contact',
    type: 'autocomplete',
    label: 'Freight Forwarder',

    allowEdit: true,
    isCreateNew: true,
    isEditable: true,
    readonlyLink: true,
    includeArchived: true,
    autoFocus: true
  },
  Type: {
    name: 'ship_type',
    label: 'Type',
    type: 'select',
    optionsList: 'select-import-export',
    capitalize: true,
    autoFocus: true
  },
  Currency: {
    name: 'currency',
    label: 'Currency',
    type: 'select',
    optionsList: 'select-currency',
    autoFocus: true
  },
  'Type of Shipment': {
    name: 'type_of_shipment',
    label: 'Type of Shipment',
    type: 'select',
    defaultValues: 'FCL',
    optionsList: 'select-shipment-type',
    autoFocus: true
  },
  'Method of Shipping': {
    name: 'method_of_shipping',
    label: 'Method of Shipping',
    type: 'select',
    defaultValues: 'SEA',
    optionsList: 'select-dispatch-method',
    autoFocus: true
  },
  Incoterms: {
    name: 'incoterms',
    label: 'Incoterms',
    type: 'text',
    autoFocus: true
  },
  'Terms of Payment': {
    name: 'terms_of_payment',
    label: 'Terms of Payment',
    type: 'textarea',
    autoFocus: true
  },
  'Cargo Ready Date': {
    name: 'cargo_ready_date',
    label: 'Cargo Ready Date',
    type: 'date',
    autoFocus: true
  },
  'Country of Origin': {
    name: 'country_of_origin',
    label: 'Country of Origin',
    type: 'country-dropdown',
    autoFocus: true
  },
  'Country of Destination': {
    name: 'country_of_destination',
    label: 'Country of Destination',
    type: 'country-dropdown',
    autoFocus: true
  },
  'Port of Discharge': {
    name: 'port_of_discharge',
    label: 'Port of Discharge',
    type: 'text',
    autoFocus: true
  },
  'Port of Loading': {
    name: 'port_of_loading',
    label: 'Port of Loading',
    type: 'text',
    autoFocus: true
  },
  'Departure Date': {
    name: 'departure_date',
    label: 'Departure Date',
    type: 'date',
    autoFocus: true
  },
  'Place of Delivery': {
    name: 'place_of_delivery',
    label: 'Place of Delivery',
    type: 'text',
    autoFocus: true
  }
};
