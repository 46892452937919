/* eslint-disable */
// TODO: Fix no-unsafe-optional-chaining eslint error
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import FormMultiSelectDropdown from '@components/dataEntry/form-multi-select-dropdown';
import ModalView from '@components/feedback/modal-view';
import { toastNotification } from '@hooks/toast-notification';
import useManageShipmentPackages from '@hooks/use-manage-shipment-packages';
import useManageShipmentProducts from '@hooks/use-manage-shipment-products';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useUpdateShipmentPackageMutation } from '@services/api/shipment-packages/use-update-shipment-package';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const PackingForm = () => {
  // states
  const modalState = useStore((state) => state.modalState);

  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [addedDefaultNetWeight, setAddedDefaultNetWeight] = useState(0);

  // hooks
  const { closeModalByPosition, closeAllModals } = useModal();
  const { getShipmentProducts } = useManageShipmentProducts();
  const { mutateAsync: addShipmentPackage } = useRequest.shipment_packages.create();
  const { mutateAsync: updateShipmentPackage } = useUpdateShipmentPackageMutation();
  const { getShipmentPackages } = useManageShipmentPackages();
  const queryClient = useQueryClient();
  const { data: organizationData } = useRequest.organization.get();

  // const
  const modalMode = modalState?.[0]?.mode;
  const shipmentPackageId = modalState?.[0]?.entityId;
  const isNewShipmentPackage = modalMode === 'add';
  const headerLabel = modalMode === 'edit' ? 'Edit Package' : 'Add Package';
  const isPopulateInitialValues = modalMode === 'edit';
  let packageDetails: Record<string, any> = {};

  const {
    query: { id: shipmentId }
  } = useRouter();

  // queries
  const { data: shipmentProducts } = getShipmentProducts(shipmentId);
  const {
    data: packageData,
    isLoading: packageLoading,
    packedShipmentProductIds
  } = getShipmentPackages(shipmentId);

  const readyForPackingProducts = shipmentProducts.filter(
    (product) => !packedShipmentProductIds.includes(product.id)
  );

  packageDetails =
    isPopulateInitialValues &&
    packageData &&
    packageData?.find((data) => data.id === shipmentPackageId);

  const containedProducts = isNewShipmentPackage
    ? readyForPackingProducts
    : [...readyForPackingProducts, ...packageDetails?.shipment_products];

  const validationSchema = yup.object({
    marks: yup.string().required('Marks is required'),
    quantity: yup
      .number()
      .typeError('Number of packages must be greater than 0')
      .min(1, 'Number of packages must be greater than 0'),
    kind: yup.string().required('Kind is required'),
    shipment_product_ids: yup.array().min(1, 'Product is required'),
    gross_weight: yup
      .number()
      .typeError('Gross weight must be greater than or equal to 0')
      .min(0, 'Gross weight must be greater than or equal to 0'),
    net_weight: yup
      .number()
      .typeError('Net weight must be greater than or equal to 0')
      .min(0, 'Net weight must be greater than or equal to 0'),
    volume: yup
      .number()
      .typeError('Volume must be greater than or equal to 0')
      .min(0, 'Volume must be greater than or equal to 0')
  });

  const fullLoading = packageLoading;

  // actions
  const getDefaultValues = () => {
    let productList = [];
    packageDetails?.shipment_products?.forEach(
      (product) => (productList = [...productList, product.id])
    );

    const defaultValues = {
      marks: isPopulateInitialValues ? packageDetails?.marks || '' : '',
      shipment_product_ids: isPopulateInitialValues ? productList || [] : [],
      volume: isPopulateInitialValues ? packageDetails?.volume || 0 : 0,
      kind: isPopulateInitialValues ? packageDetails?.kind || '' : '',
      quantity: isPopulateInitialValues ? packageDetails?.quantity || 0 : 0,
      gross_weight: isPopulateInitialValues ? packageDetails?.gross_weight || 0 : 0,
      description: isPopulateInitialValues ? packageDetails?.description || '' : ''
    };

    return defaultValues;
  };

  const [defaultValues, setDefaultValues] = useState({
    ...getDefaultValues()
  });

  const [addedProductIds, setAddedProductIds] = useState(defaultValues.shipment_product_ids);

  // multi-select placeholder
  const onSubmit = async (inputs: any) => {
    const modifiedInputs = {
      package: {
        ...inputs,
        shipment_id: shipmentId
      }
    };

    delete modifiedInputs.package.net_weight;

    const modifiedInputsForUpdate = {
      package: {
        ...modifiedInputs.package,
        id: packageDetails?.id
      }
    };

    try {
      if (isNewShipmentPackage) {
        await addShipmentPackage(modifiedInputs as any);
        queryClient.refetchQueries(API_ENDPOINTS.SHIPMENT_PACKAGES);
        toastNotification({
          type: 'success',
          description: 'Package added to shipment.'
        });
      } else {
        await updateShipmentPackage(modifiedInputsForUpdate);
      }

      setInitialValues();
      closeModalByPosition();
    } catch (err) {
      if (!err.response) {
        return;
      }
      const errors = err.response.data.errors;
      setErrorsServerValidation(errors);
    }
  };

  const setInitialValues = () => {
    setDefaultValues({ ...defaultValues });
    closeAllModals();
  };

  useEffect(() => {
    let totalNetWeight = 0.0;
    addedProductIds.map((id) => {
      const product = shipmentProducts.find((product) => product.id === id) || {};
      const netWeight = product.net_weight ? Number(product.net_weight) : 0;
      const quantity = product.quantity ? Number(product.quantity) : 0;
      totalNetWeight += netWeight * quantity;
    });
    setAddedDefaultNetWeight(totalNetWeight);
  }, [addedProductIds]);

  useEffect(() => {
    if (isPopulateInitialValues) {
      setDefaultValues(() => ({ ...defaultValues, shipment_product_ids: addedProductIds }));
    }
  }, [isPopulateInitialValues, addedProductIds]);

  const selectOptions = containedProducts.map((product) => ({
    value: product.id,
    label: product.product.name,
    descriptionForLabel: product.description
  }));

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
      >
        {({ onSubmitForm, isDirty, setValue }) => {
          const onChangeMultiSelect = (selected) => {
            if (selected.length <= 0) {
              setAddedProductIds([]);
              setValue('shipment_product_ids', []);
              return;
            }

            let productIds = [];
            selected?.forEach(({ value }) => {
              productIds = [...productIds, value];
            });
            setAddedProductIds(productIds);
            setValue('shipment_product_ids', productIds);
          };

          return (
            <>
              <div className="grid mb-4">
                <div className="mb-5">
                  <FormInput label="Marks" containerClass="col-span-2" name="marks" type="text" />
                </div>
                <div className="mb-5">
                  <FormMultiSelectDropdown
                    label="Products Contained"
                    name="shipment_product_ids"
                    options={selectOptions}
                    showSelectionSum
                    onChangeMultiSelect={onChangeMultiSelect}
                    defaultValue={addedProductIds}
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label="Net Weight (kg)"
                    containerClass="col-span-2"
                    name="net_weight"
                    value={String(addedDefaultNetWeight)}
                    disabled
                    type="number"
                    tooltipText="Net Weight is calculated from product net weights"
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label="Number of Packages"
                    containerClass="col-span-2"
                    name="quantity"
                    type="number"
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label="Kind of Packing"
                    containerClass="col-span-2"
                    name="kind"
                    type="text"
                    placeholder="CARTONS / PALLETS / BOXES / etc..."
                    tooltipText="CARTONS / PALLETS / BOXES / etc..."
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label="Package Gross Weight (kg)"
                    containerClass="col-span-2"
                    name="gross_weight"
                    type="number"
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label={`Measurements of Package (${organizationData?.volume_unit})`}
                    containerClass="col-span-2"
                    name="volume"
                    type="number"
                  />
                </div>
                <div className="mb-5">
                  <FormInput
                    label="Description"
                    containerClass="col-span-2"
                    name="description"
                    rows={3}
                    type="textarea"
                    placeholder={'Description Line 1\nDescription Line 2\nDescription Line 3'}
                  />
                </div>
                <ModalFooter
                  disabled={!isDirty}
                  onClick={onSubmitForm(onSubmit)}
                  modalId={ModalIds.PACKING_FORM}
                  mainButtonText={isNewShipmentPackage ? 'Add' : 'Update'}
                />
              </div>
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default PackingForm;
