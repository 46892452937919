import { FC } from 'react';
import Input from './input';

export type AdornmentPosition = 'end' | 'start' | 'both';
export type PropType = {
  position: 'end' | 'start' | 'both';
  startText?: string;
  endText?: string;
  inputClassName?: string;
  inputType?: string;
  disabled?: boolean;
};

export interface InputAdornmentProps {
  adornmentProps?: PropType;
}

// TODO: change approach where we use something like `startAdornment` and `endAdornment`
const InputAdornment: FC<InputAdornmentProps> = ({
  adornmentProps: { position, startText, endText, inputClassName, inputType, disabled },
  ...rest
}) => {
  const isBoth = position === 'both';
  const isStart = position === 'start';
  const isEnd = position === 'end';
  const showStartAdornment = (isBoth || isStart) && startText;
  const showEndAdornment = (isBoth || isEnd) && endText;
  const className = `w-full ${inputClassName ? inputClassName : 'pl-7 pr-12'}`;
  return (
    <div className="relative">
      {showStartAdornment && (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 text-sm">{startText}</span>
        </div>
      )}
      <Input type={inputType} inputClassName={className} disabled={disabled} {...rest} />
      {showEndAdornment && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 text-sm">{endText}</span>
        </div>
      )}
    </div>
  );
};

export default InputAdornment;
