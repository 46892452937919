import useRequest from '@services/api';
import dayjs from 'dayjs';
import { toastNotification } from './toast-notification';

const MAXIMUM_SHIPMENTS = 5;
const MAXIMUM_TRACKINGS = 3;
const MAXIMUM_FREE_TRIAL_DAYS = 7;

const useTrialSales = () => {
  let { data } = useRequest.user.get();
  const isUserOnTrial = data?.user?.stripe_customer_id === null;
  const hasSubscription = data?.user?.stripe_customer_id !== null;

  const { data: shipments } = useRequest.shipments.get({
    options: {
      enabled: isUserOnTrial
    }
  });
  const { data: trackings } = useRequest.shipment_trackings.get({
    options: {
      enabled: isUserOnTrial
    }
  });

  const onShipmentLimitValidation = () => {
    if (shipments?.data.length >= MAXIMUM_SHIPMENTS && isUserOnTrial) {
      return true;
    }
    return false;
  };

  const onTrackingLimitValidation = () => {
    if (trackings?.data.length >= MAXIMUM_TRACKINGS && isUserOnTrial) {
      return true;
    }
    return false;
  };

  const onDaysTrialValidation = () => {
    if (isUserOnTrial) {
      const daysDifference = dayjs().diff(dayjs(data?.user?.organization_created_at), 'day');
      return {
        daysDifference,
        remainingDays: MAXIMUM_FREE_TRIAL_DAYS - daysDifference,
        isLimitExceeded: daysDifference > MAXIMUM_FREE_TRIAL_DAYS,
        isLimitAlmostExceeded: daysDifference > MAXIMUM_FREE_TRIAL_DAYS - 3,
        isUserOnTrial: true
      };
    }
  };

  const freeTrialExpired =
    !onDaysTrialValidation()?.isUserOnTrial || onDaysTrialValidation()?.remainingDays < 0;

  const toastFeedBack = () =>
    toastNotification({
      type: 'warning',
      description: 'Free trial limited has been exceeded'
    });

  if (data?.user?.stripe_customer_id) {
    return {
      onShipmentLimitValidation: () => false,
      onTrackingLimitValidation: () => false,
      onDaysTrialValidation: () => ({
        daysDifference: 0,
        remainingDays: 0,
        isLimitExceeded: 0,
        isLimitAlmostExceeded: false,
        isUserOnTrial: false
      }),
      toastFeedBack: () => undefined,
      freeTrialExpired: true,
      hasSubscription: true
    };
  }

  return {
    onShipmentLimitValidation,
    onTrackingLimitValidation,
    onDaysTrialValidation,
    toastFeedBack,
    freeTrialExpired,
    hasSubscription
  };
};

export default useTrialSales;
