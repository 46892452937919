import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface BulkUpdateCustomFieldDataType {
  ids_to_update: string;
  update_column: string;
  update_key: string;
  update_value: string;
  entity_type: string;
}

const bulkUpdateCustomFieldData = () => {
  return useMutation((data: BulkUpdateCustomFieldDataType) =>
    http.put(API_ENDPOINTS.CUSTOM_FIELD_MAPPINGS_BULK_UPDATE, data)
  ) as any;
};

export default bulkUpdateCustomFieldData;
