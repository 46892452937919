import RenderIf from '@components/common/render-if';
import { FeatureLabelOptions } from '@components/documentTemplates/types';

import Tooltip from '@components/general/tooltip';
import {
  ArchiveIcon,
  ClipboardListIcon,
  CollectionIcon,
  TruckIcon,
  UserIcon
} from '@heroicons/react/outline';
import { PencilIcon } from '@heroicons/react/solid';
import useGetAutocompleteListByEntity from '@hooks/use-get-autocomplete-list-by-entity';
import useModal from '@hooks/use-modal';
import { ENTITY } from '@services/constants';
import {
  ContactsQueryOptionsType,
  EntitiesFieldType,
  ProductsQueryOptionsType,
  ShipmentsQueryOptionsType
} from '@services/types';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { FC, useEffect, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Select, { components } from 'react-select';
import { default as CreatableSelect } from 'react-select/creatable';
import FormInputContainer, { FormInputContainerProps } from './form-input-container';
import { FormLabelType } from './form-label';
// TODO: create an API call to fill special lists
const selectOptionList = {
  'select-dispatch-method': [
    { value: 'FCL', label: 'FCL' },
    { value: 'LCL', label: 'LCL' },
    { value: 'FTL', label: 'FTL' },
    { value: 'LTL', label: 'LTL' },
    { value: 'AIR', label: 'AIR' },
    { value: 'BREAK BULK', label: 'BREAK BULK' }
  ],
  'select-shipment-type': [
    { value: 'SEA', label: 'SEA' },
    { value: 'AIR', label: 'AIR' },
    { value: 'TRUCK', label: 'TRUCK' },
    { value: 'RAIL', label: 'RAIL' }
  ],
  'select-currency': [
    { value: 'USD', label: 'USD' },
    { value: 'GBP', label: 'AIR' },
    { value: 'CAD', label: 'CAD' },
    { value: 'INR', label: 'INR' },
    { value: 'EUR', label: 'EUR' },
    { value: 'CNY', label: 'CNY' }
  ]
};
const customStyles = {
  control: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    fontSize: '.875rem',
    boxShadow: 'none',
    borderColor: isFocused
      ? 'rgba(138, 213, 189, var(--tw-border-opacity));'
      : 'rgba(239, 239, 239, var(--tw-border-opacity))',
    '&:hover': {
      borderColor: isFocused ? 'rgba(138, 213, 189, var(--tw-border-opacity));' : 'inherit'
    },
    backgroundColor: 'rgba(245, 247, 248, 1)',
    paddingLeft: '2.5rem',
    color: 'rgba(153, 171, 186)',
    cursor: isDisabled ? 'not-allowed' : 'default',
    pointerEvents: isDisabled && 'auto'
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'rgb(54 73 101)'
  }),
  option: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      fontSize: '.875rem',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: isFocused ? 'rgba(243,244,246,1)' : null,
      '&:hover': {
        backgroundColor: 'rgba(243,244,246,1)'
      },
      color: `rgba(107, 114, 128, ${isDisabled ? 0.5 : 1})`
    };
  },
  dropdownIndicator: (styles, { isDisabled }) => {
    return {
      ...styles,
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: 'rgb(156 163 175)',
      svg: {
        height: '1rem',
        width: '1rem'
      }
    };
  },
  clearIndicator: (styles) => {
    return {
      ...styles,
      paddingLeft: '0.375rem',
      paddingRight: '0.375rem',
      cursor: 'pointer',
      color: 'rgb(156 163 175)',
      svg: {
        height: '1rem',
        width: '1rem'
      }
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
};
// Custom Option component for adding details
const Option = (props) => {
  const {
    label,
    data: { descriptionForLabel }
  } = props;
  return (
    <components.Option {...props}>
      <p>{label}</p>
      <RenderIf isTrue={!!descriptionForLabel}>
        <p className="text-xs">{descriptionForLabel}</p>
      </RenderIf>
    </components.Option>
  );
};
export interface FormAutocompleteProps extends FormInputContainerProps {
  inputClass?: string;
  containerClass?: string;
  entity?: EntitiesFieldType;
  labelSize?: FormLabelType['labelSize'];
  isClearable?: boolean;
  allowEdit?: boolean;
  showUserAddress?: boolean;
  returnItemKey?: string;
  disabledList?: string[];
  cleanSearch?: boolean;
  isCreateNew?: boolean;
  isEditable?: boolean;
  customOnChange?: (Value: string, { item, action }) => void;
  labelClass?: string;
  lastElement?: boolean;
  selectedId?: string | number;
  hideNewEntryOnEntity?: boolean;
  type?: 'autocomplete' | string;
  defaultValue?: any;
  customOptions?: { label: string; value: string }[];
  icon?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  readonlyLink?: boolean;
  queryParams?: ShipmentsQueryOptionsType | ContactsQueryOptionsType | ProductsQueryOptionsType;
  FeatureLabelOptions?: FeatureLabelOptions;
  includeArchived?: boolean;
  autoFocus?: boolean;
}
const FormAutocomplete: FC<FormAutocompleteProps> = ({
  defaultValue,
  name,
  label,
  inputClass,
  containerClass = 'w-full',
  icon,
  entity,
  labelSize,
  type,
  allowEdit = true,
  isClearable = true,
  showUserAddress = false,
  returnItemKey = 'value',
  disabledList = [],
  cleanSearch = false,
  isCreateNew = false,
  isEditable = false,
  customOnChange,
  customOptions,
  queryParams = {},
  readonlyLink = false,
  LabelItemAction,
  includeArchived = false,
  ...rest
}) => {
  const inputRef = useRef(null);
  const containerRef = useRef();
  const [selectValue, setSelectValue] = useState('');
  const [updateList, setUpdateList] = useState(false);

  const timeRef = useRef<NodeJS.Timeout>();
  const { pathname } = useRouter();
  const { control, getValues, setValue, readOnly: readOnlyForm } = useFormContext() as any;
  const autocompleteCreateNewEntityItemState = useStore(
    (state) => state.autocompleteCreateNewEntityItemState
  );
  const setAutocompleteCreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteCreateNewEntityItemState
  );
  const { openModal } = useModal();
  const setReactSelectClearableAction = useStore((state) => state.setReactSelectClearableAction);

  const {
    field: { onChange, onBlur, ref }
  } = useController({
    name,
    control,
    rules: { required: rest.required },
    defaultValue: defaultValue
  });
  const value = getValues(name);
  const generatedDocumentsPaths = {
    '/documents/[id]': true,
    '/documents/[id]/edit': true
  };
  const iconList = {
    user: UserIcon,
    contact: UserIcon,
    shipment: TruckIcon,
    product: ArchiveIcon,
    task: ClipboardListIcon,
    list: CollectionIcon,
    customFieldTypes: CollectionIcon
  };
  const modalIdsByEntities = {
    contact: ModalIds.CONTACT_FORM,
    product: generatedDocumentsPaths[pathname]
      ? ModalIds.PRODUCT_FORM_DOCUMENT
      : ModalIds.PRODUCT_FORM
  };

  const onHandleClearValue = () => {
    setReactSelectClearableAction(true);
    ref((elem) => (elem.value = ''));
    setValue(name, '');
    setSelectValue('');
    setUpdateList(false);
  };

  const onChangeHandle = (item, { action }) => {
    if (item === selectValue) {
      return;
    }

    // workaround for react-select bug (isClearable). Clear input value close the modal

    const { __isNew__ = false } = item || {};
    const value = item ? (__isNew__ ? item.value : item[returnItemKey]) : '';
    setSelectValue(item?.value);
    if (customOnChange) customOnChange(value, { item, action });

    onChange(value);
    if (action === 'clear' || action === 'remove-value') {
      onHandleClearValue();
      return;
    }
  };

  const onClickEditIcon = () => {
    setAutocompleteCreateNewEntityItemState({
      inputId: name
    });
    openModal({
      id: modalIdsByEntities[entity],
      isOpen: true,
      mode: 'edit',
      entityId: value,
      formValues: getValues(),
      inlineEditable: rest?.inlineEditable
    });
  };
  const {
    data,
    loading: entityLoading,
    archivedData
  } = useGetAutocompleteListByEntity({
    entity,
    disabledList,
    isRefetch: updateList,
    queryParams,
    includeArchived
  });
  // add description to option's label
  const options = customOptions || selectOptionList[type] || data;
  const isContactEntity = entity === 'contact';
  const userAddress = isContactEntity && options.find((c) => c.value == value)?.address;
  const addressAllow = isContactEntity && userAddress && showUserAddress;
  const getValueName =
    (data && data.find((item) => item.id === Number(value))?.name) ||
    (archivedData && archivedData.find((item) => item.id === Number(value))?.name);
  const isArchived =
    archivedData && Boolean(archivedData.find((item) => item.id === Number(value)));
  const fullLoading = entityLoading && rest.loading;
  const getLink = () => {
    let link = '';
    if (!value || !readonlyLink) return link;

    if (entity === ENTITY.Contact) {
      link = `/contacts/${value}`;
    } else if (entity === ENTITY.Shipment) {
      link = `/shipments/${value}`;
    } else if (entity === ENTITY.Product) {
      link = `/products/${value}`;
    }

    return link;
  };
  const Icon = entity ? iconList[entity] : iconList[icon];
  const ReadOnly = () => (
    <div>
      <div className={`${rest.readOnly && 'text-gray-900'}`}>{getValueName}</div>
      <div className={`${rest.readOnly && 'text-gray-900'} mt-2`}>{userAddress}</div>
    </div>
  );
  const handleCreate = (value) => {
    inputRef?.current?.focus();
    openModal({
      id: modalIdsByEntities[entity],
      isOpen: true,
      formValues: getValues(),
      inlineEditable: rest?.inlineEditable
    });
    setAutocompleteCreateNewEntityItemState({
      inputId: name,
      createItemName: value
    });
  };
  const onCreateOption =
    isCreateNew && Object.values(ENTITY).includes(entity) ? (val) => handleCreate(val) : undefined;
  const setSelectData = (data) => {
    setValue(name, data[returnItemKey]);
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
    timeRef.current = setTimeout(() => {
      setSelectValue(data);
    }, 50);
  };

  useEffect(() => {
    if (
      Object.keys(autocompleteCreateNewEntityItemState.data).length > 0 &&
      autocompleteCreateNewEntityItemState.inputId === name
    ) {
      const selectedData = data.find(
        (data) => data.id == autocompleteCreateNewEntityItemState.data.id
      );
      if (!selectedData) {
        setUpdateList(true);
      } else {
        setSelectData(selectedData);
        setAutocompleteCreateNewEntityItemState({ data: {}, createItemName: '', inputId: '' });
      }
    }
  }, [autocompleteCreateNewEntityItemState.data, data]);

  useEffect(() => {
    if (options) {
      setSelectValue(options.find((c) => c.value === value));
    }
  }, [value, fullLoading, customOptions, selectOptionList[type], data]);

  useEffect(() => {
    if (cleanSearch) {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      timeRef.current = setTimeout(() => {
        onHandleClearValue();
      }, 50);
    }
  }, [cleanSearch, value, options]);

  useEffect(() => {
    if (updateList) {
      setUpdateList(false);
    }
  }, [updateList]);

  useEffect(() => {
    if (value && data.length > 0) {
      const selectedData = data.find((data) => data[returnItemKey] == value);
      if (selectedData) setSelectData(selectedData);
    }
  }, [value, data, autocompleteCreateNewEntityItemState.data]);

  const SelectComponent = isCreateNew ? CreatableSelect : Select;
  const createOptionProps = isCreateNew
    ? { onCreateOption, ...(options?.length === 0 && { isValidNewOption: () => true }) }
    : null;

  const tooltip_text =
    (getValueName || value) &&
    entity &&
    `${entity.charAt(0).toUpperCase() + entity.slice(1)} has been archived`;

  const TooltipComponent = () => (
    <Tooltip
      description={tooltip_text}
      className={rest.readOnlyClassName + ' text-default text-archived'}
    >
      {getValueName || value}
    </Tooltip>
  );

  return (
    <FormInputContainer
      name={name}
      label={label}
      labelSize={labelSize}
      className={rest.labelClass}
      readOnly={rest.readOnly || readOnlyForm}
      readOnlyClassName={inputClass}
      readOnlyChildren={<ReadOnly />}
      value={getValueName || value}
      LabelItemAction={LabelItemAction}
      link={getLink()}
      tooltip={isArchived && <TooltipComponent />}
      containerClass={containerClass}
      containerRef={containerRef}
      {...rest}
    >
      <div ref={containerRef} className={`relative ${inputClass}`}>
        <SelectComponent
          classNamePrefix="form-select"
          inputId={name}
          name={name}
          ref={ref}
          isLoading={fullLoading}
          id="react-select"
          isSearchable
          onMenuOpen={() => setUpdateList(true)}
          onBlur={onBlur}
          options={options}
          value={selectValue}
          onChange={onChangeHandle}
          isDisabled={rest.disabled}
          styles={customStyles}
          isClearable={isClearable}
          components={{
            Option
          }}
          menuPortalTarget={document.querySelector('body')}
          menuShouldBlockScroll={true}
          {...rest}
          {...createOptionProps}
        />
        <RenderIf isTrue={Icon}>
          <div
            className={`absolute top-[0.05rem] left-[0.05rem] h-9 flex items-center px-3 rounded-l-md border border-l-0 border-t-0 border-b-0 border-primary-grey-100 ${
              rest.disabled ? 'cursor-not-allowed' : ''
            }`}
          >
            <Icon className="w-4 h-4" />
          </div>
        </RenderIf>
        <RenderIf isTrue={allowEdit && selectValue && !rest.disabled && isEditable}>
          <div className="absolute top-[0.05rem] right-11 h-9 flex items-center px-3">
            <a type="button" className="cursor-pointer" onClick={onClickEditIcon}>
              <PencilIcon className="h-3 w-3 text-gray-400" />
            </a>
          </div>
        </RenderIf>
      </div>
      <RenderIf isTrue={addressAllow}>
        <div
          className={`mt-2 whitespace-pre-wrap ${
            rest.FeatureLabelOptions?.hidden && 'text-gray-200'
          }`}
        >
          {userAddress}
        </div>
      </RenderIf>
    </FormInputContainer>
  );
};
export default FormAutocomplete;
