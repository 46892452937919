import RenderIf from '@components/common/render-if';
import ImageEditor from '@components/dataDisplay/ImageEditor';
import Typography from '@components/dataDisplay/typography';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import ModalView from '@components/feedback/modal-view';
import Button from '@components/general/button';
import { InformationCircleIcon, PhotographIcon } from '@heroicons/react/outline';
import { toastNotification } from '@hooks/toast-notification';
import useFileUpload from '@hooks/use-file-upload';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useUserPolicy from '@hooks/use-user-policy';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { ModalIds } from 'constants/modal-ids';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import ModalFooter from '../modal-footer';

const CropProfileImageModal = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [logo, setLogo] = useState('');
  const [croppedLogo, setCroppedLogo] = useState('');

  const { isAdmin } = useUserPolicy();

  // const
  const methods = useForm({});
  const isUploadDisabled = !methods.watch('logo');
  const { closeModalByPosition } = useModal();

  // queries
  const { data, isSuccess, refetch } = useRequest.organization.get();
  const { mutateAsync: deleteLogoMutation } = useRequest.organization.deleteLogo();

  const handleCroppedFileUpload = (file) => {
    methods.setValue('logo', file, { shouldDirty: true });
    setCroppedLogo(file);
  };

  const handleFileUpload = (file) => {
    methods.setValue('logo', file, { shouldDirty: true });
    setLogo(URL.createObjectURL(file));
  };

  const { mutateAsync: updateOrganization } = useRequest.organization.update();
  const queryClient = useQueryClient();

  const getCroppedImageUrl = async (dataUrl) => {
    const result = await fetch(dataUrl).then((res) => res.blob());
    return result;
  };

  const { handleSubmit, formId } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: false,
    onSubmit: async (inputs: any) => {
      const blobUrl = await getCroppedImageUrl(croppedLogo);

      let formData = new FormData();
      formData.append('organization[logo]', croppedLogo ? blobUrl : inputs.logo);
      formData.append('organization[id]', data.id);

      await updateOrganization({ id: data.id, input: formData });
      await queryClient.invalidateQueries([API_ENDPOINTS.ORGANIZATION]);
      closeModalByPosition();
    }
  });

  const { handleChange, fileRef, showOpenFileDialog } = useFileUpload({
    onChange: handleFileUpload
  });

  const handleDeleteLogo = async () => {
    await deleteLogoMutation(data?.logo_id, {
      onSuccess: () => {
        setLogo('');
        refetch();
        toastNotification({
          type: 'success',
          description: 'Logo was successfully removed'
        });
      },
      onError: () => {
        toastNotification({
          type: 'error',
          description: 'Something went wrong'
        });
      }
    });
  };

  useEffect(() => {
    setLogo(data?.logo);
  }, [isSuccess]);

  return (
    <ModalView header={'Upload Your Logo'}>
      <Form errorsServerValidation={errorsServerValidation} onSubmit={handleSubmit} formId={formId}>
        <FormInput
          label={false}
          name="stamp"
          type="file"
          hidden
          inputRef={fileRef}
          customOnChange={handleChange}
          accept="image/*"
          labelClass="text-primary-navy-400"
        >
          <p className="text-base">Your organization logo</p>
          <div className="flex flex-row divide-x divide-gray-200 gap-x-3 my-4">
            <RenderIf isTrue={croppedLogo || logo}>
              <div className="w-24 h-24 rounded-full border border-gray-200">
                <img
                  className="object-contain w-full h-full rounded-full"
                  src={croppedLogo || logo}
                  alt="logo"
                />
              </div>
            </RenderIf>
            <RenderIf isTrue={!logo}>
              <div className="flex items-center justify-center border w-24 h-24 border-gray-200 object-contain rounded-full">
                <PhotographIcon className="h-12 w-12 self-center text-gray-300" />
              </div>
            </RenderIf>
            <div className="self-center">
              <RenderIf isTrue={isAdmin}>
                <Button
                  onClick={showOpenFileDialog}
                  variant="secondary"
                  className="mx-3"
                  disabled={!isAdmin}
                >
                  {logo ? 'Upload New Logo' : 'Upload Logo'}
                </Button>
              </RenderIf>
              <RenderIf isTrue={isAdmin && !!data?.logo_id}>
                <Button
                  className="text-primary-grey-500 text-xs"
                  onClick={async () =>
                    window.confirm('Are you sure?') ? handleDeleteLogo() : null
                  }
                >
                  Remove
                </Button>
              </RenderIf>
            </div>
          </div>
          <RenderIf isTrue={logo}>
            <>
              <div className="flex items-center gap-2 mt-10">
                <InformationCircleIcon className="w-4 h-4" />
                <Typography variant="text-base">Resize it to fit well in documents.</Typography>
              </div>
              <div className="flex flex-col gap-x-20 mt-8">
                <ImageEditor
                  url={logo}
                  imagePreviewSizeSet={{ width: 128, height: 40 }}
                  handleSubmit={handleCroppedFileUpload}
                  isInlineEdit
                />
              </div>
            </>
          </RenderIf>
        </FormInput>
        <ModalFooter
          modalId={ModalIds.CROP_PROFILE_IMAGE_MODAL}
          mainButtonText="Upload"
          mainButtonType="submit"
          disabled={isUploadDisabled}
        />
      </Form>
    </ModalView>
  );
};

export default CropProfileImageModal;
