const CargoflipLogoText = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="110"
      height="19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.81924 18.6628C5.90436 18.6628 5.03947 18.5268 4.22573 18.2559C3.412 17.985 2.69126 17.5782 2.06468 17.0353C1.43694 16.4936 0.94637 15.8065 0.589487 14.9754C0.233768 14.1454 0.0559082 13.1793 0.0559082 12.0773C0.0559082 10.9078 0.22563 9.88602 0.563913 9.01299C0.902195 8.13997 1.373 7.41574 1.97517 6.83915C2.57617 6.26256 3.28412 5.83128 4.09786 5.54182C4.9116 5.25352 5.8102 5.10938 6.7925 5.10938C7.48766 5.10938 8.20375 5.18145 8.94077 5.3256C9.67778 5.46974 10.3264 5.66853 10.8856 5.92311V9.17807C10.2916 8.90721 9.71149 8.68169 9.1442 8.50383C8.57575 8.32597 7.99567 8.23645 7.4028 8.23645C6.33448 8.23645 5.47424 8.50383 4.82209 9.03741C4.16877 9.57098 3.84328 10.4405 3.84328 11.6437C3.84328 12.9154 4.13157 13.8733 4.70817 14.5173C5.28476 15.1614 6.24264 15.4834 7.58182 15.4834C8.2084 15.4834 8.81522 15.3729 9.39995 15.1532C9.98467 14.9335 10.4892 14.7045 10.9123 14.4662V17.6956C10.3183 18.0001 9.69987 18.2385 9.05585 18.407C8.41184 18.5767 7.66552 18.6616 6.81807 18.6616L6.81924 18.6628Z" />
      <path d="M18.5149 18.7204C17.7349 18.7204 17.0025 18.6483 16.3155 18.5042C15.6284 18.36 15.0274 18.1229 14.5101 17.7927C13.9928 17.4626 13.5906 17.0127 13.3023 16.4454C13.014 15.8781 12.8699 15.1702 12.8699 14.3227C12.8699 13.5264 13.0349 12.8312 13.3663 12.2372C13.6964 11.6443 14.2009 11.1863 14.8786 10.8643C15.5564 10.5423 16.412 10.3807 17.4466 10.3807C18.0232 10.3807 18.5823 10.44 19.1252 10.5586C19.6669 10.6772 20.1552 10.8387 20.5876 11.0422C21.02 11.2456 21.363 11.4665 21.6176 11.7036L21.7698 13.5345C21.4304 13.3311 21.0235 13.1707 20.5492 13.0509C20.0749 12.9324 19.5658 12.8731 19.0241 12.8731C18.1929 12.8731 17.5872 12.9963 17.2059 13.2416C16.8246 13.488 16.634 13.8728 16.634 14.3983C16.634 14.7203 16.727 15.0086 16.9142 15.2632C17.1001 15.5177 17.3501 15.7119 17.664 15.8479C17.9778 15.9839 18.3033 16.0513 18.6428 16.0513C19.5077 16.0513 20.1133 15.992 20.4609 15.8735C20.8085 15.7549 20.9817 15.5177 20.9817 15.162V10.433C20.9817 9.65301 20.7317 9.09037 20.2319 8.74162C19.732 8.39404 19.0241 8.22083 18.1092 8.22083C17.3629 8.22083 16.6131 8.35219 15.8586 8.61491C15.1042 8.87763 14.4892 9.19615 14.0149 9.56815V6.21206C14.4555 5.9412 15.1379 5.68662 16.0621 5.44947C16.9862 5.21232 18.0243 5.09375 19.1763 5.09375C19.5832 5.09375 20.0319 5.12746 20.5237 5.19605C21.0154 5.26347 21.5071 5.3867 21.9989 5.56456C22.4906 5.74242 22.944 6.00165 23.359 6.33993C23.774 6.67938 24.1088 7.11182 24.3633 7.63726C24.6179 8.16271 24.7446 8.81602 24.7446 9.59488L24.7191 15.6723C24.7191 16.4012 24.4726 16.9906 23.982 17.4393C23.4903 17.888 22.7824 18.2147 21.8594 18.4181C20.9352 18.6216 19.8204 18.7227 18.5161 18.7227L18.5149 18.7204Z" />
      <path d="M28.1011 18.3246V6.80674C28.3382 6.6033 28.7625 6.37081 29.3728 6.10808C29.9831 5.84536 30.6818 5.60822 31.4711 5.39548C32.2593 5.18391 33.0521 5.07812 33.8484 5.07812C34.6447 5.07812 35.2724 5.13741 35.7305 5.25598C36.1885 5.37456 36.5349 5.52684 36.7732 5.714V8.48536C36.4849 8.41794 36.121 8.36679 35.6805 8.33308C35.2399 8.29937 34.7819 8.27844 34.3076 8.26914C33.8333 8.261 33.3834 8.26565 32.9603 8.28193C32.5359 8.29936 32.1802 8.33308 31.8919 8.38423V18.3258H28.1034L28.1011 18.3246Z" />
      <path d="M43.9153 18.7116C42.7458 18.6442 41.7414 18.3721 40.9021 17.8978C40.0628 17.4236 39.4107 16.7237 38.9445 15.8007C38.4783 14.8766 38.2458 13.7117 38.2458 12.304C38.2458 10.8962 38.4237 9.69769 38.7794 8.75725C39.1351 7.8168 39.6315 7.08327 40.2674 6.55783C40.9033 6.03239 41.6694 5.65923 42.568 5.43952C43.4666 5.21981 44.4663 5.10938 45.5683 5.10938C46.6704 5.10938 47.5678 5.24539 48.3653 5.51624C49.1616 5.7871 49.78 6.1777 50.2218 6.6857C50.6623 7.19371 50.8832 7.82145 50.8832 8.56776V18.4837C50.8832 19.3823 50.7391 20.1752 50.4508 20.861C50.1625 21.548 49.7347 22.1235 49.1662 22.5896C48.5978 23.0558 47.9119 23.4069 47.1063 23.6452C46.3007 23.8823 45.373 24.0009 44.3222 24.0009C43.1864 24.0009 42.2157 23.9207 41.4113 23.7591C40.6057 23.5975 39.9919 23.3987 39.5676 23.1616V19.8311C39.8222 19.9322 40.1942 20.0682 40.6859 20.2379C41.1776 20.4077 41.7287 20.5471 42.339 20.6576C42.9493 20.768 43.5596 20.8227 44.1699 20.8227C44.9662 20.8227 45.5811 20.7122 46.0136 20.4925C46.446 20.2716 46.7459 19.9752 46.9156 19.602C47.0854 19.2289 47.1702 18.8046 47.1702 18.3303V16.2448L47.9072 16.7784C47.6364 17.169 47.3132 17.5073 46.9412 17.7956C46.5681 18.0838 46.1321 18.3082 45.6323 18.4698C45.1324 18.6302 44.5605 18.7116 43.9164 18.7116H43.9153ZM44.8813 15.8635C45.3219 15.8635 45.7288 15.817 46.1019 15.724C46.4751 15.631 46.8308 15.4915 47.1702 15.3043V9.40591C47.1702 9.05019 47.0691 8.77933 46.8657 8.59217C46.6622 8.40618 46.4076 8.2783 46.1031 8.21088C45.7985 8.14346 45.4753 8.10858 45.137 8.10858C44.357 8.10858 43.7432 8.2783 43.2933 8.61659C42.8435 8.95603 42.5215 9.42684 42.3273 10.0278C42.132 10.63 42.0344 11.3124 42.0344 12.075C42.0344 13.2444 42.2971 14.1686 42.8225 14.8463C43.348 15.5241 44.0338 15.8635 44.8825 15.8635H44.8813Z" />
      <path d="M59.8576 18.7102C57.6872 18.7102 56.0562 18.1301 54.9635 16.9688C53.8708 15.8075 53.3232 14.0998 53.3232 11.8457C53.3232 9.42195 53.8777 7.68055 54.9891 6.62036C56.0992 5.56134 57.7221 5.03125 59.8587 5.03125C61.299 5.03125 62.5069 5.27305 63.4822 5.75548C64.4563 6.23907 65.1945 6.98073 65.6944 7.98047C66.1943 8.98137 66.4442 10.2682 66.4442 11.8457C66.4442 14.0998 65.8757 15.8086 64.7411 16.9688C63.6054 18.1301 61.9779 18.7102 59.8587 18.7102H59.8576ZM59.8576 15.6842C60.4841 15.6842 61.0061 15.5366 61.4211 15.239C61.8361 14.9426 62.15 14.5101 62.3615 13.9417C62.5731 13.3744 62.6789 12.6746 62.6789 11.8446C62.6789 10.896 62.5731 10.145 62.3615 9.594C62.1488 9.04298 61.8315 8.64541 61.4083 8.39896C60.984 8.15368 60.4679 8.03046 59.8576 8.03046C59.2135 8.03046 58.6881 8.15717 58.2812 8.41175C57.8744 8.66633 57.5733 9.06855 57.3791 9.61957C57.1838 10.1706 57.0862 10.9123 57.0862 11.8446C57.0862 13.1326 57.3106 14.0951 57.7604 14.731C58.2092 15.3669 58.909 15.6842 59.8576 15.6842Z" />
      <path d="M70.0282 18.3312V8.36406H68.1206V5.46599H70.0527V5.16142C70.0527 4.33141 70.1375 3.61881 70.3072 3.02594C70.477 2.43308 70.7141 1.9367 71.0198 1.53796C71.3244 1.1404 71.6813 0.830013 72.0882 0.610304C72.495 0.390594 72.9438 0.233659 73.4355 0.139498C73.9272 0.0464993 74.4352 0 74.9607 0C75.2315 0 75.5279 0.0209247 75.8511 0.0639366C76.1731 0.106948 76.4359 0.169723 76.6393 0.254584V3.60951C76.487 3.42351 76.3173 3.27936 76.1313 3.17707C75.9441 3.07593 75.6907 3.02478 75.3687 3.02478C75.1478 3.02478 74.9444 3.05036 74.7584 3.1015C74.5712 3.15265 74.4108 3.23751 74.2748 3.35609C74.1388 3.47466 74.033 3.64438 73.9574 3.86409C73.8807 4.08497 73.8435 4.36396 73.8435 4.70341V5.46599H76.6404V8.36406H73.8179V18.3312H70.0294H70.0282ZM82.6912 18.3312H78.9282V0.609141H82.6912V18.3312Z" />
      <path d="M86.0471 3.70489V0.78125H89.9624V3.70489H86.0471ZM86.1238 18.3254V5.6381H89.9124L89.9379 18.3254H86.1238Z" />
      <path d="M100.261 18.7102C100.006 18.7102 99.6333 18.6846 99.1428 18.6335C98.651 18.5823 98.1419 18.4684 97.6176 18.2905C97.0921 18.1127 96.6423 17.8372 96.2703 17.464L97.0328 16.269V22.9312H93.321V8.1839C93.321 7.62475 93.4815 7.15046 93.8046 6.75986C94.1266 6.37043 94.5672 6.04842 95.1264 5.79384C95.6855 5.53925 96.3342 5.34861 97.0712 5.2219C97.8082 5.09519 98.601 5.03125 99.4485 5.03125C100.737 5.03125 101.868 5.24747 102.843 5.67992C103.817 6.11236 104.581 6.83659 105.132 7.85376C105.683 8.87093 105.958 10.2613 105.958 12.0236C105.958 13.3453 105.729 14.5113 105.271 15.5192C104.813 16.5282 104.165 17.3117 103.327 17.8709C102.487 18.43 101.467 18.7102 100.262 18.7102H100.261ZM99.1428 15.6587C99.8205 15.6587 100.38 15.5401 100.821 15.3029C101.262 15.0658 101.597 14.6717 101.826 14.1207C102.055 13.5697 102.169 12.828 102.169 11.8957C102.169 11.1157 102.084 10.4554 101.914 9.91252C101.744 9.3708 101.451 8.95114 101.036 8.65355C100.621 8.35711 100.04 8.20832 99.295 8.20832C98.4813 8.20832 97.9012 8.28853 97.5536 8.45011C97.2061 8.6117 97.0328 8.81862 97.0328 9.0732V15.1251C97.3549 15.3122 97.7106 15.4471 98.1012 15.532C98.4906 15.6168 98.8382 15.6587 99.1439 15.6587H99.1428Z" />
    </svg>
  );
};

export default CargoflipLogoText;
