import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const useLogoutMutation = () => {
  return useMutation(() =>
    http.delete(API_ENDPOINTS.LOGOUT).then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('admin-mode');
    })
  );
};

export default useLogoutMutation;
