import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface BulkDeleteFieldDataType {
  delete_data: string;
}

const bulkDeleteFieldData = () => {
  return useMutation((data: BulkDeleteFieldDataType) =>
    http.post(`${API_ENDPOINTS.FIELD_DATA}/bulk_delete`, data)
  ) as any;
};

export default bulkDeleteFieldData;
