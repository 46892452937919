import { useMutation } from 'react-query';

import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const addTrackingToShipmentMutation = () => {
  return useMutation((data: any) =>
    http.put(`${API_ENDPOINTS.GET_SHIPMENT_TRACKING(data.id)}`, data.inputs)
  );
};

export default addTrackingToShipmentMutation;
