import { withComponent } from '@components/@utils/withComponent';
import RenderIf from '@components/common/render-if';
import Typography, { Variant } from '@components/dataDisplay/typography';
import { YEAR_MONTH_DAY_FORMAT } from '@services/constants';
import { capitalizeWords } from '@utils/capitaize-words';
import cn from 'classnames';
import dayjs from 'dayjs';
import Link from 'next/link';
import { FC, ReactChild } from 'react';

export type FormReadOnlyProps = {
  className?: string;
  value?: any;
  children?: ReactChild;
  typographyVariant?: Variant;
  link?: string;
  type?: string;
  capitalize?: boolean;
  showBlank?: boolean;
  TypographyChildren?: any;
};

const FormReadOnly: FC<FormReadOnlyProps> = ({
  className,
  value,
  children,
  typographyVariant = '',
  link = '',
  type = '',
  capitalize = false,
  showBlank = false,
  TypographyChildren
}) => {
  const getModifiedValue = () => {
    let modifiedValue = value;
    switch (type) {
      case 'checkbox':
        modifiedValue = value ? 'Yes' : 'No';
        break;
      case 'date':
        modifiedValue = value ? dayjs(value).format(YEAR_MONTH_DAY_FORMAT) : '';
        break;
      case 'number':
        modifiedValue = value?.toString() || '';
        break;
    }
    return typeof modifiedValue !== 'object' && modifiedValue !== null ? modifiedValue : '';
  };

  let modifiedValue = getModifiedValue();
  modifiedValue = capitalize ? capitalizeWords(modifiedValue) : modifiedValue;
  let typographyChildren = children || modifiedValue;
  if (link) {
    typographyChildren = (
      <Link href={link || ''} passHref>
        <a className="link">{modifiedValue}</a>
      </Link>
    );
  }

  const TypographyProps = {
    variant: typographyVariant as Variant,
    className: cn(`whitespace-pre-wrap break-words text-primary-navy-500 ${className}`)
  };

  const TypographyChildrenComponent = withComponent(TypographyChildren, null);

  // TODO: Add typography variant for read only
  // return <div className={`text-xs ${className}`}>{children || modifiedValue}</div>;
  return (
    <div className={`${!modifiedValue && 'h-4'}`}>
      <Typography {...TypographyProps}>
        <RenderIf isTrue={modifiedValue || showBlank}>{typographyChildren || ''}</RenderIf>
        <RenderIf isTrue={!(modifiedValue || showBlank) && !!TypographyChildren}>
          {TypographyChildrenComponent}
        </RenderIf>
        <RenderIf isTrue={!(modifiedValue || showBlank) && !TypographyChildren}>{'-'}</RenderIf>
      </Typography>
    </div>
  );
};

export default FormReadOnly;
