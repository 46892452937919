import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export interface LoginFormType {
  email: string;
  password: string;
}

export const useLoginMutation = () => {
  return useMutation((inputs: LoginFormType) =>
    http.post(`${API_ENDPOINTS.LOGIN}`, { user: inputs }, { headers: { Authorization: '' } })
  );
};
