import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function deletePaymentDetail(id: string) {
  return http.delete(`${API_ENDPOINTS.PAYMENTS_DETAILS}/${id}`);
}

export const useDeletePaymentDetailMutation = () => {
  return useMutation((id: string) => deletePaymentDetail(id), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Payment detail was successfully removed.'
      });
    }
  });
};
