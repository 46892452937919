import RenderIf from '@components/common/render-if';
import Typography from '@components/dataDisplay/typography';
import Button from '@components/general/button';
import Tooltip from '@components/general/tooltip';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { toastNotification } from '@hooks/toast-notification';
import useManageParams from '@hooks/use-manage-params';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useContextUI } from 'states/ui';
import { Body } from '../components/Body';
import DrawerRow from '../components/DrawerRow';
import Footer from '../components/Footer';
import AccountNameWithAvatar from '../components/RowItem/AccountNameWithAvatar';
import Date from '../components/RowItem/Date';
import Text from '../components/RowItem/Text';
import RequestedAttachment from './components/RequestedAttachment';

import useTasksValidation from '@hooks/use-task-validation';
import Edit from './Edit';
import RequestedFields from './components/RequestedFields';
import ViewHeader from './components/ViewHeader';

const View = (props) => {
  // hooks
  const { pathname, params, cleanSearch } = useManageParams();
  const queryClient = useQueryClient();
  const [_, { openPortal, closePortal }] = useContextUI();
  const { isTaskComplete } = useTasksValidation();

  const taskId = props.taskId || params?.taskId || params?.id;
  // queries
  const { data, isLoading: taskLoading } = useRequest.tasks.getById({
    id: taskId as string,
    props: {
      params: { token: params?.token },
      options: { keepPreviousData: true, enabled: !!taskId }
    }
  });
  const { mutateAsync: uploadTaskDocument, isLoading } = useRequest.documents.uploadDocuments();
  const { data: userData } = useRequest.user.get();

  // states
  const [files, setFiles] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);

  // const
  const assignedEmail = data?.assignee_email;
  const reviewerEmail = data?.reviewer_email;
  const isCreator = data?.sender_email === userData?.user.email;
  const subHeaderText = data?.name;
  const headerTitle = pathname.includes('task-details') ? 'Task details' : '';
  const loading = isLoading;
  const emailAvatarCharacter = assignedEmail?.[0]?.toUpperCase();
  const reviewerAvatarCharacter = reviewerEmail?.[0]?.toUpperCase();
  const isDataRequested = data?.is_data_request;
  const isFileUploadRequested = data?.is_file_upload;
  const isActionRequested = isDataRequested || isFileUploadRequested;

  const DynamicChevron: any = !openDetails ? ChevronDownIcon : ChevronUpIcon;
  const taskFormDrawers = [
    {
      name: 'Assigned email',
      rowItem: (
        <AccountNameWithAvatar avatarValue={emailAvatarCharacter} accountName={assignedEmail} />
      )
    },
    {
      name: 'Reviewer',
      rowItem: (
        <AccountNameWithAvatar avatarValue={reviewerAvatarCharacter} accountName={reviewerEmail} />
      )
    },
    { name: 'Shipment', rowItem: <Text rowItem={data?.shipment_name} /> },
    { name: 'Due date', rowItem: <Date rowItem={data?.target_date} /> },
    { name: 'Completed on', rowItem: <Date rowItem={data?.finished_date} /> }
  ];

  const onChangeFile = async (files) => {
    setFiles(files);
  };

  const onRemoveFile = async () => {
    setFiles([]);
  };

  const onSubmitFile = async () => {
    try {
      await uploadTaskDocument(
        {
          documentData: {
            file: files[0],
            owner_class: 'Shipment',
            owner_id: data?.shipment_id.toString(),
            filename: data?.filename
          },
          linkedId: taskId,
          source: 'Milestone'
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([API_ENDPOINTS.GET_TASK_BY_ID(taskId)]);
            queryClient.invalidateQueries([API_ENDPOINTS.TASKS]);
            queryClient.invalidateQueries([API_ENDPOINTS.NOTIFICATIONS]);
            toastNotification({
              type: 'success',
              description: 'File was successfully uploaded.'
            });
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const validateTaskRequirements = () => {
    return isTaskComplete({ taskData: data });
  };

  const onUpdateTask = () => {
    if (data?.is_data_request) document.getElementById('requested-fields-form-click').click();
    if (files.length > 0) onSubmitFile();
  };
  const onCancelTask = () => {
    cleanSearch();
  };

  const onHandleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const onHandleDrawer = () => {
    openPortal({
      portal: 'drawer',
      params: { taskId: taskId, shipmentId: data.shipment_id, mode: 'edit' },
      Component: <Edit />
    });
  };

  const Section = (props) => {
    return <div className={props.className}>{props.children}</div>;
  };

  useEffect(() => {
    setOpenDetails(isActionRequested ? false : true);
  }, [isActionRequested]);

  return (
    <>
      <ViewHeader
        onHandleDrawer={onHandleDrawer}
        closePortal={closePortal}
        headerTitle={headerTitle}
        isCreator={isCreator}
        validateTaskRequirements={validateTaskRequirements}
      />
      <Body loading={taskLoading}>
        <div className="mt-16 mb-4 grid gap-5">
          {/* <div>
            <StatusText name="ADD NAME" />
          </div> */}
          <div className="flex items-center">
            <Typography variant="h3" className=" text-primary-navy-400">
              {subHeaderText}
            </Typography>
            <Tooltip
              descriptionTopMargin="mt-4"
              description={openDetails ? 'Hide details' : 'Show details'}
            >
              <div
                onClick={onHandleDetails}
                className="w-4 h-4 bg-primary-grey-50 hover:bg-white ml-2 border border-primary-grey-400 hove rounded-sm cursor-pointer"
              >
                <DynamicChevron className="text-primary-grey-500 cursor-pointer" />
              </div>
            </Tooltip>
          </div>
          <Typography className="text-primary-grey-500">{data?.description}</Typography>
          <RenderIf isTrue={openDetails}>
            {taskFormDrawers.map(({ name, rowItem }, idx) => (
              <DrawerRow key={`${name}_${idx}`} name={name} RowItem={rowItem} />
            ))}
          </RenderIf>

          <RenderIf isTrue={isDataRequested}>
            <Section className=" border-t-[1px]  py-4">
              <RequestedFields data={data} />
            </Section>
          </RenderIf>

          <RenderIf isTrue={isFileUploadRequested}>
            <div className=" border-t-[1px] py-4 mb-20">
              <RequestedAttachment
                data={data}
                files={files}
                onSubmitFile={onSubmitFile}
                onChangeFile={onChangeFile}
                removeFile={onRemoveFile}
              />
            </div>
          </RenderIf>
        </div>
      </Body>
      <RenderIf isTrue={data?.is_data_request || data?.is_file_upload}>
        <Footer>
          <div className="flex justify-end">
            <Button onClick={onCancelTask}>Cancel</Button>
            <Button loading={loading} variant="primary" onClick={onUpdateTask}>
              Update Task
            </Button>
          </div>
        </Footer>
      </RenderIf>
    </>
  );
};

export default View;
