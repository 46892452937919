import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';

import http from '../utils/http';

const setNewPasswordMutation = () => {
  return useMutation((data: any) => http.put(API_ENDPOINTS.SET_NEW_PASSWORD, { user: data }));
};

export default setNewPasswordMutation;
