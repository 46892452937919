export enum ENTITIES {
  shipments = 'shipments',
  generated_documents = 'generated_documents',
  uploaded_documents = 'uploaded_documents',
  documents = 'documents',
  documents_templates = 'documents_templates',
  shipment_packages = 'shipment_packages',
  contacts = 'contacts',
  custom_fields = 'custom_fields',
  field_data = 'field_data',
  products = 'products',
  shipment_payments = 'shipment_payments',
  shipment_trackings = 'shipment_trackings',
  shipment_products = 'shipment_products',
  dashboard = 'dashboard',
  workflows = 'workflows',
  user = 'user',
  notifications = 'notifications',
  tasks = 'tasks',
  organization = 'organization',
  settings = 'settings',
  sign_in_as = 'sign_in_as',
  tasks_templates = 'tasks_templates',
  plans = 'plans'
}
