import * as yup from 'yup';

export const mappedYupSchema = ({ fieldType, message }) => {
  switch (fieldType) {
    case 'image':
      return yup.mixed().test('required', message, (file) => {
        if (file) return true;
        return false;
      });
    default:
      return yup.string().required(message).nullable();
  }
};

export const createYupSchema = (schema, config) => {
  const { required, name, message, fieldType } = config;
  // TODO: Remove image check when custom field image upload is fixed
  if (required && fieldType !== 'image') {
    schema[name] = mappedYupSchema({ fieldType, message });
  }
  return schema;
};

export const createYupSchemaDocuments = (schema, field) => {
  const { name, type } = field;
  const { required, message } = field.options;
  // TODO: Remove image check when custom field image upload is fixed
  if (required) schema[name] = mappedYupSchema({ fieldType: type, message: message || 'Required' });

  return schema;
};

export const createYupSchemaAllFieldsRequired = (schema, config) => {
  const { name, fieldType } = config;
  if (fieldType !== 'image') {
    schema[name] = mappedYupSchema({ fieldType, message: 'Required' });
  }
  return schema;
};
