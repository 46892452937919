import { InputTypes } from 'constants/inputs-types';
import { DocumentSections, DocumentTemplateType } from '../types';
import { productInputListData } from './@common/product.table.config';

export const ProformaInvoice: DocumentTemplateType[] = [
  {
    name: 'Buyer',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowStart: 4 }
  },
  {
    name: 'Invoice Number',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 1, colStart: 7, colSpan: 3, rowSpan: 2 }
  },
  {
    name: 'Invoice Date',
    section: DocumentSections.preProductTable,
    type: 'date',
    GridProps: { colStart: 10, rowStart: 1, colSpan: 3, rowSpan: 2 }
  },
  {
    name: 'Buyer Reference',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { rowStart: 3, colStart: 10 }
  },
  {
    name: 'name',
    type: 'text',
    section: DocumentSections.preProductTable,
    label: 'Reference',
    GridProps: { rowStart: 3, colStart: 7, colSpan: 3 }
  },
  {
    name: 'Shipper',
    label: 'Seller',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { colStart: 1, colSpan: 6, rowSpan: 3, rowStart: 1 }
  },
  {
    name: 'Consignee',
    label: 'Destination',
    type: 'autocomplete',
    section: DocumentSections.preProductTable,
    InputProps: {
      entity: 'contact',
      allowEdit: true,
      showUserAddress: true,
      isCreateNew: true,
      isEditable: true,
      hasContactMasterOptions: true
    },
    GridProps: { rowStart: 4, colStart: 7 }
  },
  {
    name: 'method_of_shipping',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-dispatch-method',
      defaultValue: 'SEA'
    },
    GridProps: { colStart: 1, rowStart: 5, colSpan: 4 }
  },
  {
    name: 'type_of_shipment',
    section: DocumentSections.preProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-shipment-type',
      defaultValue: 'FCL'
    },
    GridProps: { colStart: 5, colSpan: 4, rowStart: 5 }
  },
  {
    name: 'terms_of_payment',
    section: DocumentSections.preProductTable,
    type: 'textarea',
    InputProps: {
      containerClass: 'h-full',
      maxLength: 160,
      limitRows: 4
    },
    GridProps: { colStart: 9, colSpan: 4, rowStart: 5, rowSpan: 2 }
  },
  {
    name: 'port_of_loading',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 1, rowStart: 6, colSpan: 4 }
  },
  {
    name: 'port_of_discharge',
    type: 'text',
    section: DocumentSections.preProductTable,
    GridProps: { colStart: 5, rowStart: 6, colSpan: 4 }
  },
  {
    name: 'product_table',
    type: InputTypes.PRODUCT_TABLE,
    section: DocumentSections.productTable,
    options: {
      entity: 'products',
      TableProps: {
        data: productInputListData
      }
    },
    GridProps: { rowStart: 9, colSpan: 12 }
  },

  {
    name: 'Additional Info',
    type: 'textarea',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 1, rowStart: 11, colSpan: 6, rowSpan: 5 },
    InputProps: { bt: 1, maxLength: 520, limitRows: 8 }
  },
  {
    name: 'Bank Details',
    section: DocumentSections.postProductTable,
    type: 'textarea',
    GridProps: { colStart: 1, rowStart: 16, colSpan: 6, rowSpan: 4 },
    InputProps: { maxLength: 900, limitRows: 18 }
  },
  {
    type: 'subtitle',
    section: DocumentSections.postProductTable,
    name: 'Additional Charges or Discounts',
    label: 'Additional Charges or Discounts',
    GridProps: { rowStart: 11, colSpan: 6 }
  },
  {
    groupName: 'Additional Charges or Discounts',
    name: 'Additional Charges or Discounts',
    section: DocumentSections.postProductTable,
    label: false,
    type: 'array',
    GridProps: { colSpan: 2, rowMappingStart: 12, colSpanInputsGroupArray: [4, 2] }
  },
  {
    type: 'subtitle',
    name: 'Tax',
    section: DocumentSections.postProductTable,
    label: 'Tax %',
    GridProps: { rowStart: 14, colSpan: 4 }
  },
  {
    name: 'Tax',
    type: 'number',
    section: DocumentSections.postProductTable,
    label: false,
    GridProps: { rowStart: 14, colSpan: 2 },
    InputProps: {
      inputClass: 'text-right text-xs',
      containerClass: 'w-full h-full flex items-center justify-end'
    }
  },
  {
    type: 'subtitle',
    name: 'total',
    section: DocumentSections.postProductTable,
    label: 'TOTAL',
    GridProps: { colStart: 7, colSpan: 4, rowStart: 15 },
    options: {
      disabledOptions: true
    }
  },
  {
    name: 'total',
    type: 'number',
    section: DocumentSections.postProductTable,
    label: false,
    GridProps: { colStart: 11, rowStart: 15, pl: 3 },
    InputProps: {
      readOnly: true,
      containerClass: 'w-full h-full flex items-center justify-end pr-2'
    }
  },
  {
    name: 'incoterms',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 16, colSpan: 4 }
  },
  {
    name: 'currency',
    section: DocumentSections.postProductTable,
    type: 'select',
    InputProps: {
      optionsList: 'select-currency'
    },
    GridProps: { colStart: 11, rowStart: 16, colSpan: 2 }
  },
  {
    name: 'Signatory Company',
    type: 'text',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 17 }
  },
  {
    name: 'Name of Authorized Signatory',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 18 }
  },
  {
    name: 'signature',
    type: 'signature',
    section: DocumentSections.postProductTable,
    GridProps: { colStart: 7, rowStart: 19 }
  }
];
