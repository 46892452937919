import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

async function recordPayment(inputs: any) {
  return http.post(
    `${API_ENDPOINTS.PAYMENTS_DETAILS}`,
    jsonToFormData({ schema: 'payment_detail', inputs })
  );
}

export const useRecordPaymentMutation = () => {
  return useMutation((input: any) => recordPayment(input), {
    onSuccess: () => {
      toastNotification({ type: 'success', description: 'Payment was recorded successfully.' });
    }
  });
};
