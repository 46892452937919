import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const completeTaskMutation = () => {
  return useMutation(({ id, token, data }: { id: string; token?: string; data: any }) =>
    http.put(API_ENDPOINTS.COMPLETE_TASK(id, token), data)
  );
};

export default completeTaskMutation;
