import RenderIf from '@components/common/render-if';
import FullInput from '@components/dataEntry/FullInput';
import Form from '@components/dataEntry/form';
import FormInput from '@components/dataEntry/form-input';
import FormInputCustomFields from '@components/dataEntry/form-input-custom-fields';
import { OptionsListProps } from '@components/dataEntry/select';
import ModalView from '@components/feedback/modal-view';
import { yupResolver } from '@hookform/resolvers/yup';
import useModal from '@hooks/use-modal';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useTrialSales from '@hooks/use-trial-sales';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { useStore } from '@store/useStore';
import { createYupSchema } from '@utils/create-yup-schema';
import { formatCustomFieldData } from '@utils/format-custom-field-data';
import { formatSelectKeyValueList } from '@utils/format-select-key-value-list';
import { formatSelectStringList } from '@utils/format-select-string-list';
import { removeNullValues } from '@utils/remove-null-values';
import { FormIds } from 'constants/form-ids';
import { ModalIds } from 'constants/modal-ids';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';

const AddShipmentForm = () => {
  // states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const modalState = useStore((state) => state.modalState);
  const { onShipmentLimitValidation, toastFeedBack } = useTrialSales();
  const autocompleteV2CreateNewEntityItemState = useStore(
    (state) => state.autocompleteV2CreateNewEntityItemState
  );
  const setAutocompleteV2CreateNewEntityItemState = useStore(
    (state) => state.setAutocompleteV2CreateNewEntityItemState
  );

  const contactMasterState = useStore((state) => state.contactMasterState);

  const { mutateAsync: addShipmentMutation, isLoading: addShipmentLoading } =
    useRequest.shipments.create();
  const { closeModalByPosition } = useModal();
  const { push, query } = useRouter();
  const queryClient = useQueryClient();

  // const
  const queryParams = {
    entity_type: 'Shipment'
  };
  const shipmentId = modalState?.[0]?.entityId;
  const formValues = modalState?.[0]?.formValues;
  const modalId = modalState?.[0]?.id;
  const redirect = modalState?.[0]?.redirect || query?.redirect;

  // queries
  const { data: { data: workFlowsData = [] } = {}, isLoading: workflowLoading } =
    useRequest.workflows.get({
      params: { pagination: false },
      options: { keepPreviousData: true }
    });
  const { data: customFieldMappingDataTable = [], isLoading: isCustomFieldLoading } =
    useRequest.custom_fields.getByEntityType({
      params: queryParams,
      options: { keepPreviousData: true }
    });
  const {
    data: shipmentDetails,
    isLoading: shipmentDetailsLoading,
    isSuccess
  } = useRequest.shipments.getById(
    { id: shipmentId },
    {
      options: {
        keepPreviousData: true,
        enabled: !!shipmentId
      }
    }
  );

  const workflowOptionList = formatSelectKeyValueList({
    data: workFlowsData,
    nameKey: 'name',
    valueKey: 'id'
  });

  const isWorkflowsPresent = workflowOptionList.length > 0;
  const hasCustomFields = customFieldMappingDataTable.length > 0;
  const hasRequiredCustomFields = customFieldMappingDataTable.some((x) => x.required);
  const validationSchema = yup.object({
    name: yup.string().required('Shipment Reference is a required field'),
    ...customFieldMappingDataTable.reduce(createYupSchema, {})
  });
  const fullLoading = workflowLoading || isCustomFieldLoading || shipmentDetailsLoading;
  const hasExceed = onShipmentLimitValidation();
  const [defaultValues, setDefaultValues] = useState<any>({});

  const methods = useForm({
    defaultValues: removeNullValues(defaultValues),
    resolver: validationSchema ? yupResolver(validationSchema) : null
  });

  const customFieldNames = formatCustomFieldData({
    data: customFieldMappingDataTable,
    details: shipmentDetails,
    onlyRequired: true
  });

  const requiredFields = customFieldMappingDataTable.filter((x) => x.required);

  const inputs = [
    {
      label: 'Shipment Name',
      name: 'name',
      containerClass: 'col-span-full',
      placeholder: 'Enter shipment reference'
    },
    {
      name: 'shipper_id',
      type: 'autocomplete',
      containerClass: 'w-1/2 pr-1.5',
      label: 'Shipper',
      entity: 'contact',
      ContactMasterProps: {
        updateValuesWithContactMaster: true,
        requiredFields
      },
      isEditable: true
    },
    {
      name: 'consignee_id',
      type: 'autocomplete',
      containerClass: 'w-1/2 pl-1.5',
      label: 'Consignee',
      entity: 'contact',
      ContactMasterProps: {
        updateValuesWithContactMaster: true,
        requiredFields
      },
      isEditable: true
    },
    {
      name: 'ship_type',
      type: 'select',
      label: 'Type',
      optionsList: 'select-import-export',
      containerClass: 'w-1/3 pr-1.5'
    },
    {
      type: 'select',
      label: 'Method of Shipping',
      name: 'method_of_shipping',
      optionsList: 'select-dispatch-method',
      containerClass: 'w-1/3 px-1.5'
    },
    {
      name: 'type_of_shipment',
      type: 'select',
      label: 'Type of Shipment',
      optionsList: 'select-shipment-type',
      containerClass: 'w-1/3 pl-1.5'
    },
    {
      type: 'select',
      label: 'Currency',
      name: 'currency',
      optionsList: 'select-currency',
      containerClass: 'w-full'
    }
  ];

  const initialValues = {
    id: shipmentDetails?.id,
    name: autocompleteV2CreateNewEntityItemState.createItemName || '',
    shipper_id: shipmentDetails?.shipper_id,
    consignee_id: shipmentDetails?.consignee_id,
    ship_type: shipmentDetails?.ship_type || 'import',
    method_of_shipping: shipmentDetails?.method_of_shipping || 'SEA',
    type_of_shipment: shipmentDetails?.type_of_shipment || 'FCL',
    currency: shipmentDetails?.currency || 'USD',
    plan_template_id: shipmentDetails?.plan_template_id || '',
    ...customFieldNames
  };

  // actions
  const { handleSubmit } = useMutationSideEffects({
    setErrorsServerValidation,
    showToastMessage: !hasExceed,
    message: 'Shipment was created',
    onSubmit: async (inputs: any) => {
      const hasExceed = onShipmentLimitValidation();
      if (hasExceed) {
        return toastFeedBack();
      }
      await addShipmentMutation(inputs, {
        onSuccess: (data) => {
          queryClient.invalidateQueries([API_ENDPOINTS.SHIPMENTS]);
          if (modalState.length > 0 && modalState?.[1]?.hide) {
            setAutocompleteV2CreateNewEntityItemState({ data: data?.data });
          }
          closeModalByPosition();
          if (redirect) {
            push(`/shipments/${data?.data?.['id']}`);
          }
        }
      });
    }
  });

  const setValues = (values) => {
    methods.reset(values);
    setDefaultValues(values);
  };

  // handle form
  useEffect(() => {
    setValues(initialValues);
  }, [isSuccess]);

  // handle form
  useEffect(() => {
    if (formValues && modalId === ModalIds.ADD_SHIPMENT_FORM) setValues(formValues);
  }, [formValues]);

  useEffect(() => {
    if (contactMasterState.fullValues) {
      setValues(contactMasterState.fullValues);
      contactMasterState?.onCleanState();
    }
  }, [contactMasterState.fullValues]);

  return (
    <ModalView header="Add Shipment" loading={fullLoading}>
      <Form
        methods={methods}
        errorsServerValidation={errorsServerValidation}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        formId={FormIds.ADD_SHIPMENT_FORM}
      >
        <div className="flex flex-wrap gap-y-3">
          {inputs.map((data, index) => (
            <FullInput key={index} {...data} />
          ))}
        </div>

        <div className="flex flex-wrap -mx-3 mt-2">
          <RenderIf isTrue={isWorkflowsPresent}>
            <div className="w-full px-3 mb-3">
              <FormInput
                type="select"
                label="Add Workflow"
                name="plan_template_id"
                optionsList={workflowOptionList}
                allowEmptyValue={true}
                defaultValue={defaultValues?.plan_template_id}
                containerClass="col-span-3"
              />
            </div>
          </RenderIf>

          <RenderIf isTrue={hasCustomFields && hasRequiredCustomFields}>
            <div
              className="text-gray-600 font-bold text-m truncate w-full border-b border-gray-200 ml-2.5 mr-2.5 mb-2 mt-4 relative"
              style={{ marginRight: '0.625rem' }}
            >
              Custom Fields
            </div>
          </RenderIf>

          {customFieldMappingDataTable.map((customField, index) => (
            // TODO: Remove image check when custom field image upload is fixed
            <RenderIf
              key={customField.name}
              isTrue={customField.fieldType !== 'image' && customField.required}
            >
              <div className="w-full px-3 mb-3">
                <FormInputCustomFields
                  data={customField}
                  autocompleteProps={{
                    lastElement: customFieldMappingDataTable.length - 1 === index,
                    defaultValue: defaultValues?.[customField.name] || ''
                  }}
                  selectProps={{
                    optionsList: formatSelectStringList(customField.value) as OptionsListProps[]
                  }}
                  checkboxProps={{
                    checked: defaultValues?.[customField.name] || false
                  }}
                  imageProps={{
                    accept: ['image/png', 'image/jpeg'],
                    message: 'PNG, JPG up to 10MB',
                    maxFiles: 1,
                    maxSizeBytes: 10485760
                  }}
                />
              </div>
            </RenderIf>
          ))}
        </div>
        <ModalFooter
          disabled={addShipmentLoading}
          modalId={ModalIds.ADD_SHIPMENT_FORM}
          mainButtonText="Add"
          mainButtonType="submit"
        />
      </Form>
    </ModalView>
  );
};

export default AddShipmentForm;
