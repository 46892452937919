const initialTasksState = {
  selectedTaskIds: []
};

export const createTasksSlice = (set) => ({
  ...initialTasksState,
  setSelectedTaskIds: (taskIds) => {
    return set(() => ({ selectedTaskIds: taskIds }), false, 'setSelectedTaskIds');
  }
});
