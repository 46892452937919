import { FC, useRef, useState } from 'react';
import { Dropdown, DropdownBox, DropdownButton } from './dropdown';

import RenderIf from '@components/common/render-if';
import { CogIcon, LogoutIcon, ViewListIcon } from '@heroicons/react/outline';
import useRequest from '@services/api';
import { User } from '@services/types';
import { convertFirstLetterUpperCase } from '@utils/convert-first-letter-uppercase';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';

interface Props {
  userData?: User;
}

//TODO: remove me after the migration is finished.
const ProfileDropdown: FC<Props> = ({ userData }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const { mutateAsync } = useRequest.user.signOut();
  const queryClient = useQueryClient();
  const { push } = useRouter();

  const handleSignOut = async () => {
    await mutateAsync();
    queryClient.clear();
    window.location.href = '/sign-in';
  };

  const handleClick = (link) => {
    setOpen(false);
    push(link);
  };

  const optionsMap = [
    {
      label: 'Workflows',
      action: () => handleClick('/workflows'),
      condition: userData?.role !== 'guest',
      icon: <ViewListIcon className="w-5 h-5" />
    },
    {
      label: 'Settings',
      action: () => handleClick('/settings/profile'),
      icon: <CogIcon className="w-5 h-5" />
    },
    {
      label: 'Sign out',
      action: handleSignOut,
      icon: <LogoutIcon className="w-5 h-5" />
    }
  ];

  const Row = ({ label, action, icon }) => (
    <div
      className="flex flex-row items-center px-5 py-2 gap-x-4 bg-white leading-none text-primary-navy-400 hover:bg-gray-100 cursor-pointer"
      onClick={action}
    >
      {icon}
      <span className="w-full text-sm">{label}</span>
    </div>
  );

  const RowWrapper = ({ label, action, condition, icon }) =>
    condition ? (
      <RenderIf isTrue={condition}>
        <Row label={label} action={action} icon={icon} />
      </RenderIf>
    ) : (
      <Row label={label} action={action} icon={icon} />
    );

  return (
    <Dropdown
      hidden={userData === undefined}
      target={ref}
      isOpen={open}
      onOpen={setOpen}
      className="relative shrink-0"
    >
      <DropdownButton
        onClick={() => setOpen(!open)}
        className="flex rounded-full text-white focus:outline-none transition duration-150 ease-in-out justify-center"
      >
        <span className="inline-flex items-center justify-center h-9 w-9 rounded-full bg-accent-green-50 hover:bg-green-100	">
          <span className="text-lg font-medium  text-primary-green-500 capitalize">
            {userData?.email.charAt(0)}
          </span>
        </span>
      </DropdownButton>
      <DropdownBox>
        <div className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg z-50">
          <div
            className="py-1 rounded-md bg-white shadow-xs w-auto min-w-[18rem]"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <div className="flex px-4 py-2">
              <span className="inline-flex items-center justify-center h-9 w-9 px-2 rounded-full bg-primary-green-500 hover:bg-primary-green-400">
                <span className="text-2xl font-medium text-white capitalize">
                  {userData?.email.charAt(0)}
                </span>
              </span>
              <div className="pl-3">
                <div className="text-sm text-gray-700">{userData?.name || userData?.email}</div>
                <div className="flex flex-row items-center gap-x-2 text-primary-grey-500 mt-1">
                  <span className="text-sm">{userData?.organization}</span>
                  <span className="text-xs bg-primary-grey-50 px-3 py-.5 ml-2 rounded-[100px]">
                    {convertFirstLetterUpperCase(userData?.role)}
                  </span>
                </div>
              </div>
            </div>
            {optionsMap.map(({ label, action, condition, icon }, index) => (
              <RowWrapper
                key={index}
                label={label}
                action={action}
                icon={icon}
                condition={condition}
              />
            ))}
          </div>
        </div>
      </DropdownBox>
    </Dropdown>
  );
};

export default ProfileDropdown;
