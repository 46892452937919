import { toastNotification } from '@hooks/toast-notification';
import { useMutation, useQueryClient } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { jsonToFormData } from '../utils/json-to-formData';

export interface UpdateUserType {
  email?: string;
  name?: string;
  company?: string;
  phone_number?: string;
  signature_upload?: string;
  signature?: string;
  stamp?: HTMLInputElement | string;
}

async function updateUser(userId: number, inputs: UpdateUserType) {
  return http.patch(
    `${API_ENDPOINTS.SETTINGS}/${userId}`,
    jsonToFormData({ schema: 'user', inputs })
  );
}
export const useUpdateUserMutation = (userId: number) => {
  const queryClient = useQueryClient();
  return useMutation((input: UpdateUserType) => updateUser(userId, input), {
    onSuccess: (data) => {
      queryClient.setQueryData([API_ENDPOINTS.SETTINGS], data.data);
      toastNotification({ type: 'success', description: 'Profile was updated' });
    }
  });
};
