import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

const getUploadedDocumentActivities = (
  { id },
  options?: ReactQueryOptionsType
): any /*TODO: Add response type */ => {
  return useQuery([API_ENDPOINTS.DOCUMENT_ACTIVITY(id)], queryFetch, options);
};

export default getUploadedDocumentActivities;
