import { ButtonPropsType } from '@components/general/button';
import { ModalIds } from 'constants/modal-ids';
import { FC } from 'react';

export type ModalFooterProps = {
  modalId?: ModalIds | string;
  disabled?: boolean;
  onClick?: () => void;
  mainButtonText?: string;
  mainButtonType?: 'submit' | 'reset' | 'button';
  mainButtonVariant?: ButtonPropsType['variant'];
  buttonsSize?: 'xs';
  addContinue?: boolean;
  onClickAddContinue?: () => void;
} & ButtonPropsType;

const Footer: FC<ModalFooterProps> = (props) => {
  return (
    <div className="px-4 py-3 fixed bottom-0 left-0 w-full border-t border-gray-200 bg-white">
      {props.children}
    </div>
  );
};

export default Footer;
