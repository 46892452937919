import { ReactQueryOptionsType } from '../../types';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';
import { useQuery } from 'react-query';
import { OptionsListProps } from '@components/dataEntry/select';

export const fetchDocumentDocTypes = async () => {
  const { data } = await http.get(`${API_ENDPOINTS.DOCUMENTS}/doc_types`);
  return data;
};

export const useDocTypesQuery = (reactQueryOptions?: ReactQueryOptionsType) => {
  return useQuery<OptionsListProps[], Error>(
    [`${API_ENDPOINTS.DOCUMENTS}/doc_types`],
    fetchDocumentDocTypes,
    reactQueryOptions
  );
};
