import { queryString } from '@services/api/utils/query-string';
import { useStore } from '@store/useStore';
import { useRouter } from 'next/router';

const useManageParams = () => {
  const setResetSearchClicked = useStore((state) => state.setResetSearchClicked);
  const router = useRouter();
  const { basePath, pathname, query } = router;
  const originPath = basePath;
  const params: any = query;
  const includeSort = Object.keys(params)
    .map((item) => ['sort', 'direction'].includes(item))
    .some((item) => item);

  const addParams = (newParams) => {
    const obj = { ...params, ...newParams };
    const isPage = Object.keys(newParams).find((key) => key === 'page');
    if (!isPage || (isPage && obj.page === 1)) {
      delete obj.page;
    }

    router.push(pathname + queryString(obj));
  };

  const removeParams = (paramsKeys) => {
    paramsKeys?.map((key) => {
      delete params[key];
    });
    router.push(pathname + queryString(params));
  };

  const getParamsByKey = (paramsKey) => {
    if (params[paramsKey]) {
      return params[paramsKey];
    }
    return false;
  };

  const updateParamsByKey = (paramsKey, paramsValue) => {
    if (typeof paramsValue === 'boolean') {
      removeParams([paramsKey]);
      return;
    }
    if (params[paramsKey]) {
      params[paramsKey] = paramsValue;
      addParams({ [paramsKey]: paramsValue });
    }
  };

  const toggleParams = (paramsObject) => {
    const paramsKey = Object.keys(paramsObject)[0];
    const paramsValue = Object.values(paramsObject)[0];
    const isSame = getParamsByKey(paramsKey) === paramsValue;

    const find = Object.keys(params).find((item) => item === paramsKey);

    if (isSame) {
      removeParams([paramsKey]);
    }

    if (find && !isSame) {
      updateParamsByKey(paramsKey, paramsValue);
    }

    if (!find && !isSame) {
      addParams(paramsObject);
    }
  };

  const cleanSearch = (query?: Record<string, any>) => {
    setResetSearchClicked(true);
    router.push({
      pathname: router.pathname,
      query:
        {
          ...(router?.query?.id ? { id: router?.query?.id } : {}),
          ...(query && { ...query })
        } ?? {}
    });
  };

  return {
    pathname,
    params,
    queryString,
    addParams,
    removeParams,
    toggleParams,
    cleanSearch,
    getParamsByKey,
    originPath,
    includeSort,
    updateParamsByKey,
    router
  };
};

export default useManageParams;
