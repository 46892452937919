import { ReactQueryOptionsType } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import { queryFetch } from '../utils/query-fetch';

export const getShipmentTracking = ({ id }, options?: ReactQueryOptionsType) => {
  return useQuery([API_ENDPOINTS.GET_SHIPMENT_TRACKING(id)], queryFetch, options);
};

export default getShipmentTracking;
