import RenderIf from '@components/common/render-if';
import Form from '@components/dataEntry/form';
import FormAutocomplete from '@components/dataEntry/form-autocomplete';
import FormInput from '@components/dataEntry/form-input';
import FormInputCheckboxOrToggle from '@components/dataEntry/form-input-checkbox-or-toggle';
import MultiSelectDropdown from '@components/dataEntry/multi-select-dropdown';
import ModalView from '@components/feedback/modal-view';
import useManageParams from '@hooks/use-manage-params';
import useModal from '@hooks/use-modal';
import useRequest from '@services/api';
import { useAllUsersQuery } from '@services/api/user/get-all-users';
import { useWorkflowsTasksQuery } from '@services/api/workflows-tasks/get-all-task-templates';
import { useUpdateWorkflowsTasksMutation } from '@services/api/workflows-tasks/use-update-workflows-tasks';
import { TaskTemplate } from '@services/types';
import { useStore } from '@store/useStore';
import { createYupSchema } from '@utils/create-yup-schema';
import { formatUserNameSelectList } from '@utils/format-user-name-select-list';
import { ModalIds } from 'constants/modal-ids';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import ModalFooter from '../modal-footer';
import { removeFieldsList } from '../portals/portalsBody/Task/Edit';

const WorkflowTaskForm = () => {
  // Set states
  const [errorsServerValidation, setErrorsServerValidation] = useState({});
  const [isDocumentRequest, setDocumentRequest] = useState(false);
  const [approvalNeeded, setApprovalNeeded] = useState(false);
  const [defaultValues, setDefaultValues] = useState<TaskTemplate>({});
  const [previousTaskSelected, setPreviousTaskSelected] = useState(false);
  const [previousWorkflowTaskOptions, setPreviousWorkflowTaskOptions] = useState([]);
  const [isRequestField, setIsRequestField] = useState(null);
  const [requestedFields, setRequestedFields] = useState(null);
  // hooks
  const { data: fieldsData } = useRequest.field_data.getByEntityType({
    params: { entity_type: 'Shipment' }
  });

  const { params } = useManageParams();
  const { closeModalByPosition, closeAllModals } = useModal();
  const fields = fieldsData ? [...fieldsData] : [];
  const requestedFieldsOptions = [
    ...fields
      .map((x) => ({ label: x.label, value: x.id }))
      .filter((x) => !removeFieldsList.includes(x.label))
  ];

  // Set data stores
  const modalState = useStore((state) => state.modalState);
  const isAutocompleteLoading = useStore((state) => state.autocompleteLoading);
  const selectedWorkflowTaskId = modalState?.[0]?.entityId;

  // Queries
  const {
    data: { data: workflowData = {} } = {},
    refetch,
    isLoading: workflowLoading
  } = useRequest.workflows.getById(
    { id: params?.id },
    { options: { keepPreviousData: true, enabled: !!params?.id } }
  );
  const {
    data: taskData,
    isLoading: taskLoading,
    isSuccess: isTaskDataSuccess
  } = useWorkflowsTasksQuery({
    task_template_id: selectedWorkflowTaskId
  });
  const { mutateAsync: updateTask, isLoading: updateTaskLoading } =
    useUpdateWorkflowsTasksMutation();
  const { mutateAsync: addTaskFromWorkflow, isSuccess } = useRequest.tasks_templates.create();
  const { data: allUsersData, isLoading: usersLoading } = useAllUsersQuery();
  const { data: currentUserData } = useRequest.user.get();

  // State variables
  const modalMode = modalState?.[0]?.mode;
  const isNewTask = modalMode === 'add' || modalMode === 'duplicate' || modalMode === 'continue';
  const headerLabel = modalMode === 'edit' ? 'Edit Task' : 'Add Task';
  const isPopulateInitialValues = modalMode === 'edit';
  const fullLoading = isAutocompleteLoading || taskLoading || usersLoading;
  const mutationsLoading = updateTaskLoading;
  const taskDataTable = !taskLoading && taskData;
  const teamMemberOptions = allUsersData ? allUsersData.data : [];
  const workflowTaskData =
    !workflowLoading && workflowData.task_templates ? workflowData.task_templates : [];
  const reviewerOptions = formatUserNameSelectList(teamMemberOptions);
  const assigneeTeamMemberOptions = formatUserNameSelectList(teamMemberOptions, true);
  const isATeamMember = (teamMember) =>
    assigneeTeamMemberOptions.find((member) => member.value === teamMember);

  const [assigneeType, setAssigneeType] = useState('none');

  // form validation
  const validationSchema = yup.object({
    has_reviewer: yup.string(),
    name: yup.string().required('Please enter a name for this task.'),
    ...[
      {
        name: 'reviewer_id',
        required: isDocumentRequest || approvalNeeded,
        message: 'Please select a reviewer.'
      },
      {
        name: 'filename',
        required: isDocumentRequest,
        message: "Can't be blank"
      },
      {
        name: 'assignee_email',
        required: assigneeType === 'team_member',
        message: 'Please fill this field in'
      },
      {
        name: 'email',
        required: assigneeType === 'email',
        message: 'Please fill this field in'
      },
      {
        name: 'previous_task_template_id',
        required: previousTaskSelected,
        message: 'Please select a previous workflow task'
      }
    ].reduce(createYupSchema, {})
  });

  // actions
  const toggleDocumentRequest = (value) => {
    setDocumentRequest(value);
    setApprovalNeeded(value);
  };
  const setInitialValues = () => {
    setDefaultValues({
      reviewer_id: parseInt(currentUserData.user.id),
      assignee_type: 'none',
      duration: '0',
      trigger_type:
        previousWorkflowTaskOptions.length > 0 ? 'previous_task' : 'workflow_import_date',
      previous_task_template_id:
        previousWorkflowTaskOptions.length > 0 ? workflowTaskData[0].id : 0,
      assignee_email: currentUserData.user.email
    });

    setPreviousTaskSelected(previousWorkflowTaskOptions.length > 0 ? true : false);
  };

  const resetModal = () => {
    setAssigneeType('none');
    setApprovalNeeded(false);
    toggleDocumentRequest(false);
  };

  const formatOnSubmitValues = (inputs) => {
    let modifiedInputsWithWorkflowId = {
      ...inputs,
      assignee_email:
        assigneeType === 'team_member' ? inputs.assignee_email : inputs.email ? inputs.email : '',
      duration: inputs.time_unit === 'weeks' ? inputs.duration * 7 : inputs.duration,
      is_file_upload: isDocumentRequest,
      has_reviewer: isDocumentRequest || approvalNeeded ? true : approvalNeeded,
      reviewer_id: isDocumentRequest || approvalNeeded ? inputs.reviewer_id : '',
      filename: isDocumentRequest ? inputs.filename : '',
      is_data_request: isRequestField && requestedFields.length > 0 ? true : false,
      plan_template_id: params.id,
      requested_data: requestedFields
    };
    delete modifiedInputsWithWorkflowId.email;
    return modifiedInputsWithWorkflowId;
  };

  const onSubmit = async (inputs) => {
    try {
      const modifiedInputsWithWorkflowId = formatOnSubmitValues(inputs);
      if (inputs.trigger_type === 'workflow_import_date') {
        delete modifiedInputsWithWorkflowId.previous_task_template_id;
      }

      if (inputs.assignee_type !== 'email' && inputs.assignee_type !== 'team_member') {
        delete modifiedInputsWithWorkflowId.assignee_email;
      }

      let modifiedInputsWhenUpdate = {
        ...modifiedInputsWithWorkflowId,
        id: selectedWorkflowTaskId
      };

      if (isNewTask) {
        delete modifiedInputsWithWorkflowId.id;
        await addTaskFromWorkflow(modifiedInputsWithWorkflowId);
        refetch();
        if (modalMode !== 'continue') {
          closeModalByPosition();
          return;
        }
      } else {
        if (modifiedInputsWhenUpdate.duration === '') {
          modifiedInputsWhenUpdate.duration = 0;
        }

        await updateTask(modifiedInputsWhenUpdate);
        refetch();
      }
      setDefaultValues({});
      resetModal();
      closeAllModals();
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors?.assignee_email) {
        setErrorsServerValidation({
          ...errors,
          assignee_id: errors.assignee_email,
          email: errors.assignee_email
        });
      } else {
        setErrorsServerValidation(errors);
      }
    }
  };

  const onSubmitContinue = async (inputs) => {
    try {
      const modifiedInputsWithWorkflowId = formatOnSubmitValues(inputs);

      delete modifiedInputsWithWorkflowId.id;
      if (inputs.trigger_type === 'workflow_import_date') {
        delete modifiedInputsWithWorkflowId.previous_task_template_id;
      }

      if (inputs.assignee_type !== 'email' && inputs.assignee_type !== 'team_member') {
        delete modifiedInputsWithWorkflowId.assignee_email;
      }
      await addTaskFromWorkflow(modifiedInputsWithWorkflowId);

      setInitialValues();
      resetModal();
      document.getElementById('name').scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch (err) {
      const errors = err.response.data.errors;
      if (errors?.assignee_email) {
        setErrorsServerValidation({
          ...errors,
          assignee_id: errors.assignee_email,
          email: errors.assignee_email
        });
      } else {
        setErrorsServerValidation(errors);
      }
    }
  };

  const toggleApprovalNeeded = (value) => {
    setApprovalNeeded(value);
  };

  const toggleRequestField = (value) => {
    setIsRequestField(value);
  };

  const onChangeRequestedFields = (selected) => {
    setRequestedFields(selected);
  };

  const updateTaskState = (newAssigneeType?: string) => {
    const task = taskDataTable;
    const newAssigneeLocalType = newAssigneeType || task?.assignee_type || assigneeType;

    if (isNewTask) {
      delete task.name;
    }
    setAssigneeType(newAssigneeType || task?.assignee_type);
    setDocumentRequest(task?.is_file_upload || false);
    setApprovalNeeded(Boolean(task?.reviewer_id) || task?.is_file_upload || false);
    setPreviousTaskSelected(task?.trigger_type === 'previous_task');
    setRequestedFields(task?.requested_data || []);
    setIsRequestField(task?.is_data_request || false);
    const taskWithWorkflowId = {
      ...task,
      plan_template_id: params.id,
      reviewer_id: Boolean(task?.reviewer_id)
        ? task?.reviewer_id
        : parseInt(currentUserData.user.id),
      assignee_type: newAssigneeType || task?.assignee_type,
      assignee_email:
        newAssigneeLocalType === 'team_member' && !isNewTask && !isATeamMember(task.assignee_email)
          ? currentUserData.user.email
          : task?.assignee_email,
      email:
        newAssigneeType === 'email'
          ? ''
          : task?.assignee_email
          ? task?.assignee_email
          : currentUserData.user.email
    };

    setDefaultValues({
      ...taskWithWorkflowId
    });
  };

  const enablePreviousTaskOptions = (value) => {
    if (value === 'previous_task') {
      setPreviousTaskSelected(true);
    } else {
      setPreviousTaskSelected(false);
    }
  };

  const onAssigneeTypeChange = (newAssigneeType) => {
    setAssigneeType(newAssigneeType);
    if (!isNewTask) {
      updateTaskState(newAssigneeType);
    }
  };

  // effects
  useEffect(() => {
    if (isPopulateInitialValues && selectedWorkflowTaskId) {
      updateTaskState();
    }
  }, [selectedWorkflowTaskId, taskDataTable]);

  useEffect(() => {
    setPreviousWorkflowTaskOptions(
      workflowTaskData
        ? workflowTaskData.reduce((acc, workflowTask) => {
            if (
              ((modalMode === 'edit' && workflowTask.id !== selectedWorkflowTaskId) ||
                modalMode === 'add' ||
                modalMode === 'continue') &&
              workflowTask.previous_task_template_id !== defaultValues.id
            ) {
              const description =
                workflowTask.trigger_type === 'workflow_import_date'
                  ? `Set when workflow is imported`
                  : `Due ${workflowTask.duration} days after ${workflowTask.previous_task_template_name}`;
              acc.push({
                value: workflowTask.id,
                label: workflowTask.name,
                descriptionForLabel: description
              });
            }
            return acc;
          }, [])
        : []
    );
  }, [workflowTaskData, defaultValues.id]);

  // set empty
  useEffect(() => {
    if (!isPopulateInitialValues) {
      setInitialValues();
    }
  }, [isPopulateInitialValues, previousWorkflowTaskOptions]);

  return (
    <ModalView header={headerLabel} loading={fullLoading}>
      <Form
        defaultValues={defaultValues}
        validationSchema={validationSchema}
        isTouched
        errorsServerValidation={errorsServerValidation}
        forceUpdate
        updateValuesAfterSuccess={isTaskDataSuccess || isSuccess}
      >
        {({ onSubmitForm, isDirty }) => {
          return (
            <>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full px-3 mb-3">
                  <FormInput label="Task Name" name="name" type="text" />
                </div>
                <div className="w-full px-3 mb-3">
                  <FormInput
                    type="select"
                    label="Assign to"
                    name="assignee_type"
                    optionsList={[
                      { name: 'None', value: 'none' },
                      { name: 'Shipper', value: 'shipper' },
                      { name: 'Consignee', value: 'consignee' },
                      { name: 'Buyer', value: 'buyer' },
                      { name: 'Freight Forwarder', value: 'freight_forwarder' },
                      { name: 'Email', value: 'email' },
                      { name: 'Team Member', value: 'team_member' }
                    ]}
                    customOnChange={onAssigneeTypeChange}
                  />
                </div>
                <RenderIf isTrue={assigneeType === 'team_member'}>
                  <div className="w-full px-3 mb-3">
                    <FormInput
                      type="select"
                      label="Team Member"
                      name="assignee_email"
                      optionsList={assigneeTeamMemberOptions}
                      disabled={assigneeType !== 'team_member'}
                      containerClass="transition ease-in-out duration-200"
                    />
                  </div>
                </RenderIf>
                <RenderIf isTrue={assigneeType === 'email'}>
                  <div className="w-full px-3 mb-3">
                    <FormInput
                      type="text"
                      label="Email"
                      name="email"
                      disabled={assigneeType !== 'email'}
                      containerClass="transition ease-in-out duration-200"
                    />
                  </div>
                </RenderIf>
                <div className="w-full px-3 mb-3">
                  <FormInput
                    type="textarea"
                    label="Description"
                    rows={3}
                    name="description"
                    placeholder="Add a description"
                  />
                </div>
                <div className="w-full px-3 mb-3">
                  <FormInput
                    type="select"
                    label="Trigger After"
                    name="trigger_type"
                    optionsList={[
                      { name: 'Workflow Import Date', value: 'workflow_import_date' },
                      { name: 'Previous Task', value: 'previous_task' },
                      { name: 'Cargo Ready Date', value: 'cargo_ready_date' }
                    ]}
                    disabled={previousWorkflowTaskOptions.length == 0}
                    customOnChange={enablePreviousTaskOptions}
                  />
                </div>
                <RenderIf isTrue={previousWorkflowTaskOptions.length > 0 && previousTaskSelected}>
                  <div className="w-full px-3 mb-3">
                    <FormAutocomplete
                      type="select"
                      icon="task"
                      label="Select a Task"
                      defaultValue={defaultValues.previous_task_template_id}
                      name="previous_task_template_id"
                      customOptions={previousWorkflowTaskOptions}
                      disabled={previousWorkflowTaskOptions.length == 0 || !previousTaskSelected}
                      hideNewEntryOnEntity
                    />
                  </div>
                </RenderIf>
                <div className="w-full px-3 mb-3">
                  <div className="flex flex-row gap-x-4">
                    <FormInput
                      containerClass="w-1/2"
                      name="duration"
                      type="number"
                      placeholder="0"
                      min={0}
                      step={1}
                    />
                    <FormInput
                      type="select"
                      name="time_unit"
                      optionsList={[{ name: 'Days', value: 'days' }]}
                      containerClass="w-1/2"
                    />
                  </div>
                </div>
                <div className="w-full px-3 mb-3">
                  <div className="flex flex-row gap-x-4">
                    <FormInputCheckboxOrToggle
                      label="Request Document"
                      containerClass="w-1/2"
                      name="is_file_upload"
                      onChangeCheckbox={toggleDocumentRequest}
                      checked={isDocumentRequest}
                      type="checkbox"
                      align="horizontal"
                    />
                    <FormInputCheckboxOrToggle
                      label="Requires Approval"
                      name="has_reviewer"
                      containerClass="w-1/2"
                      disabled={isDocumentRequest}
                      onChangeCheckbox={toggleApprovalNeeded}
                      checked={approvalNeeded}
                      type="checkbox"
                      align="horizontal"
                    />
                    <FormInputCheckboxOrToggle
                      label="Request info"
                      containerClass="w-1/2"
                      name="is_data_request"
                      onChangeCheckbox={toggleRequestField}
                      checked={isRequestField}
                      type="checkbox"
                      align="horizontal"
                    />
                  </div>
                </div>
                <RenderIf isTrue={isRequestField}>
                  <div className="w-full px-3 mb-3">
                    <MultiSelectDropdown
                      name={'requested_data'}
                      withCheckbox
                      defaultValue={requestedFields && requestedFields?.map((x) => x.value)}
                      options={requestedFieldsOptions}
                      onChange={onChangeRequestedFields}
                    />
                  </div>
                </RenderIf>
                <RenderIf isTrue={isDocumentRequest}>
                  <div className="w-full px-3 mb-3">
                    <FormInput
                      label="Document Name"
                      containerClass="transition ease-in-out duration-200"
                      name="filename"
                    />
                  </div>
                </RenderIf>
                <RenderIf isTrue={approvalNeeded}>
                  <div className="w-full px-3 mb-3">
                    <FormInput
                      type="select"
                      label="Reviewer"
                      name="reviewer_id"
                      optionsList={reviewerOptions}
                      containerClass="transition ease-in-out duration-200"
                    />
                  </div>
                </RenderIf>
              </div>
              <ModalFooter
                disabled={mutationsLoading || !isDirty}
                onClick={onSubmitForm(onSubmit)}
                modalId={ModalIds.WORKFLOW_TASK_FORM}
                mainButtonText={isNewTask ? 'Add' : 'Update'}
                addContinue={isNewTask}
                onClickAddContinue={onSubmitForm(onSubmitContinue)}
              />
            </>
          );
        }}
      </Form>
    </ModalView>
  );
};

export default WorkflowTaskForm;
