import RenderIf from '@components/common/render-if';
import Button from '@components/general/button';
import {
  ArchiveIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  TruckIcon,
  UsersIcon,
  XIcon
} from '@heroicons/react/outline';
import { SearchIcon } from '@heroicons/react/solid';
import useOnClickOutside from '@hooks/use-outside-click';
import { useSearchQuery } from '@services/api/search/search-all-records';
import { SearchRecords } from '@services/types';
import { useStore } from '@store/useStore';
import Link from 'next/link';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import BeatLoader from 'react-spinners/BeatLoader';
import { useDebounce } from 'use-debounce';

interface Props {
  children?: ReactNode;
}

const Search: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const inputRef = useRef();

  const [searchTerm, setSearchTerm] = useState('');
  const [isOnFocus, setIsOnFocus] = useState(false);
  const [searchResults, setSearchResults] = useState([] as SearchRecords[]);
  const [isSearching, setIsSearching] = useState(false);
  const setSearchState = useStore((state) => state.setSearchState);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { mutateAsync: fetchRecords, isLoading } = useSearchQuery();
  const queryClient = useQueryClient();

  const removePageBlur = () => {
    setSearchState({ focus: false });
    // @ts-ignore
    if (inputRef?.current) inputRef.current?.blur();
  };

  const resetStates = () => {
    setIsSearching(false);
    setSearchTerm('');
    setOpen(false);
    setIsOnFocus(false);
    setTimeout(() => {
      removePageBlur();
    }, 150);
  };

  const fetchData = useCallback(async () => {
    try {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        setSearchTerm('');
        setSearchTerm(debouncedSearchTerm);

        const { data } = await fetchRecords(debouncedSearchTerm);

        setSearchResults(data);
        setIsSearching(false);
        setOpen(true);
      }
    } catch (error) {
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  useEffect(
    () => {
      fetchData();
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (isOnFocus) {
      setSearchState({ focus: true });
    }
  }, [isOnFocus]);

  const iconMap = {
    contact: UsersIcon,
    document: DocumentTextIcon,
    'generated document': DocumentTextIcon,
    shipment: TruckIcon,
    product: ArchiveIcon,
    task: CheckCircleIcon
  };

  useEffect(() => {
    // keyboard commands
    const handleKeyDown = (event) => {
      // open search bar
      if (event.ctrlKey && event.key === '/') {
        event.preventDefault();
        setIsOnFocus(true);
        // @ts-ignore
        if (inputRef?.current) inputRef.current?.focus();
      }

      // close search bar
      if (event.key === 'Escape') {
        event.preventDefault();
        resetStates();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      /*removes event listener on cleanup*/
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useOnClickOutside(ref, () => {
    resetStates();
    queryClient.cancelMutations();
  });

  return (
    <div ref={ref} className="flex flex-row items-center w-[508px] z-100">
      <div className="w-full">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>

          <div className="flex">
            <input
              ref={inputRef}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`block w-full pl-10 pr-3 py-2 border border-common-stroke bg-primary-grey-50 text-primary-navy-400 placeholder-gray-400 focus:outline-none focus:bg-white rounded-md text-sm placeholder:text-sm transition duration-150 ease-in-out ${
                searchTerm ? 'pr-8' : ''
              }`}
              placeholder={
                isSearching
                  ? 'Searching ...'
                  : 'Search for shipments, documents, products and contacts'
              }
              onFocus={() => setIsOnFocus(true)}
              onBlur={() => setIsOnFocus(false)}
              value={searchTerm}
            />

            <RenderIf isTrue={isLoading}>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <BeatLoader color="#1E1E1E" size={5} />
              </div>
            </RenderIf>

            <RenderIf isTrue={searchTerm && !isLoading}>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <Button
                  disabled={isSearching}
                  onClick={() => setSearchTerm('')}
                  className="h-4 w-4 ml-1 border-transparent rounded-full focus:outline-none"
                  icon={
                    <XIcon className={`h-4 w-4 ${isOnFocus ? 'text-gray-500' : 'text-gray-300'}`} />
                  }
                  defaultClass={false}
                />
              </div>
            </RenderIf>
          </div>
          <div className="absolute right-0 mt-1 z-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none text-sm text-primary-navy-400 max-h-[26rem] overflow-y-auto">
            {open &&
              searchResults.length > 0 &&
              searchResults.map((searchResult, index) => {
                const Icon = iconMap[searchResult.category];
                return (
                  <div
                    key={index}
                    className={`w-[508px] z-50 px-4 hover:bg-primary-grey-50 ${
                      index === 0 ? 'rounded-t-md' : ''
                    } ${index === searchResults.length - 1 ? 'rounded-b-md' : ''}`}
                  >
                    <div className="border-b py-2 border-primary-grey-100">
                      <div className="rounded-lg overflow-hidden">
                        <div className="z-20 relative grid gap-6">
                          <Link href={searchResult.redirect_url || ''}>
                            <a
                              className="flex justify-between items-center group shadow-sm items-start space-x-4 transition ease-in-out duration-150"
                              onClick={removePageBlur}
                            >
                              <div className="flex flex-row items-center gap-3 flex-1">
                                <RenderIf isTrue={Icon}>
                                  <Icon className="h-4 w-4 text-primary-grey-500" />
                                </RenderIf>
                                <div className="flex flex-col truncate">
                                  <div className="mt-1.5 text-sm text-primary-navy-400">
                                    {searchResult.name}
                                  </div>
                                  <div className="text-xs text-primary-grey-500">
                                    {`${searchResult.category
                                      ?.charAt(0)
                                      ?.toLocaleUpperCase()}${searchResult.category?.slice(1)}`}
                                  </div>
                                </div>
                              </div>
                              <RenderIf isTrue={searchResult.redirect_url}>
                                <div className="flex w-12 hidden group-hover:block">
                                  <div className="mt-1.5 text-sm text-primary-grey-500">Select</div>
                                </div>
                              </RenderIf>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Search;
