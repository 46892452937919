import { toastNotification } from '@hooks/toast-notification';
import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

async function importCSV(inputs: any) {
  return http.post(`${API_ENDPOINTS.SHIPMENTS}/import_csv`, {
    import_data: JSON.stringify(inputs.import_data),
    shipment_type: inputs.shipment_type
  });
}

export const useImportShipmentCSVMutation = () => {
  return useMutation((inputs: any) => importCSV(inputs), {
    onSuccess: () => {
      toastNotification({
        type: 'success',
        description: 'Shipment data was successfully imported'
      });
    }
  });
};
