import Cookies from 'js-cookie';

export const setCookie = ({ name, value, expires = 7 }) => {
  removeCookie({ name });
  Cookies.set(name, value, { expires });
};

export const removeCookie = ({ name }) => {
  const cookie = getCookie({ name });

  if (cookie) {
    Cookies.remove(name);
  }
};

export const getCookie = ({ name }: { name: string }) => {
  return Cookies.get(name);
};
