export const queryString = (params) => {
  if (!params) return '';

  let string = '';
  //remove empty key
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === null) {
      delete params[key];
    } else {
      string += `&${key}=${params[key]}`;
    }
  });

  string = string.substring(1);

  return `${string.length > 0 ? `?${string}` : ''}`;
};
