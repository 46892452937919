import Button, { ButtonPropsType } from '@components/general/button';
import useOnClickOutside from '@hooks/use-outside-click';
import cn from 'classnames';
import React, { FC, ReactChildren, ReactElement, ReactNode, RefObject } from 'react';
import DropdownList, { DropdownListProps } from './dropdown-list';

interface Props {
  children: ReactChildren | ReactNode;
  isOpen: boolean;
  onOpen: (open: boolean) => void;
  target: RefObject<HTMLDivElement>;
  list?: DropdownListProps['data'];
  clickOnChildList?: DropdownListProps['onClick'];
  hidden?: boolean;
  className?: string;
}

export const DropdownButton: FC<ButtonPropsType> = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

export const DropdownBox = ({
  children,
  className,
  align = 'bottom',
  ref,
  ...props
}: {
  className?: string;
  align?: 'top' | 'bottom';
  children?: any;
  ref?: any;
}) => {
  const containerClass = cn(
    'origin-top-right absolute right-0 w-full rounded-md bg-white focus:outline-none z-20',
    {
      [className]: !!className,
      'bottom-[2rem] mb-2': align === 'top',
      'mt-2': align === 'bottom'
    }
  );
  return (
    <div className={containerClass} ref={ref} {...props}>
      {children}
    </div>
  );
};

export const Dropdown: FC<Props> = ({
  children,
  isOpen,
  onOpen,
  target,
  hidden = false,
  className,
  list,
  clickOnChildList
}) => {
  const DropdownButton = React.Children.toArray(children)[0] as ReactElement<ButtonPropsType>;
  const DropdownBox = React.Children.toArray(children)[1];

  useOnClickOutside(target, () => {
    if (isOpen) onOpen(false);
  });

  if (hidden) return <></>;

  const onClickWrapper = () => {
    DropdownButton.props.onClick;
    onOpen(!isOpen);
  };

  return (
    <div ref={target} className={`relative ${className}`}>
      <div>
        {DropdownButton && (
          <Button
            key={DropdownButton.key}
            defaultClass={false}
            {...DropdownButton.props}
            onClick={onClickWrapper}
          >
            {DropdownButton.props.children}
          </Button>
        )}
      </div>
      {isOpen && !list && <>{DropdownBox}</>}
      {isOpen && list && (
        <>
          <DropdownList data={list} onClick={clickOnChildList} />
        </>
      )}
    </div>
  );
};
