import { User } from '@services/types';
import { useQuery } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

export const fetchUser = async () => {
  const { data } = await http.get(`${API_ENDPOINTS.ACCOUNTS}`);
  return { user: data as User };
};

const getUser = (options = {}) => {
  return useQuery<{ user: User }, Error>([API_ENDPOINTS.ACCOUNTS], fetchUser, options);
};

export default getUser;
