import { toastNotification } from '@hooks/toast-notification';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
const isDev = process.env.NODE_ENV === 'development' ? true : false;

export const ReactQueryOptions = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query) => {
      const status = error.response?.status;

      // only show error if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined && status !== 422) {
        toastNotification({ type: 'error', description: `Something went wrong` });
      }

      if (
        status === 401 &&
        error.response.data.error ===
          'Your login credentials were used in another browser. Please sign in again to continue in this browser.'
      ) {
        return (window.location.href = '/logged_in_elsewhere');
      }

      if (status >= 500) {
        if (isDev) window.alert(`Error: ${status}`);
        if (!isDev) {
          return (window.location.href = '/error');
        }
      }

      if (status === 404) {
        return (window.location.href = '/404');
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: (error: any) => {
      console.error(error);
      const status = error.response?.status;

      if (
        status === 401 &&
        error.response.data.error ===
          'Your login credentials were used in another browser. Please sign in again to continue in this browser.'
      ) {
        return (window.location.href = '/logged_in_elsewhere');
      }

      if (status >= 500) {
        if (isDev) window.alert(`Error: ${status}`);
        if (!isDev) {
          return (window.location.href = '/error');
        }
      }

      if (status === 404) {
        return (window.location.href = '/404');
      }
    }
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) =>
        !isDev &&
        failureCount < 2 &&
        !(
          error.response.data.error ===
            'Your login credentials were used in another browser. Please sign in again to continue in this browser.' &&
          error.response.status === 401
        ) // when failure, retries 3 times
    }
  }
});
