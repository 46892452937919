import Typography from '@components/dataDisplay/typography';
import Form from '@components/dataEntry/form';
import FullInput from '@components/dataEntry/FullInput';
import { useMutationSideEffects } from '@hooks/use-mutation-side-effect';
import useRequest from '@services/api';
import { API_ENDPOINTS } from '@services/api/utils/api-endpoints';
import { createYupSchemaAllFieldsRequired } from '@utils/create-yup-schema';
import { ShipmentInputsMappedObject } from 'constants/ShipmentInputsMappedObject';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import * as yup from 'yup';

const RequestedFields = ({ data }) => {
  const [errorsServerValidation, setErrorsServerValidation] = useState();
  const queryClient = useQueryClient();

  const { data: shipmentData } = useRequest.shipments.getById({
    id: data.shipment_id
  });
  const { mutateAsync: updateShipment } = useRequest.shipments.update({ id: data.shipment_id });
  const { data: fieldsData } = useRequest.field_data.getByEntityType({
    params: { entity_type: 'Shipment' }
  });
  const inputs = fieldsData?.filter((x) => data.requested_data.find((y) => x.id === y.value));

  const { handleSubmit } = useMutationSideEffects({
    setErrorsServerValidation,
    onSubmit: async (values) => {
      await updateShipment(values, {
        onSuccess: async () => {
          await queryClient.resetQueries([API_ENDPOINTS.GET_SHIPMENT(data.shipment_id)]);
        }
      });
    }
  });

  const methods = useForm({
    defaultValues: shipmentData
  });

  useEffect(() => {
    methods.reset(shipmentData);
  }, [shipmentData]);

  const validationSchema = yup.object({
    ...inputs?.reduce(createYupSchemaAllFieldsRequired, {})
  });

  return (
    <Form
      methods={methods}
      errorsServerValidation={errorsServerValidation}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      formId="requested-fields-form"
    >
      <Typography className="text-primary-grey-500">Requested Information</Typography>
      <div className="grid gap-3 mt-4">
        {inputs?.map((x) => (
          <FullInput
            key={x.label}
            labelClass="pb-2"
            name={ShipmentInputsMappedObject[x.label]?.name || x.label}
            label={x.label}
            isRequired
            type={ShipmentInputsMappedObject[x.label]?.type || x.type.toLowerCase()}
            {...(ShipmentInputsMappedObject[x.label] && { ...ShipmentInputsMappedObject[x.label] })}
          />
        ))}

        <button
          type="submit"
          className="hidden"
          id="requested-fields-form-click"
          form="requested-fields-form"
        >
          Submit
        </button>
      </div>
    </Form>
  );
};

export default RequestedFields;
