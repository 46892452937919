import Input from '@components/dataEntry/input';
import ModalView from '@components/feedback/modal-view';
import Button from '@components/general/button';
import useModal from '@hooks/use-modal';
import { useStore } from '@store/useStore';
import { useRef, useState } from 'react';

const UserInviteLinkModal = () => {
  const [text, setText] = useState('Copy');
  const inviteLink = useStore((state) => state.inviteLink);
  const { closeModalByPosition } = useModal();
  const ref = useRef();

  const Footer = () => (
    <div
      id="modal-footer"
      className="flex flex-row-reverse text-right w-full px-4 py-3 border-t border-gray-200 bg-white"
    >
      <Button onClick={closeModalByPosition} className="text-primary-grey-500 text-xs">
        Close
      </Button>
    </div>
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    if (ref.current) {
      // @ts-ignore
      ref.current.focus();
      // @ts-ignore
      ref.current.select();
      setText('Copied');
    }
  };

  return (
    <ModalView header="Invitational URL" footer={<Footer />}>
      <div>
        <div className="mt-1 flex rounded-md shadow-sm">
          <div className="relative flex items-stretch flex-grow focus-within:z-10">
            <Input
              readOnly
              inputRef={ref}
              value={inviteLink}
              autoFocus
              type="text"
              className="form-input w-full"
            />
          </div>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <button onClick={copyToClipboard}>
              <span>{text}</span>
            </button>
          </button>
        </div>
      </div>
    </ModalView>
  );
};

export default UserInviteLinkModal;
