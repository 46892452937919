import Modal from '@components/feedback/modal';
import useModal from '@hooks/use-modal';
import { useStore } from '@store/useStore';
import { ModalIds } from 'constants/modal-ids';
import AddTrackingToShipmentForm from './forms/AddTrackingToShipmentForm';
import UploadFilesModal from './forms/UploadProductFilesModal';
import AddPaymentToShipmentForm from './forms/add-payment-to-shipment-form';
import AddShipmentForm from './forms/add-shipment-form';
import AddSignatureForm from './forms/add-signature-form';
import AddStampForm from './forms/add-stamp-form';
import BulkAssignTaskForm from './forms/bulk-assign-task-form';
import ContactForm from './forms/contact-form';
import ContactMasterForm from './forms/contact-master-form';
import EditDueDateForm from './forms/edit-due-date-form';
import EditTotalForm from './forms/edit-total-form';
import GeneratedDocumentAddShipmentForm from './forms/generate-document-add-shipment-form';
import GeneratedDocumentForm from './forms/generate-document-form';
import GeneratedDocumentShareForm from './forms/generate-document-share-form';
import PackingForm from './forms/packing-form';
import ProductForm from './forms/product-form';
import ShipmentDetailShareForm from './forms/shipment-detail-share-form';
import ShipmentPaymentForm from './forms/shipment-payment-form';
import ShipmentProductForm from './forms/shipment-product-form';
import TrackingForm from './forms/tracking-form';
import UploadDocumentForm from './forms/upload-document-form';
import UserForm from './forms/user-form';
import WorkflowForm from './forms/workflow-form';
import WorkflowTaskForm from './forms/workflow-task-form';
import CropProfileImageModal from './modals/CropProfileImageModal';
import AddCustomFieldModal from './modals/add-custom-field-modal';
import Edit from './portals/portalsBody/Task/Edit';
import ShipmentUploadModal from './shipment-upload-modal';
import UserInviteLinkModal from './user-invite-link-modal';

const internalModals = [
  ModalIds.ADD_PRODUCT_TO_SHIPMENT_DOCUMENT_VIEW,
  ModalIds.LOST_CHANGES_DOCUMENT_VIEW,
  ModalIds.PRODUCT_FORM_DOCUMENT,
  ModalIds.SAVE_DOCUMENT_MODAL,
  ModalIds.ADD_PACKAGE_TO_SHIPMENT_DOCUMENT_VIEW
];

const isDocumentModal = (modalState) => modalState.find((item) => internalModals.includes(item.id));

const ModalWrapper = () => {
  const modalState = useStore((state) => state.modalState);
  const isModalOpen = modalState.some((modal) => modal.isOpen);
  const inlineEditable = modalState?.[0]?.inlineEditable;
  const { getModalStateById } = useModal();

  // do not allow opened a modal that needs document form state to work
  if (isDocumentModal(modalState)) {
    return <></>;
  }

  const isModalView = (id: ModalIds) => {
    const modal = getModalStateById({ id });
    const isOpen = modal?.isOpen;
    const isHide = modal?.hide;

    return !isHide && isOpen;
  };

  return (
    <>
      <Modal open={isModalOpen} inlineEditable={inlineEditable}>
        {isModalView(ModalIds.CONTACT_FORM) && <ContactForm />}
        {isModalView(ModalIds.PRODUCT_FORM) && <ProductForm />}
        {isModalView(ModalIds.INVITE_LINK_FORM) && <UserInviteLinkModal />}
        {isModalView(ModalIds.SHIPMENT_UPLOAD_MODAL) && <ShipmentUploadModal />}
        {isModalView(ModalIds.GENERATE_DOCUMENT_FORM) && <GeneratedDocumentForm />}
        {isModalView(ModalIds.GENERATE_DOCUMENT_ADD_SHIPMENT_FORM) && (
          <GeneratedDocumentAddShipmentForm />
        )}
        {isModalView(ModalIds.UPLOAD_DOCUMENT_FORM) && <UploadDocumentForm />}
        {isModalView(ModalIds.GENERATE_DOCUMENT_SHARE_FORM) && <GeneratedDocumentShareForm />}
        {isModalView(ModalIds.TASK_FORM) && <Edit />}
        {isModalView(ModalIds.BULK_ASSIGN_TASKS_FORM) && <BulkAssignTaskForm />}
        {isModalView(ModalIds.WORKFLOW_FORM) && <WorkflowForm />}
        {isModalView(ModalIds.TRACKING_FORM) && <TrackingForm />}
        {isModalView(ModalIds.EDIT_TOTAL_FORM) && <EditTotalForm />}
        {isModalView(ModalIds.EDIT_DUE_DATE_FORM) && <EditDueDateForm />}
        {isModalView(ModalIds.ADD_PAYMENT_TO_SHIPMENT_FORM) && <AddPaymentToShipmentForm />}
        {isModalView(ModalIds.SHIPMENT_PAYMENT_FORM) && <ShipmentPaymentForm />}
        {isModalView(ModalIds.SHIPMENT_DETAIL_SHARE_FORM) && <ShipmentDetailShareForm />}
        {isModalView(ModalIds.SHIPMENT_PRODUCT_FORM) && <ShipmentProductForm />}
        {isModalView(ModalIds.PACKING_FORM) && <PackingForm />}
        {isModalView(ModalIds.WORKFLOW_TASK_FORM) && <WorkflowTaskForm />}
        {isModalView(ModalIds.CONTACT_MASTER_FORM) && <ContactMasterForm />}
        {isModalView(ModalIds.ADD_STAMP_FORM) && <AddStampForm />}
        {isModalView(ModalIds.ADD_SIGNATURE_FORM) && <AddSignatureForm />}
        {isModalView(ModalIds.ADD_SHIPMENT_FORM) && <AddShipmentForm />}
        {isModalView(ModalIds.ADD_CUSTOM_FIELD) && <AddCustomFieldModal />}
        {isModalView(ModalIds.CROP_PROFILE_IMAGE_MODAL) && <CropProfileImageModal />}
        {isModalView(ModalIds.ADD_TRACKING_TO_SHIPMENT_FORM) && <AddTrackingToShipmentForm />}
        {isModalView(ModalIds.USER_FORM) && <UserForm />}
        {isModalView(ModalIds.UPLOAD_PRODUCT_FILES_MODAL) && <UploadFilesModal />}
      </Modal>
    </>
  );
};

export default ModalWrapper;
