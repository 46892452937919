import { useMutation } from 'react-query';
import { API_ENDPOINTS } from '../utils/api-endpoints';
import http from '../utils/http';

const importContactsMutation = () => {
  return useMutation((inputs: any) =>
    http.post(`${API_ENDPOINTS.IMPORT_CONTACTS}`, { import_data: JSON.stringify(inputs) })
  );
};

export default importContactsMutation;
