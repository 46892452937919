import { CurrencyNumberFormatMap } from 'constants/currency';

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const formatter = ({ currency }) =>
  new Intl.NumberFormat(CurrencyNumberFormatMap[currency], {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2
  });

export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertToNumber(string) {
  return Number(string?.replace(/[^0-9.]/g, ''));
}

export function toCurrencyString(value, currency) {
  const currencyFormat = formatter({ currency });
  return currencyFormat.format(value);
}
